
/** Angular imports */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonNgbdModalContent } from './common-modal';
import { CommonExtendTime } from './extend-time.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// Custom imports

@NgModule({
  imports: [CommonModule, NgbModule],
  declarations: [CommonNgbdModalContent],
  exports: [],
  entryComponents: [
    CommonNgbdModalContent
]
})
export class CommonUtilitiesModule {

    static forRoot() {
        return {
            ngModule: CommonUtilitiesModule,
            providers : [CommonExtendTime],
        };
    }
}
