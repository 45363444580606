<p>{{(relatedcareers)?.RelatedCareers?.intro}}</p>
<div class="row gutter-10" *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers") != -1'>
  <div class="col-lg col-md col-12">
    <div class="career-card cardcolor-25">
      <div class="career-card-header">
        <div class="card-title">
          <i class="icon-careers panal-icon-align" aria-hidden="true"></i> {{(relatedcareers)?.RelatedCareers?.lists[0]?.header}}
        </div>

      </div>
      <div class="card-block career-card-body scroll-class">
        <div *ngFor="let item of (relatedcareers)?.RelatedCareers?.lists[0]?.links">
          <ul class="list pr-4" *ngIf="item.linkTitle">
            <!-- CallOccDetailCareer method clicking the list in this div -->
            <li>
              <div class="pointer">
                <a href="#" (click)="$event.preventDefault();CallOccDetailCareer(item.linkID,item.linkTitle,relatedCareers.clusIcon,relatedCareers.clusColor)">
                  {{item.linkTitle}}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- clusicon and clusColor for getting the icon and color dynamically -->
  <div class="col-lg col-md col-12">
    <div class="career-card" [ngStyle]="{'background':clusterColor}">
      <div class="career-card-header">
        <div class="card-title">
          <i class="{{clusterIcon}} panal-icon-align" aria-hidden="true"></i> {{(relatedcareers)?.RelatedCareers?.lists[1]?.header}}</div>

      </div>
      <div class="card-block career-card-body scroll-class">
        <div *ngFor="let item of (relatedcareers)?.RelatedCareers?.lists[1]?.links">
          <ul class="list pr-4" *ngIf="item.linkTitle">
            <!-- CallOccDetailCluster method clicking the list in the clusters div -->
            <li>
              <div class="pointer">
                <a href="#" (click)="$event.preventDefault();CallOccDetailCluster(item.linkID,item.linkTitle,clusterIcon,clusterColor)">
                  {{item.linkTitle}}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- showMilataryCareer variable for not displaying the div when the data is not available -->
  <div class="col-lg col-md col-12" *ngIf='(settingsTextTab?.sections).indexOf("RelatedMilitary")!= -1 && showMilataryCareer'>
    <div class="career-card cardcolor-7">
      <div class="career-card-header">
        <div class="card-title">
          <i class="icon-military-careers panal-icon-align" aria-hidden="true"></i> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedMilitary}}
        </div>

      </div>
      <div class="card-block career-card-body scroll-class">
        <div *ngFor="let item of (relatedcareers)?.RelatedMilitary;">
          <ul class="list pr-4" *ngIf="item.linkTitle">
            <li>
              <a href="#" class="pointer" (click)="$event.preventDefault(); CallMilitaryCareer(item.linkID,item.linkTitle)">{{item.linkTitle}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- OCC-EMERG Print Start -->
<div [hidden]="!printview">
  <div class="print-result-page" #relatedCareerPrint>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
          <div class="icon-occresize-sprite"></div>
        <span class="title-wraps pl-1 pt-2">{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" [src]="logo" >
      </div>
    </div>
    <div class="main-body">
      <div class="careers-title">{{tabTitle}}</div>
      <p>{{(relatedcareers)?.RelatedCareers?.intro}}</p>
      <div *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers") != -1'>
        <div class="career-card cardcolor-25">
          <div class="career-card-header font-weight-bold">
            <div class="icon-careers-sprite mr-2"></div><span>{{(relatedcareers)?.RelatedCareers?.lists[0]?.header}}</span>
          </div>
        </div>
        <div *ngFor="let item of (relatedcareers)?.RelatedCareers?.lists[0]?.links">
          <ul class="list-unstyled mb-0 pl-4"  *ngIf="item.linkTitle">
            <li> 
              <div class="headding-arrow pl-1 mb-1">{{item.linkTitle}}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="career-card" [ngStyle]="{'background':clusterColor}">
        <div class="career-card-header font-weight-bold">
          <div class="{{clusterIcon}}-sprite mr-2"></div><span>{{(relatedcareers)?.RelatedCareers?.lists[1]?.header}}</span>
        </div>
      </div>
      <div *ngFor="let item of (relatedcareers)?.RelatedCareers?.lists[1]?.links">
        <ul class="list-unstyled mb-0 pl-4" *ngIf="item.linkTitle">
          <li>
            <div class="headding-arrow pl-1 mb-1">{{item.linkTitle}}</div>
          </li>
        </ul>
      </div>
      <div *ngIf='(settingsTextTab?.sections).indexOf("RelatedMilitary") != -1 && showMilataryCareer'>
        <div class="career-card cardcolor-7">
          <div class="career-card-header font-weight-bold">
            <div class="icon-military_careers-sprite mr-2"></div><span>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedMilitary}}</span>
          </div>
        </div>
        <div *ngFor="let item of (relatedcareers)?.RelatedMilitary">
          <ul class="list-unstyled mb-0 pl-4"  *ngIf="item.linkTitle">
            <li>
              <div class="headding-arrow pl-1 mb-1">{{item.linkTitle}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!-- OCC-EMERG Print End -->