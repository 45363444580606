<p *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers")!= -1'>{{(occCareerStore).RelatedCareers?.intro}}</p>
<div class="row gutter-10" *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers")!= -1'>
  <!-- Display  Careers card with list of careers -->
  <div class="col-lg col-md col-12">
    <div class="career-card cardcolor-25">
      <div class="career-card-header">
        <div class="card-title">
          <i class="icon-careers  panal-icon-align" aria-hidden="true"></i> {{(occCareerStore)?.RelatedCareers?.lists[0]?.header}}
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <ul class="list">
          <li *ngFor="let val of (occCareerStore)?.RelatedCareers?.lists[0]?.links">
            <a href="#" class="pointer" (click)="$event.preventDefault();CallOccDetailCareer(val.linkID,val.linkTitle)">{{val.linkTitle}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Display  cluster card with cluster link -->
  <div class="col-lg col-md col-12">
    <div class="career-card" [ngStyle]="{'background':(occCareerStore).ParentCluster?.parentClusterColor}">
      <div class="career-card-header">
        <div class="card-title">
          <i class="{{(occCareerStore).ParentCluster?.parentClusterIcon}}  panal-icon-align" aria-hidden="true"></i> {{(occCareerStore)?.RelatedCareers?.lists[1]?.header}}
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <ul class="list">
          <li *ngFor="let value of (occCareerStore)?.RelatedCareers?.lists[1]?.links">
            <a href="#" class="pointer" (click)="$event.preventDefault();CallOccDetailCluster(value.linkID,value.linkTitle)">{{value.linkTitle}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Display  military card -->

 <div class="col-lg col-md col-12" *ngIf='((settingsTextTab?.sections).indexOf("RelatedMilitary")!= -1) && showMilataryCareer'>
    <div class="career-card cardcolor-7">
      <div class="career-card-header">
        <h5 class="card-title">
          <i class="icon-military-careers  panal-icon-align" aria-hidden="true"></i> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedMilitary}}
        </h5>

      </div>
      <div class="card-block career-card-body scroll-class">
        <ul class="list">
          <li *ngFor="let val of (occCareerStore).RelatedMilitary;">
            <a href="#" class="pointer" (click)="$event.preventDefault();CallMilitaryCareer(val.linkID,val.linkTitle)">{{val.linkTitle}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<br>
<!-- Display related jobs -->
<div *ngIf='(settingsTextTab?.sections).indexOf("FindJobs")!= -1'>
  <div class="header_5">
    <b *ngIf="(occCareerStore).FindJobs?.length!=0">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.FindJobs}}</b>
  </div>
  <div *ngFor="let jobValue of (occCareerStore).FindJobs;let jobinx=index;">
    <div class="inner">
      <p *ngIf="jobValue.type =='p'">{{jobValue.text}}</p>
      <div class='common-heading font-weight-bold' *ngIf="(jobValue.type == 'h')"
        [innerHtml]="jobValue.text | MarkdownToLink">
      </div>
      <ul *ngIf="(jobValue.type =='ul')" class="list-unstyled p-0">
        <li class="findjobs-text" *ngFor="let jobval of jobValue.text;" [innerHTML]='jobval | MarkdownToLink'>
          <i class="fa fa-chevron-right arrow-align-plp3" aria-hidden="true"></i>&nbsp;&nbsp;
        </li>
      </ul>
    </div>
  </div>
</div>







 <!-- Print Start Related Careers -->

<!-- Start Header -->
<div class="hidden-page">
  <div class="print-result-page" #RelatedCarees> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-occresize-sprite"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{logo}}"> 
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div> 
      <p *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers")!= -1'>{{(occCareerStore).RelatedCareers?.intro}}</p>
      <div class="row gutter-10" *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers")!= -1'>
          <!-- Display  Careers card with list of careers -->
          <div class="col-12">
            <div class="career-card ">
              <div class="cardcolor-25"> 
              <div class="career-card-header">
                <div class="card-title pre">
                  <div class="icon-careers-sprite panal-icon-align" aria-hidden="true"></div> {{(occCareerStore)?.RelatedCareers?.lists[0]?.header}}
                </div>
              </div>
            </div>
              <div class="card-block">
                <ul class="list-unstyled text-dark">
                  <li class="text-dark" *ngFor="let val of (occCareerStore)?.RelatedCareers?.lists[0]?.links">
                    <div class="text-dark headding-arrow pl-2">{{val.linkTitle}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Display  cluster card with cluster link -->
          <div class="col-12">
            <div class="career-card">
              <div [ngStyle]="{'background':(occCareerStore).ParentCluster?.parentClusterColor}"> 
              <div class="career-card-header">
                <div class="card-title pre">                  
                  <div class="icon-clu_agriculture-sprite panal-icon-align" aria-hidden="true"></div>
                  {{(occCareerStore)?.RelatedCareers?.lists[1]?.header}}
                </div>
              </div>
            </div>
              <div class="card-block">
                <ul class="list-unstyled pr-4 text-dark">
                  <li class="text-dark" *ngFor="let value of (occCareerStore)?.RelatedCareers?.lists[1]?.links">
                    <div class="text-dark headding-arrow pl-2">{{value.linkTitle}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>          
          <!-- Display  military card -->        
         <div class="col-12" *ngIf='((settingsTextTab?.sections).indexOf("RelatedMilitary")!= -1) && showMilataryCareer'>
            <div class="career-card">
              <div class="cardcolor-7"> 
              <div class="career-card-header">
                <h5 class="card-title pre">
                  <div class="icon-military_careers-sprite  panal-icon-align" aria-hidden="true"></div> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedMilitary}}
                </h5>
              </div>
            </div>
              <div class="card-block">
                <ul class="list-unstyled">
                  <li class="text-dark" *ngFor="let val of (occCareerStore).RelatedMilitary;">
                    <div class="headding-arrow pl-2">{{val.linkTitle}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="html2pdf__page-break"></div>
        <!-- Display related jobs -->
         <div *ngIf='(settingsTextTab?.sections).indexOf("FindJobs")!= -1'>
          <div class="header_5 mt-2">
            <b *ngIf="(occCareerStore).FindJobs?.length!=0">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.FindJobs}}</b>
          </div>
          <div *ngFor="let jobValue of (occCareerStore).FindJobs;let jobinx=index;">
            <div class="inner">
              <p *ngIf="jobValue.type =='p'">{{jobValue.text}}</p>
              <ul *ngIf="(jobValue.type =='ul')" class="list-unstyled p-0">
                <li class="findjobs-text text-secondary" *ngFor="let jobval of jobValue.text;" [innerHTML]='jobval | customText'>
                  <i class="fa fa-chevron-right arrow-align-plp3" aria-hidden="true"></i>&nbsp;&nbsp;
                </li>
              </ul>
            </div>
          </div>
        </div> 
    </div>
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!-- Print Start Related Careers -->