<div class="career-list" id='occ-list-id'>
  <div class="row align-items-center py-2">
    <div class="col-md-4 col-lg-4">
      <!-- Display career list header -->
      <div class="common-headding" #occIndexFocus>
      <!-- When the Careers list is sorted by Cluster, it displays "Career Clusters List" and sorted by Title, it displays "Careers List" -->
      <span *ngIf="search.clusterOrTitle == 0; else clustersHeader"><img alt="occresize" src="{{LandingImages}}favorite-cluster.png" />
        <span>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersClusterList}}</span>
      </span>
        <ng-template #clustersHeader><span><img alt="occresize" src="{{CareersImages}}occresize.png" /></span>
        <span class="title-wraps">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersList}}</span>
        </ng-template>
      </div>
    </div>
    <div class="col-md-8 col-lg-8 text-right">
      <div class="btn-group-plp3 btn-group-justified">
        <!-- Display Back to Assessment button -->
        <button type="button" [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.backAssess'
          *ngIf="backAssessmentValue" class="common-button green ml-0" (click)="backAssessment()">
          <b>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.backAssess}}</b>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!hideBannerImage" id="occ-img" class="p-b">
    <img src="" class="w-100" alt="" src='{{CareersImages}}career-index.jpg'/>
  </div>
  <button class="action-button occ-list-arrow pointer" *ngIf='showIcon' (click)='scrollTop()'>
    <span class="fa fa-arrow-up fa-2x"></span>
  </button>
  <div #scrollUp class="invisible">scrollUp</div>
  <div class="fixScreen0 row d-flex align-items-flex-end"
    [ngClass]="(filtericon[iconInxVal]=='fa fa-times' && filterIconStatus.length==0) ? 'pb-5':'pb-2'" id='fixScreenid'>
    <ng-template #tipContentTitle>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.titleHover}}</ng-template>
    <div class="col-12 col-md-7 col-lg-7 col-sm-12 switch-button-align mt-3">


      <!-- Display cluser and career buttons  -->

      <div class="header_6 mb-1">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.view}}</div>
      <ng-template #tipContentCluster>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.clusterHover}}
      </ng-template>



      <div class="btn-group btn-toggle btn-group-styles tooltip-id mr-1">
        <button class="btn-common"
          [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.cluster + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.clusterHover'
          [ngClass]="(search.clusterOrTitle === 0)?'foc-blue-bg-switch':'foc-white-bg-switch'" (click)="clusterListFun('cluster')"
          #ts11="ngbTooltip" (focus)='ts11.open()' (blur)='ts11.close()' [ngbTooltip]="tipContentCluster">
          {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.cluster}}
        </button>
        <button class="btn-common"
          [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.titleHover'
          [ngClass]="(search.clusterOrTitle === 1)?'foc-blue-bg-switch':'foc-white-bg-switch'" (click)="clusterListFun('title')"
          #ts12="ngbTooltip" (focus)='ts12.open()' (blur)='ts12.close()' [ngbTooltip]="tipContentTitle" placement="top">
          {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.title}}
        </button>

      </div>
      <!-- Display compare button -->

      <div class="btn-group mb-1 mb-md-0" triggers="hover">
        <div class="d-flex align-items-center" #ts14="ngbTooltip" (focus)='ts14.open()' (blur)='ts14.close()' [ngbTooltip]="tipContentcom" >
          <button class="common-button btn-primary1"
            [ngStyle]="(indexOccId.length < 2)  ? {'opacity':'0.5','pointer-events': 'none','cursor': 'pointer','border-radius':'6px'}:''"
            [disabled]="indexOccId.length < 2" (click)="CompareOccupations()">
            {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.compare}}
          </button>
          <div id="chips-design-label">
            <ul class="chips-design">
              <ng-template #tipContentcom>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.compare2Careers}}
              </ng-template>
              <li *ngFor="let i of indexTitleName;let inx = index">
                <div class="cluster-cmpar header_6">{{i}}
                    <a tabindex="0" aria-label="Remove-button" class="pointer filter-Removebtn m-0 p-0" data-dismiss="modal"
                      (keyup.enter)="$event.preventDefault();checkCareer(inx,indexOccId[inx],i,'click');ts14.close()"
                      (click)="$event.preventDefault();checkCareer(inx,indexOccId[inx],i,'click');ts14.close()">
                      <i aria-hidden>&times;</i>
                    </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <ng-template #tipContentcom>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.compare2Careers}}</ng-template>
      </div>
    </div>

    <br>
    <div class="col-12 col-md-5 col-lg-5 col-sm-12 pull-right mt-md-3">
      <div class="input-group">
        <!-- Display search box -->

        <input type="text" spellcheck="false" aria-label="Search for" class="form-control career-index-searchbox"
          (keyup)="changeText()" placeholder="Search for..." [(ngModel)]="search.text">
        <button aria-label="Search Icon" *ngIf='searchIcon==0||searchIcon==-1'
          class="btn-common career-index-searchicon" type="button">
          <i class="fa fa-search"></i>
        </button>
        <button aria-label="Close icon" *ngIf='searchIcon==1' (click)='dismissSearch()'
          class="btn-common career-index-searchicon pointer" type="button">
          <i class="fa fa-times" [ngStyle]='{"color":"red"}'></i>
        </button>
        <ng-template #tipContent><span aria-hidden="true" tabindex="-1">{{filterText}}</span></ng-template>

        <button aria-label="filter" *ngIf="filterpipeshow" #filterFocusDefault #t="ngbTooltip" (click)="showHideIcon()"
          class="career-index-filter-icon pointer ml-1 d-table" [attr.aria-expanded]="isCollapsed"
          aria-controls="collapseExample4"
          [ngStyle]="(searchIcon==1||showFilterChange==1)?{'background-color': '#29bd2f','border':'none'}:''"
          [ngbTooltip]="tipContent" (focus)='t1?.open()' (blur)='t1?.close()'>
          <i aria-hidden class="d-flex align-items-center justify-content-center" [ngClass]="filtericon[iconInxVal]"
            [ngStyle]="(searchIcon==1||showFilterChange==1)?{'color': 'white'}:''"></i>
        </button>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-md-end pt-1">
      <div class="d-flex col-md-5 pl-0" *ngIf="filterdownpipeshow"
        [ngClass]="filtericon[iconInxVal]=='fa fa-times'? 'fillter-search-pb':''">
        <div class="careers-filter">
          <button aria-label="filter" #focusFilter (click)="showHideIcon()"
            class="career-index-filter-icon pointer ml-1 d-block" [attr.aria-expanded]="isCollapsed"
            aria-controls="collapseExample4"
            [ngStyle]="(searchIcon==1||showFilterChange==1)?{'background-color': '#29bd2f','border':'none'}:''">
            <i class="d-flex align-items-center justify-content-center" [ngClass]="filtericon[iconInxVal]"
            [ngStyle]="(searchIcon==1||showFilterChange==1)?{'color': 'white'}:''"> 
            </i>
          </button>
          <span class="pl-1"> Filter On:</span>
        </div>
        <div class="d-flex flex-wrap">
          <div class="d-flex align-items-center pb-1 pr-1 pl-1" *ngFor="let item of filterIconStatus"
            [ngbTooltip]="item.hover ? tipFilterIconStatusHover : ''" #tsFilterIconStatusHover
            (blur)='tipFilterIconStatusHover.close()' (focus)='tipFilterIconStatusHover.open()'>
            <ng-template #tipFilterIconStatusHover>
              {{item?.hover}}
            </ng-template>
            <img height="24" width="24" alt="filter" class="float-left img-fit" src="{{item.image}}" />
            <span class="remove-filter-icon" tabindex="0" (keyup.enter)="removeFilter(item)" (keyup.space)="removeFilter(item)" (click)="removeFilter(item)"><i aria-hidden>&times;</i></span>
          </div>
        </div>
      </div>
    </div>
    <!-- Display card that contain options to filter list -->
    <div class="slider-drop-down w-100" id="collapseExample4" [ngbCollapse]="!isCollapsed" *ngIf="searchBox == false">
      <div class="col-12 col-md-12 col-lg-12 pb-5">
        <div class="card occ-index-fillter-height">
          <div class="card-block-slider-filter  pt-0 scroll-class" tabindex="-1">
            <div class="row border-bottom border-sm-none">
              <!-- User Favorite -->
              <div class="col-12 col-md-4 col-lg-4 border-right" 
                *ngIf="filterData.indexOf('user')>=0 && !restrictedAccountType"
                #tsUser="ngbTooltip" (focus)='tsUser.open()' 
                (blur)='tsUser.close()'
                [ngbTooltip]="tipUser" placement="top-left">
                <ng-template #tipUser>
                    {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user?.filterHover}}
                </ng-template>
                <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                data-toggle="collapse" href="#collapseFavorite"
                aria-controls="collapseFavorite"
                role="tablist">
                  <ul class="d-table p-0 pt-2">
                      <li class="d-table-cell align-middle">
                      <li class="d-table-cell">
                          <img alt="interest" src="{{CareersImages}}thumbs-up-hover.svg" />
                      </li>
                      <li class="d-table-cell align-middle">
                          <div id="favoriteDropdown" class="list-text-color header_5 ml-1" 
                            [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.user.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.user.filterHover' >
                            {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.user.title}}
                            <i *ngIf="search.rating.length===0" class="fa fa-angle-down ml-1"></i>
                          </div>
                      </li>
                  </ul>
                </button>
                <div id="collapseFavorite" class="collapse" role="tabpanel"
                aria-labelledby="favoriteDropdown" [ngbCollapse]="search.rating.length===0">
                    <ul class="list-unstyled pl-1 pl-md-0 mb-0"
                        *ngFor="let item of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.user.ratings;let indx = index">
                        <li *ngIf="item.id==1">
                            <div class="common-checkbox filter-block-checkbox">
                                <input id="{{item.id}}" type="checkbox" name="{{item.title}}"
                                    (click)='setFilterVal("rating",item.id)'
                                    [checked]="search.rating.indexOf(item.id)!=-1" />
                                <label for="{{item.id}}">
                                    <p>
                                        <span>
                                        </span>{{item.title}}
                                    </p>
                                </label>
                            </div>
                        </li>
                        <li *ngIf="item.id==0">
                            <div class="common-checkbox filter-block-checkbox">
                                <input id="{{item.id}}" type="checkbox" name="{{item.title}}"
                                    (click)='setFilterVal("rating",item.id)'
                                    [checked]="search.rating.indexOf(item.id)!=-1" />
                                <label for="{{item.id}}">
                                    <p>
                                        <span>
                                        </span>{{item.title}}
                                    </p>
                                </label>
                            </div>
                        </li>
                        <li *ngIf="item.id==-1">
                            <div class="common-checkbox filter-block-checkbox">
                                <input id="{{item.id}}" type="checkbox" name="{{item.title}}"
                                    (click)='setFilterVal("rating",item.id)'
                                    [checked]="search.rating.indexOf(item.id)!=-1" />
                                <label for="{{item.id}}">
                                    <p>
                                        <span class="span-notrate"></span>{{item.title}}
                                    </p>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
              </div>
              <!-- edulevel -->
              <div class="p-t-b border-right"
                [ngClass]="restrictedAccountType ? 'col-6' : 'col-lg-4 col-md-4 col-sm-12'"
                *ngIf="filterData.indexOf('edLevel')>=0"
                #tsEdLevel="ngbTooltip" (focus)='tsEdLevel.open()' 
                (blur)='tsEdLevel.close()'
                [ngbTooltip]="tipEdLevel" placement="top-left">
                <ng-template #tipEdLevel>
                  {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel?.filterHover}}
                </ng-template>
                <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                data-toggle="collapse" href="#collapseEdLevel"
                aria-controls="collapseEdLevel"
                role="tablist">
                  <ul class="d-table p-0 pt-2">
                      <li class="d-table-cell align-middle">
                      <li class="d-table-cell">
                          <img alt="education icon" src="{{CareersImages}}education.png" />
                      </li>
                      <li class="d-table-cell align-middle">
                          <div id="edLevelDropdown" class="list-text-color header_5 ml-1" 
                              [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.edLevel.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.edLevel.filterHover' >
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.edLevel.title}}
                              <i *ngIf="search.edu.length===0" class="fa fa-angle-down ml-1"></i>
                          </div>
                      </li>
                  </ul>
                </button>
                <div id="collapseEdLevel" class="collapse" role="tabpanel"
                aria-labelledby="edLevelDropdown" [ngbCollapse]="search.edu.length===0">
                    <ul class="list-unstyled pl-1 pl-md-0 mb-0"
                        *ngFor="let item of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.edLevel.ratings;let indx = index">
                        <li *ngIf="item.id !=-1">
                            <div class="common-checkbox filter-block-checkbox">
                                <input id="edu{{item.id}}" type="checkbox" name="{{item.title}}"
                                    (click)='setFilterVal("edu",item.id)' (keyup.enter)='setFilterVal("edu",item.id)'
                                    [checked]="search.edu.indexOf(item.id)!=-1" />
                                <label for="edu{{item.id}}">
                                    <p>
                                        <span>
                                        </span>{{item.title}}
                                    </p>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
              </div>
              <!-- wages -->
              <div class="col-12 col-md-4 col-lg-4 p-t-b"
                *ngIf="filterData.indexOf('wages')>=0"
                #tsWages="ngbTooltip" (focus)='tsWages.open()' 
                (blur)='tsWages.close()'
                [ngbTooltip]="tipWages" placement="top-left">
                <ng-template #tipWages>
                  {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages?.filterHover}}
                </ng-template>
                <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                data-toggle="collapse" href="#collapseWages"
                aria-controls="collapseWages"
                role="tablist">
                  <ul class="d-table p-0 pt-2">
                      <li class="d-table-cell align-middle">
                      <li class="d-table-cell">
                          <img alt="finance icon" src="{{CareersImages}}finance.png" />
                      </li>
                      <li class="d-table-cell align-middle">
                          <div id="wagesDropdown" class="list-text-color header_5 ml-1" 
                              [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.wages.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.wages.filterHover' >
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.wages.title}}
                              <i *ngIf="search.wages.length===0" class="fa fa-angle-down ml-1"></i>
                          </div>
                      </li>
                  </ul>
                </button>
                <div id="collapseWages" class="collapse" role="tabpanel"
                aria-labelledby="wagesDropdown" [ngbCollapse]="search.wages.length===0">
                    <ul class="list-unstyled pl-1 pl-md-0 mb-0"
                        *ngFor="let item of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.wages.ratings;let indx = index">
                        <li *ngIf="item.id !=-1">
                            <div class="common-checkbox filter-block-checkbox">
                                <input id="wages{{item.id}}" type="checkbox" name="{{item.title}}"
                                    (click)='setFilterVal("wages",item.id)' (keyup.enter)='setFilterVal("wages",item.id)'
                                    [checked]="search.wages.indexOf(item.id)!=-1" />
                                <label for="wages{{item.id}}">
                                    <p>
                                        <span>
                                        </span>{{item.title}}
                                    </p>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
            <div class="row border-bottom">
              <!-- Hot Jobs-->
              <div class="col-12 col-md-4 col-lg-4 p-t-b border-right"
                *ngIf="filterData.indexOf('hotJobs')>=0"
                #tsHotJobs="ngbTooltip" (focus)='tsHotJobs.open()' 
                (blur)='tsHotJobs.close()'
                [ngbTooltip]="tipHotJobs" placement="top-left">
                <ng-template #tipHotJobs>
                  {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs?.filterHover}}
                </ng-template>
                <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                  data-toggle="collapse" href="#collapseHotJobs"
                  aria-controls="collapseHotJobs"
                  role="tablist">
                  <ul class="d-table p-0 pt-2">
                      <li class="d-table-cell align-middle">
                      <li class="d-table-cell">
                          <img alt="hot jobs icon" src="{{CareersImages}}hot-jobs.png" />
                      </li>
                      <li class="d-table-cell align-middle">
                          <div id="hotJobsDropdown" class="list-text-color header_5 ml-1" 
                              [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.hotJobs.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.hotJobs.filterHover' >
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.hotJobs.title}}
                              <i *ngIf="search.hotjob.length===0" class="fa fa-angle-down ml-1"></i>
                          </div>
                      </li>
                  </ul>
                </button>
                <div id="collapseHotJobs" class="collapse" role="tabpanel"
                  aria-labelledby="hotJobsDropdown" [ngbCollapse]="search.hotjob.length===0">
                  <ul class="list-unstyled pl-1 pl-md-0 mb-0"
                      *ngFor="let item of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.hotJobs.ratings;let indx = index">
                      <li *ngIf="item.id !=-1">
                          <div class="common-checkbox filter-block-checkbox">
                              <input id="hotJobs{{item.id}}" type="checkbox" name="{{item.title}}"
                                  (click)='setFilterVal("hotjob",item.id)' (keyup.enter)='setFilterVal("hotjob",item.id)'
                                  [checked]="search.hotjob.indexOf(item.id)!=-1" />
                              <label for="hotJobs{{item.id}}">
                                  <p>
                                      <span>
                                      </span>{{item.title}}
                                  </p>
                              </label>
                          </div>
                      </li>
                  </ul>
                </div>
              </div>
              <!-- Stem -->
              <div class="col-12 col-md-4 col-lg-4 p-t-b border-right"
                *ngIf="filterData.indexOf('stem')>=0"
                #tsStem="ngbTooltip" (focus)='tsStem.open()' 
                (blur)='tsStem.close()'
                [ngbTooltip]="tipStem" placement="top-left">
                <ng-template #tipStem>
                  {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem?.filterHover}}
                </ng-template>
                <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                  data-toggle="collapse" href="#collapseStem"
                  aria-controls="collapseStem"
                  role="tablist">
                  <ul class="d-table p-0 pt-2">
                      <li class="d-table-cell align-middle">
                      <li class="d-table-cell">
                          <img alt="stem icon" src="{{CareersImages}}stem.png" />
                      </li>
                      <li class="d-table-cell align-middle">
                          <div id="stemDropdown" class="list-text-color header_5 ml-1" 
                              [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.stem.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.stem.filterHover' >
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.stem.title}}
                              <i *ngIf="search.stem.length===0" class="fa fa-angle-down ml-1"></i>
                          </div>
                      </li>
                  </ul>
                </button>
                <div id="collapseStem" class="collapse" role="tabpanel"
                  aria-labelledby="stemDropdown" [ngbCollapse]="search.stem.length===0">
                  <ul class="list-unstyled pl-1 pl-md-0 mb-0"
                      *ngFor="let item of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.stem.ratings;let indx = index">
                      <li *ngIf="item.id !=-1">
                          <div class="common-checkbox filter-block-checkbox">
                              <input id="stem{{item.id}}" type="checkbox" name="{{item.title}}"
                                  (click)='setFilterVal("stem",item.id)' (keyup.enter)='setFilterVal("stem",item.id)'
                                  [checked]="search.stem.indexOf(item.id)!=-1" />
                              <label for="stem{{item.id}}">
                                  <p>
                                      <span>
                                      </span>{{item.title}}
                                  </p>
                              </label>
                          </div>
                      </li>
                  </ul>
                </div>
              </div>

              <!-- Self Employ -->
              <div class="col-12 col-md-4 col-lg-4 p-t-b border-right"
                #tsSelfEmploy="ngbTooltip" (focus)='tsSelfEmploy.open()' 
                (blur)='tsSelfEmploy.close()'
                [ngbTooltip]="tipSelfEmploy" placement="top-left">
                <ng-template #tipSelfEmploy>
                  {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.selfEmploy?.filterHover}}
                </ng-template>
                <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                  data-toggle="collapse" href="#collapseSelfEmploy"
                  aria-controls="collapseSelfEmploy"
                  role="tablist">
                  <ul class="d-table p-0 pt-2">
                      <li class="d-table-cell align-middle">
                      <li class="d-table-cell">
                          <img alt="self employ icon" src="{{CareersImages}}enterpreneurial-icon.png" />
                      </li>
                      <li class="d-table-cell align-middle">
                          <div id="selfemployDropdown" class="list-text-color header_5 ml-1" 
                              [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.selfEmploy.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.selfEmploy.filterHover' >
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.selfEmploy.title}}
                              <i *ngIf="search.selfemploy.length===0" class="fa fa-angle-down ml-1"></i>
                          </div>
                      </li>
                  </ul>
                </button>
                <div id="collapseSelfEmploy" class="collapse" role="tabpanel"
                  aria-labelledby="selfemployDropdown" [ngbCollapse]="search.selfemploy.length===0">
                  <ul class="list-unstyled pl-1 pl-md-0 mb-0"
                      *ngFor="let item of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.selfEmploy.ratings;let indx = index">
                      <li *ngIf="item.id !=-1">
                          <div class="common-checkbox filter-block-checkbox">
                              <input id="selfemploy{{item.id}}" type="checkbox" name="{{item.title}}"
                                  (click)='setFilterVal("selfemploy",item.id)' (keyup.enter)='setFilterVal("selfemploy",item.id)'
                                  [checked]="search.selfemploy.indexOf(item.id)!=-1" />
                              <label for="selfemploy{{item.id}}">
                                  <p>
                                      <span>
                                      </span>{{item.title}}
                                  </p>
                              </label>
                          </div>
                      </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row border-bottom">
              <!-- Apprenticeship *ngIf="filterData.indexOf('apprenticeship')>=0" -->
              <div class="col-12 col-md-4 col-lg-4 p-t-b border-right"
                #tsApprenticeship="ngbTooltip" (focus)='tsApprenticeship.open()' 
                (blur)='tsApprenticeship.close()'
                [ngbTooltip]="tipApprenticeship" placement="top-left">
                <ng-template #tipApprenticeship>
                  {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.apprenticeship?.filterHover}}
                </ng-template>
                <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                  data-toggle="collapse" href="#collapseApprenticeship"
                  aria-controls="collapseApprenticeship"
                  role="tablist">
                  <ul class="d-table p-0 pt-2">
                      <li class="d-table-cell align-middle">
                      <li class="d-table-cell">
                          <img alt="apprenticeship icon" src="{{CareersImages}}apprentice.svg" />
                      </li>
                      <li class="d-table-cell align-middle">
                          <div id="apprenticeshipDropdown" class="list-text-color header_5 ml-1" 
                              [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.apprenticeship.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.apprenticeship.filterHover' >
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.apprenticeship.title}}
                              <i *ngIf="search.apprenticeship.length===0" class="fa fa-angle-down ml-1"></i>
                          </div>
                      </li>
                  </ul>
                </button>
                <div id="collapseApprenticeship" class="collapse" role="tabpanel"
                  aria-labelledby="apprenticeshipDropdown" [ngbCollapse]="search.apprenticeship.length===0">
                  <ul class="list-unstyled pl-1 pl-md-0 mb-0"
                      *ngFor="let item of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.apprenticeship.ratings;let indx = index">
                      <li *ngIf="item.id !=-1">
                          <div class="common-checkbox filter-block-checkbox">
                              <input id="apprenticeship{{item.id}}" type="checkbox" name="{{item.title}}"
                                  (click)='setFilterVal("apprenticeship",item.id)' (keyup.enter)='setFilterVal("apprenticeship",item.id)'
                                  [checked]="search.apprenticeship.indexOf(item.id)!=-1" />
                              <label for="apprenticeship{{item.id}}">
                                  <p>
                                      <span>
                                      </span>{{item.title}}
                                  </p>
                              </label>
                          </div>
                      </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- readiness -->
            <div class="row border-bottom" *ngIf="filterData.indexOf('readiness')>=0" #ratingFocus>
              <div class="col-12 col-md-12 col-lg-9 p-t-b"
              #tsReadiness="ngbTooltip" (focus)='tsReadiness.open()' 
              (blur)='tsReadiness.close()'
              [ngbTooltip]="tipReadiness" placement="top-left">
                <ng-template #tipReadiness>
                  {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.readiness?.filterHover}}
                </ng-template>
                <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                  data-toggle="collapse" href="#collapseReadiness"
                  aria-controls="collapseReadiness"
                  role="tablist">
                  <div class="d-table-cell">
                    <img alt="Career Readiness Levels icon" src="{{CareersImages}}Career-Readiness-Levels.png" />
                  </div>
                  <div class="d-table-cell align-middle">
                    <div class="list-text-color header_5 ml-1" tabindex="0">
                      {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.readiness.title}}
                      <i *ngIf="search.reading.length===0 && search.locating.length===0 && search.math.length===0" class="fa fa-angle-down ml-1"></i>
                    </div>
                  </div>
                </button>
                <div id="collapseReadiness" class="collapse" role="list"
                  aria-labelledby="readinessDropdown" [ngbCollapse]="search.reading.length===0 && search.locating.length===0 && search.math.length===0">
                  <div id="readinessDropdown" class="readiness-titles">
                    <p class="pr-4">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.readiness.left.title}}</p>
                    <p class="pl-5">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.readiness.right.title}}</p>
                  </div>
                  <div role="listitem" class="list-unstyled pr-2 pl-1 pl-md-0 w-100"
                    *ngFor="let item of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.readiness.left.ratings;let indx1 = index">
                    <div class="d-md-flex" *ngIf="item.id !=-1">
                      <div class="common-checkbox w-100 w-md-50 pt-1">
                        <input id="readiness{{item.id}}" name="checkbox-group1" type="checkbox"
                          (change)="onFilterChange($event,indx1,item.id)"
                          [checked]="carrerRedinessLevelCheckedArr[indx1].isCareerChecked" />
                        <label for="readiness{{item.id}}" class="checkbox-custom-label">
                          <p>
                            <span class="pl-1">{{item.title}}</span>
                          </p>
                        </label>
                      </div>
                      <div class="w-md-50 w-100 level-check-list">

                        <ul class="list-unstyled w-100 pl-md-0">
                          <li class="pr-1">
                            <div
                              *ngIf="(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.readiness.right.ratings[indx1].skill==item.id">
                              <div
                                *ngFor="let item2 of (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.readiness.right.ratings[indx1].levels; let inx2=index"
                                class="pr-3 float-left">

                                <div
                                  [attr.aria-hidden]="!carrerRedinessLevelCheckedArr[indx1].isCareerChecked? true: false"
                                  [tabindex]="!carrerRedinessLevelCheckedArr[indx1].isCareerChecked? -1: 0"
                                  class="common-checkbox w-100 w-md-50 pt-1"
                                  [ngStyle]="!carrerRedinessLevelCheckedArr[indx1].isCareerChecked?{'opacity':'0.5','pointer-events':'none'}:{}"
                                  (click)="onRadioChange(indx1,inx2,item2.id,item.id,$event)"
                                  (keyup.enter)="onRadioChange(indx1,inx2,item2.id,item.id,$event)"
                                  id="ratingreadiness{{item.id}}{{item2.id}}"
                                  [attr.aria-label]='(carrerRedinessLevelCheckedArr[indx1]?.careerRadiosCheckedArr[inx2])? item2.title+ " Rating Checkbox checked":item2.title+ " Rating Checkbox not checked"'
                                  (keyup.space)="onRadioChange(indx1,inx2,item2.id,item.id,$event)">

                                  <input tabindex="-1"
                                    *ngIf="!carrerRedinessLevelCheckedArr[indx1].careerRadiosCheckedArr[inx2]"
                                    id="level{{item2.id}}" [disabled]="true" name="level{{item2.id}}" type="checkbox"
                                    class="checkbox-custom-label" aria-hidden="true" />
                                  <input *ngIf="carrerRedinessLevelCheckedArr[indx1].careerRadiosCheckedArr[inx2]"
                                    id="level{{item2.id}}" [disabled]="true" [checked]="true" name="level{{item2.id}}"
                                    type="checkbox" class="checkbox-custom-label" aria-hidden="true" tabindex="-1" />
                                  <label tabindex="-1" for="level{{item2.id}}" class="checkbox-custom-label"
                                    aria-hidden="true">
                                    <p>
                                      <span class="pl-1">{{item2.title}}</span>
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 

            <!-- Assessment filters -->
            <div *ngIf = "asmntFilerText?.Occ_Assessment_Filter_Text"
              #tsResults='ngbTooltip' (focus)='tsResults.open()' (blur)='tsResults.close()'
              [ngbTooltip]='tipResults' placement='top-left'>
              <ng-template #tipResults>
                {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.results?.filterHover}}
              </ng-template>
              <button tabindex="0" class="border-0 bg-transparent w-100 filter-item-button"
                *ngIf = "asmntFilerText?.Occ_Assessment_Filter_Text"
                data-toggle="collapse" href="#collapseResults"
                aria-controls="collapseResults"
                role="tablist">
                <ul class="d-table p-0 pt-2">
                    <li class="d-table-cell align-middle">
                    <li class="d-table-cell align-middle">
                        <div id="resultDropdown" class="list-text-color header_5 ml-1" 
                            [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.results.title + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.resultsfilterHover' >
                            {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters.results.title}}
                            <i *ngIf="search.result.length===0" class="fa fa-angle-down ml-1"></i>
                        </div>
                    </li>
                </ul>
              </button>
              <div id="collapseResults" class="collapse" [ngbCollapse]="search.result.length===0">
                <div class="row ml-4 assessment-filter" *ngIf = "asmntFilerText?.Occ_Assessment_Filter_Text">
                  <div class="col-lg-3 col-md-6 col-sm-12 pl-4 assessment-filte-card assessment-filter-icons" *ngFor="let assessment of asmntFilerText.Occ_Assessment_Filter_Text">
                    <div class="pl-1">
                      <div class="pt-2">
                        <div class="pb-2"><img alt="Assessment Icon"  src="{{LandingImages}}{{asmntImageNames[assessment.sortId]}}"></div>
                      <div>
                        <div class="list-text-color header_5 pt-0" #ts="ngbTooltip" (focus)='ts.open()'
                          placement="right" (blur)='ts.close()' [ngbTooltip]="tipContenth1" tabindex="0">
                          {{assessment.title}} </div>
                      </div>
                      </div>
                        <div *ngFor = "let subText of subText">
                          <div class="pl-3 common-checkbox ml-1" *ngIf="subText.sortid == assessment.sortId">
                            <input  id="{{subText.sortid}}" type="checkbox" name="{{subText.text}}"
                            (click)='setFilterVal("result",subText.sortid)' (keyup.enter)='setFilterVal("result",subText.sortid)'
                            [checked]="search.result.indexOf(subText.sortid)!=-1"/>
                            <label for="{{subText.sortid}}">
                              <p class="ml-1">
                                <span>{{subText.text}}</span>
                              </p>
                            </label>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         

          <div class="card-footer-slider career-footer-slider pt-0 text-right">
            <!-- Display show career button -->
            <button type="button" class="common-button green"
              [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.showCareers'
              (click)="getResultFilter()">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.showCareers}}</button>
            <!-- Display rest button -->
            <button class="common-button orange" type="button"
              [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.reset'
              (click)="resetCheckBoxes()"
              (keydown.Tab)="hideOnTabReset()">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.reset}}</button>
            <!-- Display cancel button -->
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>

  <!--   panal list  -->
  <div class="panel" [ngStyle]="clusterShow?{'display':'block'}:{'display':'none'}">
    <!-- Display cluster list -->
    <div class="career-list-table powderblue mt-2 fixScreen2">
      <div class="row align-items-center">
        <!-- Display cluster headers -->
        <div class="col-12 col-md-4">
          <div class="row align-items-center">
            <ng-template #tipContents>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.clusterHover}}
            </ng-template>
            <a tabindex="0" class="col-md-8 col-7 pointer whitespace" (keyup.enter)="clusterBarClicked('title',0)"
              (click)="clusterBarClicked('title',0)" [ngClass]="(clusterBackground==0)?'occ-active-menu':''"
              [attr.aria-label]="((clusterBackground!=0))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersCluster +' sorting order':((titleSort[0]==-1) && (clusterBackground==0))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersCluster +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersCluster+' Ascending order '"
               (focus)='ts6.open()' (blur)='ts6.close()'
              >
              <span #ts6="ngbTooltip" [ngbTooltip]="tipContents">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersCluster}}
              <img alt="" *ngIf='(clusterBackground!=0)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && clusterBackground==0' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& clusterBackground==0' src ="{{CareersImages}}sort-down.png">
              </span>
            </a>
            <ng-template #tipContentin>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeaderHover}}
            </ng-template>
            <a tabindex="0" class="col-4 col-md-4 text-center whitespace pointer px-md-0 pl-0" *ngIf = "!restrictedAccountType"
              (keyup.enter)="clusterBarClicked('rating',1)" (click)="clusterBarClicked('rating',1)"
              [attr.aria-label]="((clusterBackground!=1))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeader +' sorting order':((titleSort[0]==-1) && (clusterBackground==1))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeader+' Ascending order '"
              [ngClass]="(inxForBackground==1 || clusterBackground==1)?'occ-active-menu':''" 
              (focus)='ts7.open()' (blur)='ts7.close()' 
              >
              <span #ts7="ngbTooltip" [ngbTooltip]="tipContentin" placement="top">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeader}}
              <img alt="" *ngIf='(clusterBackground!=1)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && clusterBackground==1' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& clusterBackground==1' src ="{{CareersImages}}sort-down.png">
              </span>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-8 desktop-design-display" [hidden]="showoccclustertitles">
          <div class="row align-items-center">
            <ng-template #tipContenthot>
              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeaderHover}}</ng-template>
            <a class="col-12 col-md-3 text-center pointer" (keyup.enter)="titleBarClicked('HotJobs',2)"
              (click)="titleBarClicked('HotJobs',2)" [ngClass]="(inxForBackground==2)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=2))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==2))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader+' Ascending order '"
               (focus)='ts8.open()' (blur)='ts8.close()'
              >
              <span class="tooltip-md" tabindex="0" #ts8="ngbTooltip" [ngbTooltip]="tipContenthot">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader}}
              <img alt="" *ngIf='(inxForBackground!=2)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==2' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==2' src ="{{CareersImages}}sort-down.png">
              </span>
            </a>
            <ng-template #tipContentstem>
              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeaderHover}}</ng-template>
            <a tabindex="0" class="col-12 col-md-2 px-0 text-center pointer" (keyup.enter)="titleBarClicked('stem',2)"
              (click)="titleBarClicked('stem',3)" [ngClass]="(inxForBackground==3)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=3))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==3))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader+' Ascending order '"
               (focus)='ts8.open()' (blur)='ts8.close()'
              >
              <span class="tooltip-md" #ts8="ngbTooltip" [ngbTooltip]="tipContentstem">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeader}}
              <img alt="" *ngIf='(inxForBackground!=3)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==3' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==3' src ="{{CareersImages}}sort-down.png">
              </span>
            </a>
            <ng-template #tipContented>
              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeaderHover}}</ng-template>
            <a tabindex="0" class="col-12 col-md-4 px-0 text-center pointer"
              (keyup.enter)="titleBarClicked('edLevelID',4)" (click)="titleBarClicked('edLevelID',4)"
              [ngClass]="(inxForBackground==4)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=4))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==4))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeader+' Ascending order '"
               (focus)='ts9.open()' (blur)='ts9.close()'
              >
              <span #ts9="ngbTooltip" [ngbTooltip]="tipContented">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeader}}
              <img alt="" *ngIf='(inxForBackground!=4)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==4' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==4' src ="{{CareersImages}}sort-down.png">
              </span>
            </a>
            <ng-template #tipContentwag>
              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeaderHover}}</ng-template>
            <a class="col-12 col-md-3 text-center text-wages pointer">
              <div tabindex="0"  (keyup.enter)="titleBarClicked('wages',4)" (click)="titleBarClicked('wages',4)"
              [ngClass]="(inxForBackground==4)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=4))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==4))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader+' Ascending order '"
               (focus)='ts10.open()' (blur)='ts10.close()'>
                <span #ts10="ngbTooltip" [ngbTooltip]="tipContentwag">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader}}
                  <img alt="" *ngIf='(inxForBackground!=5)' src="{{CareersImages}}sorting.png">
                  <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==5' src ="{{CareersImages}}sort-up.png">
                  <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==5' src ="{{CareersImages}}sort-down.png">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="cluster-panal-list search-results">
      <div *ngFor="let clus of occIndexReduTxt?.Occ_Index_Reducer_Text?.clusList; let inx1=index;let first=first;">
        <div>
          <div class="career-list-table grey"
            [ngStyle]="(indexOccId.length == 2)  && (indexOccId[0]!=clus.clusterID && indexOccId[1]!=clus.clusterID) ? {'opacity':'0.5','pointer-events': 'none','cursor': 'pointer'}:''">
            <div class="row align-items-center">
              <div class="col-10 col-md-4">
                <div class="row align-items-center">
                  <div class="col-md-8 col-8">
                    <ul class="list-unstyled mb-0">
                      <li class="pr-1 d-flex cluster-icons align-items-center">
                        <i class="d-table-cell"
                          (click)="$event.preventDefault();callOccDetailCluster({ clusterIndex: 'cluster', clusterId: clus.clusterID, ClusterName: clus.title })"
                          [ngClass]="clusterValues['cls_'+clus.clusterID].clusterIconValue" aria-hidden="true"
                          [ngStyle]="{'color':clusterValues['cls_'+clus.clusterID].clusterBgColor}"></i>&nbsp;
                        <span class="d-table-cell">
                          <!-- Display cluster titles -->
                          <ng-template #tipContentfly>
                            {{clus.flyover}}
                          </ng-template>
                          <a href="#" [attr.aria-label]='clus.title + " " +  clus.flyover'
                            (click)="$event.preventDefault();callOccDetailCluster({ clusterIndex: 'cluster', clusterId: clus.clusterID, ClusterName: clus.title })"
                            #tsCluster="ngbTooltip" (focus)='tsCluster.open()' (blur)='tsCluster.close()'
                            [ngbTooltip]="tipContentfly" placement="right">{{clus.title}}</a>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4 col-4 text-center">
                    <span *ngIf = "!restrictedAccountType">
                      <ng-template #tipContentParentClusterUp>
                        {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user?.tableRowUpHover}}</ng-template>
                        <ng-template #tipContentParentClusterDown>
                          {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowDownHover}}
                        </ng-template>
                      <a tabindex="0" class="mr-2 thumbsdown-tooltips pointer"
                        [attr.aria-label]='"Thumbs up Icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover'
                        (keyup.enter)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clus.clusterID,occtitle:clus.title,rating:clus.rating},'up','clusterID')"
                        (click)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clus.clusterID,occtitle:clus.title,rating:clus.rating},'up','clusterID')"
                        #ts="ngbTooltip" (focus)='ts.open()' (blur)='ts.close()' [ngbTooltip]="(clus['rating']== 1)? tipContentParentClusterUp : tipContentParentClusterDown">
                        <i class="fillstroke thumbsIconSize"
                          [ngClass]="(clus['rating']== 1)?'icon-thumbs-up-hover':'icon-thumbs-up'" aria-hidden></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-2 col-md-8 d-tablecell-mob" (click)="methodfilter(clus.clusterID || 999)">
                <div class="row align-items-center">
                  <div class="col-md-12 col-10 d-tablecell-mob px-0 px-md-3">
                    <button class="modal-close pull-right" aria-label="arrow"
                      [attr.aria-expanded]="(expandCard != clus.clusterID)?false:true">
                      <i *ngIf="(expandCard != clus.clusterID)" class="icon-down-rounded-arrow pull-right pointer"
                        [ngStyle]="{'color':clusterValues['cls_'+clus.clusterID].clusterBgColor}"></i>
                      <i *ngIf="expandCard === clus.clusterID" class="icon-up-rounded-arrow pull-right pointer"
                        [ngStyle]="{'color':clusterValues['cls_'+clus.clusterID].clusterBgColor}"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Display titles for each clusters -->
          <div id="clus{{inx1}}" *ngIf="(expandCard == clus.clusterID)" class="collapse show panel-body p-0" role="tabpanel"
            [ngStyle]="{'overflow':'visible'}">
            <ul class="list-group"
              *ngFor="let clusvalue of occIndexReduTxt?.Occ_Index_Reducer_Text?.occList; let inx=index">
              <li class="list-group-item  mb-0 d-block"
                [ngStyle]="(indexOccId.length == 2)  && (indexOccId[0]!=clusvalue.occID && indexOccId[1]!=clusvalue.occID) ? {'opacity':'0.5','pointer-events': 'none'}:''"
                *ngIf='(occIndexReduTxt?.Occ_Index_Reducer_Text?.occList[inx].clusterID == clus?.clusterID)'>
                <div class="row align-items-center">
                  <div class="col-12 col-md-4">
                    <div class="row align-items-center">
                      <div class="col-md-8 col-12 d-flex align-items-center">
                        <div class="common-checkbox mb-0 checkbox-align ml-md-0" #ts18="ngbTooltip"
                          [ngbTooltip]="tipContentcompare" placement="right">
                          <input type="checkbox" class="ml-2" *ngIf="(clusvalue.check)"
                              (keyup.enter)="checkCareer(inx1,clusvalue.occID,clusvalue.title,'click',$event)"
                              (click)="checkCareer(inx1,clusvalue.occID,clusvalue.title,'click')"
                              id="cluster{{inx1}}{{inx}}" name="checkValue"
                              [disabled]="(indexOccId.length == 2) && (indexOccId.indexOf(clusvalue.occID) ==-1)"
                              [checked]="(indexOccId.indexOf(clusvalue.occID) !=-1)" data-toggle="modal"
                              data-target="#myModal7" [attr.aria-label]='clusvalue.title' />
                            <ng-template #tipContentcompare>
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.compareSelected }}
                            </ng-template>
                            <label [attr.aria-label]='clusvalue.title' *ngIf="(clusvalue.check)"
                              for="cluster{{inx1}}{{inx}}" [ngStyle]="(clusvalue.check)?{}:{'display':'none'}">
                              <span class="d-none">{{clusvalue.title}}</span>
                            </label>
                        </div>
                        <div>

                          <p class="mb-0 pl-md-1">
                            <ng-template #tiptfavorite>{{clusvalue.flyover}}</ng-template>
                            <a href="#" [attr.aria-label]='clusvalue.title + " " + clusvalue.flyover'
                              (click)="$event.preventDefault();callOccDetail({indexval:inx1,occID:clusvalue.occID,occName:clusvalue.title,clusterId:clusvalue.clusterID})"
                              class="btn-bg-none" #tfavorite="ngbTooltip" (focus)='tfavorite.open()' (blur)='tfavorite.close()'
                              placement="right" [ngbTooltip]="tiptfavorite">
                              {{clusvalue.title}}
                            </a>
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4 pl-0 pt-1 desktop-design-display text-center">
                        <span>
                          <ng-template #tipContentChildClusterUp>
                            {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover}}
                          </ng-template>
                          <ng-template #tipContentChildClusterDown>
                            {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowDownHover}}
                          </ng-template>
                          <a tabindex="0" class="thumbsdown-tooltips pointer"
                            [attr.aria-label]='"Thumbs up Icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover'
                            (keyup.enter)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clusvalue.occID,occtitle:clusvalue.title,rating:clusvalue.rating},'up')"
                            (click)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clusvalue.occID,occtitle:clusvalue.title,rating:clusvalue.rating},'up')"
                            #ts="ngbTooltip" (focus)='ts.open()' (blur)='ts.close()' [ngbTooltip]="(clusvalue['rating']== 1)? tipContentChildClusterUp : tipContentChildClusterDown">
                            <i class="fillstroke thumbsIconSize"
                              [ngClass]="(clusvalue['rating']== 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"
                              aria-hidden></i>
                          </a>
                        </span>
                        <!-- <span>
                          <ng-template #tipContent2>
                            {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowDownHover}}
                          </ng-template>
                          <a tabindex="0"
                            [attr.aria-label]='"Thumbs down Icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowDownHover'
                            (keyup.enter)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clusvalue.occID,occtitle:clusvalue.title,rating:clusvalue.rating},'down')"
                            (click)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clusvalue.occID,occtitle:clusvalue.title,rating:clusvalue.rating},'down')"
                            #ts1="ngbTooltip" (focus)='ts1.open()' (blur)='ts1.close()' [ngbTooltip]="tipContent2">
                            <i class="fillstroke thumbsIconSize"
                              [ngClass]="(clusvalue['rating'] == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'"
                              aria-hidden></i>
                          </a>
                        </span> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-8">
                    <!--Rebuilding the PR-->
                    <div class="row align-items-center">
                      <div class="col-12 col-md-3 text-sm-left text-md-center pt-1"
                        [ngClass]="clusvalue.hotJob ? '':'hotjob-hidden'">
                        <ul class="list-unstyled ml-3 ml-md-0">
                          <li *ngIf="clusvalue.hotJob" class="d-flex d-md-block align-items-center pr-4">
                          <!-- <li class="d-flex d-md-block justify-content-between"> -->
                            <span
                              class="mobile-design-display hotjob-text">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.title}}:</span>
                            <ng-template #hotJobToolTip>
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableRowHover}}
                            </ng-template>
                            <div tabindex="0" *ngIf="clusvalue.hotJob" href="#"
                              [attr.aria-label]='"Hot Job icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableRowHover'
                              (click)='$event.preventDefault();' #tshotjob="ngbTooltip" (focus)='tshotjob.open()'
                              (blur)='tshotjob.close()' [ngbTooltip]="hotJobToolTip" class="left-tooltip">
                              <span>
                                <img class="hot-job-image" alt="hot-job-index" src="{{CareersImages}}hot-job-index.png" />
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                       <div class="col-12 col-md-2 text-sm-left text-md-center"
                        [ngClass]="clusvalue.stem ? '':'stem-hidden'">
                        <ul class="list-unstyled ml-3 ml-md-0">
                          <li class="d-flex d-md-block align-items-center">
                            <span
                              class="mobile-design-display stem-text">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.title}}:</span>
                            <ng-template #tipContents>
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableRowHover}}
                            </ng-template>
                            <div tabindex="0" *ngIf="clusvalue.stem" href="#" class="left-tooltip"
                              [attr.aria-label]='"stem icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableRowHover'
                              (click)='$event.preventDefault();' #tsstem="ngbTooltip" (focus)='tsstem.open()'
                              (blur)='tsstem.close()' [ngbTooltip]="tipContents">
                              <span>
                                <img alt="stem" src="/assets/images/stem.png" />
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div> 
                      <div class="col-12 col-md-4 text-sm-left text-md-center">
                        <ul class="list-unstyled ml-3 ml-md-0">
                          <li class="d-flex d-md-block justify-content-between">
                            <span
                              class="mobile-design-display">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableRowHover}}:</span>
                            <ng-template #tipContentedu>
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableRowHover}}
                            </ng-template>
                            <div class="left-tooltip" tabindex="0"
                              [attr.aria-label]='clusvalue.edLevel + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableRowHover'
                              href="#" (click)='$event.preventDefault();' #tsedulevel="ngbTooltip"
                              (focus)='tsedulevel.open()' (blur)='tsedulevel.close()' [ngbTooltip]="tipContentedu">
                              <span class="text-list text-dark">{{clusvalue.edLevel}}</span>
                          </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-md-3">
                        <ul class="list-unstyled ml-3 ml-md-0">
                          <li>
                            <div class="w-100 d-flex justify-content-between d-md-block">
                              <div class="d-flex d-md-block text-right mr-md-3 mr-lg-4 mr-xl-5">
                                <span
                                  class="mobile-design-display">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader}}:</span>
                                <ng-template #tipContentwage>
                                  {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableRowHover}}
                                </ng-template>
                                <span tabindex="0" [attr.aria-label]='clusvalue.wages + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableRowHover'
                                  (keyup.enter)="$event.preventDefault()" (click)='$event.preventDefault();'
                                  #tswages="ngbTooltip" (focus)='tswages.open()' (blur)='tswages.close()'
                                  [ngbTooltip]="tipContentwage" class="left-tooltip">
                                  <span class="text-list text-dark">{{clusvalue.wages}}</span>
                                </span>
                              </div>
                              <div class="col-3 p-0 text-right mobile-design-display">
                                <span class=" mobile-design-display pull-right">
                                  <!-- <i class="icon-thumbs-up-hover" aria-hidden="true " *ngIf="clusvalue.rating == 1"></i>
                                  <i class="icon-thumbs-down-hover" aria-hidden="true "
                                    *ngIf="clusvalue.rating == 0"></i> -->
                                  <span>
                                    <ng-template #tipContent>
                                      {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover}}
                                    </ng-template>
                                    <a tabindex="0" *ngIf = "!restrictedAccountType"
                                      [attr.aria-label]='"Thumbs up Icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover'
                                      (keyup.enter)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clusvalue.occID,occtitle:clusvalue.title,rating:clusvalue.rating},'up')"
                                      (click)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clusvalue.occID,occtitle:clusvalue.title,rating:clusvalue.rating},'up')"
                                      #ts2="ngbTooltip" (focus)='ts2.open()' (blur)='ts2.close()'
                                      [ngbTooltip]="tipContent">
                                      <i class="fillstroke thumbsIconSize"
                                        [ngClass]="(clusvalue['rating']== 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"
                                        aria-hidden></i>
                                    </a>
                                  </span>
                                  <!-- <span>
                                    <ng-template #tipContent2>
                                      {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowDownHover}}
                                    </ng-template>
                                    <a tabindex="0"
                                      [attr.aria-label]='"Thumbs down Icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowDownHover'
                                      (keyup.enter)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clusvalue.occID,occtitle:clusvalue.title,rating:clusvalue.rating},'down')"
                                      (click)="$event.preventDefault();getUnFill({indexvalue:'title',occID:clusvalue.occID,occtitle:clusvalue.title,rating:clusvalue.rating},'down')"
                                      #ts1="ngbTooltip" (focus)='ts1.open()' (blur)='ts1.close()'
                                      [ngbTooltip]="tipContent2">
                                      <i class="fillstroke thumbsIconSize"
                                        [ngClass]="(clusvalue['rating'] == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'"
                                        aria-hidden></i>
                                    </a>
                                  </span> -->
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <!-- Display loading image -->
      <div *ngIf="showImg==-1" class="img-dataNotFound">
        <img alt="loading" class="dta-not-found-img w-100" src="{{CareersImages}}loading.png" />
      </div>
      <!-- Display nodata found image -->
      <div *ngIf="showImg==0" class="img-dataNotFound" aria-label="No Data Found" tabindex="0">
        <img alt="" class="dta-not-found-img" width="100%" src="{{CareersImages}}nodata-found.png" />
      </div>
    </div>
    <br>
  </div>

  <!-- title list -->
  <div [ngStyle]="titleShow?{'display':'block'}:{'display':'none'}">
    <div class="career-list-table powderblue mt-2 fixScreen1">
      <div class="row align-items-center">
        <div class="col-12 col-md-4">
          <div class="row align-items-center">
            <!-- Display title headers -->
            <a tabindex="0" class="col-md-7 col-12 pointer" (keyup.enter)="titleBarClicked('title',0)"
              (click)="titleBarClicked('title',0)" [ngClass]="(inxForBackground==0)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=0))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersTitle +' sorting order':((titleSort[0]==-1) && (inxForBackground==0))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersTitle +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersTitle+' Ascending order '"
               (focus)='ts1.open()' (blur)='ts1.close()'
              >
              <span #ts1="ngbTooltip" [ngbTooltip]="tipContentcar">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.careersTitle}}
              <ng-template #tipContentcar>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.titleHover}}
              </ng-template>
              <img alt="" *ngIf='(inxForBackground!=0)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==0' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==0' src ="{{CareersImages}}sort-down.png">
              <!-- <img alt="sort-down/up"
                [src]='(titleSort[0]==-1) ?  "/assets/images/sort-down.png": "/assets/images/sort-up.png"'
                *ngIf='inxForBackground==0'> -->
              </span>
            </a>
            <ng-template #tipContentint>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeaderHover}}
            </ng-template>
            <a tabindex="0" class="col-12 col-md-5 text-center pointer px-0 desktop-design-display"*ngIf = "!restrictedAccountType"
              (keyup.enter)="titleBarClicked('rating',1)" (click)="titleBarClicked('rating',1)"
              [ngClass]="(inxForBackground==1)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=1))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==1))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeader+' Ascending order '"
               (focus)='ts2.open()' (blur)='ts2.close()'>
              <span #ts2="ngbTooltip" [ngbTooltip]="tipContentint"
              placement="top">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableHeader}}
              <img alt="" *ngIf='(inxForBackground!=1)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==1' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==1' src ="{{CareersImages}}sort-down.png">
            </span>
              <!-- <img alt="sort-down/up"
                [src]='(titleSort[0]==-1) ?  "/assets/images/sort-down.png": "/assets/images/sort-up.png"'
                *ngIf='inxForBackground==1'> -->
            </a>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <ng-template #tipContenthotj>
            {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeaderHover}}</ng-template>
          <div class="row align-items-center desktop-design-display">
            <a tabindex="0" class="col-12 col-md-3 px-0 text-center pointer" (keyup.enter)="titleBarClicked('HotJobs',2)"
              (click)="titleBarClicked('HotJobs',2)" [ngClass]="(inxForBackground==2)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=2))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==2))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader+' Ascending order '"
               (focus)='ts3.open()' (blur)='ts3.close()'
              >
              <span class="tooltip-md" #ts3="ngbTooltip" [ngbTooltip]="tipContenthotj">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader}}
              <img alt="" *ngIf='(inxForBackground!=2)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==2' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==2' src ="{{CareersImages}}sort-down.png">
            </span>
              <!-- <img alt="sort-down/up"
                [src]='(titleSort[0]==-1) ?  "/assets/images/sort-down.png": "/assets/images/sort-up.png"'
                *ngIf='inxForBackground==2'> -->
            </a>
            <ng-template #tipContentstem>
              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeaderHover}}</ng-template>
            <a tabindex="0" class="col-12 col-md-3 px-0 text-center pointer" (keyup.enter)="titleBarClicked('stem',3)"
              (click)="titleBarClicked('stem',3)" [ngClass]="(inxForBackground==3)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=3))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==3))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeader+' Ascending order '"
               (focus)='tsstem.open()' (blur)='tsstem.close()'
              >
              <span class="tooltip-md" #tsstem="ngbTooltip" [ngbTooltip]="tipContentstem">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeader}}
              <img alt="" *ngIf='(inxForBackground!=3)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==3' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==3' src ="{{CareersImages}}sort-down.png">
            </span>
              <!-- <img alt="sort-down/up"
                [src]='(titleSort[0]==-1) ?  "/assets/images/sort-down.png": "/assets/images/sort-up.png"'
                *ngIf='inxForBackground==3'> -->
            </a>
            <ng-template #tipContentedl>
              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeaderHover}}</ng-template>
            <a tabindex="0" class="col-12 col-md-3 px-0 text-center pointer"
              (keyup.enter)="titleBarClicked('edLevelID',4)" (click)="titleBarClicked('edLevelID',4)"
              [ngClass]="(inxForBackground==4)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=4))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==4))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeader+' Ascending order '"
               (focus)='ts4.open()' (blur)='ts4.close()'
              >
              <span [ngbTooltip]="tipContentedl" #ts4="ngbTooltip">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableHeader}}
              <img alt="" *ngIf='(inxForBackground!=4)' src="{{CareersImages}}sorting.png">
              <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==4' src ="{{CareersImages}}sort-up.png">
              <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==4' src ="{{CareersImages}}sort-down.png">
            </span>
            </a>
            <ng-template #tipContentwag>
              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeaderHover}}</ng-template>

            <a class="col-12 col-md-3 text-center text-wages pointer">
              <div tabindex="0"  (keyup.enter)="titleBarClicked('wages',5)" (click)="titleBarClicked('wages',5)"
              [ngClass]="(inxForBackground==5)?'occ-active-menu':''"
              [attr.aria-label]="((inxForBackground!=5))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader +' sorting order':((titleSort[0]==-1) && (inxForBackground==5))? (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader +' Descending order': (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader+' Ascending order '"
              (focus)='ts5.open()' (blur)='ts5.close()'>
                <span  #ts5="ngbTooltip"
                  [ngbTooltip]="tipContentwag">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader}}
                  <img alt="" *ngIf='(inxForBackground!=5)' src="{{CareersImages}}sorting.png">
                  <img alt="sort-up" *ngIf='(titleSort[0]==1) && inxForBackground==5' src ="{{CareersImages}}sort-up.png">
                  <img alt="sort-down" *ngIf='(titleSort[0]==-1)&& inxForBackground==5' src ="{{CareersImages}}sort-down.png">
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="cluster-panal-list">
      <div class="search-results">
        <ul class="list-group">
          <li class="list-group-item mb-0 d-block"
            *ngFor="let value of pagedItems; let inx=index;trackBy:trackByArtNo"
            [ngStyle]="(indexOccId.length == 2)  && (indexOccId[0]!=value.occID && indexOccId[1]!=value.occID) ? {'opacity':'0.5','pointer-events': 'none'}:''">
            <div class="row align-items-center">
              <div class="col-12 col-md-4">
                <div class="row align-items-center">
                  <div class="col-md-7 col-12 d-flex align-items-center">
                    <div class="common-checkbox  mb-0 checkbox-align ml-md-0" #ts19="ngbTooltip" placement="right"
                      [ngbTooltip]="(value.check)? tipContent10: '' ">
                      <input type="checkbox" class="ml-2 final" *ngIf="(value.check)" id="test2{{inx}}"
                        (keyup.enter)="checkCareer('title',value.occID,value.title,'click')"
                        (click)="checkCareer('title',value.occID,value.title,'click')"
                        [disabled]="(indexOccId.length == 2) && (indexOccId.indexOf(value.occID) ==-1)"
                        [checked]="(indexOccId.indexOf(value.occID) !=-1)" data-toggle="modal" data-target="#myModal7"
                        [attr.aria-label]="value.title" />
                      <ng-template #tipContent10>
                        {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.compareSelected }}
                      </ng-template>

                      <label for="test2{{inx}}" *ngIf="(value.check)" [ngStyle]="(value.check)?{}:{'display':'none'}">
                        <p [attr.aria-label]="value.title" class="d-none">{{ value.title }}</p>
                      </label>
                      <!-- Display title list -->
                    </div>
                    <div>

                      <p class="mb-0 pl-md-1">
                        <ng-template #tipContent>{{value.flyover}}</ng-template>
                        <a href="#" [attr.aria-label]='value.title + " " + value.flyover'
                          (click)="$event.preventDefault();callOccDetail({indexval:'title',occID:value.occID,occName:value.title,clusterId:value.clusterID})"
                          class=" btn-bg-none" #t2="ngbTooltip" (focus)='t2.open()' (blur)='t2.close()'
                          placement="right" [ngbTooltip]="tipContent">
                          <span>{{value.title}}</span>
                        </a>
                      </p>
                    </div>
                  </div>

                  <div class="col-md-5 desktop-design-display text-center d-md-flex justify-content-center pt-1">
                    <div *ngIf = "!restrictedAccountType">
                      <ng-template #tipContentTitleUp>
                        {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover}}
                      </ng-template>
                      <ng-template #tipContentTitleDown>
                        {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowDownHover}}
                      </ng-template>
                      <a tabindex="0" class="thumbsdown-tooltips pointer"
                        [attr.aria-label]='"Thumbs up Icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover'
                        (keyup.enter)="$event.preventDefault();getUnFill({indexvalue:'title',occID:value.occID,occtitle:value.title,rating:value.rating},'up')"
                        (click)="$event.preventDefault();getUnFill({indexvalue:'title',occID:value.occID,occtitle:value.title,rating:value.rating},'up')"
                        #ts="ngbTooltip" (focus)='ts.open()' (blur)='ts.close()' [ngbTooltip]="(value['rating']== 1)? tipContentTitleUp : tipContentTitleDown">
                        <i class="fillstroke thumbsIconSize"
                          [ngClass]="(value['rating']== 1)?'icon-thumbs-up-hover':'icon-thumbs-up'" aria-hidden></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8">
                <div class="row align-items-center">
                  <div class="col-12 col-md-3 pt-1  text-md-center text-sm-left"
                    [ngClass]="value.hotJob ? '':'hotjob-hidden'">
                    <ul class="list-unstyled ml-3 ml-md-0">
                      <li *ngIf="value.hotJob" class="d-flex d-md-block align-items-center">
                        <span
                          class="mobile-design-display hotjob-text">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableHeader}}:</span>
                        <ng-template #tipContents>
                          {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableRowHover}}</ng-template>
                        <div class="left-tooltip" tabindex="0" *ngIf="value.hotJob" href="#"
                          [attr.aria-label]='"Hot Job icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.hotJobs.tableRowHover'
                          (click)='$event.preventDefault();' #ts15="ngbTooltip" (focus)='ts15.open()'
                          (blur)='ts15.close()' [ngbTooltip]="tipContents">
                          <span aria-hidden>
                            <img class="hot-job-image" alt="hot-job-index" src="{{CareersImages}}hot-job-index.png" />
                          </span>
                        </div>

                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-3 text-md-center text-sm-left"
                    [ngClass]="value.stem ? '':'stem-hidden'">
                    <ul class="list-unstyled ml-3 ml-md-0">
                      <li *ngIf="value.stem" class="d-flex d-md-block align-items-center">
                        <span
                          class="mobile-design-display hotjob-text" [ngClass]="value.stem ? '':'hotjob-hidden'">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableHeader}}:</span>
                        <ng-template #tipContentstem>
                          {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableRowHover}}</ng-template>
                        <div class="left-tooltip" tabindex="0" *ngIf="value.stem" href="#"
                          [attr.aria-label]='"Stem icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.stem.tableRowHover'
                          (click)='$event.preventDefault();' #ts20="ngbTooltip" (focus)='ts20.open()'
                          (blur)='ts20.close()' [ngbTooltip]="tipContentstem">
                          <span aria-hidden>
                            <img alt="stem-index" src="{{CareersImages}}stem.png" />
                          </span>
                        </div>

                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-3 text-sm-left text-md-center">
                    <ul class="list-unstyled ml-3 ml-md-0">
                      <li class="d-flex d-md-block justify-content-between">
                        <span
                          class="mobile-design-display">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableRowHover}}:</span>
                        <ng-template #tipContentedu>
                          {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableRowHover}}</ng-template>
                        <div class="left-tooltip" tabindex="0"
                          [attr.aria-label]='value.edLevel + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.edLevel.tableRowHover'
                          href="#" (click)='$event.preventDefault();' #ts16="ngbTooltip" (focus)='ts16.open()'
                          (blur)='ts16.close()' [ngbTooltip]="tipContentedu">
                          <span class="text-dark">{{value.edLevel}}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-3">
                    <ul class="list-unstyled ml-3 ml-md-0">
                      <li>
                        <div class="w-100 d-flex justify-content-between d-md-block">
                          <div class="d-flex d-md-block text-right mr-md-3 mr-lg-4 mr-xl-5">
                            <span class="mobile-design-display ">
                              <ng-template #tipContentwage>
                                {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableRowHover}}
                              </ng-template>
                              {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableHeader}}:
                            </span>
                            <span class="left-tooltip" tabindex="0" [attr.aria-label]='value.wages + " " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.wages.tableRowHover'
                              (click)='$event.preventDefault();' #ts17="ngbTooltip" (focus)='ts17.open()'
                              (blur)='ts17.close()' [ngbTooltip]="tipContentwage">
                              <span aria-hidden class="text-dark">{{value.wages}}</span>
                            </span>
                          </div>
                          <div
                            class="col-3 col-md-3  mobile-design-display text-center d-flex d-md-none justify-content-end px-0 ml-auto">
                            <div class="mr-2" *ngIf = "!restrictedAccountType">
                              <ng-template #tipContentsrat>
                                {{(occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover}}
                              </ng-template>
                              <a tabindex="0"
                                [attr.aria-label]='"Thumbs up Icon " + (occIndexListText)?.Occ_Buttons_Reducer_Text?.filters?.user.tableRowUpHover'
                                (keyup.enter)="$event.preventDefault();getUnFill({indexvalue:'title',occID:value.occID,occtitle:value.title,rating:value.rating},'up')"
                                (click)="$event.preventDefault();getUnFill({indexvalue:'title',occID:value.occID,occtitle:value.title,rating:value.rating},'up')"
                                #ts="ngbTooltip" (focus)='ts.open()' (blur)='ts.close()' [ngbTooltip]="tipContentsrat">
                                <i class="fillstroke thumbsIconSize"
                                  [ngClass]="(value['rating']== 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"
                                  aria-hidden></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- Display loading image -->
          <div *ngIf="showImg==-1" class="img-dataNotFound">
            <img alt="loading" class="dta-not-found-img w-100" src="{{CareersImages}}loading.png" />
          </div>
          <!-- Display noDataFound image -->
          <div *ngIf="showImg==0" class="img-dataNotFound" aria-label="No Data Found" tabindex="0">
            <img alt="" class="dta-not-found-img" width="100%" src="{{CareersImages}}nodata-found.png" />
          </div>
        </ul>
      </div>
    </div>
  </div>
  <nav aria-label="..." [ngStyle]="titleShow?{'display':'block'}:{'display':'none'}">
    <ul class="pagination flex-wrap" *ngIf="pager.pages && pager.pages.length">
      <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item disabled">
        <span class="page-link pointer" (click)="setPage(1,true)">First</span>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item disabled">
        <span (click)="setPage(pager.currentPage - 1,true)" class="page-link pointer">Previous</span>
      </li>
      <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
        <span class="page-link pointer" (click)="setPage(page,true)">
          <span>{{page}}</span>
        </span>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <span class="page-link pointer" (click)="setPage(pager.currentPage + 1,true)">Next</span>
      </li>
      <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item disabled">
        <span class="page-link pointer" (click)="setPage(pager.totalPages,true)">Last</span>
      </li>
    </ul>
  </nav>
  
  <!-- Display compare popup -->
  <div *ngIf="indexOccId.length == 2" class="modal fade" id="myModal7" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content content-color">
        <div class="modal-header blue">
          <div class="modal-title header_6 text-white">
            <b>{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.compare2Careers}}</b>
          </div>
          <button class="modal-close common-button default mt-1" #closeModal data-dismiss="modal">
            <i aria-label="Close">&times;</i>
          </button>
        </div>
        <div class="modal-body">
          <ul class="chips-design">
            <li *ngFor="let i of indexTitleName;let inx = index">
              <div class="header_6 pb-2">
                <div>{{i}}
                  <a tabindex="0" aria-label="Remove-button" class="pointer filter-Removebtn m-0 p-0" data-dismiss="modal"
                    (keyup.enter)="$event.preventDefault();checkCareer(inx,indexOccId[inx],i,'click','keyup')"
                    (keyup.space)="$event.preventDefault();checkCareer(inx,indexOccId[inx],i,'click','keyup')"
                    (click)="$event.preventDefault();checkCareer(inx,indexOccId[inx],i,'click')">
                    <i aria-hidden>&times;</i>
                  </a>
                </div>
              </div>
            </li>
          </ul>
          <div>
            <!-- Display compare button -->
            <button type="button" [attr.aria-label]='(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.compare'
              class="common-button green pull-right mt-3" data-dismiss="modal"
              [ngStyle]="(indexOccId.length < 2)  ? {'opacity':'0.5','pointer-events': 'none','cursor': 'pointer','border-radius':'6px'}:''"
              (click)="CompareOccupations()">{{(occIndexListText)?.Occ_Buttons_Reducer_Text?.common?.compare}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- <static-occ-footer  class="footerheightlength"></static-occ-footer> -->