<div class="p-t-b  mobie-width mobile-container bodypadding">
  <div class="row align-items-center py-2">
    <div [ngClass]="(backAssessmentValue)?'col-md-4':'col-md-5'">
      <!-- Display occupation name -->

      <div class="common-headding" #occCarrerFocus>
        <span><img alt="occresize" src="{{CareersImages}}occresize.png" /></span>
        <span class="title-wraps">{{(occName)}}</span>
      </div>
    </div>
    <div class="text-right" [ngClass]="(backAssessmentValue)?'col-md-8':'col-md-7 mt-md-1'">
      <div class="pull-right desktop-design-display">
        <!-- Display compare button -->
        <button type="button" class="common-button mb-0 green"
          [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.compare' (click)='showAllCareer()'
          data-toggle="modal" data-target="#myModal7">
          <b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.compare}}</b>
        </button>
        <!-- Display career list button -->
        <button type="button" class="common-button mb-0 green"
          [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.careersList' (click)="careerList('no')">
          <b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.careersList}}</b>
        </button>
        <!-- Display save button -->
        <!-- <button type="button" class="common-button green" [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.save'
        <!-- Display back to assessment button -->
        <button type="button" [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.backAssess'
          class="common-button mb-0 green" *ngIf="backAssessmentValue" (click)="backAssessment()">
          <b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.backAssess}}</b>
        </button>
        <!-- Display print button -->
        <button type="button" class="common-button mb-0 green" (click)="careersPrint()">
          <b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.print}}</b>
        </button>
      </div>
      <div class="btn-group btn-group-justified text-center  mobile-design-display">
        <!-- Display compare button in mobile-->
        <button type="button" [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.compare'
          class="btn common-button green" (click)='showAllCareer()' data-toggle="modal" data-target="#myModal7">
          <b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.compare}}</b>
        </button>
        <!-- Display career list button in mobile -->
        <button type="button" [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.careersList'
          class="btn common-button green" (click)="careerList('no')">
          <b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.careersList}}</b>
        </button>
        <!-- Display back to assessment button in mobile -->
        <button type="button" [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.backAssess'
          class="btn common-button green" *ngIf="backAssessmentValue" (click)="backAssessment()">
          <b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.backAssess}}</b>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <!--Display image-->
      <div *ngIf='(settingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image")!=-1'>
        <!-- <img *ngIf="(occCareerStore)?.Image==''" alt="header-career-image" src='/assets/images/school-header.jpg'
                                                                  class="w-100" /> -->
        <img *ngIf="(occCareerStore)?.Image!=''" alt="header-career-image" src='{{(occCareerStore)?.Image }}' class="w-100"
          class="w-100" onerror="this.onerror=null;this.src='/assets/images/careerDetail-header.png';" />

        <div class="rate-career-bg-black"></div>
        <div class="opacity-career-header row">
          <!--video popup-->
          <div class="col-lg-5  col-2 col-md-6 col-sm-2">
            <!-- <a class="video-icon" href="#myModal" data-toggle="modal" data-target="#myModal" [hidden]="((occCareerStore|async)?.Video|json) == '[]'">
              <img class="video-hight-plp3 pointer" src="/assets/images/video-icon-plp3.png" alt="" />
            </a> -->
            <ng-template #tipContent>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.video}}</ng-template>
            <a class="video-icon"
              [attr.aria-label]='"video-icon " + (occCareerText)?.Occ_Buttons_Reducer_Text?.common?.video'
              href="#myModal" data-toggle="modal" data-target="#myModal"
              [hidden]="((occCareerStore)?.Video|json) == '[]'" #t="ngbTooltip" (blur)='t.close()' placement="top"
              [ngbTooltip]="tipContent">
              <img alt="video-icon" class="video-hight-plp3 pointer" src="{{CareersImages}}video-icon-plp3.png" />
            </a>
          </div>
          <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header blue">
                  <button type="button" class="close plp3-model-close-align" (click)="videostop()" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body" *ngFor="let video of (occCareerStore)?.VideoWithText">
                  <video *ngIf="video.textURL; else notrack" crossorigin="anonymous" disablePictureInPicture
                    class="video-box" controls controlsList="nodownload" #videoplayer>
                    <source src="{{video.videoURL}}" type="video/mp4" />
                    <track src="{{video.textURL}}" type="text/vtt" kind="subtitles" srclang="en" label="On"
                      default />
                  </video>
                  <ng-template #notrack>
                    <video class="video-box" disablePictureInPicture controls controlsList="nodownload" #videoplayer>
                      <source src="{{video.videoURL}}" type="video/mp4" />
                    </video>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>


          <!-- User rating card for China-->
          <div class="col-lg-7 col-md-6 col-sm-10 col-10">
            <div class="pull-right">

              <div class="d-inline-flex" *ngIf="restrictedAccountType">
                <p class="rate-this-text">{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.UserRating}}</p>
                <span class="rating-up-down mr-2">
                  <a href="#" aria-label="Thumbs up icon" (click)="$event.preventDefault();getUnFill('up')">
                    <i class="fillstroke"
                      [ngClass]="(showCareerColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"></i>
                  </a>
                  <!-- <a href="#" aria-label="Thumbs down icon" (click)="$event.preventDefault();getUnFill('down')">
                    <i class="fillstroke" [ngClass]="(showCareerColorUp == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'" ></i>
                  </a> -->
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="career-tabs">
        <div class="ngb-tabset desktop-design-display">
          <ul class="nav nav-pills" role="tablist">
            <li role="tab" *ngFor='let val of (settingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index'
              class="nav-item" [attr.aria-selected]="(activeVal==inx)? true:false">
              <a class="nav-link " tabindex="0" [class.active]="(activeVal==inx)" (click)="dropDownVal(val.title,inx)"
                data-toggle="tab" href="#tab{{inx}}" [attr.aria-label]='val.title'>{{val.title}}</a>
              <div class="tab-down-arrow"></div>
            </li>
            <!-- User rating card for desktop-->
            <div align="center" class="rating-in-tabs"
              *ngIf='(settingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image") ==-1'>
              <div class="d-inline-flex" *ngIf="restrictedAccountType">
                <p class="text-white">{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.UserRating}}</p>
                <span class="rating-up-down rating-down-2">
                  <a href="#" (click)="$event.preventDefault();getUnFill('up')">
                    <i class="fillstroke" [ngClass]="(showCareerColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"
                      aria-hidden="true"></i>
                  </a>
                  <a href="#" (click)="$event.preventDefault();getUnFill('down')">
                    <i class="fillstroke"
                      [ngClass]="(showCareerColorUp == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'"
                      aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
          </ul>
        </div>
        <!-- User rating card for desktop-->
        <div class="ngb-tabset-mobile mobile-design-display  mb-3">
          <div class="d-table w-100">
            <ul class="nav nav-pills d-table-cell">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle font-size" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">{{value}}</a>
                <div class="dropdown-menu">
                  <a *ngFor='let val of (settingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index' class="nav-link "
                    [class.active]="(activeVal==inx)" (click)="dropDownVal(val.title,inx)" data-toggle="tab"
                    href="#tab{{inx}}" role="tab" [attr.aria-label]='val.title'>{{val.title}}</a>
                </div>
              </li>
            </ul>
            <div class=" d-table-cell"
              *ngIf='(settingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image") ==-1'>
              <div align="center" class="rating-in-tabs">
                <div class="d-inline-flex" *ngIf="restrictedAccountType">
                  <span class="rating-up-down rating-down-2">
                    <a href="#" (click)="$event.preventDefault();getUnFill('up')">
                      <i class="fillstroke" [ngClass]="(showCareerColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"
                        aria-hidden="true"></i>
                    </a>
                    <a href="#" (click)="$event.preventDefault();getUnFill('down')">
                      <i class="fillstroke"
                        [ngClass]="(showCareerColorUp == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'"
                        aria-hidden="true"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <!-- tab Values -->
      <div class="tab-content">
        <div *ngFor='let val of (settingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index' class="tab-pane "
          [class.active]="inx==0" [class.active]="(activeVal==inx)" id="tab{{inx}}" role="tabpanel">
          <div *ngIf='val.tabId == 0'>
            <at-a-glance [logo]="logo" [careerTitle]="occName" [tabTitle]="val.title" [frameWorkdata]="frameworkObj">
            </at-a-glance>
          </div>
          <div *ngIf='val.tabId == 1 && activeTab==1'>
            <job-description [logo]="logo" [careerTitle]="occName" [tabTitle]="val.title"
              [frameWorkdata]="frameworkObj"></job-description>
          </div>
          <div *ngIf='val.tabId == 2 && activeTab==2'>
            <wages [logo]="logo" [careerTitle]="occName" [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></wages>
          </div>
          <div *ngIf='val.tabId == 3 && activeTab==3'>
            <emp-and-outlook [logo]="logo" [careerTitle]="occName" [tabTitle]="val.title"
              [frameWorkdata]="frameworkObj"></emp-and-outlook>
          </div>
          <div *ngIf='val.tabId == 4 && activeTab==4'>
            <edu-and-traninig [logo]="logo" [careerTitle]="occName" [tabTitle]="val.title"
              [frameWorkdata]="frameworkObj"></edu-and-traninig>
          </div>
          <div *ngIf='val.tabId == 5 && activeTab==5'>
            <personal-qualities [logo]="logo" [careerTitle]="occName" [tabTitle]="val.title"
              [frameWorkdata]="frameworkObj"></personal-qualities>
          </div>
          <div *ngIf='val.tabId == 6 && activeTab==6'>
            <related-careers (changeViewRelate)="relate($event)" [logo]="logo" [careerTitle]="occName"
              [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></related-careers>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<!-- <static-occ-footer class="footerheightlength"></static-occ-footer> -->
<!--pop up for compare and save career-->
<div class="comparemodal">
  <div class="mobile-design-display  float-btn-plp3">
    <div class=''>
      <button aria-label="floating button" class='action-button' (click)="filter = !filter"
        [ngClass]="{'active': filter }">
        <span aria-hidden class='fa fa-plus'></span>
      </button>
      <ul class='actions'>
        <!-- <li class="pointer" *ngIf="restrictedAccountType" (click)="savePost()">
          <a>
            <span class='fa fa-floppy-o'></span>
          </a>
        </li> -->
        <li class="pointer" (click)="careersPrint()">
          <a>
            <span class='fa fa-print'></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal fade" id="myModal7" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content content-color">
        <!-- Header part in model -->
        <div class="modal-header blue">
          <div class="modal-title">
            <span id="exampleModalLabel"
              class="text-white"><b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.compare2Careers}}</b></span>
          </div>
          <button class="modal-close common-button default mt-1" data-dismiss="modal" (click)="cancleOccupation()">
            <i aria-label="Close">&times;</i>
          </button>
        </div>
        <!-- Body part in model -->
        <div class="modal-body">
          <div *ngIf="(indexOccId.length>=1)">{{this.indexTitleName[0]}}&nbsp;
            <a href="#" class="pointer" [ngStyle]="{'color':'red','font-size':'20px','position':'relative','top':'2px'}"
              (click)="$event.preventDefault();checkCareer(indexOccId[0])">
              <i>&times;</i>
            </a>
          </div>
          <div *ngIf="(indexOccId.length==2)">{{this.indexTitleName[1]}}&nbsp;
            <a href="#" class="pointer" [ngStyle]="{'color':'red','font-size':'20px','position':'relative','top':'2px'}"
              (click)="$event.preventDefault();checkCareer(indexOccId[1])">
              <i>&times;</i>
            </a>
          </div>
          <br>
          <div>
            <p *ngIf="(indexOccId.length<2) && (indexOccId.length !== 0)"> Select one more career to compare</p>
            <p *ngIf="(indexOccId.length == 0)"> Select at least one career</p>
            <button *ngIf="(indexOccId.length<2)" type="button"
              [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.save'
              class="common-button green  pull-right" data-dismiss="modal"
              (click)="saveOccupation()">{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.save}}</button>
            <button *ngIf="(indexOccId.length==2)" type="button"
              [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.compare'
              class="common-button green  pull-right" data-dismiss="modal"
              (click)="compareOccupations()">{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.compare}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>