
    <ngbd-modal-snackbar></ngbd-modal-snackbar>
    <ngbd-modal-snackbar-red></ngbd-modal-snackbar-red>
    <ng-template ngbModalContainer></ng-template>
    <ngx-spinner size="medium" type="ball-spin-clockwise"></ngx-spinner>
    <!--<ng4-loading-spinner style="z-index:9999999999999;position: relative;"> </ng4-loading-spinner>-->
    <div id="wrapper-body">
      <a
        (click)="skipLink()"
        href
        onclick="return false;"
        class="sr-only sr-only-focusable btn btn-link "
        accesskey="k"
      >
        <span class="skip-maincontent btn-primary d-block">
          Skip to content
        </span>
      </a>
      <div id="main-wrapper-body">
        <div id="main-body" tabindex="-1" class="pb-3">
          <router-outlet></router-outlet>

          <static-footer class="footerheightlength"></static-footer>
        </div>
      </div>
    </div>
  