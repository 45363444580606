<div role="tablist" aria-multiselectable="true">
  <div class="career-card cardcolor-28 " *ngIf='(settingsTextTab?.sections).indexOf("Preparation") != -1'>
    <!-- methodfilter method for div expandable and collapsible based on the value -->
    <div class="career-card-header pointer" role="tab" id="headingOne" activeIds="1" (click)="(filter != 1)?methodfilter(1):methodfilter(999)">
      <div class="card-title">
        <img src='{{fontIcons}}preparation.svg' class="mx-1" alt='' />

        <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Preparation}}</span>
      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(filter != 1)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(filter != 1)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="filter == 1" class="icon-up-rounded-arrow icn-font-size"></i>
        </button>
      </div>

    </div>
    <div id="1" #filter1 *ngIf="filter == 1" class="" role="tabpanel" aria-labelledby="headingOne">
      <div class="card-block ">
        <div *ngFor="let item of(eduandtraining|async)?.Preparation">
          <p *ngIf="item.type == 'p'" [innerHtml]="item.text | MarkdownToLink"></p>
          <ul class="list pr-4" *ngIf="item.type == 'ul'">
            <li *ngFor="let val of item.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>

          <div class='header_6 text-white' *ngIf="item.type == 'h'">
            <b [innerHtml]="item.text | MarkdownToLink"></b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="career-card cardcolor-14" *ngIf='(settingsTextTab?.sections).indexOf("HelpfulHSCourses") != -1'>
    <div class="career-card-header  pointer" role="tab" id="headingTwo" (click)="(filter != 2)?methodfilter(2):methodfilter(999)">
      <div class="card-title">
        <span>
          <i class="icon-helpful-high-school-courses panal-icon-align" aria-hidden="true"></i>
        </span>
        <span class="w-100" tabindex="0">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.HelpfulHSCourses}}</span>
      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(filter != 2)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(filter != 2)" class="icon-down-rounded-arrow  icn-font-size"></i>
          <i *ngIf="filter == 2" class="icon-up-rounded-arrow icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="2" #filter2 *ngIf="filter == 2" class="" role="tabpanel" aria-labelledby="headingTwo">
      <div class="card-block ">
        <div *ngFor="let item of (eduandtraining|async)?.HelpfulHSCourses">
          <p *ngIf="item.type == 'p'" [innerHTML]='item.text | MarkdownToLink '></p>
          <ul class="list pr-4" *ngIf="item.type == 'ul'">
            <li *ngFor="let val of item.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="career-card Occupation-Sort-card" *ngIf='(settingsTextTab?.sections).indexOf("LicensingCert") != -1 && (eduandtraining|async)?.LicensingCert?.length'>
    <div class="career-card-header  pointer" role="tab" id="headingThree" (click)="(filter != 3)?methodfilter(3):methodfilter(999)">

      <div class="card-title">
        <span>
          <i class="icon-licensing-certification panal-icon-align" aria-hidden="true"></i>
        </span>
        <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.LicensingCert}}</span>
      </div>
      <div class="d-table-cell align-middle pt-1">
        <button class="common-button default m-0" [attr.aria-label]="(filter != 3)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(filter != 3)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="filter == 3" class="icon-up-rounded-arrow icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="3" #filter3 *ngIf="filter == 3" class="" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block pl-4">
        <div *ngFor="let item of (eduandtraining|async)?.LicensingCert">
          <p *ngIf="item.type == 'p'" [innerHTML]='item.text | MarkdownToLink '></p>
          <ul *ngIf="(item.type == 'ul')" class="list pr-4">
            <li *ngFor="let val of item.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
          <div class='header_6 text-white' *ngIf="(item.type == 'h')">
            <b [innerHtml]="item.text | MarkdownToLink"></b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="career-card cardcolor-8" *ngIf='(settingsTextTab?.sections).indexOf("RelatedPrograms") != -1'>
    <div class="career-card-header  pointer" role="tab" id="headingThree" (click)="(filter != 4)?methodfilter(4):methodfilter(999)">
      <div class="card-title">
        <span>
          <i class="icon-programs-of-study panal-icon-align" aria-hidden="true"></i>
        </span>
        <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedPrograms}}</span>
      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(filter != 4)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(filter != 4)" class="icon-down-rounded-arrow  icn-font-size"></i>
          <i *ngIf="filter == 4" class="icon-up-rounded-arrow   icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="4" #filter3 *ngIf="filter == 4" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block ">
        <p>{{(eduandtraining|async)?.RelatedPrograms?.intro}}</p>
        <div>
          <p>
            <b>{{(eduandtraining|async)?.RelatedPrograms?.lists[0]?.header}}</b>
          </p>
          <ul class="list pr-4">          
            <li *ngFor="let val of (eduandtraining|async)?.RelatedPrograms?.lists[0]?.links">
              <a href="#" class="pointer" (click)="$event.preventDefault();callProgsDetails(val.linkID,val.linkTitle)">{{val.linkTitle}}</a>
            </li>
          </ul>
        </div>
        <div>
          <p>
            <b>{{(eduandtraining|async)?.RelatedPrograms?.lists[1]?.header}}</b>
          </p>
          <ul class="list pr-4">          
            <li *ngFor ="let val of (eduandtraining|async)?.RelatedPrograms?.lists[1]?.links">
              <a href="#" class="pointer" (click)="$event.preventDefault();callProgsDetails(val.linkID,val.linkTitle)">{{val.linkTitle}}</a>
            </li>
          </ul>        
      </div>
    </div>
  </div>
</div>
<!-- OCC-EMERG Print Start -->
<div class="hidden-page">
<div class="print-result-page" #educationPrint>
  <div class="header d-flex align-items-center justify-content-between border-bottom">
    <div class="common-headding d-flex align-items-center">
        <div class="icon-occresize-sprite"></div>
      <span class="title-wraps pl-1 pt-2">{{careerTitle}}</span>
    </div>
    <div class="print-header-logo">
      <img alt="Career Information System-logo" class="float-right" [src]="logo">
    </div>
  </div>
  <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <div *ngIf='(settingsTextTab?.sections).indexOf("Preparation") != -1'>
        <div class="career-card cardcolor-28">
          <div class="career-card-header font-weight-bold">
            <div class="icon-preparation-sprite mr-2" ></div>
            {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Preparation}}
          </div>
        </div>
        <div class="pl-3" *ngFor="let item of(eduandtraining|async)?.Preparation">
          <p class="mb-2" *ngIf="item.type == 'p'">{{item.text}}</p>
          <ul class="list-unstyled career-indent pl-3" *ngIf="item.type == 'ul'">
            <li *ngFor="let val of item.text">
              <div class="headding-arrow pl-2">{{val}}</div>
            </li>
          </ul>
          <div class="font-weight-bold" *ngIf="item.type == 'h'">{{item.text}}</div>
        </div>
      </div>
    <div class="html2pdf__page-break"></div>
      <div *ngIf='(settingsTextTab?.sections).indexOf("HelpfulHSCourses") != -1'>
        <div class="career-card cardcolor-14 mt-4">
          <div class="career-card-header font-weight-bold">
            <div class="icon-helpful_high_school_courses-sprite mr-2" ></div>
            <span>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.HelpfulHSCourses}}</span>
          </div>
        </div>
        <div class="pl-3" *ngFor="let item of (eduandtraining|async)?.HelpfulHSCourses">
          <!-- The white-space class is added to overcome the text over-lapping issue -->
          <p class="card-content white-space text-secondary mb-1" *ngIf="item.type == 'p'" [innerHTML]='item.text | PrintWeblinkPipe'></p>
          <ul class="list-unstyled" *ngIf="item.type == 'ul'">
                <li *ngFor="let val of item.text">
                  <div class="headding-arrow pl-2">{{val}}</div>
                </li>
           </ul>
        </div>
      </div>
    <div *ngIf='(settingsTextTab?.sections).indexOf("LicensingCert") != -1 && (eduandtraining|async)?.LicensingCert?.length' class="html2pdf__page-break"></div>
     <div *ngIf='(settingsTextTab?.sections).indexOf("LicensingCert") != -1 && (eduandtraining|async)?.LicensingCert?.length'>
        <div class="career-card Occupation-Sort-card mt-4">
          <div class="career-card-header font-weight-bold">
            <div class="icon-licensing_certification-sprite mr-2" ></div> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.LicensingCert}}
          </div>
        </div>
        <div *ngFor="let item of (eduandtraining|async)?.LicensingCert">
          <p  class="text-secondary mb-1 pl-4" *ngIf="item.type == 'p'" [innerHTML]='item.text | PrintWeblinkPipe'></p>
          <ul *ngIf="(item.type == 'ul')" class="list-unstyled">
            <li *ngFor="let val of item.text">
              <div class="headding-arrow pl-3">{{val}}</div>
            </li>
          </ul>
          <div class='px-3 header_6 text-white' *ngIf="(item.type == 'h')">
            <b>{{item.text}}</b>
          </div></div>
      </div>
    <div class="html2pdf__page-break"></div>
    <div *ngIf='(settingsTextTab?.sections).indexOf("RelatedPrograms") != -1'>
      <div class="career-card cardcolor-8 mt-4">
        <div class="career-card-header font-weight-bold">
          <div class="icon-programs_of_study-sprite mr-2" aria-hidden="true"></div><span>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedPrograms}}</span>
        </div>
      </div>
      <div class="pl-3">
        <p>{{(eduandtraining|async)?.RelatedPrograms?.intro}}</p>
        <div>
          <div class="font-weight-bold">{{(eduandtraining|async)?.RelatedPrograms?.lists[0]?.header}}</div>
          <ul class="list-unstyled">
            <li *ngFor="let val of (eduandtraining|async)?.RelatedPrograms?.lists[0]?.links">
             <div class="headding-arrow pl-2">{{val.linkTitle}}</div>
            </li>
          </ul>
        </div>
        <div>
          <div class="font-weight-bold">{{(eduandtraining|async)?.RelatedPrograms?.lists[1]?.header}}</div>
          <ul class="list-unstyled">
            <li *ngFor="let val of (eduandtraining|async)?.RelatedPrograms?.lists[1]?.links">
             <div class="headding-arrow pl-2">{{val.linkTitle}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>    
  </div>
  <div class="mt-4 pt-4 text-center">
    <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
  </div>
</div>
</div><!-- OCC-EMERG Print End -->