<div class="career-ataglance career-jr">
  <div class="row gutter-10">
    <div class="col-lg-12 col-md-12"
      *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription")!= -1'>
      <p class="header_5 header-text-color">
        <b>{{CareerJrCareerStore.ShortDescription}}</b></p>
    </div>
    <div class="row gutter-10">
      <!-- Card that describe about TopOverview-->
      <div class="col-lg-4 col-md-4" *ngIf='(settingsTextTab?.sections).indexOf("TopOverview")!= -1'>
        <div tabindex="0">
          <div class="career-card emerging-card cardcolor-50 p-3 p-md-0 px-md-1">
            <div class="cluster-at-glance text-white d-flex align-items-center">
              <div>
              <div *ngFor="let item of (CareerJrCareerStore).TopOverview;let inx=index;">
                <div *ngIf="(item.type == 'ul')">
                  <div *ngFor="let val of item.text">
                    <p class="text-center font-weight-bold mb-1" [innerHtml]="val | MarkdownToLink"></p>
                  </div>
                </div>
                <div  *ngIf="(item.type == 'p')">
                  <div class="card-block py-4 text-center">
                    <!-- <i class="icon-question_mark panal-icon-align" aria-hidden="true"></i> -->
                    <img src="{{fontIcons}}question_mark.svg" alt="questionmark" />
                  </div>
                  <p class="mb-2 mx-1 text-center" [innerHtml]="item.text | MarkdownToLink"></p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <!-- Card that describe about ClusterDefinition-->
        
        <div *ngIf='(settingsTextTab?.sections).indexOf("ClusterDefinition")!= -1 && showClusterBox' tabindex="0">
          <div class="career-card cluster-card-border" (click)="careerJrRedirect()">
            <div class="card-block card-block2-p-plp3 align-middle d-table-cell cluster-noun-text text-left"
              [ngStyle]="{'cursor':'default'}">
              <div class="d-flex flex-column h-100 justify-content-center pointer">
                <div *ngFor="let value of (CareerJrCareerStore).ClusterDefinition">
                  <div class="text-left px-3 cluster-text header_3" *ngIf="(value.type == 'h')">
                    <b [innerHtml]="value.text | MarkdownToLink"></b>
                  </div>
                  <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                    <i class="cluster-text" [innerHtml]="value.text | MarkdownToLink"></i>
                  </p>
                  <ol *ngIf="(value.type == 'ol')" class="text-left m-0 cluster-text">
                    <li *ngFor="let val of value.text">
                      <div class="font-weight-bold" [innerHtml]="val | MarkdownToLink"></div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card that describe about parant cluster-->
        <div *ngIf='(settingsTextTab?.sections).indexOf("ParentCluster")!= -1' tabindex="0">
          <div class="career-card"
            [ngStyle]="{border : '1px solid' + (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}"
            (click)="$event.preventDefault();
                  workplaceRedirect(CareerJrCareerStore.ParentCluster?.linkID,CareerJrCareerStore.ParentCluster?.linkTitle,CareerJrCareerStore.ParentCluster?.parentClusterIcon,(CareerJrCareerStore).ParentCluster?.parentClusterColor)">
            <div class="card-block card-block2-p-plp3 align-middle d-table-cell pointer">
              <div class="d-flex flex-column h-100 justify-content-center">
                <div class="mt-1">
                  <div class="header_5 font-weight-bold" [ngStyle]="{color : (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">
                    {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.ParentCluster}}
                  </div>
                </div>
                <i [ngStyle]="{color: (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}"
                  class="{{(CareerJrCareerStore).ParentCluster?.parentClusterIcon}}" aria-hidden="true"></i>
                <i aria-hidden="true"></i>
                <div class="header_6 font-weight-bold" [ngStyle]="{color : (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">{{
                  (CareerJrCareerStore).ParentCluster?.linkTitle}}</div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <!-- Card that describe about CCi Score-->
      <div class="col-lg col-md col-12 pointer" *ngIf='(settingsTextTab?.sections).indexOf("CCIScore") != -1'>
        <div tabindex="0" *ngIf="((CareerJrCareerStore)?.CCIScore?.taken==false)">
          <div class="career-card"
            [ngStyle]="{'background': atAGlance.clusterColor}"
            (click)="$event.preventDefault();goToCCI((CareerJrCareerStore)?.CCIScore?.taken)">
            <div class="cluster-at-glance p-0">
              <div class="cluster-icn-div-two d-table m-auto" align="center">
                <div class="d-table-cell align-middle">
                  <i class="{{(CareerJrCareerStore).ParentCluster?.parentClusterIcon}}" aria-hidden="true"></i>
                </div>
              </div>            
              <div class="bg-opacity-text  d-table">
                <p class="mt-3 mb-0 py-4">{{(CareerJrCareerStore)?.CCIScore?.text}} </p>             
                  <img alt="CCI Quick Pic icon"  src="{{LandingImages}}baby_CCI.png"/>                
              </div>          
            </div>
          </div>
        </div>  
        <div tabindex="0" *ngIf="((CareerJrCareerStore)?.CCIScore?.taken==true)">
          <div class="career-card d-table pointer"
          [ngStyle]="{'background': atAGlance?.clusterColor}"
          (click)="$event.preventDefault();goToCCI((CareerJrCareerStore)?.CCIScore?.taken)">
          <div class="card-block cluster-at-glance scroll-class py-3">
            <div align="center" class="">
              <div class="header_6 pb-3 justify-content-center text-white">{{(CareerJrCareerStore).ParentCluster?.linkTitle}}</div>
              <div class="school-vertical-graph justify-content-center"
                *ngIf="((CareerJrCareerStore)?.CCIScore?.level!=-1)">
                <div>
                  <div class="cluster-icn" align="center" *ngIf="((CareerJrCareerStore)?.CCIScore?.level==0)">
                    <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                  </div>
                  <div [ngStyle]="{'background':CCIcolor['low']}" class="low-bar"></div>
                </div>
                <div>
                  <div class="cluster-icn" align="center" *ngIf="((CareerJrCareerStore)?.CCIScore?.level==1)">
                    <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                  </div>
                  <div [ngStyle]="{'background':CCIcolor['medium']}" class="medium-bar"></div>
                </div>
                <div>
                  <div class="cluster-icn" align="center" *ngIf="((CareerJrCareerStore)?.CCIScore?.level==2)">
                    <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                  </div>
                  <div [ngStyle]="{'background':CCIcolor['high']}" class="high-bar"></div>
                </div>
              </div>
              <p class="pt-3 mx-1 font-weight-bold">{{(CareerJrCareerStore)?.CCIScore?.text}}</p>
            </div>
          </div>
        </div>
        </div>              
      </div>
    </div>
  </div>
</div>
<!-- at-a-glance print page-->
<div class="hidden-page">
  <div class="print-result-page" #atAGlancePrintPage>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="{{clusterIcon}}_color-sprite"></div>
        <span>{{clusterTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right cis-logo" src="{{logo}}" width="50%">
      </div>
    </div>
    <div class="main-body white-space career-jr">
      <div class="careers-title">{{tabTitle}}</div>

      <div class="header-text-color" *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription")!= -1'>
        <p class="font-weight-bold">{{CareerJrCareerStore.ShortDescription}} </p>
      </div>
      <div class="d-flex flex-wrap mt-3">
        <div class="flex-fill w-50 pr-1">
          <!-- Card that describe about TopJobTasks-->   
          <div class="career-card cardcolor-50 w-100">
            <div class="card-block career-card-body text-white">
              <div *ngFor="let item of (CareerJrCareerStore).TopOverview;let inx=index;">
                <div *ngIf="(item.type == 'ul')">
                  <div *ngFor="let val of item.text">
                    <p class="text-center font-weight-bold mb-0">{{val}}</p>
                  </div>
                </div>
                <div *ngIf="(item.type == 'p')">
                  <div
                    class="card-block align-middle justify-content-center align-items-center d-flex">
                    <div class="icon-questin_mark-sprite"></div>
                  </div>
                  <p class="text-center">{{item.text}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card that describe about CCI Score-->
        <div class="flex-fill w-50  pl-1" *ngIf='(settingsTextTab?.sections).indexOf("CCIScore") != -1'>
          <div *ngIf="((CareerJrCareerStore)?.CCIScore?.taken==false)" class="career-card"
            [ngStyle]="{'background': atAGlance.clusterColor}">
            <div class="card-block cluster-at-glance p-0">
              <div class="cluster-icn-div-two d-table m-auto" align="center">
                <div class="d-table-cell align-middle">
                  <div class="{{clusterIcon}}_white-sprite m-auto"></div>
                </div>
              </div>
              <div class="bg-opacity-text d-table m">
                <p class="mb-0 py-2">{{(CareerJrCareerStore)?.CCIScore?.text}} </p>
                <div class="icon-quick_pic-sprite m-auto"></div>
              </div>
            </div>
          </div>

          <div *ngIf="((CareerJrCareerStore)?.CCIScore?.taken==true)" class="career-card d-table"
            [ngStyle]="{'background': atAGlance?.clusterColor}">
            <div class="card-block career-card-body d-table-cell align-middle py-3">
              <div align="center">
                <div class="header_6 pb-3 justify-content-center text-white">{{(CareerJrCareerStore).ParentCluster?.linkTitle}}</div>
                <div class="school-vertical-graph justify-content-center"
                  *ngIf="((CareerJrCareerStore)?.CCIScore?.level!=-1)">
                  <div>
                    <div class="cluster-icn" align="center" *ngIf="((CareerJrCareerStore)?.CCIScore?.level==0)">
                      <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                    </div>
                    <div [ngStyle]="{'background':CCIcolor['low']}" class="low-bar"></div>
                  </div>
                  <div>
                    <div class="cluster-icn" align="center" *ngIf="((CareerJrCareerStore)?.CCIScore?.level==1)">
                      <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                    </div>
                    <div [ngStyle]="{'background':CCIcolor['medium']}" class="medium-bar"></div>
                  </div>
                  <div>
                    <div class="cluster-icn" align="center" *ngIf="((CareerJrCareerStore)?.CCIScore?.level==2)">
                      <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                    </div>
                    <div [ngStyle]="{'background':CCIcolor['high']}" class="high-bar"></div>
                  </div>
                </div>
                <p class="pt-3 font-weight-bold">{{(CareerJrCareerStore)?.CCIScore?.text}}</p>
              </div>
            </div>
          </div>
        </div>       
      </div>
      <div class="d-flex flex-wrap mt-3">
        <div class="flex-fill w-50 pr-1" *ngIf='showClusterBox'>
          <div class="career-card cluster-card-border d-table w-100">
            <div class="card-block align-middle d-table-cell cluster-noun-text text-left"
              [ngStyle]="{'cursor':'default'}">
              <div *ngFor="let value of (CareerJrCareerStore).ClusterDefinition">
                <div class="text-left px-3 text-white header_3 cluster-text" *ngIf="(value.type == 'h')">
                  <b>{{value.text}}</b>
                </div>
                <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                  <i class="cluster-text">{{value.text}}</i>
                </p>
                <ol *ngIf="(value.type == 'ol')" class="text-left cluster-text">
                  <li *ngFor="let val of value.text">
                    <div class="font-weight-bold">{{val}}</div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1">
          <!-- Card that describe about parant cluster-->
          <div class="career-card d-table w-100"
            [ngStyle]="{border: '1px solid' + (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">
            <div class="card-block align-middle d-table-cell cluster-noun-text text-center">
              <div class="mt-1">
                <div class="header_5 font-weight-bold" [ngStyle]="{color: (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">
                  {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.ParentCluster}}
                </div>
                <div class="{{(CareerJrCareerStore).ParentCluster?.parentClusterIcon}}_color-sprite mx-auto my-2"></div>
              </div>
              <div class="header_6 font-weight-bold" [ngStyle]="{color: (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">
                {{(CareerJrCareerStore).ParentCluster?.linkTitle}}</div>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1" *ngIf='!showClusterBox'>
          <div class="career-card cardcolor-50 w-100">
            <div
              class="card-block card-block2-p-plp3 align-middle p-2 d-flex justify-content-center flex-column scroll-class cluster-noun-text text-left"
              [ngStyle]="{'cursor':'default'}">
              <div class="career-cluster-panel pb-3 pt-xl-1 pt-lg-0 pt-md-3" *ngIf="occCareerStore.HotJob.rating">
                <div class="career-cluster-icon"><img alt="career-hotjob" width="35"
                    src="{{CareersImages}}career-hotjob.png" /></div>
                <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                  [innerHTML]="occCareerStore.HotJob.text |BoldPipe"></div>
              </div>
              <div class="career-cluster-panel" *ngIf="occCareerStore.STEM.rating">
                <div class="career-cluster-icon"><img alt="career-stem" width="35"
                    src="{{CareersImages}}career-stem.png" />
                </div>
                <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                  [innerHTML]="occCareerStore.STEM.text |BoldPipe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="html2pdf__page-break"></div> -->
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{copyright}}</p>
    </div>
  </div>
</div>