<div class="employment-outlook">
  <div class="eo-allcards-box">
    <div *ngIf='(settingsTextTab?.sections).indexOf("OutlookRatings")!= -1'>
      <p class="header_5 header-text-color"><b>{{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.OutlookRatings}}</b></p>
      <div *ngFor="let item of (occCareerStore)?.OutlookRatings?.states | StatesName;let inx=index"
        class="row gutter-10 eo-card-bottom-align mb-1">
        <div class="col-lg-2 col-md-2">
          <!-- Selected state name -->
          <div class="location-box" align="center">
            <b>{{item.state}}</b>
          </div>
        </div>
        <div class="col-lg-10 col-md-10 col-sm-12 ">
          <div class="row gutter-10">
            <!-- Current Employment card -->
            <div class="col-lg-4 col-md-4 col-sm-12 ">
              <div class="location-card">
                <div class="row ">
                  <div class="col-lg-8 col-md-8 col-sm-8 col-8 location-heading">
                    <b>{{(occCareerStore)?.OutlookRatings?.headers[0].header}}</b>
                    <br>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                    <ul id="empId" class="chart-mini eo-location-card-graph">
                      <li *ngFor="let currentitem of [0,1,2,3,4,-1];let inx=index">
                        <span [ngStyle]="(currentitem == item.ratings[0].ratingID)? {'background-color':'#2c974b'}:{}"></span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="row employee-states">
                  <div class="col-lg-5 col-md-5 col-sm-5 col-5">
                    <div class="row box-status-text">
                      {{item.ratings[0].rating}}
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-7 col-7 text-right pl-0 pr-3">
                    <div class="row box-avg-text">
                      {{item.ratings[0].amount}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Job Growth card -->
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="location-card">
                <div class="row">
                  <div class="col-lg-8 col-md-8 col-sm-8 col-8 location-heading">
                    <b>{{(occCareerStore)?.OutlookRatings?.headers[1].header}}</b>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-4 text-right">
                    <img alt="average" *ngIf="item.ratings[1].ratingID == 2" src="{{CareersImages}}average.png"
                      [ngStyle]="{'width':'40px'}" />
                    <img alt="bright" *ngIf="item.ratings[1].ratingID == 3" src="{{CareersImages}}bright.png"
                      [ngStyle]="{'width':'40px'}" />
                    <img alt="poor" *ngIf="item.ratings[1].ratingID == 1" src="{{CareersImages}}poor.png"
                      [ngStyle]="{'width':'40px'}" />
                    <img alt="declining" *ngIf="item.ratings[1].ratingID == 0" src="{{CareersImages}}declining.png"
                      [ngStyle]="{'width':'40px'}" />
                  </div>
                </div>
                <div class="row employee-states">
                  <div class="col-lg-5 col-md-5 col-sm-5 col-5"> {{item.ratings[1].rating}} </div>
                  <div class="col-lg-7 col-md-7 col-sm-7 col-7 text-right">{{item.ratings[1].amount}}</div>
                </div>
              </div>
            </div>
            <!-- Employment Opportunities card -->
            <div class="col-lg-4 col-md-4 col-sm-12 ">
              <div class="location-card">
                <div class="row location-heading">
                  <div class="col-lg-8 col-md-8 col-sm-8 col-8">
                    <b>{{(occCareerStore)?.OutlookRatings?.headers[2].header}}</b>

                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                    <ul class="chart-mini-1 eo-location-card-graph">
                      <li *ngFor="let item1 of [0,1,2];let inx=index">
                        <span [ngStyle]="(item1 == item.ratings[2].ratingID)? {'background-color':'#2c974b'}:{}"></span>
                      </li>
                    </ul>

                  </div>
                </div>

                <div class="row employee-states">
                  <div class="col-lg-7 col-md-7 col-sm-5 col-5">
                    <div class="row box-status-text"> {{item.ratings[2].rating}} </div>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-7 col-7 text-right pl-0">
                    <div class="row box-avg-text">{{item.ratings[2].amount}}</div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row gutter-10 mb-2">
      <div class="col-lg-2 col-md-2">
        <!-- select state button -->
        <div ngbDropdown class="d-inline-block w-100">
          <button type="button" [attr.aria-label]='(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.selectStates' class="common-button select-state-btn w-100 m-0" (click)="checkopen()" data-toggle="collapse" href="#collapseExample3"
            aria-expanded="false" aria-controls="collapseExample3">
            <b>{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.selectStates}}</b>
          </button>
        </div>
      </div>


      <!-- drop down toggel start -->
      <div class="col-12 col-md-12 col-lg-12 p-b">
        <div class="collapse slider-drop-down-2" id="collapseExample3">
          <div class="card ">
            <div class="card-header-slider">
              {{(occCareerText)?.careersJr_Buttons_Reducer_Text?.select?.intro}}
            </div>
            <div class="card-block-slider-filter">
              <div class="statelist ml-2">
                <div class="statelist-items" *ngFor="let stateName of (occCareerText)?.careersJr_Buttons_Reducer_Text?.select?.states;let checkId=index;">
                  <ul class="list-unstyled ml-2">
                    <li>
                      <div class="common-checkbox">

                        <input type="checkbox" (keydown)="count(stateName.abbr,$event)" (click)="count(stateName.abbr,'click')" id="emptest{{checkId}}"
                          [checked]="duplicateState.indexOf(stateName.abbr) != -1" [disabled]="duplicateState.indexOf(stateName.abbr) == -1 && displayValue>=5"
                        />
                        <label for="emptest{{checkId}}" class="d-flex" [class.disabledp2]="duplicateState.indexOf(stateName.abbr) == -1 && displayValue>=5">
                          <p class="ml-2">{{stateName.name}} </p>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- buttons to display cancel and reset selected states -->
            <div class="card-footer-slider  text-right">
              <button class="btn-common btn-success mr-1" data-toggle="collapse" (click)="showStates()" href="#collapseExample3" aria-expanded="false"
                aria-controls="collapseExample" type="button" [attr.aria-label]='(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.apply'>{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.apply}}</button>
              <button type="button" [attr.aria-label]='(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.reset' class="btn-common btn-warning mr-1" (click)="resetStates()">{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.reset}}</button>
              <button class="btn-common btn-danger" data-toggle="collapse" (click)="checkopen()" href="#collapseExample3" aria-expanded="false"
                aria-controls="collapseExample" type="button" [attr.aria-label]='(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.cancel'>{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.cancel}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row gutter-10">
    <!-- Major Employers card -->
    <div class="col-lg-8 col-md-8 col-12" *ngIf='(settingsTextTab?.sections).indexOf("MajorEmployers")!= -1'>
      <div class="career-card cardcolor-25">
        <div class="career-card-header">
          <div class="card-title p-0">
            <i aria-hidden="true " class="icon-major-employers-icon panal-icon-align"> </i> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.MajorEmployers}}
          </div>
        </div>
        <div class="card-block  employment-outlook-card-height scroll-class">
          <div class="mt-1" *ngFor="let item of (occCareerStore)?.MajorEmployers;let inx=index;">
            <p *ngIf="(item.type == 'p')" [innerHtml]="item.text | MarkdownToLink"></p>
            <div *ngIf="(item.type == 'ul')">
              <ul *ngFor="let val of item.text" class="list">
                <li class="mb-1" [innerHtml]="val | MarkdownToLink"></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Opportunities card -->
    <div class="col-lg-4 col-md-4 col-12" *ngIf='(settingsTextTab?.sections).indexOf("Opportunities")!= -1'>
      <div class="career-card cardcolor-3">
        <div class="career-card-header">
          <div class="card-title px-2">            
            <!-- <i class="icon-opportunity panal-icon-align" aria-hidden="true"></i>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Opportunities}} -->
            <img class="panal-icon-align" src="{{fontIcons}}opportunity.svg" alt="opportunity" />{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Opportunities}}
          </div>
        </div>
        <div class="card-block employment-outlook-card-height scroll-class ">

          <div *ngFor="let value of (occCareerStore)?.Opportunities">
            <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
            <ul *ngIf="(value.type == 'ul')" class="list">
              <li *ngFor="let val of value.text" [innerHtml]="val | MarkdownToLink"> </li>
            </ul>
            <div class='header_6 px-3 text-white' *ngIf="(value.type == 'h')">
              <b [innerHtml]="value.text | MarkdownToLink"></b>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- print Start Employment Outlook  -->
<div class="hidden-page">
  <div class="print-result-page" #empOutLookPrintPage> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-careersJr-sprite mr-1"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{logo}}" width="50%"> 
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <p class="header_5 header-text-color"><b>{{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.OutlookRatings}}</b></p>
      <div class="employment-outlook">
          <div class="eo-allcards-box">
            <div *ngIf='(settingsTextTab?.sections).indexOf("OutlookRatings")!= -1'>
              <div *ngFor="let item of (occCareerStore)?.OutlookRatings?.states | StatesName;let inx=index"
                class="row gutter-10 eo-card-bottom-align mb-1">
                <div class="col-2">
                  <!-- Selected state name -->
                  <div class="location-box states-box">
                    <b>{{item.state}}</b>
                  </div>
                </div>
                <div class="col-10">
                  <div class="row gutter-10">
                    <!-- Current Employment card -->
                    <div class="col-4">
                      <div class="location-card">
                        <div class="row ">
                          <div class="col-lg-8 col-md-8 col-sm-8 col-8 location-heading">
                            <b>{{(occCareerStore)?.OutlookRatings?.headers[0].header}}</b>
                            <br>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4 col-4">
                            <ul id="empId" class="chart-mini eo-location-card-graph">
                              <li *ngFor="let currentitem of [0,1,2,3,4,-1];let inx=index">
                                <span [ngStyle]="(currentitem == item.ratings[0].ratingID)? {'background-color':'#2c974b'}:{}" style="animation: none;"></span>
                              </li>
                            </ul>
                          </div>
                        </div>
        
                        <div class="row employee-states">
                          <div class="col-lg-5 col-md-5 col-sm-5 col-5">
                            <div class="row box-status-text">{{item.ratings[0].rating}}</div>
                          </div>
                          <div class="col-lg-7 col-md-7 col-sm-7 col-7 text-right pl-0 pr-3">
                            <div class="row box-avg-text">{{item.ratings[0].amount}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Job Growth card -->
                    <div class="col-4">
                      <div class="location-card">
                        <div class="row">
                          <div class="col-lg-8 col-md-8 col-sm-8 col-8 location-heading">
                            <b>{{(occCareerStore)?.OutlookRatings?.headers[1].header}}</b>
                          </div>
                          <div>
                            <div *ngIf="item.ratings[1].ratingID == 2" class="icon-average-sprite"></div>
                            <div *ngIf="item.ratings[1].ratingID == 3" class="icon-bright-sprite"></div>
                            <div *ngIf="item.ratings[1].ratingID == 1" class="icon-poor-sprite"></div>
                            <div *ngIf="item.ratings[1].ratingID == 0" class="icon-declining-sprite"></div>
                          </div>
                        </div>
                        <div class="row employee-states">
                          <div class="col-lg-5 col-md-5 col-sm-5 col-5">{{item.ratings[1].rating}}</div>
                          <div class="col-lg-7 col-md-7 col-sm-7 col-7 pr-3 text-right">{{item.ratings[1].amount}}</div>
                        </div>
                      </div>
                    </div>
                    <!-- Employment Opportunities card -->
                    <div class="col-4">
                      <div class="location-card">
                        <div class="row location-heading">
                          <div class="col-8">
                            <b>{{(occCareerStore)?.OutlookRatings?.headers[2].header}}</b>
                          </div>
                          <div class="col-4">
                            <ul class="chart-mini-1 eo-location-card-graph">
                              <li *ngFor="let item1 of [0,1,2];let inx=index">
                                <span [ngStyle]="(item1 == item.ratings[2].ratingID)? {'background-color':'#2c974b'}:{}" style="animation: none;"></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between employee-states">
                          <div>{{item.ratings[2].rating}}</div>
                          <div>{{item.ratings[2].amount}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gutter-10 mb-2">
              <div class="col-2">
                <!-- select state button -->
                <div ngbDropdown class="d-inline-block w-100">
                  <!-- <button type="button" [attr.aria-label]='(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.selectStates' class="common-button select-state-btn w-100 m-0" (click)="checkopen()" data-toggle="collapse" href="#collapseExample3"
                    aria-expanded="false" aria-controls="collapseExample3">
                    <b>{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.selectStates}}</b>
                  </button> -->
                </div>
              </div>
        
        
              <!-- drop down toggel start -->
              <div class="col-12 p-b">
                <div class="collapse slider-drop-down-2" id="collapseExample3">
                  <div class="card ">
                    <div class="card-header-slider">{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.select?.intro}}</div>
                    <div class="card-block-slider-filter">
                      <div class="statelist">
                        <div *ngFor="let stateName of (occCareerText)?.careersJr_Buttons_Reducer_Text?.select?.states;let checkId=index;">
                          <ul class="list-unstyled">
                            <li>
                              <div class="common-checkbox">  
                                <input type="checkbox" (keydown)="count(stateName.abbr,$event)" (click)="count(stateName.abbr,'click')" id="emptest{{checkId}}"
                                  [checked]="duplicateState.indexOf(stateName.abbr) != -1" [disabled]="duplicateState.indexOf(stateName.abbr) == -1 && displayValue>5"/>
                                <label for="emptest{{checkId}}" class="popup-states" [class.disabledp2]="duplicateState.indexOf(stateName.abbr) == -1 && displayValue>5">
                                  <p>{{stateName.name}}</p>
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- buttons to display cancel and reset selected states -->
                    <div class="card-footer-slider  text-right">
                      <button class="common-button green" data-toggle="collapse" (click)="showStates()" href="#collapseExample3" aria-expanded="false"
                        aria-controls="collapseExample" type="button" [attr.aria-label]='(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.apply'>{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.apply}}</button>
                      <button type="button" [attr.aria-label]='(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.reset' class="common-button orange mr-1" (click)="resetStates()">{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.reset}}</button>
                      <button class="common-button red" data-toggle="collapse" (click)="checkopen()" href="#collapseExample3" aria-expanded="false"
                        aria-controls="collapseExample" type="button" [attr.aria-label]='(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.cancel'>{{(occCareerText)?.careersJr_Buttons_Reducer_Text?.common?.cancel}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <div class="row gutter-10">
            <!-- Major Employers card -->
            <div class="col-6">        
              <div class="career-card ">
                <div class="cardcolor-25"> 
                  <div class="career-card-header">
                    <div class="card-title p-0 pre">
                      <div aria-hidden="true" class="icon-major_employers_icon-sprite panal-icon-align"> </div> 
                      {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.MajorEmployers}}
                    </div>
                  </div>
                </div>
                <div class="text-dark pl-3 py-2">
                    <div class="mt-1" *ngFor="let item of (occCareerStore)?.MajorEmployers;let inx=index;">
                      <p *ngIf="(item.type == 'p')"> {{item.text}}</p>
                      <ul *ngIf="(item.type == 'ul')" class="list-unstyled list">
                        <li *ngFor="let val of item.text">
                          <div class="headding-arrow mb-2">{{val}}</div>
                        </li>
                      </ul>
                    </div>
                </div>
              </div>
            </div>
          
            <!-- Opportunities card -->
            <div class="col-6" *ngIf='(settingsTextTab?.sections).indexOf("Opportunities")!= -1'>
              <div class="career-card">
                <div class="cardcolor-3"> 
                  <div class="career-card-header">
                    <div class="card-title p-0 pre">
                      <div class="icon-opportunities-sprite panal-icon-align"></div>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Opportunities}}</div>
                  </div>
                </div>
                <div class="text-dark pl-3 pt-1">  
                  <div class="mb-1" *ngFor="let value of (occCareerStore)?.Opportunities">
                    <p class="text-dark" *ngIf="(value.type == 'p')">{{value.text}}</p>
                    <ul *ngIf="(value.type == 'ul')" class="list-unstyled">
                      <li *ngFor="let val of value.text">
                        <div class="headding-arrow pl-3 mb-1">{{val}}</div>
                      </li>
                    </ul>
                    <!-- <div class='header_6 px-3 text-dark' *ngIf="(value.type == 'h')">
                      <b>{{value.text}}</b>
                    </div> -->
                  </div>
                </div>
              </div>  
            </div>
          </div>
      </div>
    </div>    
    <div class="html2pdf__page-break"></div>
    <div class="pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!-- print Ending-->

   
   