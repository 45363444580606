<div class="row  gutter-10 flex-row-cards">
  <!-- For displaying the overviewcard. -->
  <div class="col-lg-6 col-md-6 col-12">
    <div class="career-card cardcolor-5 cards-equal-height">
      <div class="career-card-header">
        <div class="card-title p-0">
          <i class="icon-overview panal-icon-align" aria-hidden="true"></i> {{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.Overview}}</div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <div *ngFor="let value of (occClusterStore)?.Overview">
          <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div>
                <i [innerHtml]="val | MarkdownToLink"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- For displaying the skills related card. -->
  <div class="col-lg-6 col-md-6 col-12">
    <div class="career-card cardcolor-9 cards-equal-height">
      <div class="career-card-header">
        <div class="card-title p-0">
          <i class="icon-skills-abilities panal-icon-align" aria-hidden="true"></i> {{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.Skills}}</div>
      </div>
      <div class="card-block career-card-body scroll-class ">
        <div *ngFor="let value of (occClusterStore)?.Skills">
          <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- For displaying the Employmentandoutlook related card. -->
<div class="career-card cardcolor-3" *ngIf='(settingsTextTab?.sections).indexOf("EmploymentOutlook") != -1'>
  <div class="career-card-header">
    <div class="card-title">
      <i class="icon-employment-outlook panal-icon-align" aria-hidden="true "> </i> {{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.EmploymentOutlook}}</div>
  </div>
  <div class="card-block  career-card-body h-100">
    <p *ngFor="let item of (occClusterStore)?.EmploymentOutlook" [innerHtml]="item.text | MarkdownToLink">
    </p>
  </div>
</div>
<!-- OCC-careers Print Start -->
<div [hidden]="!printview">
<div class="print-result-page" #clusRightForMe>
  <div class="header d-flex align-items-center justify-content-between border-bottom">
    <div class="common-headding d-flex align-items-center">
      <div class="{{clusterIcon}}_color-sprite"></div>
      <span class="title-wraps pl-1 pt-2">{{clusterTitle}}</span>
    </div>
    <div class="print-header-logo">
      <img alt="Career Information System-logo" class="float-right" [src]="logo" > 
    </div>
  </div>
  <div class="main-body white-space">
    <div>
      <div class="careers-title">{{tabTitle}}</div>
    <div class="table-text" *ngIf='(settingsTextTab?.sections).indexOf("Overview") != -1'>
       <div class="career-card cardcolor-5">
         <div class="career-card-header font-weight-bold">
          <div class="icon-overview-sprite panal-icon-align"></div>{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.Overview}}
         </div>
         </div>
       <div>
        <div class="pl-3" *ngFor="let value of (occClusterStore)?.Overview">
          <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
          <ul *ngIf="(value.type == 'ul')" class="list-unstyled">
            <li *ngFor="let val of value.text">
              <div class="headding-arrow pl-2 mb-2">
                <i [innerHtml]="val | MarkdownToLink"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <div *ngIf='(settingsTextTab?.sections).indexOf("Skills") != -1'>
      <div class="career-card cardcolor-9 mt-3">
        <div class="career-card-header font-weight-bold">
          <div class="icon-skills_abilities-sprite panal-icon-align"></div>{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.Skills}}
        </div>
      </div>
      <div>
        <div class="pl-3" *ngFor="let value of (occClusterStore)?.Skills; let inx=index" [class.html2pdf__page-break]="(inx==8)">
          <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
          <div class='pb-1 header_6' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list-unstyled mb-2">
            <li class="pb-1 mb-0" *ngFor="let val of value.text; let inx=index">
              <div class="headding-arrow pl-2" [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
      <!-- <div class="html2pdf__page-break"></div> -->
    <div *ngIf='(settingsTextTab?.sections).indexOf("EmploymentOutlook") != -1'>
      <div class="career-card cardcolor-3 mt-3">
        <div class="career-card-header font-weight-bold">
            <div class="icon-employment_outlook-sprite panal-icon-align"></div>
            <span class="white-space">{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.EmploymentOutlook}}</span>
        </div>
      </div>
      <div class="pl-3">
        <p *ngFor="let item of (occClusterStore)?.EmploymentOutlook" [innerHtml]="item.text | MarkdownToLink"></p>
     </div>
    </div>
    </div>    
  </div>
  <div class="mt-4 pt-4 text-center">
    <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
  </div>
</div>
</div><!-- OCC-careers Print End -->