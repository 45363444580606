import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared-module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { OccDetailStaticHeaderComponent } from './occ-detail-header-component';
import { AtAGlanceComponent } from './occ-careers/at-a-glance/at-a-glance-component';
import { EduTrainingComponent } from './occ-careers/edu-and-training/edu-training-component';
import { EmpOutlookComponent } from './occ-careers/emp-and-outlook/emp-outlook-component';
import { JobDescriptionComponent } from './occ-careers/job-description/job-desc-component';
import { PersonalQualitiesComponent } from './occ-careers/personal-qualities/personal-qualities-component';
import { RelatedCareersComponent } from './occ-careers/related-careers/related-careers-component';
import { WagesComponent } from './occ-careers/wages/wages-component';
import { AtAGlanceClusterComponent } from './occ-clusters/at-a-glance/at-a-glance.component';
import { EducationComponent } from './occ-clusters/education/education.component';
import { PathwaysComponent } from './occ-clusters/pathways/pathways.component';
import { RelatedCareerClusterComponent } from './occ-clusters/related-careers/related-careers.component';
import { RightForMeComponent } from './occ-clusters/right-for-me/right-for-me-component';
import { AboutThisComponent } from './occ-emerg-careers/about-this-career/abt-this-career.component';
import { EduTrainingEmergComponent } from './occ-emerg-careers/edu-and-training/edu-and-training-component';
import { RelatedCareersEmergComponent } from './occ-emerg-careers/related-careers/related-careers.component';
import { OccCareerHeaderComponent } from './occ-shared/occ-common-header/occ-career-header-component';
import { OccClusterHeaderComponent } from './occ-shared/occ-common-header/occ-cluster-header-component';
import { OccEmergHeaderComponent } from './occ-shared/occ-common-header/emergCareers-header-component';
import { StaticOccFooterComponent } from './occ-shared/occ-common-footer/occ-footer-component';
import { OccIndexComponent } from './occ-shared/occ-index/occ-index-component';
import { OccDetailsRoutingModule } from './occ-details-routing';
import { OccCompareComponent } from './occ-compare/occ-compare.component';
import { OccCommonMethodsService } from './occ-services/occ-common-methods.service';
import { OccDispatchService } from './occ-services/occ-dispatch.service';
import { OccApiCallService } from './occ-services/occ-apicalls.service';
import { OccApiCallClass } from './occ-constants/model';
import { OccSnackBar, OccModalPopup } from './occ-modal/occ-modal-component';
// import { TextIteratorPipe } from './occ-shared/text-custompipe';
import { EffectsModule } from '@ngrx/effects';
import { OccEffects } from './occ-state-management/occ-effects/occ-effects';
// import { AuthInterceptor } from '../../../shared/outer-services/authentication-interceptors';
import { OccAuthInterceptor } from './occ-services/occ-authentication-interceptors';
import { StoreModule } from '@ngrx/store';
import { ChartsModule } from 'ng2-charts';
import {
  Occ_Index_Reducer_Text_Reducer,
  Occ_Settings_Tab_Text_Reducer,
  Occ_EmpOutlook_Wages_Reducer,
  Occ_Buttons_Reducer_Text,
  Occ_Assessment_Filter_Reducer,
  Career_Detail_AtGlance_Reducer,
  Career_Detail_JobDescription_Reducer,
  Career_Detail_Wages_Reducer,
  Career_Detail_EmploymentOutlook_Reducer,
  Career_Detail_EducationTraining_Reducer,
  Career_Detail_PersonalQualities_Reducer,
  Career_Detail_RelatedCareers_Reducer,
  Career_Detail_Header_Reducer,
} from './occ-state-management/occ-reducer/occ-reducer';
import { FrameworkConfigReducer } from '../../../state-management/reducers/main-reducer';
import { AtAGlancePrintResultComponent } from './occ-careers/at-a-glance/at-a-glance-print-result/at-a-glance-print-result-component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { initCareer_Detail_Related_Careers_State } from './occ-state-management/occ-state/occ-state';
@NgModule({
  imports: [
    // HttpClientModule,
    FormsModule,
    CommonModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    OccDetailsRoutingModule,
    ChartsModule,
    EffectsModule.forRoot([OccEffects]),
    /**link selector to reducer */
    StoreModule.forRoot({
      Occ_Index_Reducer_Text: Occ_Index_Reducer_Text_Reducer,
      Occ_Settings_Tab_Text: Occ_Settings_Tab_Text_Reducer,
      Occ_EmpOutlook_Wages: Occ_EmpOutlook_Wages_Reducer,
      Occ_Buttons_Reducer_Text: Occ_Buttons_Reducer_Text,
      Occ_Assessment_Filter_Reducer: Occ_Assessment_Filter_Reducer,
      config: FrameworkConfigReducer,
      /**add new reducer here for Career Detail view */
      Career_Details_At_Glance: Career_Detail_AtGlance_Reducer,
      Career_Detail_Job_Description: Career_Detail_JobDescription_Reducer,
      Career_Detail_Wage: Career_Detail_Wages_Reducer,
      Career_Detail_Employment_Outlook: Career_Detail_EmploymentOutlook_Reducer,
      Career_Detail_Education_Training: Career_Detail_EducationTraining_Reducer,
      Career_Detail_Personal_Qualities: Career_Detail_PersonalQualities_Reducer,
      Career_Detail_Related_Careers: Career_Detail_RelatedCareers_Reducer,
      Career_Detail_Header_Content: Career_Detail_Header_Reducer,
      //end of Career Details reducer
    }),
  ],
  declarations: [
    OccCareerHeaderComponent,
    OccModalPopup,
    // TextIteratorPipe,
    OccSnackBar,
    OccClusterHeaderComponent,
    OccEmergHeaderComponent,
    StaticOccFooterComponent,
    AtAGlanceComponent,
    EduTrainingComponent,
    EmpOutlookComponent,
    JobDescriptionComponent,
    PersonalQualitiesComponent,
    RelatedCareersComponent,
    WagesComponent,
    AtAGlanceClusterComponent,
    EducationComponent,
    PathwaysComponent,
    RelatedCareerClusterComponent,
    RightForMeComponent,
    AboutThisComponent,
    EduTrainingEmergComponent,
    RelatedCareersEmergComponent,
    OccDetailStaticHeaderComponent,
    OccIndexComponent,
    OccCompareComponent,
    AtAGlancePrintResultComponent,
  ],
  providers: [
    OccApiCallService,
    OccDispatchService,
    OccApiCallClass,
    // TextIteratorPipe,
    OccCommonMethodsService,
    DeviceDetectorService,
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: OccAuthInterceptor,
    //     multi: true
    // }
  ],
  entryComponents: [OccModalPopup, OccSnackBar],
})
export class OccDetailModule {}
