<footer id="fhid" class="common-footer w-100"  *ngIf="showFooter">
              <section class="copyright">
                <div class="container w-100">
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="footer-text-desktop desktop-design-display" [ngStyle]="(defaultFooter1)?{'justify-content': 'inherit'}:{'justify-content':'center'}" >
                        <div>{{defaultFooter}}</div>
                        <div *ngIf="defaultFooter2">{{defaultFooter2}}</div>
                        <div class="pt-2" *ngIf="defaultFooter1">{{defaultFooter1}}</div>
                      </div>
                    <div class="mobile-design-display">
                 <p>
                  <span>{{defaultFooter}}</span>
                  <span class="tooltip-footer">
                    <span class="footer-mobile-text" *ngIf="defaultFooter1">Read more...</span>
                    <span class="tooltiptext">{{defaultFooter}}<br><span *ngIf="defaultFooter2">{{defaultFooter2}}</span><br>{{defaultFooter1}}</span>
                  </span>
                </p>
            </div>
          </div>
        </div>
    </div>
    </section>
    <section class="footer-bottom"> </section>
  </footer>