<div id="accordion" class="pathway-p3" role="tablist" aria-multiselectable="true" *ngIf='(settingsTextTab?.sections).indexOf("Pathways") != -1'>
  <div>
    <!-- For displaying the pathways related cluster cards-->
    <div *ngFor="let item of (occClusterStore)?.Pathways;let inx=index" class=" pathway-card-bg-color">
      <div *ngIf="(inx%2==0)" class="career-card inner-card">
        <div class="career-card-header" role="tab" id="headingTwo" (click)="(filter != inx)?methodfilter (inx,inx,inx):methodfilter(999,inx,inx)">
          <span class="card-title accordion-pathways" tabindex="0">{{item.header}} </span>
            <button class="common-button default m-0" [attr.aria-label]="(filter != inx )? 'down rounded arrow': 'up rounded arrow'">
              <i *ngIf="(filter != inx )" class="icon-down-rounded-arrow  icn-font-size"></i>
              <i *ngIf="filter == inx" class="icon-up-rounded-arrow  icn-font-size"></i>
            </button>
        </div>
        <div id=1 #filter1 *ngIf="(filter ==inx && showId==inx)" role="tabpanel" aria-labelledby="headingOne">
          <div class="card-block " [ngClass]="{'': highlightedDiv === inx}">
            <p>{{item.intro}}</p>
            <ul class="list pr-4">
              <li *ngFor="let items of item.links;let in=index;">
                <a href="#" class="pointer" (click)="$event.preventDefault();CallOccDetailCareer(items.linkID,items.linkTitle)"
                  [innerHtml]="items.linkTitle | MarkdownToLink"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="(inx%2==1)" class="career-card inner-card">
        <div>
          <div class="career-card-header" role="tab" id="headingOne" (click)="(filter != inx)?methodfilter (inx,inx,inx):methodfilter(999,inx,inx)">
            <span class="card-title accordion-pathways" tabindex="0"> {{item.header}}</span>                  
              <button class="common-button default m-0" [attr.aria-label]="(filter != inx )? 'down rounded arrow': 'up rounded arrow'">
                <i *ngIf="(filter != inx )" class="icon-down-rounded-arrow  icn-font-size"></i>
                <i *ngIf="filter == inx" class="icon-up-rounded-arrow  icn-font-size"></i>
              </button>  
          </div>
        </div>
        <div id=1 #filter1 *ngIf="(filter ==inx && showId==inx)" role="tabpanel" aria-labelledby="headingOne">
          <div class="card-block " [ngClass]="{'': highlightedDiv === inx}">
            <p>{{item.intro}}</p>
            <ul class="list pr-4">
              <li *ngFor="let items of item.links;let in=index;">
                <a href="#" class="pointer" (click)="$event.preventDefault();CallOccDetailCareer(items.linkID,items.linkTitle)"
                  [innerHtml]="items.linkTitle | MarkdownToLink"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- OCC-careers Print Start -->
<div [hidden]="!printview">
<div class="print-result-page" #clusPathway>
  <div class="header d-flex align-items-center justify-content-between border-bottom">
    <div class="common-headding d-flex align-items-center">
      <div class="{{clusterIcon}}_color-sprite"></div>
      <span class="title-wraps pl-1 pt-2">{{clusterTitle}}</span>
    </div>
    <div class="print-header-logo">
      <img alt="Career Information System-logo" class="float-right" [src]="logo" > 
    </div>
  </div>
    <div class="main-body">
      <div>
        <div class="careers-title">{{tabTitle}}</div>
        <div *ngIf='(settingsTextTab?.sections).indexOf("Pathways") != -1'>
          <div *ngFor="let item of (occClusterStore)?.Pathways;let inx=index" class="pathway-card-bg-color" [class.html2pdf__page-break]="(inx==1 || inx==3 || inx==4)">
            <div class="career-card inner-card">
              <div class="career-card-header font-weight-bold pre">{{item.header}}</div>
            </div>
            <div class="pl-3">
              <p>{{item.intro}}</p>
              <ul class="list-unstyled pl-3 mb-2">
                <li *ngFor="let items of item.links;let inx=index;" [class.html2pdf__page-break]="(inx==34)">
                  <div class="headding-arrow pl-2">{{items.linkTitle}}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!-- OCC-careers Print End -->