<p class="header_5 header-text-color">
  <b>{{relatedcareers.RelatedCareers?.intro}}</b>
</p>
<div class="career-card cardcolor-25">
  <div class="career-card-header">
    <div class="card-title">
        <i class="icon-careers  panal-icon-align" aria-hidden="true"></i>  {{(relatedcareers)?.RelatedCareers?.lists[0]?.header}}
    </div>
</div>
<div class="career-card" *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers") != -1'>
  <div *ngFor="let value of (relatedcareers)?.RelatedCareers?.lists">
    <div class="card-block career-card-body scroll-class">
      <ul class="list row">
        <li class="col-12 col-md-6" *ngFor="let val of value.links">
          <a href="#" class="pointer" (click)="$event.preventDefault();CallOccDetailCareer(val.linkID,val.linkTitle)">{{val.linkTitle}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
</div>
<!-- OCC-EMERG Print Start -->
<div [hidden]="!printview">
  <div class="print-result-page" #relatedCareerPrint>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
          <div class="{{clustersIcon}}_color-sprite"></div>
        <span class="title-wraps pl-1 pt-2">{{clusterTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" [src]="logo">
      </div>
    </div>
    <div class="main-body">
      <div>
        <div class="careers-title">{{tabTitle}}</div>
        <p><b>{{(relatedcareers)?.RelatedCareers?.intro}}</b></p>
        <div class="career-card cardcolor-25">
          <div class="career-card-header font-weight-bold">
            <i class="icon-careers panal-icon-align" aria-hidden="true"></i> {{(relatedcareers)?.RelatedCareers?.lists[0]?.header}}
          </div>
        </div>
        <div *ngFor="let value of (relatedcareers)?.RelatedCareers?.lists">
          <ul class="list-unstyled row career-indent pl-4">
            <li class="col-6" *ngFor="let val of value.links; let i=index">
              <div class="text-dark headding-arrow pl-4" [class.html2pdf__page-break]="((i==61))" [ngClass]="(i==62) || (i==63) ? 'mt-2' : '' ">{{val.linkTitle}}</div>
            </li>
          </ul>               
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj?.Result?.headerFooter?.copyright}}</p>
    </div>
  </div>
</div><!-- OCC-EMERG Print End -->