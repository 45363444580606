<!--for deployment -->

<div>

  <!--Display about Preparation-->
  <div class="career-card cardcolor-28"  *ngIf='(settingsTextTab?.sections).indexOf("Preparation")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingThree" (click)="(expandCard != 9) ? methodfilter(9): methodfilter(999)">
      <div class="card-title align-items-center">
        <img src='{{fontIcons}}preparation.svg' class="ml-1 mr-2" alt="edu-preparation-icon"  aria-hidden />
        <span class="w-100" tabindex="0" (keyup.enter)="(expandCard != 9) ? methodfilter(9): methodfilter(999)"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Preparation}}</span>
      </div>
      <div class="d-table-cell align-middle">
       <button class="common-button default m-0" [attr.aria-label]="(expandCard != 9)? 'down rounded arrow': 'up rounded arrow'">
        <i [ngClass]="{'icon-down-rounded-arrow':(expandCard != 9), 'icon-up-rounded-arrow': (expandCard == 9)}" 
        class="icn-font-size"></i>
        </button>
      </div>
    </div>

    <div id="9" *ngIf="expandCard == 9" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore).Preparation">
          <p *ngIf="(value.type == 'p')" [innerHTML]='value.text | MarkdownToLink'> </p>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <div *ngIf="(value.type=='address')"  > 
            <address class="mb-0" *ngFor="let val of value.text">
                <p class="mb-0 " [innerHTML]='val | MarkdownToLink'></p>
            </address>
          </div>
          <div *ngIf="(value.type == 'table')">
            <div *ngFor="let list of value.text">
              <div class="row" *ngIf="(list.type == 'head')">
                <div class="col-6 font-weight-bold" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink"></div>
              </div>
              <div class="row" *ngIf="(list.type == 'row')">
                <div class="col-6" *ngFor="let text of list.text">
                  <span [innerHTML]="text | MarkdownToLink"></span>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </div>

  <!--Display about Apprenticeship-->
  <div class="career-card  cardcolor-59"  *ngIf='(settingsTextTab?.sections).indexOf("Apprenticeship")!= -1 && (occCareerStore).Apprenticeship && (occCareerStore).Apprenticeship.length > 0'>
    <div class="career-card-header pointer" role="tab" id="headingThree" (click)="(expandCard != 12) ? methodfilter(12): methodfilter(999)">
      <div class="card-title align-items-center">
        <img src='{{fontIcons}}apprentice-white.png' class="ml-1 mr-2" alt="edu-appreticeship-icon"  aria-hidden />
        <span class="w-100" tabindex="0" (keyup.enter)="(expandCard != 12) ? methodfilter(12): methodfilter(999)"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Apprenticeship}}</span>
      </div>
      <div class="d-table-cell align-middle">
       <button class="common-button default m-0" [attr.aria-label]="(expandCard != 12)? 'down rounded arrow': 'up rounded arrow'">
        <i [ngClass]="{'icon-down-rounded-arrow':(expandCard != 12), 'icon-up-rounded-arrow': (expandCard == 12)}" 
        class="icn-font-size"></i>
        </button>
      </div>
    </div>

    <div id="12" *ngIf="expandCard == 12" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore).Apprenticeship">
          <p *ngIf="(value.type == 'p')" [innerHTML]='value.text | MarkdownToLink'> </p>
          <ul *ngIf="(value.type == 'ul')" class="list" style="margin-top: -1rem;">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <div *ngIf="(value.type=='address')"  > 
            <address class="mb-0" *ngFor="let val of value.text">
                <p class="mb-0 " [innerHTML]='val | MarkdownToLink'></p>
            </address>
          </div>
          <div *ngIf="(value.type == 'table')">
            <div *ngFor="let list of value.text">
              <div class="row" *ngIf="(list.type == 'head')">
                <div class="col-6 font-weight-bold" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink"></div>
              </div>
              <div class="row" *ngIf="(list.type == 'row')">
                <div class="col-6" *ngFor="let text of list.text">
                  <span [innerHTML]="text | MarkdownToLink"></span>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </div>

  <!--Display about helpful High School Courses-->


  <div class="career-card cardcolor-14" *ngIf='(settingsTextTab?.sections).indexOf("HelpfulHSCourses")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingOne" activeIds="6" (click)="(expandCard != 11)?methodfilter(11):methodfilter(999)">
      <div class="card-title align-items-center">
          <i class="icon-helpful-high-school-courses panal-icon-align" aria-hidden="true"></i>
         <span class="w-100" tabindex="0" (keyup.enter)="(expandCard != 11) ? methodfilter(11): methodfilter(999)"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.HelpfulHSCourses}}</span>
      </div>

      <div class="d-table-cell align-middle">
          <button class="common-button default m-0" [attr.aria-label]="(expandCard != 11)? 'down rounded arrow': 'up rounded arrow'">
            <i [ngClass]="{'icon-down-rounded-arrow':(expandCard != 11), 'icon-up-rounded-arrow': (expandCard == 11)}" 
            class="icn-font-size"></i>
          </button>
      </div>
      </div>

    <div id="6" *ngIf="expandCard == 11" role="tabpanel" aria-labelledby="headingOne">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore).HelpfulHSCourses">
          <p *ngIf="(value.type == 'p')" [innerHTML]='value.text | MarkdownToLink'></p>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>


  <!--Display about Knowledge-->
  <div class="career-card cardcolor-30" *ngIf='(settingsTextTab?.sections).indexOf("Knowledge")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingTwo" (click)="(expandCard != 7) ? methodfilter(7):methodfilter(999)">
      <div  class="card-title align-items-center">
          <i class="icon-knowledge panal-icon-align" aria-hidden="true"></i>
          <span class="w-100" tabindex="0" (keyup.enter)="(expandCard != 7) ? methodfilter(7): methodfilter(999)"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Knowledge}}</span>
          </div>
          <div class="d-table-cell align-middle">
          <button class="common-button default m-0" [attr.aria-label]="(expandCard != 7)? 'down rounded arrow': 'up rounded arrow'">
              <i [ngClass]="{'icon-down-rounded-arrow':(expandCard != 7), 'icon-up-rounded-arrow': (expandCard == 7)}" 
              class="icn-font-size"></i>
          </button>
        </div>
     </div>
    <div id="7" *ngIf="expandCard == 7" role="tabpanel" aria-labelledby="headingTwo">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore).Knowledge">
          <div class='text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list mt-1">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>

  <!--Display about RelatedPrograms-->
  <div class="career-card cardcolor-8" *ngIf='(settingsTextTab?.sections).indexOf("RelatedPrograms")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingThree" (click)="(expandCard != 10)? methodfilter(10):methodfilter(999)">
      <div class="card-title align-items-center">
          <i class="icon-programs-of-study panal-icon-align" aria-hidden="true"></i>
        <span class="w-100" tabindex="0" (keyup.enter)="(expandCard != 10) ? methodfilter(10): methodfilter(999)"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedPrograms}}</span>
        </div>
        <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 10)? 'down rounded arrow': 'up rounded arrow'">
            <i [ngClass]="{'icon-down-rounded-arrow':(expandCard != 10), 'icon-up-rounded-arrow': (expandCard == 10)}" 
            class="icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="10" #filter3 *ngIf="expandCard == 10" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block  ">
        <div *ngFor="let value of abilityRes">
          <p *ngIf="(value.intro)" [innerHtml]="value.intro | MarkdownToLink"> </p>
          <div *ngIf="(value.lists)">
            <div *ngFor="let listvalue of value.lists;let in=index">
              <div class='header_6 text-white' *ngIf="(listvalue.header)" [innerHtml]="listvalue.header | MarkdownToLink"></div>
              <ul *ngIf="(listvalue.links)" class="list mt-1">
                <li *ngFor="let val of listvalue.links">
                  <a *ngIf="availProgs" href="javascript:void()" class="pointer"
                    (click)="$event.preventDefault();callProgramsCareer(val.linkID,val.linkTitle)">{{val.linkTitle}}</a>
                  <a href="javascript:void()" *ngIf="!availProgs" class="pointer"
                    (click)="$event.preventDefault();callProgramsCareer(val.linkID,val.linkTitle)">{{val.linkTitle}}</a>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Display about LicensingCert-->
  <div *ngIf='(settingsTextTab?.sections).indexOf("LicensingCert")!= -1'>
    <div *ngIf="(occCareerStore)?.LicensingCert?.length!=0" class="career-card Occupation-Sort-card">
      <div class="career-card-header pointer " role="tab" id="headingThree" (click)="(expandCard != 8)? methodfilter(8):methodfilter(999)">
        <div class="card-title align-items-center">
            <i class="icon-licensing-certification panal-icon-align" aria-hidden="true"></i>
            <span class="w-100" tabindex="0" (keyup.enter)="(expandCard != 8) ? methodfilter(8): methodfilter(999)"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.LicensingCert}}</span>
        </div>
        <div class="d-table-cell align-middle">
          <button class="common-button default m-0" [attr.aria-label]="(expandCard != 8)? 'down rounded arrow': 'up rounded arrow'">
              <i [ngClass]="{'icon-down-rounded-arrow':(expandCard != 8), 'icon-up-rounded-arrow': (expandCard == 8)}" 
              class="icn-font-size"></i>
          </button>
        </div>
      </div>
      <div id="8" *ngIf="expandCard == 8" role="tabpanel" aria-labelledby="headingThree">
        <div class="card-block ">
          <div *ngFor="let value of (occCareerStore).LicensingCert">
            <p *ngIf="(value.type == 'p')" [innerHTML]='value.text | MarkdownToLink'> </p>
            <ul *ngIf="(value.type == 'ul')" class="list">
              <li *ngFor="let val of value.text">
                <div [innerHtml]="val | MarkdownToLink"></div>
              </li>
            </ul>
            <div class='header_6 text-white' *ngIf="(value.type == 'h')">
              <b [innerHtml]="value.text | MarkdownToLink"></b>
            </div>
            <div *ngIf="(value.type== 'address')"  > 
              <address class="mb-0" *ngFor="let addressval of value.text">
                  <p class="mb-0 " [innerHTML]='addressval | MarkdownToLink'></p>
              </address>
          </div>
          </div>
          <!-- MoreLicensing section card added-->
          <div *ngIf='(settingsTextTab?.sections).indexOf("MoreLicensing")!= -1'>
            <div *ngIf="(occCareerStore)?.MoreLicensing?.length!=0">   
              <div *ngFor="let value of (occCareerStore)?.MoreLicensing; let inx1=index;let first=first;" (click)="methodfilter(inx1, 'MoreLicensing')">
            
                  <div class="career-card Occupation-Sort-card mb-2">
                      <div class="career-card-header px-3">
                          <i class="icon-licensing-certification panal-icon-align" aria-hidden="true"></i>
                          <span class="card-title" tabindex="0" [innerHtml]="value.title | MarkdownToLink"></span>
                          <button class="common-button default m-0" [attr.aria-label]="(moreLicensingExpand != inx1)? 'down rounded arrow': 'up rounded arrow'">
                              <i [ngClass]="{'icon-down-rounded-arrow':(moreLicensingExpand != inx1), 'icon-up-rounded-arrow': (moreLicensingExpand == inx1)}" 
                                class="icn-font-size"></i>
                          </button>
                      </div>
                      <div class="card-block" id="value{{inx1}}" *ngIf="(moreLicensingExpand ==inx1)">
                        <div *ngFor="let val of value.lines">
                          <p class="px-3" *ngIf="(val.type == 'p')" [innerHTML]='val.text | MarkdownToLink'> </p>
                          <ul *ngIf="(val.type == 'ul')" class="list">
                            <li *ngFor="let listval of val.text">
                              <div [innerHtml]="listval | MarkdownToLink"></div>
                            </li>
                          </ul>
                          <div class='px-3 header_6 text-white' *ngIf="(val.type == 'h')">
                            <b [innerHtml]="val.text | MarkdownToLink"></b>
                          </div>
                          <div *ngIf="(val.type=='address')"  > 
                              <address class="mb-0" *ngFor="let addressval of val.text">
                                  <p class="mb-0" [innerHTML]='addressval | MarkdownToLink'></p>
                              </address>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
  <!--Rebuilding PR-->
  <!--MoreEducation section added-->
  <div *ngIf='(settingsTextTab?.sections).indexOf("MoreEducation")!= -1'>
    <div *ngIf="(occCareerStore)?.MoreEducation?.length!=0">   
       <div *ngFor="let value of (occCareerStore)?.MoreEducation; let inx1=index;let first=first;" (click)="methodfilter(inx1,'MoreEducation')">
      <!-- <ng-template #tipContentfly> 
              {{clus.flyover}}
          </ng-template> -->
          <div class="career-card cardcolor-28 mb-2">
              <div class="career-card-header">
                  <!-- <span [ngClass]="programsValue['cls_'+clus.clusterID].clusterIconValue"></span> -->
                  <i class="icon-career_rediness_key panal-icon-align" aria-hidden="true"></i>
                  <span class="card-title align-items-center"  tabindex="0">{{value.title}}</span>
                  <button class="common-button default m-0" [attr.aria-label]="(educationExpand != inx1)? 'down rounded arrow': 'up rounded arrow'">
                      <i [ngClass]="{'icon-down-rounded-arrow':(educationExpand != inx1), 'icon-up-rounded-arrow': (educationExpand == inx1)}" 
                      class="icn-font-size"></i>
                  </button>
              </div>
              <div class="card-block" id="value{{inx1}}" *ngIf="(educationExpand ==inx1)">
              <div *ngFor="let val of value.lines">
                <p *ngIf="val.type == 'p'" [innerHTML]='val.text | MarkdownToLink'></p>
                <div class="py-2 px-3 font-weight-bold" *ngIf="(val.type == 'h')" [innerHtml]="val.text | MarkdownToLink"></div>
                 <div class="list-table px-3" *ngIf="(val.type == 'table')">
                   <div class="alternative-list-color" *ngFor="let list of val.text">
                     <div class="row list-head p-1" *ngIf="(list.type == 'head')">
                      <div class="col-3 text-center" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink"></div>
                     </div>
                     <div class="row sub-list-color p-1" *ngIf="(list.type == 'row')">
                      <div class="col-3 text-center" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink">
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
              </div>
          </div>
  </div>
  </div>
</div>
<!--Rebuilding-->
<!-- MoreLicensing section card added-->
</div>

 <!-- Print Start Eduction Training -->
<div class="hidden-page">
 <div class="print-result-page" #EducationTrainingPrintPage>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-occresize-sprite"></div>
        <span [innerHtml]="careerTitle | MarkdownToLink"></span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" [src]="logo">
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
        <!--Display about Preparation-->
        <div class="career-card"  *ngIf='(settingsTextTab?.sections).indexOf("Preparation")!= -1'>
          <div class="cardcolor-28 mb-3">
            <div class="career-card-header">
              <div class="card-title">
                <!-- <img src='{{CareersImages}}preparation.png' class="ml-1 mr-2" alt="edu-preparation-icon" /> -->
                <div class="icon-preparation-sprite"></div>
                <span class="pl-2">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.Preparation}}</span>
              </div>
            </div>
          </div>
            
          <div class="card-block text-dark pl-3">
            <div *ngFor="let value of (occCareerStore).Preparation; let inx = index" [class.html2pdf__page-break]="(inx==10 || ((inx-10)%50==0)) && !NotIE">
              <p class="mb-2 text-secondary" *ngIf="(value.type == 'p')" [innerHTML]='value.text | MarkdownToLink'> </p>
              <ul *ngIf="(value.type == 'ul')" class="list-unstyled pl-2">
                <li *ngFor="let val of value.text">
                  <div class="headding-arrow pl-3 career-indent mb-1" [innerHtml]="val | MarkdownToLink"></div>
                </li>
              </ul>
              <div class='header_6' *ngIf="(value.type == 'h')">
                <b [innerHtml]="value.text | MarkdownToLink"></b>
              </div>
              <div *ngIf="(value.type=='address')"  > 
                <address class="mb-0" *ngFor="let val of value.text">
                    <p class="mb-0 " [innerHTML]='val | MarkdownToLink'></p>
                </address>
              </div>
              <div *ngIf="(value.type == 'table')">
                <div *ngFor="let list of value.text">
                  <div class="row" *ngIf="(list.type == 'head')">
                    <div class="col-6 font-weight-bold" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink"></div>
                  </div>
                  <div class="row" *ngIf="(list.type == 'row')">
                    <div class="col-6" *ngFor="let text of list.text">
                      <span [innerHTML]="text | MarkdownToLink"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="html2pdf__page-break"></div>
        <div class="career-card mt-3" *ngIf='(settingsTextTab?.sections).indexOf("HelpfulHSCourses")!= -1'>
          <div class="cardcolor-14 mb-3">
          <div class="career-card-header">
            <div class="card-title">
                <div class="icon-helpful_high_school_courses-sprite panal-icon-align" aria-hidden="true"></div>
                <span class="w-100">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.HelpfulHSCourses}}</span>
            </div>
          </div>   
        </div>       
          <div class="card-block text-dark pl-3 py-0">
            <div *ngFor="let value of (occCareerStore).HelpfulHSCourses">
              <p class="text-secondary" *ngIf="(value.type == 'p')" [innerHTML]='value.text | PrintWeblinkPipe'></p>
              <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4">
                <li *ngFor="let val of value.text">
                  <div class="headding-arrow mb-1 pl-2" [innerHtml]="val | MarkdownToLink"></div>
                </li>
              </ul>
            </div>
        </div>
      </div>
      <!--Display about Knowledge-->
      <div class="html2pdf__page-break"></div>
      <div class="career-card mt-3">
        <div class="cardcolor-30 mb-3">
          <div class="career-card-header">
            <div  class="card-title">
              <div class="icon-knowledge-sprite panal-icon-align" aria-hidden="true"></div>
                <span class="w-100">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.Knowledge}}</span>
              </div>
          </div>
        </div>     
        <div class="card-block text-dark pl-3 py-0">
          <div *ngFor="let value of (occCareerStore).Knowledge">
            <div *ngIf="(value.type == 'h')">
              <b [innerHtml]="value.text | MarkdownToLink"></b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled pl-3">
              <li *ngFor="let val of value.text">
                <div class="headding-arrow mb-1 pl-3 career-indent" [innerHtml]="val | MarkdownToLink"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="html2pdf__page-break"></div>
      <!--Display about RelatedPrograms-->
      <div class="career-card mt-3">
        <div class="cardcolor-8 mb-3">
            <div class="career-card-header" >
            <div class="card-title">
              <div class="icon-programs_of_study-sprite panal-icon-align" aria-hidden="true"></div>
                <span class="w-100"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedPrograms}}</span>
              </div>
          </div>
        </div>   
          <div class="card-block text-dark pl-3 py-0">
            <div *ngFor="let value of abilityRes">
              <p *ngIf="(value.intro)"><b [innerHtml]="value.intro | MarkdownToLink"></b> </p>
              <div *ngIf="(value.lists)">
                <div *ngFor="let listvalue of value.lists;let in=index">
                  <div class='header_6' *ngIf="(listvalue.header)"><b [innerHtml]="listvalue.header | MarkdownToLink"></b></div>
                    <ul *ngIf="(listvalue.links)" class="list-unstyled pr-4">
                      <li class="headding-arrow mb-1 pl-3" *ngFor="let val of listvalue.links;let inx=index"  [class.html2pdf__page-break]="(inx==28 || ((inx-28)%30==0)) && !NotIE">
                        <a *ngIf="availProgs" class="text-dark" [innerHtml]="val.linkTitle | MarkdownToLink"></a>
                        <a *ngIf="!availProgs" class="text-dark" [innerHtml]="val.linkTitle | MarkdownToLink"></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
        </div>
     <!--Display about LicensingCert-->
     <div class="html2pdf__page-break"></div>
      <div *ngIf='(settingsTextTab?.sections).indexOf("LicensingCert")!= -1'>
        <div *ngIf="(occCareerStore)?.LicensingCert?.length!=0" class="career-card mt-3">
          <div class="Occupation-Sort-card mb-3"> 
          <div class="career-card-header">
            <div class="card-title">
                <div class="icon-licensing_certification-sprite panal-icon-align" aria-hidden="true"></div>
                <span class="w-100">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.LicensingCert}}</span>
            </div>
          </div>
        </div>        
            <div class="card-block text-dark pl-3 py-0">
              <div *ngFor="let value of (occCareerStore).LicensingCert">
                <p class="text-secondary wt-space d-block" *ngIf="(value.type == 'p')" [innerHTML]='value.text | PrintWeblinkPipe'> </p>
                <ul *ngIf="(value.type == 'ul')" class="list text-dark">
                  <li *ngFor="let val of value.text">
                    <div [innerHtml]="val | MarkdownToLink"></div>
                  </li>
                </ul>
                <div class='header_6' *ngIf="(value.type == 'h')">
                  <b [innerHtml]="value.text | MarkdownToLink"></b>
                </div>
              </div>
            </div>

        </div>
      </div>
      <div *ngIf='(settingsTextTab?.sections).indexOf("MoreLicensing")!= -1'>
        <div *ngIf="(occCareerStore)?.MoreLicensing?.length!=0">  
          <div class="mt-3" *ngFor="let value of (occCareerStore)?.MoreLicensing; let inx1=index;let first=first;">
              <div class="career-card Occupation-Sort-card mb-2 mt-3">
                <div class="career-card-header">
                  <div class="card-title">
                    <!-- <span [ngClass]="programsValue['cls_'+clus.clusterID].clusterIconValue"></span> -->
                    <i class="icon-licensing-certification panal-icon-align" aria-hidden="true"></i>
                    <span class="w-100" [innerHtml]="value.title | MarkdownToLink"></span>
                </div>
              </div>
            </div>
              <div class="card-block text-dark pl-3 py-0" id="value{{inx1}}">
                <div *ngFor="let val of value.lines">
                  <p class="text-secondary" *ngIf="(val.type == 'p')" [innerHTML]='val.text | PrintWeblinkPipe'> </p>
                  <ul *ngIf="(val.type == 'ul')" class="list">
                    <li *ngFor="let listval of val.text">
                      <div [innerHtml]="listval | MarkdownToLink"></div>
                    </li>
                  </ul>
                  <div class='header_6' *ngIf="(val.type == 'h')">
                    <b [innerHtml]="val.text | MarkdownToLink"></b>
                  </div>
                  <div *ngIf="(val.type=='address')"> 
                      <address class="mb-0" *ngFor="let addressval of val.text">
                          <p class="mb-0 text-secondary" [innerHTML]='addressval | PrintWeblinkPipe'></p>                        
                      </address>
                  </div>
                </div>
              </div>            
              </div>
            </div>
          </div>
            <!--MoreEducation print code-->
          <div class="career-card text-dark mt-3" *ngIf='(settingsTextTab?.sections).indexOf("MoreEducation")!= -1'>
            <div *ngIf="(occCareerStore)?.MoreEducation?.length!=0">   
               <div *ngFor="let value of (occCareerStore)?.MoreEducation; let inx1=index;let first=first;">
                  <div class="cardcolor-28 mb-3">
                    <div class="career-card-header">
                      <div class="card-title">
                        <i class="icon-career_rediness_key panal-icon-align" aria-hidden="true"></i>
                        <span class="card-title pl-2" tabindex="0" [innerHtml]="value.title | MarkdownToLink"></span>
                    </div>
                  </div>
                 </div>       
                  <div class="card-body pl-3 py-0" id="value{{inx1}}">
                    <div *ngFor="let val of value.lines">
                      <p class="mb-2" *ngIf="val.type == 'p'" [innerHTML]='val.text | MarkdownToLink'></p>
                      <div class="py-2 px-3 font-weight-bold" *ngIf="(val.type == 'h')" [innerHtml]="val.text | MarkdownToLink"></div>
                        <div class="list-table px-3" *ngIf="(val.type == 'table')">
                          <div class="alternative-list-color" *ngFor="let list of val.text">
                            <div class="row list-head p-1" *ngIf="(list.type == 'head')">
                              <div class="col-3 text-center" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink"></div>
                            </div>
                            <div class="row sub-list-color p-1" *ngIf="(list.type == 'row')">
                              <div class="col-3 text-center" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>            
                  </div>
                </div>
              </div>
            </div>  
          <div class="pt-3 text-center">
            <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
          </div>
        </div>  
</div><!-- Print End Eduction Training -->