/** Angular imports */
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

/** rxjs imports */
// import { Observable } from 'rxjs/Rx';
import { Subscription } from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/observable/forkJoin';
import { EventDispatchService } from '../shared/outer-services/event-dispatch.service';
import { StorageService } from '../shared/outer-services/storage.service';
import { Utilities } from '../shared/outer-services/utilities.service';
import { RouteService } from '../shared/outer-services/route.service';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonNgbdModalContent } from './common-modal';

/** Third party imports  */

// import { ApiCallClass } from '../modal/apicall.modal';
// import { StorageService } from '../outer-services/storage.service';
// import { Utilities } from './utilities.service';
// import { EventDispatchService } from './event-dispatch.service';


let expiredTime, eventTime;

@Injectable()
export class CommonExtendTime implements OnDestroy {
  modalRef1;
  newSessionId;
  sessionOutTime;
  transText = {};
  subscription = new Subscription();
  options: NgbModalOptions = {
    backdrop: 'static',
  }; // options variable passing as a parameter to the open method of modalService
  constructor(
    private http: HttpClient,
    private router: Router,
    private routeService: RouteService,
    private storageService: StorageService,
    private eventService: EventDispatchService,
    private modalService: NgbModal,
    private utils: Utilities
  ) {
    this.subscription = eventService.listen().subscribe((e) => {
      /** After event listen it will check whether user want to save partially or completely */
      if (e.type == 'sessionExtend') {
        this.extendTime(eventTime);
        //  this.utils.clearTimeoutVariable();
      }
      // console.log('Utilities Subscription event called:' + e.type);
    });
  }

  entendTimeForUser(res) {
    const body = res;
    // console.log('succes bodey-->'+ JSON.stringify(res));
    eventTime = body.eventTime;
    window.localStorage.setItem('formioToken', body.sessionID);
    this.storageService.sessionStorageSet('auth_key', body.sessionID);
    try {
      this.extendExpiration(body, eventTime, this);
    } catch (e) {
      console.log('exc==>' + e.message);
    }

    return body || {};
  }

  extendExpiration = function (body, sec, ref) {
    if (body.Success + '' == 'true') {
      // console.log("new session--->" + this.storageService.sessionStorageGet('auth_key'));
      clearTimeout(expiredTime);
      // This setTimeout() function is used to call this function
      // called once after the specified delay.
      expiredTime = setTimeout(
        function () {
          const authkey = this.utils.getAuthKey();
          // console.log("authkey-->" + authkey);
          if (authkey != '' && authkey != undefined && authkey != null) {
            const newtime = new Date().getTime();
            const oldtime = parseInt(
              this.storageService.sessionStorageGet('currentSec')
            );
            const diff = newtime - oldtime;
            //  console.log("newtime-->" + newtime + "     oldtime-->" + oldtime + "  diff-->" + diff);

            // console.log('called setTimeot()');
            if (diff >= eventTime) {
              //  console.log('called if()');
              //ref.popUpDialog.show(ref);
              this.openModalForSession();
            } else {
              // console.log("called else");
              this.extendTime(eventTime - diff);
            }
          }
        }.bind(this),
        parseInt(sec)
      );
    }
    return body;
  }.bind(this);

  extendTime = function (sec) {
    if (!this.storageService.sessionStorageGet('auth_key') || this.storageService.sessionStorageGet('auth_key') === 'undefined') {
      this.routeService.mainLogOut();
      return;
    }
    const xhttp = new XMLHttpRequest();
    xhttp.open('GET', '/api/extendTime', true);
    xhttp.setRequestHeader(
      'Authorization',
      'Bearer ' + this.storageService.sessionStorageGet('auth_key')
    );
    xhttp.send();
    xhttp.onreadystatechange = function () {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        if (xhttp.responseText) {
          const newSessionId = JSON.parse(xhttp.responseText);
          newSessionId.Success = 'true';
          this.storageService.sessionStorageRemove('auth_key');
          window.localStorage.setItem('formioToken', newSessionId.sessionID);
          this.storageService.sessionStorageSet(
            'auth_key',
            newSessionId.sessionID
          );
          this.extendExpiration(newSessionId, sec, this);
        }
      } else if (xhttp.status == 400) {
        this.utils.sessionExpired();
      }
    }.bind(this);
  }.bind(this);

  // call when session expired modalpopup
  openModalForSession() {
    this.modalRef1 = this.modalService.open(
      CommonNgbdModalContent,
      this.options
    );
    this.modalRef1.componentInstance.commonButtons = {
      yesbtn: 'yes',
      nobtn: 'no',
      headerText: 'Session Expired',
    };
    this.modalRef1.componentInstance.session_exp_txt =
      'Your session will expire in 2 minutes. Do you wish to continue?';
    this.modalRef1.componentInstance.sessionName = 'sessioncheck';
  }
  ngOnDestroy() {
    //window.location.href.replace(location.hash, "");
    this.subscription.unsubscribe();
  }
}


