<ng-template ngbModalContainer>
</ng-template>
<div class="fixed-top">
  <div class="container">
    <div class="header-logo">   
      <div class="main-logo" [attr.aria-label]='headerFooterObj?.appName+ "-logo"'>
          <img tabindex="0" alt="" src="{{logo}}" />
      </div>       
    </div>
  </div>
  
  <div class="master-head">
    <div class="container">

      <div class="row">
        <div class="col-lg-12">
          <h1 class="master-head-text">
            <b>{{headerFooterObj?.appName}}</b>
          </h1>
        </div>

      </div>
      <div class="row master-header-top">
        <div class="col-lg-12 plp-blue  master-header-menu text-right p-0">
          <ul class="mb-0 mt-1 d-flex">
            <!-- When there is only one language We are hiding language dropdown -->
            <li *ngIf="(userObj?.accountType!='Guest') && userObj?.langList?.length > 1"
              placement="left" ngbTooltip="Language">
              <div class="dropdown" ngbDropdown>
                <button type="button" aria-label="Language drop-down" 
                  class="d-flex align-items-center btn-common font-weight-bold pointer dropdown-toggle"
                   id="dropdownLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="disableLanguageChanged()" [attr.title]="getLanguageTitle()"
                  >
                  <span class="text-display-head">{{changedLang}}</span>
                  <span class="icn-display-head">
                    <img alt="CIS-user" src="../../../assets/images/Language.png"> 
                  </span>
                </button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownLanguage">
                  <a class="dropdown-item pointer" (click)="selectLang(lang)" *ngFor="let lang of userObj?.langList"
                    tabindex="0">
                    <span id="dropdownMenu">{{ lang.name }}</span>
                  </a>
                </div>
              </div>
            </li>
            <li class="account-dropdown" *ngIf="accountTypeStore!='Guest' && accountTypeStore!='Restricted'"
              [ngStyle]="{'cursor':'pointer'}" placement="left" ngbTooltip="Logout">
              <div class="dropdown" ngbDropdown>
                <button type="button" aria-label="Profile drop-down"
                  class="d-flex align-items-center btn-common font-weight-bold pointer dropdown-toggle mb-0"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownprofile">
                  <a class="text-display-head align-items-center">
                    <!-- desktop logout button -->
                    <img class="rounded-circle" alt="" src="{{personalData?.dashBoardPersonalInfoData?.pictureLink}}">
                    <label class="text-capitalize login-mobile-username mb-0 pointer ml-1">
                      {{personalData?.dashBoardPersonalInfoData?.firstName}}
                    </label>
                  </a>
                  <span class="icn-display-head">
                    <img alt="CIS-user" src="{{personalData?.dashBoardPersonalInfoData?.pictureLink}}">
                   </span>
                </button>
                <div class="dropdown-menu p-1 border-0" ngbDropdownMenu aria-labelledby="dropdownprofile">
                  <!-- <a class="pb-2 pt-1 font-weight-bold d-flex align-items-center justify-content-center">
                      <img alt="" class="mr-1 rounded-circle" src="{{personalData?.dashBoardPersonalInfoData?.pictureLink}}">
                      {{personalData?.dashBoardPersonalInfoData?.firstName}}
                  </a> -->
                  <a class="pb-2 pt-1 username-3-dots px-2 d-block font-weight-bold text-center">
                    <img alt="" class="mr-1 rounded-circle"
                      src="{{personalData?.dashBoardPersonalInfoData?.pictureLink}}">
                    <span>{{personalData?.dashBoardPersonalInfoData?.firstName}}</span>
                  </a>
                  <a class="btn icn-signout mb-1 w-100 pointer px-2" tabindex="0" (keyup.enter)="accountSetting()"
                    (click)="accountSetting()"><img class="pr-1 gear-icon"
                      src="/assets/images/account-settings.png">{{headerFooterObj?.settings}}</a>
                  <a *ngIf="(headerFooterObj?.showEnterprise || enableGotoEnterprise)" class="btn icn-signout mb-1 w-100 pointer px-2"
                    tabindex="0" (keyup.enter)="navigateEnterprise()"
                    (click)="navigateEnterprise()"><i class="fa fa-sign-out pr-1"
                    aria-hidden="true"></i>{{headerFooterObj?.enterprise}}</a>
                  <a class="btn icn-signout w-100 pointer px-2" tabindex="0" (keyup.enter)="logout()"
                    (click)="logout()"><i class="fa fa-sign-out pr-1"
                      aria-hidden="true"></i>{{headerFooterObj?.logOut}}</a>
                </div>
              </div>
            </li>
            <li *ngIf="accountTypeStore=='Restricted'" class="bg-transparent">
              <button type="button" class="align-middle common-button green font-weight-bold pointer"
                tabindex="0" (keyup.enter)="logout()" (click)="logout()"><i class="fa fa-sign-out pr-1"
                aria-hidden="true"></i>{{headerFooterObj?.logOut}}</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="logo-navbar">
    <div class="container">
      <nav class="d-flex justify-content-end navbar-toggleable-md">
        <div class="navbar-menu-mobile">
          <button class="mobile-icon-align" *ngIf="(menuItems.length>1)" (click)="hideFil('btn')" type="button" data-toggle=""
            data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
            aria-label="Toggle navigation">
            <i *ngIf="!clickBtn " class="fa fa-bars" aria-hidden="true"></i>
            <i *ngIf="clickBtn " class="fa fa-times" aria-hidden="true"></i>
          </button>
          <button class="mobile-icon-align d-flex" type="button" *ngIf="ltiReturnUrl">
            <p class="px-2 mb-0 text-white">&#124;</p>
            <span class="font-weight-bold pointer ml-0" tabindex="0" (keyup.enter)="logout()" (click)="logout()"><i
                class="fa fa-sign-out pr-1" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div #filter [hidden]="!clickBtn || hideFilter" class="navbar-menu" id="navbarNavAltMarkup" (click)="hideFil('bar')">
          <div *ngIf="(menuItems.length>1)" class="collapse navbar-nav">
            <a href="#" *ngFor="let item of menuItems" class="nav-link pointer"
              [class.nav-list-active]="isActive([item?.root])" (click)="$event.preventDefault();loadModules(item);setDocTitle(item.title)">
              {{item.title}}</a>
            <!-- *ngIf="ltiReturnUrl" -->
            <div class="text-white d-flex login-menu-desktop" *ngIf="ltiReturnUrl.length>0">
              <p class="pr-2 mb-0">&#124;</p>
              <div class="font-weight-bold pointer nav-link ml-0" tabindex="0" (keyup.enter)="logout()" (click)="logout()">
                Return Home</div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
<div class="w-100 h-100" style="position: absolute;z-index:1;" [hidden]='!clickBtn || hideFilter'
  (click)="hideFil('bar')"></div>
<router-outlet></router-outlet>