<div class="career-ataglance career-jr">
  <div class="row gutter-10 d-table-cell">
    <div class="col-lg-12 col-md-12 pt-3 header-text-color"
      *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription")!= -1'>
      <p class="font-weight-bold">
        <b>{{CareerJrCareerStore.ShortDescription}}</b>
      </p>

    </div>
    <div class="row gutter-10">
      <div class="col-lg-4 col-md-4" *ngIf='(settingsTextTab?.sections).indexOf("TopOverview")!= -1'>
        <a class="career-card emerging-card  cardcolor-50 p-3 p-md-0 scroll-class">
          <div class="cluster-at-glance text-white align-items-center d-flex  px-2">
            <div>
            <div *ngFor="let item of (CareerJrCareerStore).TopOverview;let inx=index;">
              <div *ngIf="(item.type == 'ul')">
                <div *ngFor="let val of item.text">
                  <p class="text-center font-weight-bold mb-0" [innerHtml]="val | MarkdownToLink"></p>
                </div>
              </div>

              <div *ngIf="(item.type == 'p')">
                <div class="card-block py-4 py-lg-5 text-center">
                  <!-- <i class="icon-question_mark panal-icon-align" aria-hidden="true"></i> -->
                  <img class="panal-icon-align" src="{{fontIcons}}question_mark.svg" alt="questionmark" />
                </div>
                <p class="text-center" [innerHtml]="item.text | MarkdownToLink"></p>
              </div>
            </div>
          </div>
          </div>
        </a>

      </div>
      <div class="col-lg-4 col-md-4">
        <div class="row career-cluster-boxs">
          <div class="col-lg-12" *ngIf='(settingsTextTab?.sections).indexOf("ClusterDefinition")!= -1 && showClusterBox'
            tabindex="0">
            <div class="career-card cluster-card-border">
              <div class="card-block card-block2-p-plp3 align-middle d-table-cell cluster-noun-text text-left"
                [ngStyle]="{'cursor':'default'}">
                <div class="d-flex flex-column h-100 justify-content-center" [ngClass]="(CareerJrCareerStore).ClusterDefinition?'career-card-block':''">
                  <div *ngFor="let value of (CareerJrCareerStore).ClusterDefinition">
                    <div class="text-left px-3 cluster-text header_3" *ngIf="(value.type == 'h')">
                      <b [innerHtml]="value.text | MarkdownToLink"></b>
                    </div>
                    <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                      <i class="cluster-text" [innerHtml]="value.text | MarkdownToLink"></i>
                    </p>
                    <ol *ngIf="(value.type == 'ol')" class="text-left m-0 cluster-text">
                      <li *ngFor="let val of value.text">
                        <div class="font-weight-bold" [innerHtml]="val | MarkdownToLink"></div>
                      </li>
                    </ol>
                  </div>  
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12" *ngIf='(settingsTextTab?.sections).indexOf("ParentCluster")!= -1'>
            <!-- Card that describe about parant cluster-->
            <div class="career-card pointer"
              [ngStyle]="{border : '1px solid' + (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}"
              (click)="$event.preventDefault();
                    workplaceRedirect(CareerJrCareerStore.ParentCluster?.linkID,CareerJrCareerStore.ParentCluster?.linkTitle,CareerJrCareerStore.ParentCluster?.parentClusterIcon,(CareerJrCareerStore).ParentCluster?.parentClusterColor)">
              <div class="card-block card-block2-p-plp3 align-middle d-table-cell pointer">
                <div class="d-flex flex-column h-100 justify-content-center">
                  <div class="mt-1">
                    <div class="header_5 font-weight-bold" [ngStyle]="{color : (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">
                      {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.ParentCluster}}
                    </div>
                    <i [ngStyle]="{color: (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}"
                      class="{{(CareerJrCareerStore).ParentCluster?.parentClusterIcon}}" aria-hidden="true"></i>
                    <i aria-hidden="true"></i>
                  </div>
                  <div class="header_6 font-weight-bold" [ngStyle]="{color : (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">{{
                    (CareerJrCareerStore).ParentCluster?.linkTitle}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4" *ngIf='(settingsTextTab?.sections).indexOf("TopPreparation")!= -1'>
        <!-- Card that describe about TopPreparation-->
        <div class="career-card">
          <div class="cardcolor-28 education-card-height align-items-center scroll-class" [ngClass]="dataView? 'd-block':'d-flex'">
            <div class="py-4 px-3">
              <h5 class="text-center font-weight-bold mb-0">
                {{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.TopPreparation}}</h5>
              <div>

                <!-- {{(CareerJrCareerStore).TopPreparation.length}}
                {{(CareerJrCareerStore).TopPreparation | JSON}} -->
                <div *ngFor="let item of (CareerJrCareerStore)?.TopPreparation;let inx=index;">
                  <div class="mt-4" *ngIf="(item.type == 'p')">
                    <h6 class="text-center" [innerHtml]="item.text | MarkdownToLink"></h6>
                  </div>
                  <div class="mt-4" *ngIf="(item.type == 'ul')">
                    <div class="emerging-preparationList">
                      <ul *ngFor="let val of item.text;let inx=index" class="list">
                        <li *ngIf="(val!=='[More]')" [innerHtml]="val | MarkdownToLink"></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <a *ngIf="moreDataDailog" href="#" data-toggle="modal" data-target="#MoreData" class="text-more">More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 cardcolor-25 text-white py-4">
    <div class="text-center">
      <p class="m-0">
        <span>
          <!-- <i class="icon-check_box panal-icon-align" aria-hidden="true"></i> -->
          <img class="check-icon" src="{{fontIcons}}check_box.svg" alt="check_box" />

        </span>
        <b class="ml-2">{{CareerJrCareerStore.Disclaimer}}</b>
      </p>
    </div>

  </div>
</div>

<!-- at-a-glance print page-->
<div class="hidden-page">
  <div class="print-result-page" #atAGlancePrintPage>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-careersJr-sprite mr-1"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right cis-logo" src="{{logo}}" width="50%">
      </div>
    </div>

    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <div class="header-text-color" *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription")!= -1'>
        <p class="font-weight-bold">
          <b>{{CareerJrCareerStore.ShortDescription}}</b>
        </p>
      </div>
      <div class="d-flex flex-wrap mt-3">
        <div class="flex-fill w-50 pr-1" *ngIf='showClusterBox'>
          <div class="career-card cluster-card-border d-table w-100">
            <div class="card-block align-middle d-table-cell cluster-noun-text text-left"
              [ngStyle]="{'cursor':'default'}">
              <div *ngFor="let value of (CareerJrCareerStore).ClusterDefinition">
                <div class="text-left px-3 text-white header_3 cluster-text" *ngIf="(value.type == 'h')">
                  <b>{{value.text}}</b>
                </div>
                <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                  <i class="cluster-text">{{value.text}}</i>
                </p>
                <ol *ngIf="(value.type == 'ol')" class="text-left cluster-text">
                  <li *ngFor="let val of value.text">
                    <div class="font-weight-bold">{{val}}</div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1">
          <!-- Card that describe about parant cluster-->
          <div class="career-card d-table w-100"
            [ngStyle]="{border: '1px solid' + (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">
            <div class="card-block align-middle d-table-cell cluster-noun-text text-center">
              <div class="mt-1">
                <div class="header_5 font-weight-bold" [ngStyle]="{color: (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">
                  {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.ParentCluster}}
                </div>
                <div class="{{(CareerJrCareerStore).ParentCluster?.parentClusterIcon}}_color-sprite mx-auto my-2"></div>
              </div>
              <div class="header_6 font-weight-bold" [ngStyle]="{color: (CareerJrCareerStore)?.ParentCluster?.parentClusterColor}">
                {{(CareerJrCareerStore).ParentCluster?.linkTitle}}</div>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1" *ngIf='!showClusterBox'>
          <div class="career-card cardcolor-50 w-100">
            <div class="card-block card-block2-p-plp3 align-middle p-2 d-flex justify-content-center flex-column scroll-class cluster-noun-text text-left"
              [ngStyle]="{'cursor':'default'}">
              <div class="career-cluster-panel pb-3 pt-xl-1 pt-lg-0 pt-md-3" *ngIf="occCareerStore.HotJob.rating">
                <div class="career-cluster-icon"><img alt="career-hotjob" width="35"
                    src="{{CareersImages}}career-hotjob.png" /></div>
                <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                  [innerHTML]="occCareerStore.HotJob.text |BoldPipe"></div>
              </div>
              <div class="career-cluster-panel" *ngIf="occCareerStore.STEM.rating">
                <div class="career-cluster-icon"><img alt="career-stem" width="35"
                    src="{{CareersImages}}career-stem.png" />
                </div>
                <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                  [innerHTML]="occCareerStore.STEM.text |BoldPipe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100">
        <!-- Card that describe about TopJobTasks-->
        <div class="career-card cardcolor-50 w-100">
          <div class="career-card-body text-white">
            <div class="mt-5 text-white" *ngFor="let item of (CareerJrCareerStore).TopOverview;let inx=index;">
              <div *ngIf="(item.type == 'ul')" class="list">
                <div *ngFor="let val of item.text">
                  <p class="text-center font-weight-bold mb-1">{{val}}</p>
                </div>
              </div>
              <div *ngIf="(item.type == 'p')">
                <div
                  class="card-block card-block2-p-plp3 align-middle h-50 d-flex justify-content-center">
                  <div class="icon-questin_mark-sprite"></div>
                </div>
                <p class="mb-2 mx-1 pb-5 text-center">{{item.text}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="html2pdf__page-break"></div>
      <div class="w-100" *ngIf='showClusterBox'>
        <div class="career-card cardcolor-28 d-table w-100">
          <div class="card-block card-block2-p-plp3 align-middle d-table-cell cluster-noun-text text-left"
            [ngStyle]="{'cursor':'default'}">
            <div>
              <h5 class="text-center font-weight-bold">
                {{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.TopPreparation}}</h5>
            </div>
            <div class="d-flex justify-content-center"
              *ngFor="let item of (CareerJrCareerStore).Preparation;let inx=index;">
              <div class="w-60 m-auto" *ngIf="(item.type == 'p')">
                <h6 class="text-center py-3 table-text">{{item.text}}</h6>
              </div>
              <div class="px-4" *ngIf="(item.type == 'ul')">
                <ul *ngFor="let val of item.text;let inx=index;" class="list">
                  <li>{{val}}</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="flex-fill w-50 pl-1" *ngIf='!showClusterBox'>
        <div class="career-card cardcolor-50 w-100">
          <div
            class="card-block card-block2-p-plp3 align-middle p-2 d-flex justify-content-center flex-column scroll-class cluster-noun-text text-left"
            [ngStyle]="{'cursor':'default'}">
            <div class="career-cluster-panel pb-3 pt-xl-1 pt-lg-0 pt-md-3" *ngIf="CareerJrCareerStore.HotJob.rating">
              <div class="career-cluster-icon"><img alt="career-hotjob" width="35"
                  src="{{CareersImages}}career-hotjob.png" /></div>
              <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                [innerHTML]="CareerJrCareerStore.HotJob.text |BoldPipe"></div>
            </div>
            <div class="career-cluster-panel" *ngIf="CareerJrCareerStore.STEM.rating">
              <div class="career-cluster-icon"><img alt="career-stem" width="35"
                  src="{{CareersImages}}career-stem.png" />
              </div>
              <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                [innerHTML]="CareerJrCareerStore.STEM.text |BoldPipe"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 cardcolor-25 text-white py-4">
      <div class="justify-content-center px-4 mx-4 align-items-start text-center d-flex">
      <div class="pt-1">
          <!-- <img class="checkIcon" src="assets/images/check_icon.svg" alt="question_mark">   -->
          <div class="icon-check_box-sprite"></div>        
      </div>
      <div class="font-weight-bold">{{CareerJrCareerStore.Disclaimer}}</div>
    </div>
    </div>
    <!-- <div class="html2pdf__page-break"></div> -->
    <div class="mt-1 pt-4 text-center">
      <p class="pt-2 border-top">{{copyright}}</p>
    </div>
  </div>
</div>

<!-- Modal pop up for showing more data -->
<div class="modal fade ability-model" id="MoreData" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="dialog">
    <div class="modal-content modal-color w-75 m-auto">
      <div class="cardcolor-28 modal-heading careerEmerModal">
        <p class="modal-title modal-card-title font-weight-bold pl-3">
          <span id="exampleModalLabel">{{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.TopPreparation}}</span>
        </p>
        <div class="close-btn d-flex justify-content-center align-items-center">
          <button class="modal-close modalPopup-close" data-dismiss="modal">&times;</button>
        </div>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <div class="row">
          <span class="career-card">
            <div class="text-white align-items-center d-flex">
              <div class="careerjr-popup scroll-class">
                <div class="emerging-model" *ngFor="let item of (CareerJrCareerStore).Preparation;let inx=index;">
                  <div *ngIf="(item.type == 'p')">
                    <h6 class="text-center m-0 py-3">{{item.text}}</h6>
                  </div>
                  <div class="px-4" *ngIf="(item.type == 'ul')">
                    <ul class="mb-0 list" *ngFor="let val of item.text;let inx=index">
                      <li>{{val}}</li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>