<div class="row align-items-center">
  <div class="col-md-4 col-lg-4">
    <div class="common-headding" #occcompareFocus>{{(occIndexListText)?.careersJr_Buttons_Reducer_Text?.common?.compareCareers}}</div>
  </div>
  <div class="col-md-8 col-lg-8 text-right">
    <div class="btn-group-plp3 btn-group-justified">
      <button type="button" [attr.aria-label]='(occIndexListText)?.careersJr_Buttons_Reducer_Text?.common?.careersList'
        class="common-button green ml-0" (click)="CareerList('no')">
        <b>{{(occIndexListText)?.careersJr_Buttons_Reducer_Text?.common?.careersList}}</b>
      </button>
      <button type="button" [attr.aria-label]='(occIndexListText)?.careersJr_Buttons_Reducer_Text?.common?.backAssess'
        *ngIf="backAssessmentValue" class="common-button green" (click)="backAssessment()">
        <b>{{(occIndexListText)?.careersJr_Buttons_Reducer_Text?.common?.backAssess}}</b>
      </button>
    </div>
  </div>

</div>
<div id="cmpr-img" class="row cmpr-graph-career-card  ">
  <div class="col-lg-12">
    <a>
      <img alt="compare" src="{{CareersImages}}compare.jpg" class="w-100" /> </a>
  </div>

</div>
<!--  displaying the occupation name that are selected by user-->
<div class="w-100 compHeader">
  <div class="compare-titles" id="cmpr-header">
    <div class="waves-table cmpir-2-btn-plp3 w-100 d-flex align-items-center justify-content-center px-1" tabindex="0" *ngFor="let comName of comOccName;let inx=index">
      <b>
        {{comName}}</b>
    </div>
  </div>
</div>
<!--  displaying the details of each occupation-->
<div class="w-100" role="tablist" aria-multiselectable="true">
  <div class="career-card" *ngFor="let label1 of Firstresult;let inx=index;">
    <div [ngClass]="colorClass[inx]">
      <div class="career-card-header pointer w-100" role="tab" id="heading1"
        (click)="(filter[inx] != inx) ? methodfilter(inx,inx):methodfilter(999,inx)">
        <!--  display the common header for both occupation and its icon-->
        <div class="card-title">
          <span>
            <i [ngClass]="iconClass[inx]" aria-hidden="true" class="panal-icon-align"></i>
          </span>
          <span class="w-100" tabindex="0">
            {{compareNames[inx]}}
          </span>
        </div>
        <div class="d-table-cell align-middle pt-1">
          <button class="common-button default pull-right panal-icon-align p-0 m-0" [attr.aria-label]="(filter[inx] != inx)? 'down rounded arrow':'up rounded arrow'">
            <i *ngIf="(filter[inx] != inx)" [attr.data-parent]="" [attr.href]="valueOccIndexCheck(inx,'ref')"
              [attr.aria-controls]="valueOccIndexCheck(inx,'controls')"
              class="icon-down-rounded-arrow  icn-font-size"></i>
            <i *ngIf="filter[inx] == inx" [attr.data-parent]="" [attr.href]="valueOccIndexCheck(inx,'ref')"
              aria-expanded="true" [attr.aria-controls]="valueOccIndexCheck(inx,'controls')"
              class="icon-up-rounded-arrow icn-font-size"></i>
          </button>
          </div>        
      </div>
    </div>
    <div id="collapsed{{inx}}" *ngIf="filter[inx] == inx" class="" role="tabpanel" aria-labelledby="headingOne">
      <div>
        <!-- this is for first occupation -->
        <div class="row gutter-10 flex-row-cards mt-1">
          <div class="col-lg-6 col-md-6 col-12 ">
            <div class="career-card  card-block cards-equal-height" [ngClass]="colorClass[inx]"
              *ngIf="(IndexkeyValue[inx] != 'OutlookRatings' && IndexkeyValue[inx] != 'WageLevels')">
              <p class="mobile-design-display font-weight-bold">
                <u>{{comOccName[0]}}:</u>
              </p>

              <div *ngFor="let value of label1;let indexOfValue=index;">
                <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"> </p>
                <ul *ngIf="(value.type == 'ul')" class="list">
                  <li *ngFor="let val of value.text;let in=index;">
                    <div [innerHtml]="val | MarkdownToLink"></div>
                  </li>
                </ul>
                <ul *ngIf="(value.items)" class="list">
                  <li *ngFor="let val of value.items">
                    <div [innerHtml]="val | MarkdownToLink"></div>
                  </li>
                </ul>
                <div class="header_6 text-white" *ngIf="(value.type == 'h')">
                  <b [innerHtml]="value.text | MarkdownToLink"></b>
                </div>
                <p *ngIf="(value.intro)"> {{value.intro}}</p>
                <div *ngIf="(value.lists)">
                  <div *ngFor="let listvalue of value.lists;let in=index">
                    <div class="header_6 text-white" *ngIf="(listvalue.header)">{{listvalue.header}} </div>
                    <ul *ngIf="(listvalue.items)" class="list">
                      <li *ngFor="let val of listvalue.items">
                        <div [innerHtml]="val | MarkdownToLink"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!--wage start-->
            <div *ngIf="IndexkeyValue[inx] == 'WageLevels'" class="cmpr-wages-plp3 cards-equal-height">
              <div *ngFor="let wageLabel of CompWageLabel;let allinx=index;" [ngStyle]="{'padding':'10px'}">
                <p [ngStyle]="{'color':'black'}">{{wageLabel}}</p>
                <div class="career-card  cardcolor-1 cards-equal-height">

                  <div class="wages-card-header">
                    <div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 wag-cmpr-mob-show">{{wageTextVal.location}}</div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6">{{wageTextVal.payPeriod}}</div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-6">{{wageTextVal.median}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--display the lacations according to its label values-->
                  <div *ngFor="let locationsVal of finalCompArray[allinx];let locValInx=index;"
                    class="card-block bgColr-cmpr">
                    <div class="row ">
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="row">
                          <div class="wag-cmpr-mob-hide col-lg-1 col-md-1 col-sm-1 col-12 ">
                            <p>
                              <b>{{wageTextVal.location}}:</b>&nbsp;{{locationsVal.plocV}}</p>
                          </div>
                          <div class="col-6 col-lg-11 col-md-11 col-sm-8 wag-cmpr-mob-show">{{locationsVal.plocV}}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-8">
                        <!-- display the median value accoring to period-->
                        <div class="row" *ngFor="let pvalu of locationsVal.pVal;let pinx=index;">
                          <div class="col-lg-6 col-md-6 col-6  cmpr-cap-letr">
                            <p>{{pvalu[0]}}</p>
                          </div>
                          <div class="col-lg-6 col-md-6 col-6">
                            <p>{{pvalu[1]}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- wage ends-->
            <!-- out look starts -->
            <div class="career-card  cardcolor-3 cards-equal-height" *ngIf="IndexkeyValue[inx] == 'OutlookRatings'">

              <div *ngFor="let value of label1;let indexOfValue=index;">
                <div *ngFor="let item of value.states;let inx=index" class="card-block">
                  <p class="mobile-design-display font-weight-bold">
                    <u>{{comOccName[0]}}:</u>
                  </p>
                  <!-- dispaly section name-->
                  <div class="header_4 text-white" align="center">{{item.state}}</div>

                  <div *ngFor="let items of item.ratings;let ratinginx=index">
                    <div class="cmpr-graph-career-card">
                      <div class="eo-location-card cmpr-h-grp-mb">
                        <div class="eo-location-card-data">
                          <!-- display header according to its section-->
                          <div>
                            <b>{{value.headers[ratinginx].header}}</b>
                          </div>
                          <div>
                            <!--  display graph according to scetion value-->
                            <ul id="empId" class="chart-mini eo-location-card-graph " *ngIf="(ratinginx == 0)">
                              <li *ngFor="let currentitem of [0,1,2,3,4];let inx=index">
                                <span
                                  [ngStyle]="(currentitem == items.ratingID)? {'background-color':'#0d47a1 '}:{}"></span>
                              </li>
                            </ul>


                            <ul *ngIf="(ratinginx == 1)" class="eo-location-card-graph cmpr-img-weather">
                              <img alt="average" *ngIf="items.ratingID == 2" src="{{CareersImages}}average.png" />
                              <img alt="bright" *ngIf="items.ratingID == 3" src="{{CareersImages}}bright.png" />
                              <img alt="poor" *ngIf="items.ratingID == 1" src="{{CareersImages}}poor.png" />
                              <img alt="declining" *ngIf="items.ratingID == 0" src="{{CareersImages}}declining.png" />
                            </ul>


                            <ul class="chart-mini-1 eo-location-card-graph" *ngIf="(ratinginx == 2)">
                              <li *ngFor="let item of [0,1,2];let inx=index">
                                <span [ngStyle]="(item == items.ratingID)? {'background-color':'#0d47a1 '}:{}"></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="cmpr-crd-graph-text employee-states">
                          <!-- display rating and amount-->
                          <div>{{items.rating}}</div>
                          <div>{{items.amount}}</div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>
            <!-- outlook ends -->
          </div>
          <!-- this is for second occupation -->
          <div class="col-lg-6 col-md-6 col-12 ">
            <div class="career-card  card-block cards-equal-height" [ngClass]="colorClass[inx]"
              *ngIf="(IndexkeyValue[inx] != 'OutlookRatings' && IndexkeyValue[inx] != 'WageLevels')">
              <p class="mobile-design-display font-weight-bold">
                <u>{{comOccName[1]}}:</u>
              </p>

              <!-- same as first all the screen contain same text like paragraph,heading... write
                common funtionality for all except for wages and employment outlook
                screen, because those two screen have to display data in table -->
              <div *ngFor="let value of SecondResult[inx];let indexOfValue=index;">
                <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
                <ul *ngIf="(value.type == 'ul')" class="list">
                  <li *ngFor="let val of value.text;let in=index;">
                    <div [innerHtml]="val | MarkdownToLink"></div>
                  </li>
                </ul>
                <ul *ngIf="(value.items)" class="list">
                  <li *ngFor="let val of value.items">
                    <div [innerHtml]="val | MarkdownToLink"></div>
                  </li>
                </ul>
                <div class="header_6 text-white" *ngIf="(value.type == 'h')">
                  <b [innerHtml]="value.text | MarkdownToLink"></b>
                </div>
                <p *ngIf="(value.intro)"> {{value.intro}}</p>
                <div *ngIf="(value.lists)">
                  <div *ngFor="let listvalue of value.lists;let in=index">
                    <div class="header_6 text-white" *ngIf="(listvalue.header)"> {{listvalue.header}} </div>
                    <ul *ngIf="(listvalue.items)" class="list">
                      <li *ngFor="let val of listvalue.items">
                        <div [innerHtml]="val | MarkdownToLink"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

            <!--wage start-->
            <div *ngIf="IndexkeyValue[inx] == 'WageLevels'" class="cmpr-wages-plp3 cards-equal-height">

              <div *ngFor="let wageLabel of CompWageLabel1;let allinx=index;" [ngStyle]="{'padding':'10px'}">
                <p [ngStyle]="{'color':'black'}">{{wageLabel}}</p>
                <div class="career-card  cardcolor-1 ">
                  <div class="wages-card-header">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4 wag-cmpr-mob-show">{{wageTextVal.location}}</div>
                      <div class="col-lg-8 col-md-8 col-sm-8">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6 col-6">{{wageTextVal.payPeriod}}</div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-6">{{wageTextVal.median}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--   dispaly the lacations according to its label values-->
                  <div *ngFor="let locationsVal of finalCompArray2[allinx];let locValInx=index;"
                    class="card-block  scroll-class  bgColr-cmpr">
                    <div class="row ">
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <div class="row">
                          <div class="wag-cmpr-mob-hide col-lg-1 col-md-1 col-sm-1 col-12">
                            <p>
                              <b>{{wageTextVal.location}}:</b>&nbsp;{{locationsVal.plocV}}</p>
                          </div>
                          <div class="col-6 col-lg-11 col-md-11 col-sm-8 wag-cmpr-mob-show">{{locationsVal.plocV}}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-8">
                        <!-- display the median value accoring to period-->
                        <div class="row" *ngFor="let pvalu of locationsVal.pVal;let pinx=index;">
                          <div class="col-lg-6 col-md-6 col-6  cmpr-cap-letr">
                            <p>{{pvalu[0]}}</p>
                          </div>
                          <div class="col-lg-6 col-md-6 col-6">
                            <p>{{pvalu[1]}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- wage ends-->
            <!-- out look starts -->
            <div class="career-card  cardcolor-3 cards-equal-height" *ngIf="IndexkeyValue[inx] == 'OutlookRatings'">



              <div *ngFor="let value of SecondResult[inx];let indexOfValue=index;">
                <div *ngFor="let item of value.states;let inx=index" class="card-block ">
                  <p class="mobile-design-display font-weight-bold">
                    <u>{{comOccName[1]}}:</u>
                  </p>

                  <!-- dispaly section name-->
                  <div class="header_4 text-white" align="center">{{item.state}}</div>

                  <div *ngFor="let items of item.ratings;let ratinginx=index">
                    <div class="cmpr-graph-career-card">
                      <div class="eo-location-card cmpr-h-grp-mb">
                        <div class="eo-location-card-data">
                          <!-- display header according to its section-->
                          <div>
                            <b>{{value.headers[ratinginx].header}}</b>
                          </div>
                          <div>
                            <!--  display graph according to scetion value-->
                            <ul id="empId" class="chart-mini eo-location-card-graph " *ngIf="(ratinginx == 0)">
                              <li *ngFor="let currentitem of [0,1,2,3,4];let inx=index">
                                <span
                                  [ngStyle]="(currentitem == items.ratingID)? {'background-color':'#0d47a1 '}:{}"></span>
                              </li>
                            </ul>
                            <ul *ngIf="(ratinginx == 1)" class="eo-location-card-graph cmpr-img-weather">
                              <img alt="average" *ngIf="items.ratingID == 2" src="{{CareersImages}}average.png" />
                              <img alt="bright" *ngIf="items.ratingID == 3" src="{{CareersImages}}bright.png" />
                              <img alt="poor" *ngIf="items.ratingID == 1" src="{{CareersImages}}poor.png" />
                              <img alt="declining" *ngIf="items.ratingID == 0" src="{{CareersImages}}declining.png" />
                            </ul>
                            <ul class="chart-mini-1 eo-location-card-graph" *ngIf="(ratinginx == 2)">
                              <li *ngFor="let item of [0,1,2];let inx=index">
                                <span [ngStyle]="(item == items.ratingID)? {'background-color':'#0d47a1 '}:{}"></span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="cmpr-crd-graph-text employee-states">
                          <!-- display rating and amount-->
                          <div>{{items.rating}}</div>
                          <div>{{items.amount}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- outlook ends -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>