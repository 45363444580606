/**Angular core packages */
import { ModuleWithProviders, NgModule } from '@angular/core';

/**Custom imports */
import { ApiCallClass } from '../../shared/modal/apicall.modal';
import { ServerApi } from '../../shared/outer-services/app.apicall.service';
import { Utilities } from '../../shared/outer-services/utilities.service';
import { RouteService } from '../../shared/outer-services/route.service';
import { DashboardApiCallService } from '../outer-services/dashBoard-api-calls.service';

@NgModule({

})
export class ServiceModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ServiceModule,
            providers: [ServerApi, ApiCallClass,
                DashboardApiCallService, Utilities, RouteService]
        };
    }
}
