<!-- Nav tabs -->
<div id="wages" class="career-wages" *ngIf='(settingsTextTab?.sections).indexOf("WageLevels")!= -1'>
  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item" *ngFor="let tab of occCareerStore.WageLevels?.periods;let i=index;">
      <a class="nav-link " [class.active]="i == 0" data-toggle="tab" [attr.href]="value(tab.period)" role="tab">{{occCareerStore.WageLevels?.headers[tab.period]}}</a>
    </li>
  </ul>
  <!-- Tab panes -->
  <div *ngIf='showWages' class="tab-content content-wages-body">
    <div class="tab-pane" *ngFor="let wagesTabs of occCareerStore.WageLevels?.periods;let inx=index;" id="{{wagesTabs.period}}" 
    role="tabpanel" [class.active]="inx == 0">
      <div class="content wages-tab-mobile">
        <b>{{wagestabs[wagesTabs.period]}}</b>
        <div class="wages-table ">
          <div *ngFor="let wagesVal of wagesTabs.wages;let inx1=index;" id="hideWage">
            <b [ngStyle]="{'color':'black'}"> {{wagesVal.label}} </b>
            <div class="row wages-table-header-bg">
              <!-- Display header text -->
              <div class="col-3 pl-0">{{occCareerStore.WageLevels?.headers.location}}</div>
              <div class="col-3 pl-3 text-center">{{occCareerStore.WageLevels?.headers.entry}}</div>
              <div class="col-3 pl-3 text-center">{{occCareerStore.WageLevels?.headers.median}}</div>
              <div class="col-3 pl-1 text-center">{{occCareerStore.WageLevels?.headers.expert}}</div>
            </div>
            <div id="" role="tablist" aria-multiselectable="true" *ngFor="let locations of wagesVal.locations;let in=index;">
              <div>
                <div>
                  <div role="tab" id="headingOne" *ngIf='locations.isRegion==false'>
                    <div class="row main-table-row-header">
                      <div class="col-3 wages-location">{{locations.location}}</div>
                      <div class="col-3 text-center">{{locations.entry}}</div>
                      <div class="col-3 text-center">{{locations.median}}</div>
                      <div class="col-3 text-center">{{locations.expert}}</div>                      
                    </div>
                  </div>
                  <div *ngIf='locations.isRegion==true'>
                    <div class="row" [ngClass]="(ci%2==0)?'wages-row-bg1':'wages-row-bg2'">
                      <div class="col-3 wages-location"> {{locations.location}} </div>
                      <div class="col-3 wages-mobile-screen text-center">{{locations.entry}}</div>
                      <div class="col-3 wages-mobile-screen text-center">{{locations.median}}</div>
                      <div class="col-3 wages-mobile-screen text-center">{{locations.expert}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [ngStyle]="{'color':'#ef5350 ','font-weight':'bold'}"  *ngFor="let stateName of (occCareerText)?.Occ_Buttons_Reducer_Text?.select?.states;let inxState=index;">
            <span *ngIf="unavailableLocations && unavailableLocations.indexOf(stateName.abbr) != -1" [ngStyle]="{'color':'#424242'}"><i class="fa fa-asterisk" aria-hidden="true"></i>&nbsp;{{stateName.name}} wages unavailable</span>
          </div>
          <div [ngStyle]="{'margin-bottom':'4px','margin-top':'4px'}" *ngFor="let item of occCareerStore.WageLevels?.notes">
            <span [ngStyle]="{'color':'#ef5350 ','font-weight':'bold'}">Note:</span>
            <span [ngStyle]="{'color':'#424242'}">{{item}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row  gutter-10  eo-card-bottom-align">

    <div class="col-lg-2 col-md-2">
      <!-- select state button -->
      <div ngbDropdown class="d-inline-block w-100">
        <button class="common-button select-state-btn w-100 m-0" (click)="checkopen()" data-toggle="collapse" href="#collapseExample"
         aria-expanded="false" aria-controls="collapseExample">
          <b>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.selectStates}}</b>
        </button>
      </div>
    </div>

    <!-- drop down toggel start -->
    <div class="col-12 col-md-12 col-lg-12">
      <div class="collapse slider-drop-down-2" id="collapseExample">
        <div class="card ">
          <div class="card-header-slider">
            {{(occCareerText)?.Occ_Buttons_Reducer_Text?.select?.intro}}
          </div>
          <div class="card-block-slider-filter">


            <div class="statelist ml-2">
              <div class="statelist-items"  *ngFor="let stateName of (occCareerText)?.Occ_Buttons_Reducer_Text?.select?.states;let checkId=index;">

                <ul class="list-unstyled">

                  <li>
                    <div class="common-checkbox">
                      <input type="checkbox" (keydown)="count(stateName.abbr,$event)" (click)="count(stateName.abbr,'click')" id="test{{checkId}}"
                        [checked]="duplicateState.indexOf(stateName.abbr) != -1" [disabled]="duplicateState.indexOf(stateName.abbr) == -1 && displayValue>=5"/>
                      <label for="test{{checkId}}" class="d-flex" [class.disabledp2]="duplicateState.indexOf(stateName.abbr) == -1 && displayValue>=5">
                        <p class="ml-2">{{stateName.name}}</p>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- buttons to display cancel and reset selected states -->
          <div class="card-footer-slider text-right">
            <button class="btn-common btn-success mr-1" data-toggle="collapse" (click)="showStates()" href="#collapseExample" aria-expanded="false" 
            aria-controls="collapseExample" type="button" [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.apply'>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.apply}}</button>
            <button type="button" [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.reset' class="btn-common btn-warning mr-1" (click)="resetStates()">{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.reset}}</button>
            <button class="btn-common btn-danger" data-toggle="collapse" (click)="checkopen()" href="#collapseExample" aria-expanded="false"
             aria-controls="collapseExample" type="button" [attr.aria-label]='(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.cancel'>{{(occCareerText)?.Occ_Buttons_Reducer_Text?.common?.cancel}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>



</div>

<div class="clearfix"> </div>



<div *ngFor="let value of occCareerStore.WageInfo" [ngStyle]="{'margin-top':'15px'}">
  <p *ngIf="(value.type == ' p ') && (settingsTextTab?.sections).indexOf('WageInfo')!= -1"
    [innerHtml]="value.text | MarkdownToLink"></p>
</div>
<!-- Top Locations card -->

<div style="position: relative; "
  *ngIf='(settingsTextTab?.sections).indexOf("TopSalaryLocations")!= -1 && occCareerStore.TopSalaryLocations && occCareerStore.TopSalaryLocations.length>0'>
  <span> </span>
  <div class="top-five-states desktop-table mb-2 desktop-design-display ">
    <div>
      <div class="eo-image-heading header_6">
        {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TopSalaryLocations}}
      </div>
      <div class="row text-center">

        <div class="col-lg-1 col-md-1 "> </div>

        <div class="col-lg-2 col-md-2 col-sm-4 col-6 px-0" *ngFor="let item of occCareerStore.TopSalaryLocations;let
        inx=index ">
          <button type="button " tabindex="-1" class=" loc-button-color " [ngStyle]="{ 'background':loc_btn_color[inx]} ">
            <i> {{item}}</i>
          </button>
        </div>
        <div class="col-lg-1 col-md-1 ">
        </div>
      </div>
    </div>
  </div>
</div>



<!-- print Start Wages-->
<div class="hidden-page">
  <div class="print-result-page" #atWagesPrintPage> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-occresize-sprite"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{logo}}"> 
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <div class="career-wages w-100">
        <div *ngIf='showWages'>
          <div class="tab-pane" *ngFor="let wagesTabs of occCareerStore.WageLevels?.periods;let inx=index;" id="{{wagesTabs.period}}" role="tabpanel" [class.active]="inx == 0">
            <div class="content wages-tab-mobile wages-print-text" [ngStyle]="inx>0 ? {'padding-top':'25px'} : ''">
              <b>{{wagestabs[wagesTabs.period]}}</b>
              <div class="wages-table">
                <div *ngFor="let wagesVal of wagesTabs.wages;let inx1=index;" id="hideWage"  >
                  <div class="content wages-tab-mobile wages-print-text">
                  <b [ngStyle]="{'color':'black'}">{{wagesVal.label}} </b>
                  <div class="col-12">
                    <div  class="row pr-0 py-3 wages-print-text wages-table-header-bg">
                      <!-- Display header text -->
                      <div class="col-3 ">{{occCareerStore.WageLevels?.headers.location}}</div>
                      <div class="col-3 text-center">{{occCareerStore.WageLevels?.headers.entry}}</div>
                      <div class="col-3 text-center">{{occCareerStore.WageLevels?.headers.median}}</div>
                      <div class="col-3 text-center">{{occCareerStore.WageLevels?.headers.expert}}</div>
                    </div>
                  </div>    
                  <div *ngFor="let locations of wagesVal.locations;let in=index;" [class.html2pdf__page-break]="wagesVal.locations.length-1>18 ? ((locations.inx==19 || (locations.inx-19)%20==0)) : in==wagesVal.locations.length-1" [ngClass]="(locations.inx==20 || (locations.inx-20)%20==0)? 'wagesprint' : '' ">
                    <div>
                      <div>
                        <div id="headingOne" *ngIf='locations.isRegion==false'>
                          <div class="col-12">
                            <div class="row main-table-row-header wages-print-text px-0" [ngClass]="{'wagesprint-header': (locations.inx==20 || (locations.inx-20)%20==0)}">
                              <div class="col-3">{{locations.location}}
                              </div>
                              <div class="col-3 text-center">{{locations.entry}}</div>
                              <div class="col-3 text-center">{{locations.median}}</div>
                              <div class="col-3 text-center">{{locations.expert}}</div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf='locations.isRegion==true'>                          
                          <div class="col-12">                            
                            <div class="row wages-auditors-list pr-1" [ngClass]="{'wagesprint': (locations.inx==20 || (locations.inx-20)%20==0)}">
                              <div class="col-3 text-dark">{{locations.location}}</div>                
                              <div class="col-3 wages-mobile-screen text-center text-dark">{{locations.entry}}</div>
                              <div class="col-3 wages-mobile-screen text-center text-dark">{{locations.median}}</div>
                              <div class="col-3 wages-mobile-screen text-center text-dark">{{locations.expert}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div [ngStyle]="{'margin-bottom':'4px','margin-top':'4px'}"
                  *ngFor="let item of occCareerStore.WageLevels?.notes">
                  <span [ngStyle]="{'color':'#ef5350 ','font-weight':'bold'}">Note:</span>
                  <span [ngStyle]="{'color':'#424242'}">{{item}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>      
      </div>
    </div>
    <!-- <div class="html2pdf__page-break"></div>  -->
    <div class="mt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div> <!--Wages print End-->