<div class="modal-header" data-keyboard="false">
      <h4 class="modal-title"> {{headerText}}{{((modalCommonText)?.commonText[headsection])}}{{trans_error}}</h4>
      </div>
      <div class="modal-body"  >
     {{session_exp_txt }} {{err_occ }}{{((modalCommonText)?.commonText[headingText])}}</div>
      <div class="modal-footer"  [hidden]="closevalue">
      <button id="save" type="button" [attr.aria-label]='(modalCommonText)?.commonText[yesbtn]' class="btn-common btn-success" (click)="deleteTestScore()" >{{(modalCommonText)?.commonText[yesbtn]}}</button>
      <button id="cancel" type="button" [attr.aria-label]='(modalCommonText)?.commonText[nobtn]' class="btn-common btn-primary" (click)="activeModal.close('Close click')">{{(modalCommonText)?.commonText[nobtn]}}</button>
      </div>
     <div class="modal-footer" [hidden]="!closevalue">

    <button id="reload" type="button" class="btn-common btn-warning" (click)="reloadmodal()" >Reload</button>
     <button id="logout" type="button" class="btn-common btn-warning" (click)="logoutmodal()" >Sign out</button>
     </div>
    