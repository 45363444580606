<div class="career-ataglance">
  <div class="row gutter-10 ">
    <!-- Wages card  -->
    <ng-template #wages>{{(occCareerStore)?.SalaryRating?.flyover}}
    </ng-template>
    <div class="col-lg-4 col-md-12 pointer mb-2 mb-lg-0" 
    title="Wages"
    *ngIf='(settingsTextTab?.sections).indexOf("SalaryRating") != -1' 
    (click)='$event.preventDefault();callPage("wage")'>
      <a href="javascript:void();"  class="card graph-card" >
        <div class="card-block card-block-height-sm">
          <div class="row gutter-10" #ts4="ngbTooltip" [ngbTooltip]="wages">
            <div class="col-lg-5 col-md-5 text-center-graph text-md-left" >
              <div>
                <b>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.SalaryRating}}</b>
              </div>
              {{(occCareerStore).SalaryRating?.rating}}
            </div>
            <div class="col-lg-7 col-md-7 ">
              <ul class="chart-skills mx-auto">
                <li>
                  <span [ngClass]="((occCareerStore).SalaryRating?.ratingID==0)? 'activeChartClass0':''">
                  </span>
                </li>
                <li>
                  <span [ngClass]="((occCareerStore).SalaryRating?.ratingID==1)? 'activeChartClass1':''">
                  </span>
                </li>
                <li>
                  <span [ngClass]="((occCareerStore).SalaryRating?.ratingID==2)? 'chart-ative-p3':''">
                  </span>
                </li>
              </ul>

              <div class="salry-value at-glance-salry-value"> {{(occCareerStore).SalaryRating?.amount}} </div>

            </div>
          </div>
        </div>
      </a>
    </div>
    <!-- Education & Training card  -->
    <ng-template #education>{{(occCareerStore)?.EducationLevel?.flyover}}
    </ng-template>
    <div class="col-lg-4 col-md-6 pointer mb-2 mb-lg-0" *ngIf='(settingsTextTab?.sections).indexOf("EducationLevel") != -1' (click)='$event.preventDefault();callPage("edu")'
       title="Education & Training">
      <a href="javascript:void();" class="card graph-card">
        <!-- Card that displays about EducationLevel-->
        <div class="card-block education-card text-center-graph text-md-left">
          <div class="row gutter-10" #ts5="ngbTooltip" [ngbTooltip]="education">
            <div class="mb-1">
              <b> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.EducationLevel}}</b>
            </div>
            <div class="stacked-bar-graph d-inline-flex">
              <span *ngFor="let item of [0,1,2,3,4]" [ngStyle]="(item == (occCareerStore).EducationLevel?.ratingID)? {'background-color':'#0d47a1'}:{}">&nbsp;
                <p *ngIf="(item == (occCareerStore).EducationLevel?.ratingID)" class="edu-name-style"> {{(occCareerStore).EducationLevel.rating}}</p>
  
              </span>
  
            </div>
          </div>         
        </div>
      </a>
    </div>
    <!-- Employment Opportunities card  -->
    <ng-template #opportunities>{{(occCareerStore)?.OpportunityRating?.flyover}}
    </ng-template>
    <div class="col-lg-4 col-md-6 pointer" 
    title="Employment Opportunities"
    *ngIf='(settingsTextTab?.sections).indexOf("OpportunityRating")!= -1' (click)='$event.preventDefault();callPage("outlook")'>
      <a href="javascript:void();" class="card graph-card" >
        <!--  Card that displays about OpportunityRating -->
        <div class="card-block">
          <div class="row gutter-10 text-center-graph text-md-left" #ts6="ngbTooltip" [ngbTooltip]="opportunities">
            <div class="col-lg-8 col-md-8">
              <div>
                <b>{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.OpportunityRating}}</b>
              </div>
              {{(occCareerStore)?.OpportunityRating?.rating}}
            </div>
            <div class="col-lg-4 col-md-4 text-center-graph text-md-left py-2 py-md-0">
              <div class="eo-bar-chart m-auto p-0 d-table w-100">

                <li *ngFor="let item of [0,1,2];let inx=index">
                  <span [ngStyle]="(item == (occCareerStore)?.OpportunityRating?.ratingID)? {'background-color':'#0d47a1'}:{}"></span>
                </li>
              </div>
            </div>
            <span class="ataglance-bargraph">
              {{(occCareerStore)?.OpportunityRating?.amount}}
            </span>
          </div>
        </div>
      </a>
    </div>

  </div>
  <div class="row gutter-10">
    <!-- Card that describe about occupation-->
    <div class="col-lg-12 col-md-12 pt-3 " *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription")!= -1'>
      <p>
        <b [innerHtml]="(occCareerStore)?.ShortDescription | MarkdownToLink"></b>
      </p>
    </div>
    <div class="col-lg-4 col-md-4 pointer" *ngIf='(settingsTextTab?.sections).indexOf("TopJobTasks")!= -1' (click)='$event.preventDefault();callPage("job_Task")'>
      <!-- Card that describe about TopJobTasks-->

      <a href="#" class="career-card  cardcolor-12">
        <div class="career-card-header">
          <div class="card-title">
            <i class="icon-job-list  panal-icon-align" aria-hidden="true"></i> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TopJobTasks }} </div>
        </div>
        <div class="card-block career-card-body scroll-class">
          <ul class="list" style="color:white">
            <li *ngFor="let item of (occCareerStore).TopJobTasks;let inx=index;">
              <div class="mb-1" [innerHtml]="(occCareerStore).TopJobTasks[inx] | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </a>

    </div>
    <div class="col-lg-4 col-md-4">
      <!--Cluster Definition-->
      <div *ngIf='(settingsTextTab?.sections).indexOf("ClusterDefinition")!= -1 && showClusterBox' tabindex="0">
        <div class="career-card cardcolor-25 pointer" (click)="careerRedirect()">
          <div class="card-block card-block2-p-plp3 align-middle d-table-cell cluster-noun-text text-left">
            <div class="d-flex flex-column h-100 justify-content-center" [ngClass]="(occCareerStore).ClusterDefinition?'career-card-block':''">
              <div *ngFor="let value of (occCareerStore).ClusterDefinition">
                <div class="text-left px-3 text-white header_3" *ngIf="(value.type == 'h')">
                  <b [innerHtml]="value.text | MarkdownToLink"></b>
                </div>
                <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                  <i [innerHtml]="value.text | MarkdownToLink"></i>
                </p>
                <ol *ngIf="(value.type == 'ol')" class="text-left">
                  <li *ngFor="let val of value.text">
                    <div class="font-weight-bold" [innerHtml]="val | MarkdownToLink"></div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- HotJobs & Stem Card & SelfEmployment-->
      <ng-template #tipContents>          
        <div class="career-cluster-text" [innerHTML]="occCareerStore.HotJob.flyover |BoldPipe"></div>
      </ng-template>
      <ng-template #tipContent1>          
        <div class="career-cluster-text" [innerHTML]="occCareerStore.STEM.flyover |BoldPipe"></div>
      </ng-template>
      <ng-template #tipContents2>          
        <div class="career-cluster-text" [innerHTML]="occCareerStore.SelfEmployment.flyover |BoldPipe"></div>
      </ng-template>
      <div *ngIf='!showClusterBox' tabindex="0">
        <div class="career-card cardcolor-25">
          <div class="card-block card-block2-p-plp3 align-middle p-2 cluster-noun-text" [ngStyle]="{'cursor':'default'}">
            <div class="d-flex align-items-center justify-content-center flex-column h-100">
              <div class="career-cluster-panel pl-xl-2 pr-lg-2 pl-md-0 pb-3" *ngIf="occCareerStore.HotJob.rating" [attr.aria-hidden]="occCareerStore.HotJob.rating? false: true">
                <div class="career-cluster-icon pr-md-4 pl-2 pl-xl-3"><img alt="career-hotjob" width="35" src="{{CareersImages}}career-hotjob.png" />
                  <span class="career-cluster-text pl-1 font-weight-bold career-text-block" #ts6="ngbTooltip" [ngbTooltip]="tipContents">{{occCareerStore.HotJob.text}}</span>
                </div>
              </div>
              <div class="career-cluster-panel pr-0 pr-xl-0 pl-lg-0 pr-md-3 pb-3" *ngIf="occCareerStore.STEM.rating"  [attr.aria-hidden]="occCareerStore.STEM.rating? false: true">
                <div class="career-cluster-icon pr-md-3 pr-xl-4"><img alt="career-stem" width="35" src="{{CareersImages}}career-stem.png" />
                  <span class="career-cluster-text pl-1 font-weight-bold career-text-block" #ts7="ngbTooltip" [ngbTooltip]="tipContent1">{{occCareerStore.STEM.text}}</span>
                </div>
              </div>
              <div class="career-cluster-panel pl-lg-4 pl-md-4" *ngIf="occCareerStore.SelfEmployment.rating"  [attr.aria-hidden]="occCareerStore.SelfEmployment.rating? false: true">
                <div class="career-cluster-icon pl-md-0 pl-xl-2"><img class="pb-md-3" alt="career-selfemployment" width="35" src="{{CareersImages}}enterpreneurial-icon.png" />  
                  <span class="career-cluster-text pl-1 font-weight-bold career-text-block" #ts8="ngbTooltip" [ngbTooltip]="tipContents2">{{occCareerStore.SelfEmployment.text}}</span>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card that describe about parent cluster-->
      <div *ngIf='(settingsTextTab?.sections).indexOf("ParentCluster")!= -1'>
        <a href="#" class="career-card d-table w-100" [ngStyle]="{'background-color': (occCareerStore)?.ParentCluster?.parentClusterColor}"
          (click)="$event.preventDefault();
                  workplaceRedirect(occCareerStore.ParentCluster?.linkID,occCareerStore.ParentCluster?.linkTitle,occCareerStore.ParentCluster?.parentClusterIcon,(occCareerStore).ParentCluster?.parentClusterColor)">
          <div class="card-block card-block2-p-plp3 align-middle d-table-cell">
            <div class="d-flex flex-column h-100 justify-content-center">
              <div class="mt-1">
                <div class="text-white font-weight-bold">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.ParentCluster}}</div>
                <i class="{{(occCareerStore).ParentCluster?.parentClusterIcon}} " aria-hidden="true"></i>
              </div>
              <div  class="text-white font-weight-bold" [ngStyle]="{'-webkit-line-clamp': '3'}">{{ (occCareerStore).ParentCluster?.linkTitle}}</div>
            </div>
          </div>
        </a>
      </div>
      <!--EdLevel Card-->
      <div *ngIf='(settingsTextTab?.sections).indexOf("EdLevelOnCard")!= -1' (click)='$event.preventDefault();callPage("edu")'>
        <a href="#" class="pointer">
          <div class="career-card sky-blue d-table w-100">
            <div class="card-block card-block2-p-plp3 align-middle d-table-cell cluster-noun-text text-left" [ngStyle]="{'cursor':'default'}">
              <div class="graph-card">
                <!-- Card that displays about EducationLevel-->
                <div class="text-center-graph text-md-left et-graph-card mt-5">
                  <div class="headding">
                    <b> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.EdLevelOnCard}}</b>
                  </div>
                  <div class="stacked-bar-graph d-inline-flex">
                    <span *ngFor="let item of [0,1,2,3,4];let inx=index;" [ngStyle]="(item == (occCareerStore).EdLevelOnCard?.ratingID)? {'background-color':'#0d47a1 '}:{}">&nbsp;
                      <i *ngIf="(item == (occCareerStore).EdLevelOnCard?.ratingID)" class={{eduIcon[inx]}}></i>
                      <p *ngIf="(item == (occCareerStore).EdLevelOnCard?.ratingID)" class="edu-name-style"> {{(occCareerStore).EdLevelOnCard?.rating}}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 pointer" *ngIf='(settingsTextTab?.sections).indexOf("TopWorkSetting")!= -1' (click)='$event.preventDefault();callPage("job_Setting")'>


      <!-- Card that describe about TopWorkSetting-->
      <a href="#" class="career-card cardcolor-11">
        <div class="career-card-header">
          <div class="card-title">
            <i class="icon-work-setting panal-icon-align" aria-hidden="true"></i> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TopWorkSetting}}
          </div>
        </div>
        <div class="card-block career-card-body scroll-class">
          <ul class="list">
            <li *ngFor="let item of (occCareerStore).TopWorkSetting;let inx=index;">
              <div class="mb-1" [innerHtml]="(occCareerStore).TopWorkSetting[inx] | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </a>

    </div>
  </div>
</div>
<!-- <div > -->
<at-a-glance-print-result [printHidden]="printHidden" [logo]="logo" [careerTitle]="occName" [tabTitle]="tabTitle" (printHidden1)="printchange($event)"
[occCareerStore]="occCareerStore" [settingsText]="settingsText" [settingsTextTab]="settingsTextTab" [showClusterBox]="showClusterBox"
></at-a-glance-print-result>
<!-- </div> -->
