

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// tslint:disable-next-line:rxjs-proper-imports
import { Observable, forkJoin } from 'rxjs';



import { Utilities } from '../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../shared/outer-services/storage.service';
import { CommonExtendTime } from '../../../../Utilities/extend-time.service';
import { map, catchError } from 'rxjs/operators';

let expiredTime, eventTime;


@Injectable()
export class OccApiCallService {
    constructor(
        private http: HttpClient,
        private utils: Utilities,
        private storageService: StorageService,
        private extendTimeForUser: CommonExtendTime
    ) { }
    public extractData = function (res) {
        let body = { eventTime: '', sessionID: '', result: '' };
        try {
            body = JSON.parse(res);
            if (body.result && body.result === 'jwt expired') {
                this.utils.log('occ-apicall.service.ts extractData jwt expired:;', 'debug').subscribe();
            }
        }
        catch (e) {
            this.utils.log(`occ-apicall.service.ts extractData parse failure: ${res}`, 'debug').subscribe();
            throw e;
        }
        eventTime = body.eventTime;
        this.storageService.sessionStorageSet('auth_key', body.sessionID);
        try {
            this.extendTimeForUser.entendTimeForUser(body);
        } catch (e) {
            console.log('exc==>' + e.message);
        }
        return body || {};
    }.bind(this);

    sessionExpired = function () {
        this.utils.sessionExpired();
    }.bind(this);

    // This service is called each time the client tries to hit the server api after login
    callApi(data): Observable<any> {

        if (data[0].method == 'POST') {
            try {
                return this.http.post('/api/postCall', { data: data[0] }, { responseType: 'text' })
                    .pipe(map(this.extractData),
                        catchError(e => this.utils.handleError(`occ-apicalls.service.ts callApi post: ${JSON.stringify(
                            e,
                            Object.getOwnPropertyNames(e)
                        )}`)));
            } catch (e) {
                this.utils.handleError(`occ-apicalls.service.ts callApi post: exception ${JSON.stringify(
                    e,
                    Object.getOwnPropertyNames(e)
                )}`);
            }
        } else {
            const observableBatch = [];
            try {
                data.forEach((componentarray, inx) => {

                    const str = Object.keys(componentarray).map(function (key) {
                        return encodeURIComponent(key) + '=' + encodeURIComponent(componentarray[key]);
                    }).join('&');
                    observableBatch.push(this.http.get(`/api/getCall?${str}`, { responseType: 'text' }).pipe(
                        map(this.extractData),
                        catchError(e => this.utils.handleError(`occ-apicalls.service.ts callApi get: ${JSON.stringify(
                            e,
                            Object.getOwnPropertyNames(e)
                        )} : ${str}`))
                    )
                    );
                });
            } catch (e) {
                this.utils.handleError(`occ-apicalls.service.ts callApi get: exception ${JSON.stringify(
                    e,
                    Object.getOwnPropertyNames(e)
                )}`);
            }
            return forkJoin(observableBatch);
        }
    }

}
