<div class="p-t-b  mobie-width mobile-container  bodypadding">
  <div class="row align-items-center py-2">
    <div class="col-md-4 col-lg-4">
      <!-- dynamic emergingoccname  -->
      <div class="common-headding" #occEmergingFocus> 
        <span><img alt="occresize" src="{{CareersImages}}occresize.png" /></span>
        <span class="title-wraps">{{(occName)}}</span>
      </div>
    </div>
    <!-- careerlist,save,backtoassessment buttons -->
    <div class="col-md-8 col-lg-8 text-right">
      <div class="pull-right desktop-design-display">
        <button type="button" [attr.aria-label]='(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.careersList' class="common-button green" (click)="CareerList('no')">
          <b>{{(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.careersList}}</b>
        </button>
        <!-- <button type="button" [attr.aria-label]='(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.save' class="common-button green" *ngIf="restrictedAccountType" (click)="savePost()">
          <b>{{(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.save}}</b>
        </button> -->
        <button type="button" [attr.aria-label]='(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.backAssess' class="common-button green" *ngIf="backAssessmentValue" (click)="backAssessment()">
          <b>{{(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.backAssess}}</b>
        </button>
        <button type="button" class="common-button green" (click)="emergingPrint()">
          <b>{{(occTextStore).Occ_Buttons_Reducer_Text?.common?.print}}</b>
        </button>
      </div>
      <div class="btn-group btn-group-justified mobile-design-display">
        <button type="button" [attr.aria-label]='(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.careersList' class="btn common-button green" (click)="CareerList('no')">
          <b>{{(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.careersList}}</b>
        </button>
        <button type="button" [attr.aria-label]='(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.backAssess' class="btn common-button green" *ngIf="backAssessmentValue" (click)="backAssessment()">
          <b>{{(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.backAssess}}</b>
        </button>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="row"> 
    <div class="col-lg-12">
      <div *ngIf='(settingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image")!=-1'>
        <a>
          <img alt="header-Emerging-image" src={{(occEmergingStore|async)?.Image}} class="w-100" src='{{CareersImages}}loading.png'
          /> </a>
        <div class="rate-career-bg-black"></div>
        <div class="opacity-career-header row">
          <div class="col-lg-5  col-2 col-md-6 col-sm-2">
            <!-- <a class="video-icon" href="#myModal" data-toggle="modal" data-target="#myModal" [hidden]="((occEmergingStore|async)?.Video|json) == '[]'">
              <img class="video-hight-plp3" src="/assets/images/video-icon-plp3.png" alt="" />
            </a> -->
            <ng-template #tipContent>{{(occTextStore)?.Occ_Buttons_Reducer_Text?.common?.video}}</ng-template>
            <a class="video-icon" href="#myModal" data-toggle="modal" data-target="#myModal" [hidden]="((occEmergingStore|async)?.Video|json) == '[]'"
              #t="ngbTooltip" (blur)='t.close()' placement="top" [ngbTooltip]="tipContent">
              <img class="video-hight-plp3 pointer" src="{{CareersImages}}video-icon-plp3.png" alt="video-icon" />
            </a>
          </div>
          <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
            <div class="modal-dialog modal-width" role="document">
              <div class="modal-content">
                <div class="modal-header">          
                  <button type="button" class="close plp3-model-close-align" (click)="videostop()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" *ngFor="let video of (occEmergingStore|async)?.VideoWithText">
                    <video *ngIf="video.textURL; else notrack" crossorigin="anonymous" disablePictureInPicture class="video-box" controls controlsList="nodownload" #videoplayer>
                      <source src="{{video.videoURL}}" type="video/mp4" />
                      <track src="{{video.textURL}}" type="text/vtt" kind="subtitles" srclang="en" label="On" default/>
                    </video>
                    <ng-template #notrack>
                        <video disablePictureInPicture class="video-box" controls controlsList="nodownload" #videoplayer>
                          <source src="{{video.videoURL}}" type="video/mp4" />
                        </video>
                      </ng-template>
                </div>                   
              </div>
            </div>
          </div>    
          <!-- getUnFill method for clicking the thumbs icons -->
          <div class="col-lg-7 col-md-6 col-sm-10 col-10">
            <div class="row text-right pull-right">

              <div class="d-inline-flex" *ngIf="restrictedAccountType">
                <p class="rate-this-text">{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.UserRating}}</p>
                <span class="rating-up-down mr-2">
                  <a href="#" aria-label="Thumbs up icon" (click)="$event.preventDefault();getUnFill('up')">
                    <i class="fillstroke" [ngClass]="(showColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'" ></i>
                  </a>
                  <!-- <a href="#" aria-label="Thumbs down icon" (click)="$event.preventDefault();getUnFill('down')">
                    <i class="fillstroke" [ngClass]="(showColorUp == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'" ></i>
                  </a> -->
                </span>
              </div>
            </div>
          </div> 
        </div>
      </div>
      <!-- dropDownVal method for displaying the dropdown list in mobileview -->
      <div class="career-tabs">
        <div class="ngb-tabset desktop-design-display">
          <ul class="nav nav-pills justify-content-start menu-alignment-desc-emerg-plp3" role="tablist">
            <li *ngFor='let val of (settingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index' class="nav-item" role="tab" [attr.aria-selected]="(activeVal==inx)? true:false">
              <a class="nav-link" tabindex="0" [class.active]="(activeVal==inx)" (click)="dropDownVal(val.title,inx)" data-toggle="tab" href="#tab{{inx}}"
                [attr.aria-label]='val.title'>{{val.title}}</a>
                <div class="tab-down-arrow"></div>
            </li>
            <!-- getUnFill method for clicking the thumbs icons in chinese view-->
            <div align="center" class="rating-in-tabs" *ngIf='(settingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image") ==-1'>
              <div class="d-inline-flex" *ngIf="restrictedAccountType">
                <p class="rate-this-text">{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.UserRating}}</p>
                <span class="rating-up-down rating-down-2">
                  <a href="#" (click)="$event.preventDefault();getUnFill('up')">
                    <i class="fillstroke" [ngClass]="(showColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'" aria-hidden="true"></i>
                  </a>
                  <a href="#" (click)="$event.preventDefault();getUnFill('down')">
                    <i class="fillstroke" [ngClass]="(showColorUp == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
          </ul>
        </div>
        <div class="ngb-tabset-mobile mobile-design-display  mb-3">
          <div class="d-table w-100">
            <ul class="nav nav-pills d-table-cell">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle font-size" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{value}}</a>
                <div class="dropdown-menu">
                  <a *ngFor='let val of (settingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index' class="nav-link " [class.active]="(activeVal==inx)"
                    (click)="dropDownVal(val.title,inx)" data-toggle="tab" href="#tab{{inx}}" role="tab" [attr.aria-label]='val.title'>{{val.title}}</a>
                </div>
              </li>
            </ul>
            <div class=" d-table-cell" *ngIf='(settingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image") ==-1'>
              <div align="center" class="rating-in-tabs">
                <div class="d-inline-flex" *ngIf="restrictedAccountType">

                  <span class="rating-up-down rating-down-2">
                    <a href="#" (click)="$event.preventDefault();getUnFill('up')">
                      <i class="fillstroke" [ngClass]="(showColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'" aria-hidden="true"></i>
                    </a>
                    <a href="#" (click)="$event.preventDefault();getUnFill('down')">
                      <i class="fillstroke" [ngClass]="(showColorUp == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'" aria-hidden="true"></i>
                    </a>
                  </span>
                </div>
              </div>

            </div>

          </div>
        </div>
        <!-- using childcomponents selectors about-this-career,edu-and-training-emerg,
          related-careers-emerg property binding pass data from child to parent component-->
        <div class="tab-content">
          <div class="tab-pane active" *ngFor='let val of (settingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index' [class.active]="(activeVal==inx)"
            id="tab{inx}" role="tabpanel">
            <div *ngIf='val.tabId == 0'>
              <about-this-career [aboutJsonValue]="emergingAbout" [mainlogo]="logo" [careerTitle]="occName" [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></about-this-career>
            </div>
            <div *ngIf='val.tabId == 1'>
              <edu-and-training-emerg [educationTraining]="emergingEducation" [mainlogo]="logo" [careerTitle]="occName" [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></edu-and-training-emerg>
            </div>
            <div *ngIf='val.tabId == 2'>
              <related-careers-emerg [relatedCareers]="emergingJsonValue" [mainlogo]="logo" [careerTitle]="occName" [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></related-careers-emerg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile view fab save,print buttons -->
    <div class="mobile-design-display  float-btn-plp3">
      <div class=''>
        <button aria-label="Skip to main navigation" class='action-button' (click)="filter = !filter" [ngClass]="{'active': filter }">
          <span aria-hidden class='fa fa-plus'></span>
        </button>
        <ul class='actions'>
          <li class="pointer" (click)="emergingPrint()">
            <a>
              <span class='fa fa-print'></span>
            </a>
          </li>
        </ul>

      </div>
    </div>

  </div>
  <!-- <static-occ-footer class="footerheightlength"></static-occ-footer> -->
