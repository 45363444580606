<div class="personal-qualities">
  <div class="row p-b gutter-10 flex-row-cards">
    <!-- IP Card -->
    <div class="col-lg-6 col-md-6 col-12 " *ngIf='(settingsTextTab?.sections).indexOf("Interests")!= -1'>
      <div class="career-card  cards-equal-height blue">
        <div class="career-card-header my-1 pb-lg-4">
          <div>
          <div class="card-title"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Interests}}</div>
          <p class="cards-font m-0" *ngIf="occCareerStore.TopInterests?.interests.length!=0">{{occCareerStore.TopInterests?.text}}</p>
        </div>
        </div>
        <div *ngIf="asessmentInterestAttended">

          <div *ngIf="occCareerStore.TopInterests?.taken" align="center" class="pq-img-card">
            <!-- Display chart along with top two interest -->
            <div class="pichart-align-plp3">
              <ul>
                <li *ngFor="let val of [0,1]" class="pointer pq-list list-unstyled">
                  <a href="#" (click)="$event.preventDefault();callIpResult('IP',occCareerStore.TopInterests?.interests[val].interest)" [ngStyle]="{'color': pieColor[occCareerStore.TopInterests?.interests[val].interest]}">
                    <span class="pq-list-circle" [ngStyle]="{'background-color': pieColor[occCareerStore.TopInterests?.interests[val].interest]}"></span>
                    <u [ngStyle]="{'color': '#0e90a0'}">{{occCareerStore.TopInterests?.interests[val].interest}}</u>
                  </a>
                </li>
              </ul>
              <canvas baseChart [data]="ipdoughnutChartData" [colors]="iplineChartColors" [chartType]="ipdoughnutChartType"
              [labels]="ipdoughnutChartLabels" [options]="ipoptions" >
            </canvas>
              <!-- <img alt="ip-chart" src="/assets/images/ip-chart.png" class="pt-3" /> -->
            </div>
          </div>
          <div *ngIf="!occCareerStore.TopInterests?.taken" (click)="backAssessment('ip')" align="center" class="pq-img-card pointer">
            <div>
              <img alt="personal_Qua_1" src="{{CareersImages}}personal_Qua_1.png" /> </div>
          </div>
        </div>
        <!-- Display list of interest -->
        <div class="card-block career-card-body scroll-class ">
          <div *ngFor="let value of occCareerStore.Interests">
            <div class='pt-3 header_6 text-white' *ngIf="(value.type == 'h')">
              <b [innerHtml]="value.text | MarkdownToLink"></b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list">
              <li *ngFor="let val of value.text">
                <div [innerHtml]="val | MarkdownToLink"></div>
              </li>
            </ul>

          </div>

        </div>
      </div>
    </div>

    <!-- WIL Card -->
    <div class="col-lg-6 col-md-6 col-12 " *ngIf='(settingsTextTab?.sections).indexOf("Values")!= -1'>
      <div class="career-card purple cards-equal-height">
        <div class="career-card-header my-1">
          <div>
          <div class="card-title"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Values}}</div>
          <p class="cards-font m-0" *ngIf="occCareerStore.TopValues?.values.length!=0">{{occCareerStore.TopValues?.text}}</p>
        </div>
        </div>
        <div *ngIf="asessmentValueAttended">

          <div *ngIf="occCareerStore.TopValues?.taken" align="center" class="pq-img-card">
            <!-- Display chart along with top two Values -->
            <div class="pichart-align-plp3">
              <ul>
                <li *ngFor="let val of [0,1];" class="pointer pq-list list-unstyled">
                  <a href="#" (click)="$event.preventDefault();callIpResult('WIL',occCareerStore.TopValues?.values[val].value)" [ngStyle]="{'color': wilColors[occCareerStore.TopValues?.values[val].value]}">
                    <span class="pq-list-circle" [ngStyle]="{'background-color': wilColors[occCareerStore.TopValues?.values[val].value]}"></span>
                    <u [ngStyle]="{'color': '#0e90a0'}">{{occCareerStore?.TopValues?.values[val]?.title}}</u>
                  </a>
                </li>
              </ul>
              <canvas baseChart [data]="wildoughnutChartData" [colors]="willineChartColors" [chartType]="wildoughnutChartType"
              [labels]="wildoughnutChartLabels" [options]="wiloptions" >    </canvas>
              <!-- <img alt="pieChartimg" src="/assets/images/pieChartimg.jpg" class="pt-3" /> -->
            </div>

          </div>
          <div *ngIf="!occCareerStore.TopValues?.taken" (click)="backAssessment('WIL')" align="center" class="pq-img-card pointer">
            <div>
              <img alt="personal_Qua_2" src="{{CareersImages}}personal_Qua_2.png" /> </div>
          </div>
        </div>
        <!-- Display list of Values -->
        <div class="card-block career-card-body scroll-class ">
          <div *ngFor="let value of occCareerStore.Values">
            <div class='pt-3 header_6 text-white' *ngIf="(value.type == 'h')">
              <b [innerHtml]="value.text | MarkdownToLink"></b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list">
              <li *ngFor="let val of value.text">
                <div [innerHtml]="val | MarkdownToLink"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--- Print Start personal-qualities  --->

<!-- Start Header -->  
<div class="hidden-page">
  <div class="print-result-page" #personalQulitiesPrintPage> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-occresize-sprite"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{logo}}"> 
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div> 
      <div class="personal-qualities">
          <div class="row p-b gutter-10 flex-row-cards">
            <!-- IP Card -->
            <div class="col-12">
              <div class="career-card blue"> 
                <div class="card-header-color py-2 px-3 text-white">          
                  <div class="card-title mb-0"> <b>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.Interests}}</b></div>
                  <p class="cards-font m-0" *ngIf="occCareerStore.TopInterests?.interests.length!=0">{{occCareerStore.TopInterests?.text}}</p>            
                </div>                
                </div>
                <div *ngIf="asessmentInterestAttended" class="row align-items-center justify-content-center">  
                  <div *ngIf="occCareerStore.TopInterests?.taken" class="">
                    <div class="py-3 d-flex justify-content-center">
                      <ul class="pl-0">
                        <li *ngFor="let val of [0,1]" class="pq-list list-unstyled">
                          <a  [ngStyle]="{'color': pieColor[occCareerStore.TopInterests?.interests[val].interest]}">
                            <span class="pq-list-circle" [ngStyle]="{'background-color': pieColor[occCareerStore.TopInterests?.interests[val].interest]}"></span>
                            <p [ngStyle]="{'color': '#0e90a0','margin-bottom':'0px'}">{{occCareerStore.TopInterests?.interests[val].interest}}</p>
                          </a>
                        </li>
                      </ul>                        
                    </div>
                    <canvas *ngIf="ipdoughnutChartData" class="m-auto chart-size" baseChart [data]="ipdoughnutChartData" [colors]="iplineChartColors" [chartType]="ipdoughnutChartType"
                      [labels]="ipdoughnutChartLabels" [options]="ipoptions">
                    </canvas>
                    <!-- <img alt="ip-chart" src="/assets/images/ip-chart.png" class="pt-3" /> -->
                  </div>
    
                    <div *ngIf="!occCareerStore.TopInterests?.taken" align="center" class="pq-img-card col-11 col-md-10 col-lg-10">
                      <div class="icon-personal_Qua_1-sprite">
                         
                      </div>
                    </div>
                    <!-- Display list of interest -->
                    <div class="pt-2 pl-3">
                        <div *ngFor="let value of occCareerStore.Interests">
                          <div class='header_6 text-dark pb-3' *ngIf="(value.type == 'h')">
                            <b [innerHtml]="value.text | MarkdownToLink"></b>
                          </div>
                          <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4 text-dark">
                            <li class="headding-arrow pl-4 mb-1 d-flex" *ngFor="let val of value.text">
                            <p [innerHtml]="val | MarkdownToLink"></p>
                           </li>
                          </ul>      
                        </div>      
                      </div>
                  </div>
            </div>
        
            <!-- WIL Card -->
            <div class="col-12 " >
               <div class="html2pdf__page-break"></div> 
              <div class="career-card purple mt-3"> 
                <div class="card-header-color py-2 px-3 text-white"> 
                  <div class="card-title mb-0"><b>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.Values}}</b></div>
                  <p class="cards-font m-0" *ngIf="occCareerStore.TopValues?.values.length!=0">{{occCareerStore.TopValues?.text}}</p>                              
                </div>                                                 
              </div>
              <div *ngIf="asessmentValueAttended" class="row align-items-center justify-content-center">
        
                  <div *ngIf="occCareerStore.TopValues?.taken" class="">
                    <!-- Display chart along with top two Values -->
                    <div class="py-3 d-flex justify-content-center">
                      <ul class="pl-0">
                        <li *ngFor="let val of [0,1];" class="pq-list list-unstyled">
                          <a [ngStyle]="{'color': wilColors[occCareerStore.TopValues?.values[val].value]}">
                            <span class="pq-list-circle" [ngStyle]="{'background-color': wilColors[occCareerStore.TopValues?.values[val].value]}"></span>
                            <p [ngStyle]="{'color': '#0e90a0','margin-bottom':'0px'}">{{occCareerStore.TopValues?.values[val].value | titlecase}}</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <canvas *ngIf="wildoughnutChartData" class="m-auto chart-size" baseChart [data]="wildoughnutChartData" [colors]="willineChartColors" [chartType]="wildoughnutChartType"
                    [labels]="wildoughnutChartLabels" [options]="wiloptions" >    </canvas>
                    <!-- <img alt="pieChartimg" src="/assets/images/pieChartimg.jpg" class="pt-3" /> -->                    
                  </div>
                  <div *ngIf="!occCareerStore.TopValues?.taken" align="center" class="pq-img-card col-11 col-md-10 col-lg-10">
                    <div class="icon-personal_Qua_2-sprite"></div>
                  </div>
  
                    <!-- Display list of Values -->
                  <div class="pt-2 pl-3">
                    <div *ngFor="let value of occCareerStore.Values">
                      <div class='header_6 text-dark pb-3' *ngIf="(value.type == 'h')">
                        <b [innerHtml]="value.text | MarkdownToLink"></b>
                      </div>
                      <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4 text-dark">
                        <li class="headding-arrow pl-4 mb-1 d-flex" *ngFor="let val of value.text">
                          <p [innerHtml]="val | MarkdownToLink"></p>
                         </li>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>  
    <div class="html2pdf__page-break"></div>  
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div> 
  </div>
</div><!--- Print End personal-qualities  --->
