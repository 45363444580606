import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { LoginModule } from './modules/login/login-module';
import { LoginRoutingModule } from './modules/login/login.routing';
import { OccDetailModule } from '../app/modules/careers-details/occ-details/occ-details-module';
import { OccDetailsRoutingModule } from '../app/modules/careers-details/occ-details/occ-details-routing';
import { StorageService } from './shared/outer-services/storage.service';
import { ServiceModule } from './shared/service-module/shared-service-module';
import { StoreSharedService } from './shared/outer-services/store-shared.service';

import { AuthManager } from './shared/auth/authmanager';
import { ActivatingClass } from './shared/auth/activateGuard';
import { EventDispatchService } from './shared/outer-services/event-dispatch.service';
import { NgbdModalContent, NgbdModalLoaderContent, AssessmentModalPopups, SnackBar, NgbdModalRC, SnackBarRed } from './shared/modal/shared-modal-component';

import { SharedModule } from './shared/shared-module';

import { AssesmentsSharedModule } from './modules/assessments/assessments-shared-module';

import { PLPSectionsModule } from './modules/PLP/plp-shared-module';
import { AppSharedModule } from './app.sharedmodule';
import { StoreService } from './state-management/services/store-service';

import { UnSavedChangesCanActive } from './shared/auth/deactivateGuard';
import { MainEffects } from './state-management/effects/main-effects';
// import { CustomFormModule } from './modules/custom-form/custom-form.module';
import {
  FrameworkConfigReducer, AsmntIntroText, OccIndexReducerText,
  GetAllOccList, AsmntCommonText,
  OccPageText, OccSettingsText, OccText,
  AsmntQuestionsResponses, AsmntQuestionsText,
  SectionsStatusValues, AsmntLocationText,
  ProgIndexReducerText, ProgText, AidText,
  AidIndexReducerTextReducer, SchoolIndexReducerTextReducer,
  SchoolText, MilitaryIndexReducerTextReducer,
  PlanConfigReducer, MilitaryText
} from './state-management/reducers/main-reducer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PagerService } from './shared/outer-services/pager.service';
import { AppRoutingModule } from './app-routing.module';
import { FrameworkComponent } from './modules/framework/framework.component';

import { OutsideStaticFooterComponent } from './shared/outside-footer';

import { FormioModule } from 'angular-formio';
import { planReducer, planSummaryReducer } from './modules/framework/layouts/plans/state-management/reducer/plan.reducer';
import { environment } from '../environments/environment';
import { CommonUtilitiesModule } from './Utilities/utilities.module';
import {
  DashboardReducer,
  DashboardAssessmentResultReducer,
  DashboardAssessmentPageTextReducer,
  DashboardPersonalInfoReducer,
  AccountSettingPageTextReducer,
  AccountSettingImageReducer, PortFolioSettingsReducer, DashboardBestCareersReducer
} from './state-management/reducers/dashboard.reducer';
import { PlansMainEffects } from './modules/framework/layouts/plans/state-management/effects/plan-effects';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { accountTypeReducer } from './state-management/reducers/account-Types.reducer';
import { ltiLocationReducer } from './state-management/reducers/lti-location.reducer';
import { RedirectComponent } from './modules/framework/redirect.component';
import { CareersJrDetailModule } from './modules/careers-details/careersJr/careersJr-details-module';
import { Title } from '@angular/platform-browser';
import { PracticeTestModule } from './modules/practice-test/practice-test.module';
import { JobInterviewsModule } from './modules/job-interviews/job-interviews.module';

@NgModule({
  declarations: [
    AppComponent,
    NgbdModalContent,
    NgbdModalRC,
    AssessmentModalPopups,
    SnackBar,
    SnackBarRed,
    FrameworkComponent,
    OutsideStaticFooterComponent,
    RedirectComponent,
    // NgbdModalLoaderContent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    // HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    CommonModule,
    DeviceDetectorModule,
    // HttpClientModule,
    ReactiveFormsModule,
    FormioModule,
    NgxSpinnerModule,
    StoreModule.forRoot({
      plan: planReducer,
      dashBoardData: DashboardReducer,
      dashBoardAsmntData: DashboardAssessmentResultReducer,
      dashBoardAsmntPageTextData: DashboardAssessmentPageTextReducer,
      dashBoardPersonalInfoData: DashboardPersonalInfoReducer,
      accountSettingPageTextData: AccountSettingPageTextReducer,
      accountSettingImageData: AccountSettingImageReducer,
      config: FrameworkConfigReducer,
      AsmntIntroText: AsmntIntroText,
      GetAllOccList: GetAllOccList,
      OccIndexReducerText: OccIndexReducerText,
      OccSettingsText: OccSettingsText,
      AsmntCommonText: AsmntCommonText,
      occPage: OccPageText,
      OccText: OccText,
      commonResponseText: AsmntQuestionsResponses,
      commonIntroText: AsmntQuestionsText,
      ProgIndexReducerText: ProgIndexReducerText,
      ProgText: ProgText,
      planConfig: PlanConfigReducer,
      AidIndexReducerText: AidIndexReducerTextReducer,
      AidText: AidText,
      SectionsStatusValues,
      AsmntLocationText,
      SchoolIndexReducerText: SchoolIndexReducerTextReducer,
      SchoolText: SchoolText,
      MilitaryIndexReducerText: MilitaryIndexReducerTextReducer,
      MilitaryText: MilitaryText,
      planSummary: planSummaryReducer,
      portFolioSettingsData: PortFolioSettingsReducer,
      accountType: accountTypeReducer,
      componentId: ltiLocationReducer,
      dashBoardBestCareersData: DashboardBestCareersReducer,
    }),
    StoreDevtoolsModule.instrument({
      name: 'CIS360 DevTools',
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([MainEffects, PlansMainEffects]),
    FormsModule,
    NgbTooltipModule,
    SharedModule,
    CommonModule,
    RouterModule,
    LoginRoutingModule,
    OccDetailsRoutingModule,
    LoginModule,
    OccDetailModule,
    CareersJrDetailModule,
    ServiceModule.forRoot(),
    NgbModule.forRoot(),
    AssesmentsSharedModule,
    PLPSectionsModule,
    AppSharedModule.forRoot(),
    CommonUtilitiesModule.forRoot(),
    AppRoutingModule,
    PracticeTestModule,
    JobInterviewsModule,
  ],
  providers: [
    EventDispatchService,
    StorageService,
    NgbActiveModal,
    PagerService,
    AuthManager,
    ActivatingClass,
    StoreSharedService,
    NgbdModalLoaderContent,
    UnSavedChangesCanActive,
    StoreService,
    Title,
    {
      provide: 'Auth_Key',
      useValue: environment.Auth_Key,
    },
    {
      provide: 'cp_proxy',
      useValue: environment.CP_PROXY,
    },
    {
      provide: 'API_URL',
      useValue: environment.resumeAPIUrl,
    },
    {
      provide: 'DEV_ROUTE_VALUES',
      useValue: environment.DevRouteValues,
    },
    {
      provide: 'ASSETS_URL',
      useValue: environment.AssetsUrl,
    },
    {
      provide: 'LANDING_IMAGE_URL',
      useValue: environment.LandingImages,
    },
    {
      provide: 'CAREER_IMAGE_URL',
      useValue: environment.CareersImages,
    },
    {
      provide: 'SPANISH_ICON',
      useValue: environment.SpanishIcon,
    },
    {
      provide: 'ASSESSMENT_ICONS',
      useValue: environment.AssessmentIcons,
    },
    {
      provide: 'RESTRICTED_ACCOUNT_TYPE',
      useValue: 'Restricted',
    },
    {
      provide: 'FONT_ICONS_URL',
      useValue: environment.fontIcons,
    },
    {
      provide: 'DS_EXTERNAL_URL',
      useValue: environment.dependableStrengthsLink,
    },
    {
      provide: 'IC_VIDEO_URL',
      useValue: environment.IC_VideoUrl,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [NgbdModalContent, AssessmentModalPopups, NgbdModalRC],
})
export class AppModule {
  constructor() {}
}
