<div class="row p-b gutter-10 flex-row-cards">
  <div class="col-lg-6 col-md-6 col-12" *ngIf='(settingsTextTab?.sections).indexOf("Overview") != -1'>
    <div class="career-card cardcolor-5 cards-equal-height">
      <div class="career-card-header">
        <div class="card-title p-0">
          <i class="icon-overview panal-icon-align" aria-hidden="true"></i> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Overview}}
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <div *ngFor="let item of (aboutthiscareer|async)?.Overview">
          <p *ngIf="item.type == 'p'" [innerHtml]="item.text | MarkdownToLink"></p>
          <ul *ngIf="item.type == 'ul'" class="list pr-4">
            <li *ngFor="let val of item.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-12" *ngIf='(settingsTextTab?.sections).indexOf("ThingsToKnow") != -1'>
    <div class="career-card  cardcolor-12 cards-equal-height">
      <div class="career-card-header">
        <div class="card-title p-0">
          <i class="icon-job-list panal-icon-align" aria-hidden="true"></i> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.ThingsToKnow}}
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <div *ngFor="let item of (aboutthiscareer|async)?.ThingsToKnow">
          <p *ngIf="item.type == 'p'" [innerHtml]="item.text | MarkdownToLink"></p>
          <ul class="list pr-4" *ngIf="item.type == 'ul'">
            <li *ngFor="let val of item.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- OCC-EMERG Print Start -->
<div [hidden] = "!printview">
  <div class="print-result-page" #emergingAbout>
    <div>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
          <div class="icon-occresize-sprite"></div>
        <span class="title-wraps pl-1 pt-2">{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" [src]="logo" >
      </div>
    </div>
    <div class="main-body white-space">
      <div>
        <div class="careers-title">{{tabTitle}}</div>
        <div class="career-card cardcolor-5" *ngIf='(settingsTextTab?.sections).indexOf("Overview") != -1'>
          <div class="career-card-header font-weight-bold">
            <div class="icon-overview-sprite mr-2"></div> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Overview}}
          </div>
        </div>
        <div *ngFor="let item of (aboutthiscareer|async)?.Overview">
          <p class="pl-4" *ngIf="item.type == 'p'">{{item.text}}</p>
          <ul *ngIf="item.type == 'ul'" class="list-unstyled list ml-4">
            <li *ngFor="let val of item.text">
              <div class="headding-arrow mb-1">{{val}}</div>
            </li>
          </ul>
        </div>
        <div class="html2pdf__page-break"></div>
        <div class="career-card cardcolor-12 mt-4" *ngIf='(settingsTextTab?.sections).indexOf("ThingsToKnow") != -1'>
          <div class="career-card-header font-weight-bold">
            <div class="icon-job_list-sprite mr-2"></div><span>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.ThingsToKnow}}</span>
          </div>
        </div>
        <div class="pl-3" *ngFor="let item of (aboutthiscareer|async)?.ThingsToKnow">
          <p *ngIf="item.type == 'p'">{{item.text}}</p>
          <ul class="list-unstyled list" *ngIf="item.type == 'ul'">
            <li *ngFor="let val of item.text">
              <div class="headding-arrow mb-1"> {{val}} </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
  </div>
</div><!-- OCC-EMERG Print End -->