<!--for deployment -->

  <p class="header_5 header-text-color">
    <b>{{occCareerStore.EducationQuestion}}</b>
  </p>

<div class="row p-b gutter-10 flex-row-cards">
  <div class="col-lg-6 col-md-6 col-12" *ngIf='(settingsTextTab?.sections).indexOf("Courses") != -1 && (occCareerStore)?.Courses?.length !== 0'>
    <div class="career-card cardcolor-14 cards-equal-height">
      <div class="career-card-header">
        <div class="card-title p-0">
          <i class="icon-helpful-high-school-courses panal-icon-align" aria-hidden="true"></i> {{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.Courses}}
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <div *ngFor="let item of (occCareerStore)?.Courses">
          <p class="px-0" *ngIf="(item.type == 'p')" [innerHTML]='item.text | MarkdownToLink'></p>
          <ul *ngIf="item.type == 'ul'" class="list pr-4">
            <li *ngFor="let val of item.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg col-md col-12" *ngIf='(settingsTextTab?.sections).indexOf("Activities") != -1 && (occCareerStore)?.Activities?.length !== 0'>
    <div class="career-card  cardcolor-28 cards-equal-height">
      <div class="career-card-header">
        <div class="card-title p-0">
          <!-- <i class="icon-jr_activities panal-icon-align" aria-hidden="true"></i> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Activities}} -->
          <img class="panal-icon-align" src="{{fontIcons}}jr_activities.svg" alt="jr_activities" />{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Activities}}
        
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <div *ngFor="let item of (occCareerStore)?.Activities">
          <p *ngIf="item.type == 'p'" [innerHtml]="item.text | MarkdownToLink"></p>
          <ul class="list pr-4" *ngIf="item.type == 'ul'">
            <li *ngFor="let val of item.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


 <!-- Print Start Eduction Training -->
<div class="hidden-page">
 <div class="print-result-page" #EducationTrainingPrintPage> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
          <div class="{{clusterIcon}}_color-sprite"></div>
        <span class="title-wraps pl-1 pt-2">{{clusterTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" [src]="logo">
      </div>
    </div>
    <div class="main-body">
      <div class="careers-title">{{tabTitle}}</div>
      <p class="header_5 header-text-color"><b>{{occCareerStore.EducationQuestion}}</b></p>
      <div *ngIf='(settingsTextTab?.sections).indexOf("Courses") != -1 && (occCareerStore)?.Courses?.length !== 0'>
        <div class="career-card cardcolor-14">
          <div class="career-card-header font-weight-bold">
            <div class="icon-helpful_high_school_courses-sprite panal-icon-align mr-2"></div><span>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Courses}}</span>
          </div>
        </div>
        <div class="pl-3" *ngFor="let item of (occCareerStore)?.Courses">
          <p class="px-0 pos-progdesc" *ngIf="(item.type == 'p')" [innerHTML]='item.text | PrintWeblinkPipe'></p>
          <ul *ngIf="item.type == 'ul'" class="list-unstyled">
            <li *ngFor="let val of item.text">
              <div class="headding-arrow pl-2 mb-1">{{val}}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="html2pdf__page-break"></div>
      <div class="mt-2" *ngIf='(settingsTextTab?.sections).indexOf("Activities") != -1 && (occCareerStore)?.Activities?.length !== 0'>
        <div class="career-card cardcolor-28 cards-equal-height">
          <div class="career-card-header font-weight-bold">
          <div class="icon-activities-sprite mr-2"></div><span>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Activities}}</span>
        </div>
      </div>
      <div class="pl-3" *ngFor="let item of (occCareerStore)?.Activities">
        <p *ngIf="item.type == 'p'">{{item.text}}</p>
        <ul class="list-unstyled" *ngIf="item.type == 'ul'">
          <li *ngFor="let val of item.text">
            <div class="headding-arrow pl-2 mb-1">{{val}}</div>
          </li>
        </ul>
      </div>
    </div>
    </div>
    <div class="text-center">
      <p class="pt-2 border-top">{{frameWorkObj?.Result?.headerFooter?.copyright}}</p>
    </div>
 </div>
</div>
