

/**Angular Libraries **/
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CareersJrCommonMethodsService } from '../careersJr-services/careersJr-common-methods.service';
import { CareersJrDispatchService } from '../careersJr-services/careersJr-dispatch.service';
import { StorageService } from '../../../../shared/outer-services/storage.service';

@Component({
  selector: 'ngbd-modal-snackbar',
  template: `<div id="snackbar">
    <p aria-label="Successfully added to favorites">{{snackbarname}}</p>
    </div>    `
})
export class CareersJrSnackBar implements OnInit {
  snackbarname;
  constructor() {
  }

  ngOnInit() {

  }
  myFunction(funcName) {
    this.snackbarname = funcName;
    const x = document.getElementById('snackbar');

    document.getElementById('snackbar').setAttribute('aria-live', 'assertive');
    document.getElementById('snackbar').setAttribute('role', 'alert');

    document.getElementById('snackbar').focus();
    x.className = 'show';
    x.innerHTML = ' <i class="fa fa-check" aria-hidden="true"></i>&nbsp;' + funcName;
    setTimeout(function () { x.className = x.className.replace('show', ''); }, 3000);
  }
}


@Component({
  selector: 'ngbd-modal-content',
  template: `<div class="modal-header" data-keyboard="false">
      <h4 class="modal-title"> {{headerText}}{{((modalCommonText)?.commonText[headsection])}}{{trans_error}}</h4>
      </div>
      <div class="modal-body"  >
     {{session_exp_txt }} {{err_occ }}{{((modalCommonText)?.commonText[headingText])}}</div>
      <div class="modal-footer"  [hidden]="closevalue">
      <button id="save" type="button" [attr.aria-label]='(modalCommonText)?.commonText[yesbtn]' class="btn-common btn-success" (click)="deleteTestScore()" >{{(modalCommonText)?.commonText[yesbtn]}}</button>
      <button id="cancel" type="button" [attr.aria-label]='(modalCommonText)?.commonText[nobtn]' class="btn-common btn-primary" (click)="activeModal.close('Close click')">{{(modalCommonText)?.commonText[nobtn]}}</button>
      </div>
     <div class="modal-footer" [hidden]="!closevalue">

    <button id="reload" type="button" aria-label="Reload" title="Reload" class="btn-common btn-warning" (click)="reloadmodal()">Reload</button>
     <button id="logout" type="button" aria-label="Sign out" title="Sign out" class="btn-common btn-warning" (click)="logoutmodal()">Sign out</button>
     </div>
    `
})
export class CareersJrModalPopup implements OnInit {
  headsection;
  yesbtn;
  nobtn;
  @Input() deletevalue;
  @Input() arrayValue;

  @Input() trans_error;
  @Input() err_occ;

  @Input() session_exp_txt;
  @Input() sessionName;

  @Input() Closebtnvalue;

  unsavedTxt;
  @Input() commonButtons;
  @Input() valueofunchange;

  closevalue = false;
  testScoreArr = [];
  headerText;
  modalCommonText;
  headingText;
  constructor(
    public browserDom: Renderer2,
    public activeModal: NgbActiveModal,
    private careersJrCommonService: CareersJrCommonMethodsService,
    private careersJrDispatch: CareersJrDispatchService,
    private storageService: StorageService) {
    this.modalCommonText = JSON.parse(this.storageService.sessionStorageGet('AsmntCommonTextStorage'));
  }

  ngOnInit() {
    try {
      setTimeout(function () {
        if (this.commonButtons != undefined) {
          this.headerText = this.commonButtons.headerText;
          this.headingText = this.commonButtons.unsavedTxt;
          this.headsection = this.commonButtons.headsection;
          this.nobtn = this.commonButtons.nobtn;
          this.yesbtn = this.commonButtons.yesbtn;
        }
        this.browserDom.setStyle(document.querySelector('.modal-dialog'), 'width', '90%');
      }.bind(this), 0);
    } catch (e) {
      console.log('error--->' + e.message);
    }
    if (this.Closebtnvalue == 1) {
      this.closevalue = true;
    }
  }

  deleteTestScore() {
    if (this.deletevalue >= 0) {
      for (let i = 0; i < this.arrayValue.length; i++) {
        if (this.deletevalue == i) {
          this.arrayValue.splice(i, 1);
          for (let j = 0; j < 2; j++) {
            this.browserDom.setStyle(document.querySelectorAll('.SaveBtn' + i)[j], 'display', 'none');
            this.browserDom.setStyle(document.querySelectorAll('.edtBtn' + i)[j], 'display', 'block');
            this.browserDom.setStyle(document.querySelectorAll('.cancelBtn' + i)[j], 'display', 'none');
          }
        }
      }
    }

    if (this.valueofunchange == 'unsavedcheck') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('unsaved', true, true);
      this.careersJrDispatch.dispatch(evnt);
    }
    this.activeModal.close(true);
  }

  closemodal() {
    this.activeModal.close(true);
  }
  reloadmodal() {
    window.location.reload();
  }
  logoutmodal() {
     this.careersJrCommonService.exitApp('loggedout');
  }
}
