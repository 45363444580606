<div class="career-ataglance career-jr">
  <div class="row gutter-10 ">
    <!-- Wages card  -->
    <ng-template #wages>{{(occCareerStore)?.SalaryRating?.flyover}}
    </ng-template>
    <div class="col-lg-4 col-md-12 pointer mb-2 mb-lg-0" title="Wages"
      *ngIf='(settingsTextTab?.sections).indexOf("SalaryRating") != -1'
      (click)='$event.preventDefault();callPage("wage")'>
      <a href="#" class="card graph-card">
        <div class="card-block card-block-height-sm">
          <div class="row gutter-10 " #ts6="ngbTooltip" [ngbTooltip]="wages">
            <div class="col-lg-5 col-md-5 text-center-graph text-md-left">
              <div>
                <b>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.SalaryRating}}</b>
              </div>
              {{(occCareerStore).SalaryRating?.rating}}
            </div>
            <div class="col-lg-7 col-md-7 ">
              <ul class="chart-skills mx-auto">
                <li>
                  <span [ngClass]="((occCareerStore).SalaryRating?.ratingID==0)? 'activeChartClass0':''">
                  </span>
                </li>
                <li>
                  <span [ngClass]="((occCareerStore).SalaryRating?.ratingID==1)? 'chart-ative-p2':''">
                  </span>
                </li>
                <li>
                  <span [ngClass]="((occCareerStore).SalaryRating?.ratingID==2)? 'chart-ative-p3':''">
                  </span>
                </li>
              </ul>
              <div class="salry-value at-glance-salry-value"> {{(occCareerStore).SalaryRating?.amount}} </div>
            </div>
          </div>
        </div>
      </a>
    </div>
    <!-- Education & Training card  -->
    <ng-template #education>{{(occCareerStore)?.EducationLevel?.flyover}}
    </ng-template>
    <div class="col-lg-4 col-md-6 pointer mb-2 mb-lg-0" title="Education & Training"
      *ngIf='(settingsTextTab?.sections).indexOf("EducationLevel") != -1'
      (click)='$event.preventDefault();callPage("edu")' #ts6="ngbTooltip" [ngbTooltip]="education">
      <a href="#" class="card graph-card">
        <!-- Card that displays about EducationLevel-->
        <div class="card-block educationjr-card text-center-graph text-md-left d-block">
          <div class="mb-1">
            <b> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.EducationLevel}}</b>
          </div>
          <div class="stacked-bar-graph m-0 d-inline-flex">
            <span *ngFor="let item of [0,1,2,3,4]"
              [ngStyle]="(item == (occCareerStore).EducationLevel?.ratingID)? {'background-color':'#2c974b'}:{}">&nbsp;
              <p *ngIf="(item == (occCareerStore).EducationLevel?.ratingID)" class="edu-name-style">
                {{(occCareerStore).EducationLevel.rating}}</p>
            </span>
          </div>
        </div>
      </a>
    </div>
    <!-- Employment Opportunities card  -->
    <ng-template #opportunities>{{(occCareerStore)?.OpportunityRating?.flyover}}
    </ng-template>
    <div class="col-lg-4 col-md-6 pointer" *ngIf='(settingsTextTab?.sections).indexOf("OpportunityRating")!= -1'
      title="Employment Opportunities" (click)='$event.preventDefault();callPage("outlook")'>
      <a href="#" class="card graph-card">
        <!--  Card that displays about OpportunityRating -->
        <div class="card-block">
          <div class="row gutter-10 text-center-graph text-md-left" #ts6="ngbTooltip" [ngbTooltip]="opportunities">
            <div class="col-lg-8 col-md-8">
              <div>
                <b>{{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.OpportunityRating}}</b>
              </div>
              {{(occCareerStore)?.OpportunityRating?.rating}}
            </div>
            <div class="col-lg-4 col-md-4 text-center-graph text-md-left">
              <div class="eo-bar-chart m-auto p-0 d-table w-100">
                <li *ngFor="let item of [0,1,2];let inx=index">
                  <span
                    [ngStyle]="(item == (occCareerStore)?.OpportunityRating?.ratingID)? {'background-color':'#2c974b'}:{}"></span>
                </li>
              </div>
            </div>
            <span class="ataglance-bargraph pt-2 pt-md-0">
              {{(occCareerStore)?.OpportunityRating?.amount}}
            </span>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="row gutter-10">
    <div class="col-lg-12 col-md-12 pt-3" *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription")!= -1'>
      <p class="header-text-color header_5">
        <b>{{(occCareerStore).ShortDescription}}</b>
      </p>
    </div>
    <!-- Card that describe about TopJobTasks-->
    <div class="col-lg-4 col-md-4" *ngIf='(settingsTextTab?.sections).indexOf("TopOverview")!= -1'>
      <div tabindex="0">
        <div class="career-card cardcolor-50">
          <div class="education-card-height px-2 py-3 py-lg-4 px-xl-3 scroll-class d-flex flex-column">
            <div *ngFor="let item of (occCareerStore).TopOverview;let inx=index;">
              <div *ngIf="(item.type == 'ul')">
                <div *ngFor="let val of item.text">
                  <p class="text-center font-weight-bold mb-1" [innerHtml]="val | MarkdownToLink"></p>
                </div>
              </div>
              <div *ngIf="(item.type == 'p')">
                <div class="card-block py-4 py-lg-5 text-center">
                  <!-- <i class="icon-question_mark panal-icon-align" aria-hidden="true"></i> -->
                  <img src="{{fontIcons}}question_mark.svg" alt="questionmark" />
                </div>
                <p class="mb-2 text-center" [innerHtml]="item.text | MarkdownToLink"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4">
      <!-- Card that describe about clus·ter-->
      <div class="school-card-headding skyblue"
        *ngIf='(settingsTextTab?.sections).indexOf("ClusterDefinition")!= -1 && showClusterBox' tabindex="0">
        <div class="career-card cluster-card-border">
          <div class="card-block card-block2-p-plp3 align-middle d-table-cell cluster-noun-text text-left"
            [ngStyle]="{'cursor':'default'}">
            <div class="d-flex flex-column h-100 justify-content-center"
              [ngClass]="(occCareerStore).ClusterDefinition?'career-card-block':''">
              <div *ngFor="let value of (occCareerStore).ClusterDefinition">
                <div class="text-left px-3 cluster-text header_3" *ngIf="(value.type == 'h')">
                  <b [innerHtml]="value.text | MarkdownToLink"></b>
                </div>
                <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                  <i class="cluster-text" [innerHtml]="value.text | MarkdownToLink"></i>
                </p>
                <ol *ngIf="(value.type == 'ol')" class="text-left cluster-text">
                  <li *ngFor="let val of value.text">
                    <div class="font-weight-bold" [innerHtml]="val | MarkdownToLink"></div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card that describe about parant cluster-->
      <div *ngIf='(settingsTextTab?.sections).indexOf("ParentCluster")!= -1' tabindex="0">
        <div class="career-card pointer"
          [ngStyle]="{border: '1px solid' + (occCareerStore)?.ParentCluster?.parentClusterColor}"
          (click)="$event.preventDefault();
                workplaceRedirect(occCareerStore.ParentCluster?.linkID,occCareerStore.ParentCluster?.linkTitle,occCareerStore.ParentCluster?.parentClusterIcon,(occCareerStore).ParentCluster?.parentClusterColor)">
          <div class="card-block card-block2-p-plp3 text-center align-middle d-table-cell pointer">
            <div class="d-flex flex-column h-100 justify-content-center">
              <div>
                <div class="header_5 font-weight-bold"
                  [ngStyle]="{color: (occCareerStore)?.ParentCluster?.parentClusterColor}">
                  {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.ParentCluster}}</div>
                <div class="{{(occCareerStore).ParentCluster?.parentClusterIcon}}_color-sprite mx-auto my-2"></div>
              </div>
              <div class="header_6 font-weight-bold"
                [ngStyle]="{color: (occCareerStore)?.ParentCluster?.parentClusterColor}">{{
                (occCareerStore).ParentCluster?.linkTitle}}</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='!showClusterBox' tabindex="0">
        <div class="career-card cardcolor-25 w-100">
          <div
            class="card-block card-block2-p-plp3 align-middle p-2 d-flex justify-content-center flex-column scroll-class cluster-noun-text text-left"
            [ngStyle]="{'cursor':'default'}">
            <div class="career-cluster-panel pb-3 pt-xl-1 pt-lg-0 pt-md-3" *ngIf="occCareerStore.HotJob.rating"
              [attr.aria-hidden]="occCareerStore.HotJob.rating? false: true">
              <div class="career-cluster-icon"><img alt="career-hotjob" width="35"
                  src="{{CareersImages}}career-hotjob.png" /></div>
              <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                [innerHTML]="occCareerStore.HotJob.text |BoldPipe | MarkdownToLink"></div>
            </div>
            <div class="career-cluster-panel" *ngIf="occCareerStore.STEM.rating"
              [attr.aria-hidden]="occCareerStore.STEM.rating? false: true">
              <div class="career-cluster-icon"><img alt="career-stem" width="35"
                  src="{{CareersImages}}career-stem.png" /></div>
              <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                [innerHTML]="occCareerStore.STEM.text |BoldPipe | MarkdownToLink"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Card that describe about TopPreparation-->
    <div class="col-md-4" *ngIf='(settingsTextTab?.sections).indexOf("TopPreparation")!= -1'>
      <div tabindex="0">
        <div class="career-card cardcolor-28">
          <div class="education-card-height d-flex align-items-center justify-content-center p-3">
            <div>
              <h5 class="text-center font-weight-bold mb-0">
                {{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.TopPreparation}}</h5>
              <div>
                <div *ngFor="let item of (occCareerStore).TopPreparation;let inx=index;">
                  <div class="mt-4" *ngIf="(item.type == 'p')">
                    <h6 class="text-center" [innerHtml]="item.text | MarkdownToLink"></h6>
                  </div>
                  <div class="mt-5" *ngIf="(item.type == 'ul')">
                    <ul class="list" *ngFor="let val of item.text">
                      <li class="mb-0" *ngIf="(val!=='[More]')" [innerHtml]="val | MarkdownToLink"></li>
                    </ul>
                  </div>
                </div>
                <a *ngIf="moreDataDailog" href="#" data-toggle="modal" data-target="#MoreData"
                  class="text-more">More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- at-a-glance Print Start -->
<div class="hidden-page">
  <div class="print-result-page" #atAGlancePrintPage>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-careersJr-sprite mr-1"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" [src]="logo">
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <div class="pt-3 header-text-color" *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription")!= -1'>
        <p>
          <b>{{(occCareerStore).ShortDescription}}</b>
        </p>
      </div>
      <div class="row gutter-10 career-ataglance mb-4">
        <!-- Wages card  -->
        <div class="col-12 mb-2">
          <div class="p-0 card card-wages-height">
            <div class="p-0 card-block card-block-height-sm">
              <div class="d-flex justify-content-between">
                <div class="table-text col-5 text-center-graph text-left d-flex align-items-center">
                  <div>
                    <span
                      class="d-block"><b>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.SalaryRating}}</b></span>
                    {{(occCareerStore).SalaryRating?.rating}}
                  </div>
                </div>
                <div class="mt-3 col-7">
                  <div *ngIf='((occCareerStore).SalaryRating?.ratingID==0)' class="icon-jrBelowAverage-sprite ml-auto">
                  </div>
                  <div *ngIf='((occCareerStore).SalaryRating?.ratingID==1)' class="icon-jrWagesAverage-sprite ml-auto">
                  </div>
                  <div *ngIf='((occCareerStore).SalaryRating?.ratingID==2)' class="icon-jrAboveAverage-sprite ml-auto">
                  </div>
                  <div class="pr-4 wages-salary-rating table-text">{{(occCareerStore).SalaryRating?.amount}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Education & Training card  -->
        <div class="col-6">
          <div class="card graph-card">
            <!-- Card that displays about EducationLevel-->
            <div class="card-block d-block">
              <div class="mb-1 mt-2 text-left table-text">
                <b>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.EducationLevel}}</b>
              </div>
              <div class="stacked-bar-graph d-inline-flex">
                <span *ngFor="let item of [0,1,2,3,4]"
                  [ngStyle]="(item == (occCareerStore).EducationLevel?.ratingID)? {'background-color':'#2c974b'}:{}">&nbsp;
                  <p *ngIf="(item == (occCareerStore).EducationLevel?.ratingID)" class="edu-name-style ">
                    {{(occCareerStore).EducationLevel.rating}}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Employment Opportunities card  -->
        <div class="col-6">
          <div class="card graph-card">
            <!--  Card that displays about OpportunityRating -->
            <div class="card-block table-text">
              <div class="row gutter-10 text-center-graph text-left align-items-center">
                <div class="mb-4 col-8 text-left">
                  <div>
                    <b>{{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.OpportunityRating}}</b>
                  </div>
                  {{(occCareerStore)?.OpportunityRating?.rating}}
                </div>
                <div class="col-4 text-center-graph text-left p-0">
                  <div class="eo-bar-chart m-auto p-0 d-table w-100">
                    <li *ngFor="let item of [0,1,2];let inx=index">
                      <span
                        [ngStyle]="(item == (occCareerStore)?.OpportunityRating?.ratingID)? {'background-color':'#2c974b'}:{}"></span>
                    </li>
                  </div>
                </div>
                <p class="w-100 text-right">
                  {{(occCareerStore)?.OpportunityRating?.amount}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap mt-3">
        <div class="flex-fill w-50 pr-1" *ngIf='showClusterBox'>
          <div class="career-card cluster-card-border d-table w-100">
            <div class="card-block align-middle d-table-cell cluster-noun-text text-left"
              [ngStyle]="{'cursor':'default'}">
              <div *ngFor="let value of (occCareerStore).ClusterDefinition">
                <div class="text-left px-3 text-white header_3 cluster-text" *ngIf="(value.type == 'h')">
                  <b>{{value.text}}</b>
                </div>
                <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                  <i class="cluster-text">{{value.text}}</i>
                </p>
                <ol *ngIf="(value.type == 'ol')" class="text-left cluster-text">
                  <li *ngFor="let val of value.text">
                    <div class="font-weight-bold">{{val}}</div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1">
          <!-- Card that describe about parant cluster-->
          <div class="career-card d-table w-100"
            [ngStyle]="{border: '1px solid' + (occCareerStore)?.ParentCluster?.parentClusterColor}">
            <div class="card-block align-middle d-table-cell cluster-noun-text text-center">
              <div class="mt-1">
                <div class="header_5 font-weight-bold"
                  [ngStyle]="{color: (occCareerStore)?.ParentCluster?.parentClusterColor}">
                  {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.ParentCluster}}
                </div>
                <div class="{{(occCareerStore).ParentCluster?.parentClusterIcon}}_color-sprite mx-auto my-2"></div>
              </div>
              <div class="header_6 font-weight-bold"
                [ngStyle]="{color: (occCareerStore)?.ParentCluster?.parentClusterColor}">
                {{(occCareerStore).ParentCluster?.linkTitle}}</div>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1" *ngIf='!showClusterBox'>
          <div class="career-card cardcolor-50 w-100">
            <div
              class="card-block card-block2-p-plp3 align-middle p-2 d-flex justify-content-center flex-column scroll-class cluster-noun-text text-left"
              [ngStyle]="{'cursor':'default'}">
              <div class="career-cluster-panel pb-3 pt-xl-1 pt-lg-0 pt-md-3" *ngIf="occCareerStore.HotJob.rating">
                <div class="career-cluster-icon"><img alt="career-hotjob" width="35"
                    src="{{CareersImages}}career-hotjob.png" /></div>
                <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                  [innerHTML]="occCareerStore.HotJob.text |BoldPipe"></div>
              </div>
              <div class="career-cluster-panel" *ngIf="occCareerStore.STEM.rating">
                <div class="career-cluster-icon"><img alt="career-stem" width="35"
                    src="{{CareersImages}}career-stem.png" />
                </div>
                <div class="career-cluster-text pr-xl-3 pr-3 pr-lg-0 pr-md-0"
                  [innerHTML]="occCareerStore.STEM.text |BoldPipe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100">
        <!-- Card that describe about TopOverview-->
        <div class="career-card cardcolor-50">
          <div class="card-block career-card-body px-0">
            <div class="mt-5 text-white" *ngFor="let item of (occCareerStore).TopOverview;let inx=index;">
              <div *ngIf="(item.type == 'ul')" class="list pr-4">
                <div *ngFor="let val of item.text">
                  <p class="text-center font-weight-bold mb-1">{{val}}</p>
                </div>
              </div>
              <div class="mt-5" *ngIf="(item.type == 'p')">
                <div class="card-block card-block2-p-plp3 align-middle h-50 d-flex justify-content-center">
                  <div class="icon-questin_mark-sprite"></div>
                </div>
                <p class="mb-2 mx-1 pb-5 text-center">{{item.text}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 pl-1">
        <!-- Card that describe about TopPreparation-->
        <div class="career-card cardcolor-28 d-table w-100 mt-5">
          <div class="mt-5">
            <h5 class="text-center text-white font-weight-bold">
              {{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.TopPreparation}}</h5>
          </div>
          <div class="card-block mt-0">
            <div class="mt-1 text-white" *ngFor="let item of (occCareerStore).Preparation;let inx=index;">
              <div class="mt-3" *ngIf="(item.type == 'p')">
                <h6 class="mb-5 text-center table-text">{{item.text}}</h6>
              </div>
              <div *ngIf="(item.type == 'ul')">
                <ul class="list" *ngFor="let val of item.text">
                  <li class="mb-0">{{val}}</li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!-- At-a-glance Print End -->

<!-- modal pop up -->
<div class="modal fade ability-model" id="MoreData" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="dialog">
    <div class="modal-content modal-color w-75 m-auto">
      <div class="cardcolor-28 modal-heading careerEmerModal">
        <p class="modal-title modal-card-title font-weight-bold pl-3">
          <span
            id="exampleModalLabel">{{(settingsText)?.CareersJr_Settings_Tab_Text?.allSections?.TopPreparation}}</span>
        </p>
        <div class="close-btn">
          <button class="modal-close modalPopup-close" data-dismiss="modal">&times;</button>
        </div>
      </div>
      <div class="modal-body d-flex justify-content-center">
        <div class="row">
          <span class="career-card">
            <div class="text-white align-items-center d-flex">
              <div class="careerjr-popup scroll-class">
                <div class="emerging-model" *ngFor="let item of (occCareerStore).Preparation;let inx=index;">
                  <div *ngIf="(item.type == 'p')">
                    <h6 class="text-center">{{item.text}}:</h6>
                  </div>
                  <div class="px-4" *ngIf="(item.type == 'ul')">
                    <ul class="mb-0" *ngFor="let val of item.text;let inx=index">
                      <li>{{val}}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>