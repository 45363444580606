<div class="modal-header">
  <h4 class="modal-title"> {{headerText}}{{((modalCommonText)?.commonText[headsection])}}{{trans_error}}</h4>
  </div>
<div class="modal-body">
<form>
<div class="form-group">
 {{session_exp_txt }} {{err_occ }}{{((modalCommonText)?.commonText[headingText])}}
</div>
</form>
</div>
<div class="modal-footer" *ngIf="!closevalue">
<button id="save" type="button" [attr.aria-label]='(modalCommonText)?.commonText[yesbtn]' class="btn-common btn-success" (click)="callExtendTime()" >
{{(modalCommonText)?.commonText[yesbtn]}}</button>
<button id="cancel" type="button" [attr.aria-label]='(modalCommonText)?.commonText[nobtn]' class="btn-common btn-primary" (click)="closemodal()">
{{(modalCommonText)?.commonText[nobtn]}} </button>
</div>
 <div class="modal-footer" *ngIf="closevalue">
 <button id="reload" type="button" aria-label="Reload" title="Reload" class="btn-common btn-warning" (click)="reloadmodal()">Reload</button>
 <button id="logout" type="button" aria-label="Sign out" title="Sign out" class="btn-common btn-warning" (click)="logoutmodal()">Sign out</button>
</div>
