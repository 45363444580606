<div class="container" (click)="closeErrorDiv($event)">
    <div class="row mt-3" style="border-bottom: 1px solid gray;">
        <div class="col-sm col-md-12 text-left">
            <strong style="font-size:1.5em;" *ngIf="practiceTestType$">{{practiceTestType$.testTypeTitle}}</strong>
        </div>
    </div>
    <div class="row" style="height:1em;"></div>
    <div class="row errorDivStyle" *ngIf="routeLoadError" [style.top]="mouseY" [style.left]="mouseX">
        <div class="col-2 align-middle text-left"><i class='fa fa-exclamation-circle' style='font-size:24px;color:red'></i>
        </div>
        <div class="col-10 text-left mt-1">
            {{customError}}
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12 col-md-4">
            <div class="row">
                <div class="col-sm text-right">
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-12 text-md-right text-center ">
                    <img src="../../../../assets/images/petersons.svg" />
                </div>
            </div>
        </div>
        <div *ngIf="practiceTestType$" class="col-sm-12 col-md-8 text-md-left" style="border-left:1px solid lightgray;">
            <div *ngFor="let cat of practiceTestType$.testCategories">
                <h2 style="font-size:1em;">{{cat.categoryTitle}}</h2>
                <ul>
                    <li *ngFor="let code of cat.testCodes"><span (click)="getPetersonUserRoute(code.testId, $event)"
                            class="practiceCursor">{{code.testCodeTitle}}</span></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- <router-outlet></router-outlet> -->