<div id="wrapper-body">
  <div id="main-wrapper-body">
    <div id="main-body" tabindex="-1">
      <div class="container content-box-shadow tiles-top-spacing">
        <div class="d-block" (click)="menuClose()">
          <!-- <PLP-nav-header class="w-100" *ngIf='!tileView' [header]="sectionObject" 
          (changeInrView)="changeViewHeader($event)" [report]="reportState"></PLP-nav-header> -->
          <dynamic-layout-tag [componentTypes]="componentType" [ItemsList]="itemsList"></dynamic-layout-tag>
        </div>
      </div>
      <!-- <static-footer class="footerheightlength"></static-footer> -->
    </div>
  </div>
</div>