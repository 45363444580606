<div class="row gutter-10 flex-row-cards">
   <!-- For displaying theHelpfulHSCourses card. -->
   <div *ngIf='(settingsTextTab?.sections).indexOf("HelpfulHSCourses") != -1' [ngClass]="(settingsTextTab?.sections).indexOf('RelatedPrograms') != -1 ? 'col-md-6':'col-md-12'">
    <div class="career-card cardcolor-14 cards-equal-height">
      <div class="career-card-header">
        <div class="card-title p-0">
          <i class="icon-helpful-high-school-courses panal-icon-align" aria-hidden="true"></i> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.HelpfulHSCourses}}</div>
      </div>
      <div class="card-block career-card-body scroll-class ">
        <div *ngFor="let value of (occClusterStore)?.HelpfulHSCourses">
          <p *ngIf="(value.type == 'p')" [innerHTML]="value.text | MarkdownToLink"> </p>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- For displaying the RelatedPrograms card. -->
  <div  *ngIf='(settingsTextTab?.sections).indexOf("RelatedPrograms") != -1' [ngClass]="(settingsTextTab?.sections).indexOf('HelpfulHSCourses') != -1 ? 'col-md-6':'col-md-12'">
    <div class="career-card cardcolor-8 cards-equal-height">
      <div class="career-card-header">
        <div class="card-title p-0">
          <i class="icon-programs-of-study panal-icon-align" aria-hidden="true"></i> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedPrograms}}</div>
      </div>
      <div class="card-block career-card-body scroll-class ">
        <div *ngFor="let value of (occClusterStore)?.RelatedPrograms?.lists">
          <p> {{(occClusterStore)?.RelatedPrograms?.intro}}</p>
          <div>
            <div class='header_6 text-white'>{{value.header}}</div>
            <ul class="list">
              <li *ngFor="let val of value.links">
            <a href="#" class="pointer" (click)="$event.preventDefault();callProgramsCareer(val.linkID,val.linkTitle)"
              [innerHtml]="val.linkTitle | MarkdownToLink"></a>
                
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- OCC-careers Print Start -->
<div [hidden]="!printview">
<div class="print-result-page" #clusEducation>
  <div class="header d-flex align-items-center justify-content-between border-bottom">
    <div class="common-headding d-flex align-items-center">
      <div class="{{clusterIcon}}_color-sprite"></div>
      <span class="title-wraps pl-1 pt-2">{{clusterTitle}}</span>
    </div>
    <div class="print-header-logo">
      <img alt="Career Information System-logo" class="float-right" [src]="logo" > 
    </div>
  </div>
  <div class="main-body white-space">
    <div>
      <div class="careers-title pb-0">{{tabTitle}}</div>
    <div class="mt-2" *ngIf='(settingsTextTab?.sections).indexOf("HelpfulHSCourses") != -1'>
      <div class="career-card cardcolor-14">
      <div class="career-card-header font-weight-bold">
        <div class="icon-helpful_high_school_courses-sprite panal-icon-align"></div>
        <span class="white-space">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.HelpfulHSCourses}}</span>
      </div>
    </div>
    <div class="pl-3" *ngFor="let value of (occClusterStore)?.HelpfulHSCourses">
      <p class="text-secondary mb-2" *ngIf="(value.type == 'p')" [innerHTML]="value.text | PrintWeblinkPipe"> </p>
      <ul *ngIf="(value.type == 'ul')" class="list-unstyled">
        <li *ngFor="let val of value.text">
          <div class="headding-arrow pl-2 mb-1">{{val}}</div>
        </li>
      </ul>
    </div>
    </div>
    <div class="html2pdf__page-break"></div>
    <div class="mt-2" *ngIf='(settingsTextTab?.sections).indexOf("RelatedPrograms") != -1'>
      <div class="career-card cardcolor-8">
        <div class="career-card-header font-weight-bold">
          <div class="icon-programs_of_study-sprite panal-icon-align"></div>
          <span class=white-space>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.RelatedPrograms}}</span>
        </div>
      </div>
      <div class="pl-3" *ngFor="let value of (occClusterStore)?.RelatedPrograms?.lists">
        <p>{{(occClusterStore)?.RelatedPrograms?.intro}}</p>
        <div class="card-block-slider-filter">
          <!-- The below class(column-count-2) is added here : For splitting the list into two coloumns -->
          <!-- <div class="column-count-2"> --> 
          <div>
            <ul class="list-unstyled">
              <li class="mb-1" *ngFor="let val of value.links;let inx=index" [class.html2pdf__page-break]="(inx==222 || ((inx-22)%30==0)) && !NotIE">
                <div class="headding-arrow pl-2">{{val.linkTitle}}</div>
              </li>
            </ul>
          </div> 
        </div>       
      </div>
    </div>
  </div>    
  </div>
  <div class="mt-4 pt-4 text-center">
    <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
  </div>
</div>
</div><!-- OCC-careers Print End -->