
/** Angular imports */
import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
/** Third party library import */
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/** Custom imports */
import { StorageService } from '../shared/outer-services/storage.service';
import { EventDispatchService } from '../shared/outer-services/event-dispatch.service';
import { Utilities } from '../shared/outer-services/utilities.service';
import { RouteService } from '../shared/outer-services/route.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'common-ngbd-modal-content',
  template: `<div class="modal-header">
  <h4 class="modal-title"> {{headerText}}{{((modalCommonText)?.commonText[headsection])}}{{trans_error}}</h4>
  </div>
<div class="modal-body">
<form>
<div class="form-group">
 {{session_exp_txt }} {{err_occ }}{{((modalCommonText)?.commonText[headingText])}}
</div>
</form>
</div>
<div class="modal-footer" *ngIf="!closevalue">
<button id="save" type="button" [attr.aria-label]='(modalCommonText)?.commonText[yesbtn]' class="btn-common btn-success" (click)="callExtendTime()" >
{{(modalCommonText)?.commonText[yesbtn]}}</button>
<button id="cancel" type="button" [attr.aria-label]='(modalCommonText)?.commonText[nobtn]' class="btn-common btn-primary" (click)="closemodal()">
{{(modalCommonText)?.commonText[nobtn]}} </button>
</div>
 <div class="modal-footer" *ngIf="closevalue">
 <button id="reload" type="button" aria-label="Reload" title="Reload" class="btn-common btn-warning" (click)="reloadmodal()">Reload</button>
 <button id="logout" type="button" aria-label="Sign out" title="Sign out" class="btn-common btn-warning" (click)="logoutmodal()">Sign out</button>
</div>
`
})
// tslint:disable-next-line:component-class-suffix
export class CommonNgbdModalContent implements OnInit {
  headsection: any;
  yesbtn: any;
  nobtn: any;
  // this 'timer' variable stores two values depending on the usecase i:e if the popup is idle for 2 min then application
  // will logout after 2min and when we click on 'NO' in the popup the app will immediately logout.
  timer = 2 * 60 * 1000;
  @Input() deletevalue: any;
  @Input() arrayValue: any;

  @Input() trans_error: any;
  // @Input() closebtn;
  @Input() err_occ: any;

  @Input() session_exp_txt: any;
  @Input() sessionName: any;

  @Input() Closebtnvalue: any;

  unsavedTxt: any;
  @Input() commonButtons: any;
  @Input() valueofunchange: any;

  // browserDom: BrowserDomAdapter;
  closevalue = false;
  testScoreArr: any = [];
  headerText: any;
  modalCommonText: any;
  timeoutVariable;
  headingText: any;
  idealModal = true;
  constructor(public browserDom: Renderer2, private router: Router,
    private store: Store<any>,
    public activeModal: NgbActiveModal, private eventService: EventDispatchService,
    // private apiJson: AeApiCallClass,
    private routeService: RouteService,
    private storageService: StorageService, private utils: Utilities) {
    // this.browserDom = new BrowserDomAdapter();
    this.modalCommonText = JSON.parse(this.storageService.sessionStorageGet('AsmntCommonTextStorage'));
    // this.modalCommonText = store.select('AsmntCommonText');
    // this.unsavedTxt = this.commonButtons['unsavedTxt'];
    if (this.idealModal) {
      this.setClearTime();
    }
  }

  ngOnInit() {
    // console.log('close vlaue is  :in shared');
    try {
      // setTimeout(function () {
      // console.log('this.modalCommonText.commonButtons : ' , this.modalCommonText)
      try {
        // console.log('this.AeAsmntIntroText.commonButtons : ' + this.AeAsmntIntroText.commonButtons)
        if (this.commonButtons) {
          // console.log('this.commonButtons : ' + this.commonButtons)
          this.headerText = this.commonButtons.headerText;
          this.headingText = this.commonButtons.unsavedTxt;
          this.headsection = this.commonButtons.headsection;
          this.nobtn = this.commonButtons.nobtn;
          this.yesbtn = this.commonButtons.yesbtn;
        }
        if (this.Closebtnvalue == 1) {
          this.closevalue = true;
        }
        // console.log('headtext--' + this.headingText)
      } catch (e) {
        console.log('in model component---' + e.message);
      }
      this.browserDom.setStyle(document.querySelector('.modal-dialog'), 'width', '90%');
      // }.bind(this), 0);

    } catch (e) {
      console.log('error--->' + e.message);
    }


  }

  callExtendTime() {
    this.idealModal = false;
    clearTimeout(this.timeoutVariable);
    if (this.sessionName == 'sessioncheck') {
      const evnt = document.createEvent('CustomEvent');
      evnt.initEvent('sessionExtend', true, true);
      this.eventService.dispatch(evnt);
    }
    this.activeModal.close(true);
  }
  closemodal() {
    this.timer = 0;
    this.idealModal = false;
    // this.utils.sessionExpired();
    this.setClearTime();
    this.activeModal.close(true);
  }
  /**setClearTime function for modal closing at the time of sessionExpiration  */
  setClearTime() {
    clearTimeout(this.timeoutVariable);
    /*setTimeout() is used when  user clicks on 'NO' button it takes 2 seconds for logout meanwhile user will be saving data */
    this.timeoutVariable = setTimeout(function () {
      // this.modalRef1.close();
      this.utils.sessionExpired();
    }.bind(this), this.timer);
  }
  reloadmodal() {
    window.location.reload();
  }
  logoutmodal() {
    this.routeService.mainLogOut();
    // window.location.href = 'login/loginForm';
  }
}
