/**Angular imports. */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

/**Third Party Libraries.*/
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/**Custome imports.*/
import { AssessmentsService } from '../assessments//shared/services/assessments.service';
import { GridModule } from './grid.module';
import { SharedModule } from '../../shared/shared-module';
import { ServiceModule } from '../../shared/service-module/shared-service-module';





@NgModule({
    imports: [
        CommonModule, GridModule, NgbModule, NgbModule, ReactiveFormsModule,
        ServiceModule, SharedModule],
    declarations: [],
    providers: [AssessmentsService],
    entryComponents: []

})

export class FrameworkModule {
}
