<div class="row gutter-10">
  <!-- For displaying the cluster discription -->
  <div class="col-lg-12" *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription") != -1'>
    <p>
      <b>{{(occClusterStore)?.ShortDescription}}</b>
    </p>
  </div>
  <!-- For displaying the section name and links-->
  <div class="col-lg col-md col-12" id="ataglance" *ngIf='(settingsTextTab?.sections).indexOf("TopSalaries") != -1'>
    <div class="career-card cardcolor-25">
      <div class="career-card-header">
        <div class="card-title ">
          <i class="fa icon-top-5-salaries panal-icon-align" aria-hidden="true"></i>
          {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TopSalaries}}
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <ul class="list">
          <li *ngFor="let item of (occClusterStore)?.TopSalaries">
            <a href="#" class="pointer"
              (click)="$event.preventDefault();CallOccDetailCareer(item.linkID,item.linkTitle)" [innerHtml]="item.linkTitle | MarkdownToLink"></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- For displaying the score card based on the boolean value -->

  <div class="col-lg col-md col-12" *ngIf='(settingsTextTab?.sections).indexOf("CCIScore") != -1'>
      <div class="career-card" [ngClass]="occCCIScoreAll?.taken == true?'d-none':''"
        (click)="$event.preventDefault();goToCCI(occCCIScoreAll?.taken,occCCIScoreAll?.sortId)">
        <!--Career Cluster Box - Top-->
        <div *ngIf = '(occCCIScoreAll?.taken == false) || (showClusterBox)'>
          <a href="#" class="card-block card-block2-p-plp3 cluster-noun-text d-block pointer-events-none"
            [ngStyle]="{'background': atAGlance.clusterColor, 'margin-bottom': '10px'}">
            <div class="d-flex align-items-center justify-content-center flex-column h-100">
              <h6 class="font-weight-bold pt-2 justify-content-center">{{occClusterText}}</h6>
              <i class="{{atAGlance.iconName}} font-large" aria-hidden="true"></i>
              <h6 class="font-weight-bold">{{clusterName}} </h6>
            </div>
          </a>
        </div>

        <!--Career Cluster Match - Bottom-->
        <div *ngIf="occCCIScoreAll?.taken == false">
          <a href="#" class="card-block card-block card-block2-p-plp3 scroll-class d-block pointer-events-none"
              [ngStyle]="{'background': '#5A8500'}" *ngIf="!showClusterBox">
              <div class="text-center">
                <p class="mb-0 py-4">{{(occCCIScoreAll)?.text}}</p>
                <!--CCI JR-->
                <img alt="occresize" src="{{LandingImages}}cci-image.png" *ngIf="occCCIScoreAll.sortId === 'CCIJr'" />
                <!--CCI ADULT-->
                <img alt="occresize" src="{{LandingImages}}cciAdult-landing.png"
                  *ngIf="occCCIScoreAll.sortId === 'CCIAdult'" />
                <!-- CCI Quick Pic Jr -->
                <img alt="occresize" src="{{LandingImages}}baby_CCI.png" *ngIf="occCCIScoreAll.sortId === 'CCIQuick'" />
                <!-- CCI Quick Pic Adult -->
                <!-- <img alt="occresize" src="{{LandingImages}}cciAdult-landing.png" *ngIf="occCCIScoreAll.sortId === 'CCIQuickAdult'" /> -->
              </div>
            </a>
        </div>        
        
        <!--Cluster Definition Box-->
        <div *ngIf="showClusterBox">
          <a href="#" class="cardcolor-25 card-block card-block card-block2-p-plp3 scroll-class d-block pointer-events-none">
            <div *ngFor="let value of (occClusterStore)?.ClusterDefinition">
              <div class="text-left px-3 text-white header_3" *ngIf="(value.type == 'h')">
                <b [innerHtml]="value.text | MarkdownToLink"></b>
              </div>
              <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                <i class="font-default" [innerHtml]="value.text | MarkdownToLink"></i>
              </p>
              <ol *ngIf="(value.type == 'ol')" class="text-left">
                <li *ngFor="let val of value.text">
                  <div [innerHtml]="val | MarkdownToLink"></div>
                </li>
              </ol>
            </div>
          </a>
        </div>        
      </div>

      <div *ngIf="occCCIScoreAll?.taken == true" class="career-card d-table"
        [ngStyle]="{'background': atAGlance.clusterColor}"
        (click)="$event.preventDefault();goToCCI(occCCIScoreAll?.taken,occCCIScoreAll?.sortId)">
        <div class="card-block cluster-at-glance d-table-cell align-middle py-3">
          <div align="center">
            <div class="header_6 pb-3 justify-content-center text-white">{{clusterName}} </div>
            <div class="school-vertical-graph justify-content-center" *ngIf="occCCIScoreAll.level != -1">
              <div>
                <div class="cluster-icn" align="center" *ngIf="occCCIScoreAll.level == 0">
                  <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                </div>
                <div [ngStyle]="{'background':CCIcolor['low']}" class="low-bar"></div>
              </div>
              <div>
                <div class="cluster-icn" align="center" *ngIf="occCCIScoreAll?.level == 1">
                  <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                </div>
                <div [ngStyle]="{'background':CCIcolor['medium']}" class="medium-bar"></div>
              </div>
              <div>
                <div class="cluster-icn" align="center" *ngIf="occCCIScoreAll.level == 2">
                  <i class="{{atAGlance.iconName}}" aria-hidden="true"></i>
                </div>
                <div [ngStyle]="{'background':CCIcolor['high']}" class="high-bar"></div>
              </div>
            </div>
            <p class="pt-3 font-weight-bold">{{occCCIScoreAll?.text}}</p>
          </div>
        </div>
      </div>
  </div>
  <!-- For displaying the TopOpportunities card -->
  <div class="col-lg col-md col-12" *ngIf='(settingsTextTab?.sections).indexOf("TopOpportunities") != -1'>
    <div class="career-card cardcolor-12 ">
      <!-- If cci there code start -->
      <div *ngIf='(SortCCI == -1)' class="career-card-header">
        <div class="card-title">
          <i class="icon-clu_business panal-icon-align" aria-hidden="true"></i>
          {{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.TopOpportunities}}
        </div>
      </div>
      <!-- If cci there code end -->
      <!-- If cci not there -->
      <div *ngIf='(SortCCI != -1)' class="career-card-header d-block">
        <div class="card-title d-flex align-items-center h-100">
          <i class="icon-clu_business panal-icon-align mr-2" [ngStyle]="{'top': '0'}" aria-hidden="true"></i>
          {{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.TopOpportunities}}
        </div>
      </div>
      <!-- If cci not there code end -->
      <div class="card-block career-card-body scroll-class">
        <ul class="list">
          <li *ngFor="let item of (occClusterStore)?.TopOpportunities">
            <a href="" class="pointer"
              (click)="$event.preventDefault();CallOccDetailCareer(item.linkID,item.linkTitle,atAGlance.iconName,atAGlance.clusterColor)">
              {{item.linkTitle}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Basingon the language change this div occurs -->
  <!-- <div class="col-lg col-md col-12" *ngIf='(settingsTextTab?.sections).indexOf("ClusterDefinition") != -1'>
    <div class="lg-card-img mb-2"> </div> 
  </div> -->

  <div class="col-lg col-md col-12" *ngIf='(settingsTextTab?.sections).indexOf("ClusterDefinition") != -1'>
    <div>
      <!-- <div class="card-bg"></div> -->
      <div class="career-card orange w-100">
        <div class="card-block card-block2-p-plp3 align-middle d-table-cell larg-height cluster-noun-text text-left">
          <div *ngFor="let item of (occClusterStore)?.ClusterDefinition">
            <h2 class="text-left px-3" *ngIf="(item.type == 'h')">
              <b [innerHtml]="item.text | MarkdownToLink"></b>
            </h2>
            <p class="text-left mb-0" *ngIf="(item.type == 'p')">
              <i [innerHtml]="item.text | MarkdownToLink"></i>
            </p>
            <ol *ngIf="(item.type == 'ol')">
              <li *ngFor="let val of item.text">
                <div [innerHtml]="val | MarkdownToLink"></div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- OCC-careers Print Start -->
<div [hidden]="!printview">
  <div class="print-result-page" #clusAtaGlance>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="{{clusterIcon}}_color-sprite"></div>
        <span class="title-wraps pl-1 pt-2">{{clusterTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" [src]="logo">
      </div>
    </div>
    <div class="main-body">
      <div class="careers-title">{{tabTitle}}</div>
      <div *ngIf='(settingsTextTab?.sections).indexOf("ShortDescription") != -1'>
        <p>
          <b>{{(occClusterStore)?.ShortDescription}}</b>
        </p>
      </div>
      <div class="row gutter-10">
        <div class="col-6" *ngIf='(settingsTextTab?.sections).indexOf("TopSalaries") != -1'>
          <div class="career-card cardcolor-25">
            <div class="career-card-header font-weight-bold pre">
              <div class="icon-top_5_salaries-sprite panal-icon-align"></div>
              {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TopSalaries}}
            </div>
            <div class="card-block career-card-body">
              <ul class="list-unstyled">
                <li *ngFor="let item of (occClusterStore)?.TopSalaries">
                  <div class="headding-arrow mb-2">{{item.linkTitle}}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-6" *ngIf='(settingsTextTab?.sections).indexOf("TopOpportunities") != -1'>
          <div class="career-card cardcolor-12">
            <div class="career-card-header font-weight-bold pre">
              <div class="icon-clu_business-sprite panal-icon-align"></div>
              {{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.TopOpportunities}}
            </div>
            <div class="card-block career-card-body">
              <ul class="list-unstyled">
                <li *ngFor="let item of (occClusterStore)?.TopOpportunities">
                  <div class="headding-arrow mb-2">{{item.linkTitle}}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-6" *ngIf='(settingsTextTab?.sections).indexOf("CCIScore") != -1'>
          <div class="career-card" *ngIf="((occClusterStore)?.CCIScore?.taken==true && SortCCI != -1)"
            [ngStyle]="{'background': atAGlance.clusterColor}">
            <div class="card-block cluster-noun-text d-flex align-items-center justify-content-center p-3">
              <div class="school-vertical-graph justify-content-center"
                *ngIf="((occClusterStore)?.CCIScore?.level!=-1)">
                <div>
                  <div class="cluster-icn" align="center" *ngIf="((occClusterStore)?.CCIScore?.level==0)">
                    <i class="{{atAGlance.iconName}} font-large" aria-hidden="true"></i>
                  </div>
                  <div [ngStyle]="{'background':CCIcolor['low']}" class="low-bar"></div>
                </div>
                <div>
                  <div class="cluster-icn" align="center" *ngIf="((occClusterStore)?.CCIScore?.level==1)">
                    <i class="{{atAGlance.iconName}} font-large" aria-hidden="true"></i>
                  </div>
                  <div [ngStyle]="{'background':CCIcolor['medium']}" class="medium-bar"></div>
                </div>
                <div>
                  <div class="cluster-icn" align="center" *ngIf="((occClusterStore)?.CCIScore?.level==2)">
                    <i class="{{atAGlance.iconName}} font-large" aria-hidden="true"></i>
                  </div>
                  <div [ngStyle]="{'background':CCIcolor['high']}" class="high-bar"></div>
                </div>
              </div>
              <p class="pl-3 mb-0">{{(occClusterStore)?.CCIScore?.text}}</p>
            </div>
          </div>
        </div>
        <!--Cluster Definition Box-->
        <div class="col-6" *ngIf="showClusterBox">
          <div class="career-card">
            <div class="cardcolor-25 card-block cluster-noun-text">
              <div *ngFor="let value of (occClusterStore)?.ClusterDefinition">
                <div class="text-left px-3 text-white header_3" *ngIf="(value.type == 'h')">
                  <b>{{value.text}}</b>
                </div>
                <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                  <i class="font-default">{{value.text}}</i>
                </p>
                <ol *ngIf="(value.type == 'ol')" class="text-left">
                  <li *ngFor="let val of value.text">
                    <div>{{val}}</div>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameworkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!-- OCC-careers Print End -->