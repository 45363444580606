<!--for deployment -->
<div>
  <p  class="header_5 header-text-color"> <b>{{(occCareerStore)?.EducationQuestion}}</b></p>
  <!--Display about Courses-->
  <div class="row">
    <div class="px-1 col-lg-6 col-md-6 col-12" *ngIf='(settingsTextTab?.sections).indexOf("Courses")!= -1'>
      <div class="career-card cardcolor-38">
        <div class="career-card-header">
          <div class="card-title px-2">          
            <i class="icon-helpful-high-school-courses panal-icon-align" aria-hidden="true"></i> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Courses}}
          </div>
        </div>
        <div class="card-block employment-outlook-card-height scroll-class ">
  
          <div *ngFor="let value of (occCareerStore).Courses">
            <p class="px-0" *ngIf="(value.type == 'p')" [innerHTML]='value.text | MarkdownToLink'></p>
            <ul *ngIf="(value.type == 'ul')" class="list">
              <li *ngFor="let val of value.text" [innerHtml]="val | MarkdownToLink"> </li>
            </ul>
            <div class='header_6 px-3 text-white' *ngIf="(value.type == 'h')">
              <b [innerHtml]="value.text | MarkdownToLink"></b>
            </div>
          </div>
        </div>
      </div>  
    </div>
     <!--Display about Activities-->
    <div class="px-1 col-lg-6 col-md-6 col-12" *ngIf='(settingsTextTab?.sections).indexOf("Activities")!= -1'>
      <div class="career-card cardcolor-28">
        <div class="career-card-header">
          <div class="card-title px-2">          
            <!-- <i class="icon-jr_activities panal-icon-align" aria-hidden="true"></i>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Activities}} -->
            <img class="panal-icon-align" src="{{fontIcons}}jr_activities.svg" alt="jr_activities" />{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Activities}}
          </div>
        </div>
        <div class="card-block employment-outlook-card-height scroll-class ">
  
          <div *ngFor="let value of (occCareerStore).Activities">
            <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
            <ul *ngIf="(value.type == 'ul')" class="list">
              <li *ngFor="let val of value.text" [innerHtml]="val | MarkdownToLink"></li>
            </ul>
            <div class='header_6 px-3 text-white' *ngIf="(value.type == 'h')">
              <b [innerHtml]="value.text | MarkdownToLink"></b>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
</div>
 <!-- Print Start Eduction Training -->
<div class="hidden-page">
 <div class="print-result-page" #EducationTrainingPrintPage>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-careersJr-sprite mr-1"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right cis-logo" [src]="logo" width="50%">
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <p  class="header_5 header-text-color"> <b>{{(occCareerStore)?.EducationQuestion}}</b></p>
      <div>
        <!--Display about Courses-->
        <div class="row">
          <div class="career-card  col-12"  *ngIf='(settingsTextTab?.sections).indexOf("Courses")!= -1'>
            <div class="cardcolor-38 mb-3">
              <div class="career-card-header">
                <div class="card-title">
                  <div class="icon-helpful_high_school_courses-sprite panal-icon-align"></div>
                  <span>{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Courses}}</span>
                </div>
              </div>
            </div>              
            <div class="card-block text-dark p-0">
              <div class="pl-3" *ngFor="let value of (occCareerStore).Courses">
                <p class="px-0 pos-progdesc" *ngIf="(value.type == 'p')" [innerHTML]='value.text | PrintCustomlinkPipe'></p>
                <ul *ngIf="(value.type == 'ul')" class="list-unstyled">
                  <li *ngFor="let val of value.text">
                    <div class="headding-arrow pl-2 mb-1">{{val}}</div>
                  </li>
                </ul>
                <div class='header_6 px-3 text-white' *ngIf="(value.type == 'h')">
                  <b>{{value.text}}</b>
                </div>
              </div>
            </div>
          </div>
          <!--Display about Activities-->
          <div class="career-card col-12" *ngIf='(settingsTextTab?.sections).indexOf("Activities")!= -1'>
            <div class="cardcolor-28 mb-3">
            <div class="career-card-header">
              <div class="card-title">
                <div class="icon-activities-sprite panal-icon-align"></div>
                <span class="w-100">{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Activities}}</span>
              </div>
            </div>   
          </div>       
            <div class="card-block text-dark p-0">
              <div class="pl-3" *ngFor="let value of (occCareerStore).Activities">
                <p *ngIf="(value.type == 'p')"> {{value.text}}</p>
                <ul *ngIf="(value.type == 'ul')" class="list-unstyled">
                  <li *ngFor="let val of value.text">
                    <div class="headding-arrow pl-2 mb-1">{{val}}</div>
                  </li>
                </ul>
                <div class='header_6 px-3 text-white' *ngIf="(value.type == 'h')">
                  <b>{{value.text}}</b>
                </div>
              </div>
          </div>
        </div>
        </div>
        
    <div class="pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!-- Print End Eduction Training -->
