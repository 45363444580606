/**Angular imports */
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {
  debounceTime, takeWhile
} from 'rxjs/operators';
/**Services */
import { Utilities } from '../../../../../shared/outer-services/utilities.service';
import { StorageService } from '../../../../../shared/outer-services/storage.service';

/**Components */
import { OCCPageState, OccTextState, OccSettingsTextState, FrameworkConfigState } from '../../../../../state-management/state/main-state';
import { OccSnackBar } from '../../occ-modal/occ-modal-component';
import { OccApiCallClass } from '../../occ-constants/model';
import { OccDispatchService } from '../../occ-services/occ-dispatch.service';
import { OccApiCallService } from '../../occ-services/occ-apicalls.service';
import { Occ_Settings_Tab_Text_State, Occ_Index_Reducer_Text_State, Occ_Buttons_Reducer_Text_State, Occ_EmpOutlook_Wages_State } from '../../occ-state-management/occ-state/occ-state';
import { OccCommonMethodsService } from '../../occ-services/occ-common-methods.service';
import { EventDispatchService } from '../../../../../shared/outer-services/event-dispatch.service';


@Component({
  selector: 'occ-cluster-header',
  templateUrl: './occ-cluster-layout.html'
})

export class OccClusterHeaderComponent implements OnInit, OnDestroy {
  currentRate; /**Declare for storing the current rating value.*/
  value = 'At a Glance'; /**Declare for storing the tab value.*/
  refGlance = {}; /**Declare for storing the icon and cluster color values.*/
  starRes; /**Declare for storing the rating values.*/
  refrelatedcareer = { 'RelatedCareers': {} }; /**Declare for storing the career icon and color values.*/
  clusterID = ''; /**Declare for storing the related clusterIDs.*/
  clusterName; /**Declare for storing the cluster related text coming from store.*/
  //nameTxt; /**Declare for storing the text of different language.*/
  showColorUp = -1; /**Declare for storing the thumbs value.*/
  clusterIcon = ''; /**Declare for storing the cluster icon.*/
  clusterColor = ''; /**Declare for storing the cluster color.*/
  accountID = ''; /**Declare for storing the accountID.*/
  backAssessmentValue = false; /**Declare for storing the value for backtoAssessment.*/
  occClusterText; /**Declare for storing the cluster text.*/
  hideVal = 0; /**Declare for storing the hide values.*/
  occClusterStore; /**Declare for storing the text coming from the store.*/
  activeVal = 0;
  langChange = false; /**Declare for storing the active values.*/
  clussettingsText; /**Declare for storing the settings subscribe text.*/
  subscription = new Subscription; /** Declare to listen if any change occured.*/
  occIndexReducerSub = new Subscription; /** Declare to listen if any change occured.*/
  Occ_Settings_Tab_Text = new Subscription; /** Declare to listen if any change occured.*/
  Occ_Buttons_Reducer_Text = new Subscription; /** Declare to listen if any change occured.*/
  outereventsubscription = new Subscription;
  langVal; /**Declare for getting the language value.*/
  filter: any; /** boolean value toggle in mobile  */
  public snackbar = new OccSnackBar(); /**Declare for showing the snackbar. */
  occID = ''; /** Contains current occId value */
  occclusinx = '';
  frameworkObj;
  logo;
  restrictedAccountConstant; /**Variable used to store restrictedaccounttype  */
  accountTypeJwt; /**Variable used to store accounttype from JWT */
  restrictedAccountType: boolean;
  @ViewChild('videoplayer', { static: false }) videoplayer: ElementRef;
  @ViewChild('occClusterFocus', { static: true }) occClusterFocusElement: ElementRef;
  compAlive = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private occstore: Store<Occ_Buttons_Reducer_Text_State>,
    private apiJson: OccApiCallClass,
    private occDispatch: OccDispatchService,
    private ratingConfig: NgbRatingConfig,
    private router: Router, private occApiCall: OccApiCallService,
    private store: Store<any>,
    private occSetstore: Store<Occ_Settings_Tab_Text_State>,
    private dispatchStore: Store<Action>,
    private OCCPageStateStore: Store<Occ_EmpOutlook_Wages_State>,
    private occIndexstore: Store<Occ_Index_Reducer_Text_State>,
    private occCommonService: OccCommonMethodsService,
    private utils: Utilities,
    private storageService: StorageService,
    private eventService: EventDispatchService, private Frameworkstore: Store<FrameworkConfigState>,
    @Inject('RESTRICTED_ACCOUNT_TYPE') public restrictedAccount: string,
    @Inject('CAREER_IMAGE_URL') public CareersImages: string
  ) {

    this.eventService.listen().pipe(takeWhile(() => this.compAlive)).subscribe((e) => {
      if (e.type == 'languageChanged') {
        this.langVal = this.storageService.sessionStorageGet('langset');
        const Arr = this.router.url.split('?');
        const routArr = Arr[0].split('/');
        this.hideVal = 0;
        this.langChange = true;
        if (routArr.indexOf('occCluster') != -1) {
          this.ngOnInit();
        }
      }
    });

    this.accountTypeJwt = this.utils.getDecodedAccessToken().accountType;
    this.restrictedAccountConstant = restrictedAccount;
    if (this.accountTypeJwt !== this.restrictedAccountConstant) {
      this.restrictedAccountType = true;
    } else {
      this.restrictedAccountType = false;
    }
    this.storageService.sessionStorageSet('activeSortId', 'fileOcc');
    document.title = 'Careers';
    this.subscription = this.Frameworkstore.select('config').subscribe((res) => {
      if (res && res.config && res.config.Result && res.config.Result.headerFooter) {
        const evnt = document.createEvent('CustomEvent');
        evnt.initCustomEvent('FooterDispatch', true, true, res && res.config && res.config.Result && res.config.Result.headerFooter);
        this.eventService.dispatch(evnt);
      }
      if (res.config) {
        this.frameworkObj = res.config;
      }

    });
    const ref = this;
    function convertImgToDataURLviaCanvas(url, callback, outputFormat) {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function () {
        let canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        try {
          dataURL = canvas.toDataURL(outputFormat);
          callback(dataURL);
          canvas = null;
        } catch (e) {
        } finally {
          dataURL =
            'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
          callback(dataURL);
          canvas = null;
        }
      };
      img.src = url;
    }
    convertImgToDataURLviaCanvas(ref.frameworkObj.Result.user.logo, function (base64Img) {
      ref.logo = base64Img;
    }, 'base64');
    this.utils.showLoading();
    this.langVal = this.storageService.sessionStorageGet('langset');
    this.dispatchStore.dispatch({ type: 'RESET_OccSettingsText' });
    this.dispatchStore.dispatch({ type: 'RESET_OCC_PAGE' });
    if (!this.storageService.sessionStorageGet('OccIndexReducerText') || !this.storageService.sessionStorageGet('Reducer_OccText')) {
      this.occCommonService.OccIndexDispatch();
    }

    this.activatedRoute.queryParams.subscribe(params => {
      // Get occId from parameters
      this.occID = params['occid'];
      this.occclusinx = params['inx'];

      // Get key value to nameTxt using occID
    });
    this.ratingConfig.max = 1;
    const rtArr = this.router.url.split('/');
    this.occIndexReducerSub = this.occIndexstore.select('Occ_Index_Reducer_Text').pipe(debounceTime(100)).subscribe((v) => {
      if (this.storageService.sessionStorageGet('parentCnt') == 'true') {
        this.savedClusNames();
      }
    });
    this.Occ_Settings_Tab_Text = this.occSetstore.select('Occ_Settings_Tab_Text').subscribe((v) => {
      this.clussettingsText = v;
      this.hideLoadingSymbol();
    });
    this.Occ_Buttons_Reducer_Text = this.occstore.select('Occ_Buttons_Reducer_Text').subscribe((v) => {
      // if (this.langChange == true) {
      this.occClusterText = v;
      // }
    });
    this.occClusterStore = this.OCCPageStateStore.select('Occ_EmpOutlook_Wages');
    if (this.storageService.sessionStorageGet('inTab') != undefined || this.storageService.sessionStorageGet('inTab') != null) {
      if (this.storageService.sessionStorageGet('inTab') == 'Assess' || this.storageService.sessionStorageGet('inTab') == 'cciAssess'
        || this.storageService.sessionStorageGet('inTab') == 'osAssess') {
        this.backAssessmentValue = true;
      } else {
        this.backAssessmentValue = false;
      }
    }
    this.outereventsubscription = this.eventService.listenStaticContent().subscribe((e) => {
      if (this.occClusterFocusElement) {
        this.occClusterFocusElement.nativeElement.setAttribute('tabindex', '0');
        this.occClusterFocusElement.nativeElement.focus();
      }
    });
  }
  videostop() {
    this.videoplayer.nativeElement.load();
    this.videoplayer.nativeElement.pause();
  }
  savedClusNames() {
    const val = JSON.parse(this.storageService.sessionStorageGet('OccIndexReducerText'));
    const value = true;
    if (val && val.Occ_Index_Reducer_Text !== undefined) {
      val.Occ_Index_Reducer_Text.clusList.forEach((v) => {
        if ((v.clusterID == this.clusterID) && value == true) {
          this.clusterName = (v.title);
        }
      });
    }
  }
  /**
   * This method is used to get into Occ Cluster component.
   */
  ngOnInit() {
    this.utils.showLoading();

    this.activatedRoute.queryParams.subscribe(params => {
      /** Defaults to 0 if no query param provided.*/
      this.clusterID = params['clusId'];
      this.clusterIcon = params['clusIcon'];
      this.clusterColor = params['clusColor'];
    });
    this.savedClusNames();
    this.accountID = 'accountID';
    this.dispatchStore.dispatch({
      type: 'GET_SECTIONS_TEXT', payload: {
        methodVal: 'GET', module_Name: 'Occ/v1/',
        path_params: ['cluster', this.clusterID, this.accountID], query_params: {
          'sections': 'all', 'lang': this.langVal
        },
        body_Params: {}, endUrlVal: 'pages', setVal: 'clusterPageResult', text: ''
      }
    });
    let endVal;

    if (this.storageService.sessionStorageGet('inTab') == 'osAssess') {
      endVal = (this.accountTypeJwt !== this.restrictedAccountConstant) ? 'Users/pageText' : 'pageText/occSort';
    } else if (this.storageService.sessionStorageGet('module') == 'ideas') {
      endVal = (this.accountTypeJwt !== this.restrictedAccountConstant) ? 'Users/pageText' : 'pageText/ideas';
    } else if (this.storageService.sessionStorageGet('module') == 'rc') {
      endVal = (this.accountTypeJwt !== this.restrictedAccountConstant) ? 'Users/pageText' : 'pageText/realityCheck';
    } else {
      endVal = (this.accountTypeJwt !== this.restrictedAccountConstant) ? 'Users/pageText' : 'pageText/wil';
    }
    this.dispatchStore.dispatch({
      type: 'GET_INTRO_TEXT', payload: {
        methodVal: 'GET', module_Name: 'Assessment/v1/',
        path_params: [], query_params: { 'lang': this.langVal },
        body_Params: {}, endUrlVal: endVal, setVal: 'commonIntro', text: ''
      }
    });
    this.getRating();
    this.refGlance['iconName'] = this.clusterIcon;
    this.refGlance['clusterColor'] = this.clusterColor;
    this.refrelatedcareer['clusterIcon'] = this.clusterIcon;
    this.refrelatedcareer['clusterColor'] = this.clusterColor;
  }

  /**
   * This method is used for hiding the loading symbole after getting the entire data.
   */
  hideLoadingSymbol() {
    if (this.clusterName != undefined && (this.clussettingsText != undefined)) {
      if ((this.clussettingsText.Occ_Settings_Tab_Text.topSections != undefined)) {
        const ref = this;
        setTimeout(function () {
          ref.utils.hideLoading();
        }, 500);
      }
    }
  }

  /**
   * This method is used to get the cluster rating.
   */
  getRating() {
    try {
      this.apiJson.method = 'GET';
      this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
      this.apiJson.moduleName = 'Occ/v1/';
      const getThumbsResult = {
        input_data: [
          {
            'param_type': 'path',
            'params': [this.accountID, 'ratings', this.clusterID]
          },
          {
            'param_type': 'query',
            'params': { 'lang': this.langVal }
          }

        ]
      };
      const user = JSON.stringify(getThumbsResult);
      this.apiJson.endUrl = 'users';
      this.apiJson.data = user;
      this.occApiCall.callApi([this.apiJson]).subscribe((res) => {
        if (res[0].Success + '' == 'true') {
          if (res[0].Result === 1) {
            this.showColorUp = 1;
          } else if (res[0].Result === 0) {
            this.showColorUp = 0;
          } else {
            this.showColorUp = -1;
          }
          this.getText();
        }
      }, e => this.utils.handleError(`occ-cluster-header-component.ts getRating: ${e.message}`));
    } catch (e) {
      this.utils.handleError(`occ-cluster-header-component.ts getRating: exception ${e.message}`);
    }
  }

  /**
   *
   * @param thumbVal is used to indicate the thumb value.
   */
  getUnFill(thumbVal) {
    this.utils.showLoading();
    if (thumbVal == 'up' && (this.showColorUp != 1)) {
      this.showColorUp = 1;
    } else {
      this.showColorUp = -1;
    }
    this.resultPost();
  }
  resultPost() {
    setTimeout(function () {
      this.apiJson.method = 'POST';
      this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
      this.apiJson.moduleName = 'Occ/v1/';
      const starResult = {
        input_data: [
          {
            'param_type': 'path',
            'params': [this.accountID, 'ratings', this.clusterID, this.showColorUp]
          },
          {
            'param_type': 'query',
            'params': { 'lang': this.langVal }
          }

        ]
      };
      const user = JSON.stringify(starResult);
      this.apiJson.endUrl = 'users';
      this.apiJson.data = user;
      this.occApiCall.callApi([this.apiJson]).subscribe((res) => {
        if (res.Result + '' == 'true') {
          const val = JSON.parse(this.storageService.sessionStorageGet('OccIndexReducerText'));
          // let resOcc =
          val.Occ_Index_Reducer_Text.clusList.forEach(res => {
            if (res.clusterID == this.clusterID) {
              res.rating = this.showColorUp;
            }
            //  return ;
          });
          // console.log('al is :' + JSON.stringify(val.commonText.occList));
          this.storageService.sessionStorageSet('OccIndexReducerText', JSON.stringify(val));
          this.starRes = this.showColorUp;
          if (this.storageService.sessionStorageGet('prevRoute') === 'occSort') {
            const value = JSON.parse(this.storageService.sessionStorageGet('OSOnMyList'));
            value.OsGetAllOccList.clusList.forEach(clus => {
              if (clus.clusterID == this.clusterID) {
                clus.rating = this.showColorUp;
              }
            });
            this.storageService.sessionStorageSet('OSOnMyList', JSON.stringify(value));
          }
          this.utils.hideLoading();
          this.getRating();
        }

      }, e => this.utils.handleError(`occ-cluster-header-component.ts resultPost: ${e.message}`));
    }.bind(this));
  }
  /**
   * This method is  for getting the text from the store and display.
   */
  getText() {
    // if (this.langChange == false) {
    const val = JSON.parse(this.storageService.sessionStorageGet('Reducer_OccText'));
    if (val && val.Occ_Buttons_Reducer_Text && val.Occ_Buttons_Reducer_Text.common != undefined) {
      this.occClusterText = val;
    }
    // }
    const asmtEndurl = (this.restrictedAccountType) ? 'occ/cluster' : 'occ/cluster/default';
    this.dispatchStore.dispatch({
      type: 'GET_OCC_SETTING_TABS_TEXT', payload: {
        methodVal: 'GET', module_Name: 'Settings/v1/',
        path_params: (this.restrictedAccountType) ? ['accountID'] : [],
        query_params: { 'lang': this.langVal },
        body_Params: {}, endUrlVal: asmtEndurl
      }
    });
  }

  /**
   * This method is used in the mobile view dropdown.
   * @param name is for storing the tab value.
   * @param num is for storing the index value.
   */
  dropDownVal(name, num) {
    this.value = name;
    this.activeVal = num;

    const evnt = document.createEvent('CustomEvent');
    evnt.initEvent(name, true, true);
    this.occDispatch.dispatch(evnt);
  }
  /** This function is for saving the data as favorites */
  savePost() {
    try {
      this.utils.showLoading();
      this.apiJson.method = 'POST';
      this.apiJson.sessionID = this.storageService.sessionStorageGet('auth_key');
      this.apiJson.moduleName = 'Occ/v1/';
      const saveResult = {
        input_data: [
          {
            'param_type': 'path',
            'params': [this.accountID, 'favorites', this.clusterID]
          },
          {
            'param_type': 'query',
            'params': { 'lang': this.langVal }
          }
        ]
      };
      const user = JSON.stringify(saveResult);
      this.apiJson.endUrl = 'users';
      this.apiJson.data = user;
      this.occApiCall.callApi([this.apiJson]).subscribe((res) => {
        this.utils.hideLoading();
        const evnt = document.createEvent('CustomEvent');
        evnt.initEvent('PlanCompStatus', true, true);
        this.eventService.dispatch(evnt);
        this.snackbar.myFunction('Successfully added to favorites.');
      }, e => this.utils.handleError(`occ-cluster-header-component.ts savePost: ${e.message}`));
    } catch (e) {
      console.log('Saving as a favorite error-->' + e.message);
    }
  }
  /**
   *
   * @param check is used for checking from which career we came from.
   */
  CareerList(check) {
    this.storageService.sessionStorageSet('occShow', '0');
    this.storageService.sessionStorageSet('personalQua', 'no');
    this.router.navigate(['../occIndex'], { relativeTo: this.activatedRoute, queryParams: { chk: check, occclusinx: this.occclusinx } });
  }
  /**
   * This method is for navigating to the occlist page.
   */
  backAssessment() {
    if (this.storageService.sessionStorageGet('inTab') != 'cciAssess' && this.storageService.sessionStorageGet('inTab') != 'osAssess') {
      this.router.navigate(['../../' + this.storageService.sessionStorageGet('prevRoute') + '/occlist'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../../' + this.storageService.sessionStorageGet('prevRoute') + '/result'], { relativeTo: this.activatedRoute });
    }
  }
  clusterPrint() {
    //this.occutilities.showLoading();
    const evnt = document.createEvent('CustomEvent');
    switch (this.activeVal) {
      case 0: {
        this.occDispatch.clusterAtGlanceDispatch(evnt);
        break;
      }
      case 1: {
        this.occDispatch.clusterRighforMeDispatch(evnt);
        break;
      }
      case 2: {
        this.occDispatch.clusterEducationDispatch(evnt);
        break;
      }
      case 3: {
        this.occDispatch.clusterPaywayDispatch(evnt);
        break;
      }
      case 4: {
        this.occDispatch.clusterReatedCareerDispatch(evnt);
        break;
      }
    }
  }

  /**
   * This ngOnDestroy() function is call after Component destory.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.occIndexReducerSub.unsubscribe();
    this.Occ_Settings_Tab_Text.unsubscribe();
    this.Occ_Buttons_Reducer_Text.unsubscribe();
    this.outereventsubscription.unsubscribe();
    this.storageService.removeSessionIndexes(true);
  }
}

