<!-- print Start At-A-Glance -->
<div class="hidden-page">
  <div class="print-result-page" #atAGlancePrintPage>
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-occresize-sprite"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{logo}}">
      </div>
    </div>
    <div class="main-body">
      <div class="careers-title">{{tabTitle}}</div>
      <p>{{(occCareerStore).ShortDescription}}</p>
      <div class="row gutter-10 career-ataglance mb-4">
        <!-- Wages card  -->
        <div class="col-12 mb-2">
          <div class="p-0 card card-wages-height">
            <div class="p-0 card-block card-block-height-sm">
              <div class="d-flex align-items-center">                
                  <div class=" table-text col-5 text-center-graph text-left">
                    <div>
                    <b>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.SalaryRating}}</b>
                  </div>
                  {{(occCareerStore).SalaryRating?.rating}}
                  </div>
                <div class="mt-3 col-7">
                  <div *ngIf='((occCareerStore).SalaryRating?.ratingID==0)'  class= "icon-belowAverage-sprite ml-auto"></div>
                  <div *ngIf='((occCareerStore).SalaryRating?.ratingID==1)'  class="icon-wagesAverage-sprite ml-auto"></div>
                  <div *ngIf='((occCareerStore).SalaryRating?.ratingID==2)'  class="icon-aboveAverage-sprite ml-auto"></div>
                  <div class="pr-4 wages-salary-rating table-text">{{(occCareerStore).SalaryRating?.amount}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Education & Training card  -->
        <div class="col-6">
          <div class="card">
            <!-- Card that displays about EducationLevel-->
            <div class="card-block">
              <div class="mb-1 text-left table-text">
                <b>{{(settingsText).Occ_Settings_Tab_Text?.allSections?.EducationLevel}}</b>
              </div>
              <div class="stacked-bar-graph d-inline-flex">
                <span *ngFor="let item of [0,1,2,3,4]" [ngStyle]="(item == (occCareerStore).EducationLevel?.ratingID)? {'background-color':'#0d47a1'}:{}">&nbsp;
                  <p *ngIf="(item == (occCareerStore).EducationLevel?.ratingID)" class="edu-name-style">{{(occCareerStore).EducationLevel.rating}}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Employment Opportunities card  -->
        <div class="col-6">
          <div class="card">
            <!--  Card that displays about OpportunityRating -->
            <div class="card-block table-text">
              <div class="row gutter-10 text-center-graph text-md-left">
                <div class="col-8 text-left">
                  <div>
                    <b>{{(settingsText)?.Occ_Settings_Tab_Text?.allSections?.OpportunityRating}}</b>
                  </div>
                  {{(occCareerStore)?.OpportunityRating?.rating}}
                </div>
                <div class="col-4 p-0 text-center-graph">
                  <div class="eo-bar-chart p-0 float-right d-table w-100">
    
                    <li *ngFor="let item of [0,1,2];let inx=index">
                      <span [ngStyle]="(item == (occCareerStore)?.OpportunityRating?.ratingID)? {'background-color':'#0d47a1'}:{}"></span>
                    </li>
                  </div>
                </div>
                <p class="w-100 text-right">
                  {{(occCareerStore)?.OpportunityRating?.amount}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <div class="flex-fill w-50 pr-1">
          <!-- Card that describe about TopJobTasks-->
          <div class="career-card cardcolor-12 h-100">
            <div class="career-card-header">
              <div class="card-title pre">
                <i class="icon-job_list-sprite  panal-icon-align" aria-hidden="true"></i>
                {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TopJobTasks}}</div>
            </div>
            <div class="card-block career-card-body h-100">
              <ul class="list pl-4" style="color:white">
                <li *ngFor="let item of (occCareerStore).TopJobTasks;let inx=index;">
                   <p>{{(occCareerStore).TopJobTasks[inx]}} </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1">
          <!-- Card that describe about TopWorkSetting-->
          <div class="career-card cardcolor-11 h-100">
            <div class="career-card-header">
              <div class="card-title pre">
                <i class="icon-work_setting-sprite panal-icon-align" aria-hidden="true"></i>
                {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TopWorkSetting}}
              </div>
            </div>
            <div class="card-block career-card-body h-100">
              <ul class="list pl-4">
                <li class="pl-1" *ngFor="let item of (occCareerStore).TopJobTasks;let inx=index;">
                  <p>{{(occCareerStore).TopWorkSetting[inx]}}</p>
               </li>
              </ul>
            </div>
          </div>
        </div>      
      </div>  
      <div class="html2pdf__page-break"></div>  
      <div class="d-flex flex-wrap mt-5">
        <div class="flex-fill w-50 pr-1" *ngIf='showClusterBox'>
          <div class="career-card cardcolor-25 d-table w-100">
            <div class="card-block align-middle d-table-cell cluster-noun-text text-left" [ngStyle]="{'cursor':'default'}">
              <div class="d-flex flex-column h-100 justify-content-center" [ngClass]="(occCareerStore).ClusterDefinition?'w-50 m-auto':''">
                <div *ngFor="let value of (occCareerStore).ClusterDefinition">
                  <div class="text-left px-3 text-white header_3" *ngIf="(value.type == 'h')">
                    <b>{{value.text}}</b>
                  </div>
                  <p class="text-left mb-0" *ngIf="(value.type == 'p')">
                    <i>{{value.text}}</i>
                  </p>
                  <ol *ngIf="(value.type == 'ol')" class="text-left">
                    <li *ngFor="let val of value.text">
                      <div class="font-weight-bold">{{val}}</div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1">
          <!-- Card that describe about parant cluster-->
          <div class="career-card d-table w-100" [ngStyle]="{'background-color': (occCareerStore)?.ParentCluster?.parentClusterColor}">
            <div class="card-block align-middle d-table-cell cluster-noun-text text-center">
              <div class="d-flex flex-column h-100 justify-content-center">
                <div class="mt-1">
                  <div class="header_5 text-white font-weight-bold">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.ParentCluster}}
                  </div>
                  <div class="{{(occCareerStore).ParentCluster?.parentClusterIcon}}_white-sprite m-auto"></div>
                  <!-- <i class="{{(occCareerStore).ParentCluster?.parentClusterIcon}} " aria-hidden="true"></i> -->
                </div>
                <div class="header_5 text-white font-weight-bold" [ngStyle]="{'-webkit-line-clamp': '3'}">
                  {{(occCareerStore).ParentCluster?.linkTitle}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-fill w-50 pl-1" *ngIf='!showClusterBox'>
          <div class="career-card cardcolor-25 w-100">
            <div class="card-block align-middle p-2 d-flex justify-content-center flex-column text-left"
              [ngStyle]="{'cursor':'default'}">
              <div class="career-cluster-panel pb-3" *ngIf="occCareerStore.HotJob.rating">
                <div class="icon-hot_jobs-sprite mx-auto"></div>
                <div class="career-cluster-text text-center" [innerHTML]="occCareerStore.HotJob.flyover |BoldPipe"></div>
              </div>
              <div class="career-cluster-panel pb-3" *ngIf="occCareerStore.STEM.rating">
                <div class="icon-stem-sprite mx-auto"></div>
                <div class="career-cluster-text text-center" [innerHTML]="occCareerStore.STEM.flyover |BoldPipe"></div>
              </div>
              <div class="career-cluster-panel" *ngIf="occCareerStore.SelfEmployment.rating">
                <div class="icon-Self_Employment-sprite mx-auto"></div>
                <div class="career-cluster-text text-center" [innerHTML]="occCareerStore.SelfEmployment.flyover |BoldPipe"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="html2pdf__page-break"></div> -->
    <div class="mt-2 pt-2 text-center">
      <p class="pt-2 border-top">{{frameworkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div>
<!--At-A-Glance print Ending-->