

  <div class="modal-header" style="color: #0b8cbc;" data-keyboard="false">
      <h5 class="modal-title" [hidden]="!deleteValueTxt"> Delete Answer Set {{answerSet_txt}}  </h5>
      <h5 class="modal-title" [hidden]="deleteValueTxt"> {{(modalCommonText)?.commonText[headsection]}} {{answerSet_txt}}</h5>

      </div>
      <div class="modal-body"><h6 id="thought">{{((modalCommonText)?.commonText[enter_thought_txt])}}</h6><p  [hidden]="showcomponent">
      <textarea class="form-control popup-textarea-plp2" rows="5" aria-label="save-text" name="save-text" id="comment" placeholder="" [(ngModel)]="str"></textarea></p>
     <p [hidden]="!showcomponent" >{{delete_sure_txt}}</p>
     <p style="color: #FF0000; font-size:14px;" [hidden]="!showcomponent">{{action_undone_txt}}</p>
     </div>
      <div class="modal-footer">
      <button id="save" type="button" [attr.aria-label]='(modalCommonText)?.commonText[yesbtn]' class="{{classProperty}}"  (click)=" assessmentCommonAction()" >{{(modalCommonText)?.commonText[yesbtn]}}</button>
      <button id="cancel" type="button" [attr.aria-label]='(modalCommonText)?.commonText[nobtn]' class="btn-common btn-warning" (keydown)="keydownInput($event)" (click)="activeModal.close('Close click')">{{(modalCommonText)?.commonText[nobtn]}}</button>
      </div>
    