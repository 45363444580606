<div class="p-t-b  mobie-width mobile-container bodypadding">
  <div class="row align-items-center py-2">
    <!-- For displaying the cluster name dynamicaly in header -->
    <div class="col-md-5">
      <div class="career-heading" #occClusterFocus>
        <i [ngClass]="clusterIcon" [ngStyle]="{'color': clusterColor}"></i>
        <div class="career-text ">{{clusterName}} {{(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.cluster}}</div>
      </div>
    </div>
    <div class="col-md-7 text-right">
      <div class=" pull-right desktop-design-display">
        <!-- careerlist button occurs here -->
        <button type="button" [attr.aria-label]='(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.careersList'
          class="common-button mb-0 green" (click)="CareerList('no')">
          <b>{{(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.careersList}}</b>
        </button>
        <!-- save button occurs here -->
      
        <!-- backAssessment button occures here -->
        <button type="button" [attr.aria-label]='(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.backAssess'
          class="common-button mb-0 green" *ngIf="backAssessmentValue" (click)="backAssessment()">
          <b>{{(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.backAssess}}</b>
        </button>
        <!-- print button occures here -->
        <button type="button" class="common-button mb-0 green" (click)="clusterPrint()">
          <b>{{(occClusterText).Occ_Buttons_Reducer_Text?.common?.print}}</b>
        </button>
      </div>
      <div class="btn-group btn-group-justified mobile-design-display">
        <!-- careerslist button occures here in mobile view-->
        <button type="button" [attr.aria-label]='(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.careersList'
          class="btn common-button green" (click)="CareerList('no')">
          <b>{{(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.careersList}}</b>
        </button>
        <!-- backassessment button occures here in mobile view -->
        <button type="button" [attr.aria-label]='(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.backAssess'
          class="btn common-button green" *ngIf="backAssessmentValue" (click)="backAssessment()">
          <b>{{(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.backAssess}}</b>
        </button>
      </div>
    </div>
  </div>
  <!-- {{(occClusterStore|async)|json}} -->

  <div class="row">
    <div class="col-lg-12">
      <div *ngIf='(clussettingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image")!=-1'>
        <!--Here we display the image for cluster -->
        <a>
          <img alt=" " src="{{(occClusterStore|async)?.Image}}" class="w-100" /> </a>
        <div class="rate-career-bg-black"></div>
        <div class="opacity-career-header row">
          <div class="col-lg-5  col-2 col-md-6 col-sm-2">

            <ng-template #tipContent>{{(occClusterText)?.Occ_Buttons_Reducer_Text?.common?.video}}</ng-template>
            <a class="video-icon"
              [attr.aria-label]='"video-icon " + (occClusterText)?.Occ_Buttons_Reducer_Text?.common?.video'
              href="#videomodal" data-toggle="modal" data-target="#videomodal"
              [hidden]="((occClusterStore|async)?.Video|json) == '[]'" #t="ngbTooltip" (blur)='t.close()'
              placement="top" [ngbTooltip]="tipContent">
              <img alt="video-icon" class="video-hight-plp3 pointer" src="{{CareersImages}}video-icon-plp3.png" />
            </a>
          </div>
          <div class="modal fade" data-backdrop="static" data-keyboard="false" id="videomodal" tabindex="-1"
            role="dialog" aria-labelledby="videomodal" aria-hidden="true">
            <div class="modal-dialog modal-width" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close plp3-model-close-align" (click)="videostop()" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" *ngFor="let video of (occClusterStore|async)?.VideoWithText">
                  <video *ngIf="video.textURL; else notrack" crossorigin="anonymous" disablePictureInPicture
                    class="video-box" controls controlsList="nodownload" #videoplayer>
                    <source src="{{video.videoURL}}" type="video/mp4" />
                    <track src="{{video.textURL}}" type="text/vtt" kind="subtitles" srclang="en" label="On"
                      default />
                  </video>
                  <ng-template #notrack>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-7 col-md-6 col-sm-10 col-10">
            <div class="row text-right pull-right">
              <!-- For showing the userrating icons -->
              <div class="d-inline-flex" *ngIf="restrictedAccountType">
                <p class="rate-this-text">{{(clussettingsText)?.Occ_Settings_Tab_Text?.allSections?.UserRating}}</p>
                <span class="rating-up-down mr-2">
                  <a href="#" aria-label="Thumbs up icon" (click)="$event.preventDefault();getUnFill('up')">
                    <i class="fillstroke" [ngClass]="(showColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"></i>
                  </a>
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="career-tabs">
        <!-- For showing the related text and tab values in the desktop view.  -->
        <div class="ngb-tabset desktop-design-display">
          <ul class="nav nav-pills justify-content-start  menu-alignment-desc-cluster-plp3" role="tablist">
            <li *ngFor='let val of (clussettingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index' class="nav-item"
              role="tab">
              <a class="nav-link" [class.active]="(activeVal==inx)" (click)="dropDownVal(val.title,inx)"
                href="#tab{{inx}}" [attr.aria-label]='(activeVal==inx)? val.title +" selected ":val.title'
                data-toggle="pill">{{val.title}}</a>
              <div class="tab-down-arrow"></div>
            </li>
            <div align="center" class="rating-in-tabs"
              *ngIf='(clussettingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image") ==-1'>
              <div class="d-inline-flex" *ngIf="restrictedAccountType">
                <p class="rate-this-text">{{(clussettingsText)?.Occ_Settings_Tab_Text?.allSections?.UserRating}}</p>
                <span class="rating-up-down rating-down-2">
                  <a href="#" (click)="$event.preventDefault();getUnFill('up')">
                    <i class="fillstroke" [ngClass]="(showColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"
                      aria-hidden="true"></i>
                  </a>
                  <a href="#" (click)="$event.preventDefault();getUnFill('down')">
                    <i class="fillstroke" [ngClass]="(showColorUp == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'"
                      aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
          </ul>
        </div>
        <!-- For showing the related text and tab values in the mobile view. -->
        <div class="ngb-tabset-mobile mobile-design-display  mb-3">
          <div class="d-table w-100">
            <ul class="nav nav-pills d-table-cell">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle font-size" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">{{value}}</a>
                <div class="dropdown-menu" role="tablist">
                  <a *ngFor='let val of (clussettingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index'
                    class="nav-link active" [class.active]="(activeVal==inx)" (click)="dropDownVal(val.title,inx)"
                    data-toggle="tab" href="#tab{{inx}}" role="tab" [attr.aria-label]='val.title'>{{val.title}}</a>
                </div>
              </li>
            </ul>
            <div class=" d-table-cell"
              *ngIf='(clussettingsText)?.Occ_Settings_Tab_Text?.topSections?.indexOf("Image") ==-1'>
              <div align="center" class="rating-in-tabs">
                <div class="d-inline-flex" *ngIf="restrictedAccountType">
                  <span class="rating-up-down rating-down-2">
                    <a href="#" (click)="$event.preventDefault();getUnFill('up')">
                      <i class="fillstroke" [ngClass]="(showColorUp == 1)?'icon-thumbs-up-hover':'icon-thumbs-up'"
                        aria-hidden="true"></i>
                    </a>
                    <a href="#" (click)="$event.preventDefault();getUnFill('down')">
                      <i class="fillstroke" [ngClass]="(showColorUp == 0)?'icon-thumbs-down-hover':'icon-thumbs-down'"
                        aria-hidden="true"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- For showing the tab values in the mobile view -->
        <div class="tab-content ">
          <div *ngFor='let val of (clussettingsText)?.Occ_Settings_Tab_Text?.tabs;let inx=index' class="tab-pane"
            id="tab{{inx}}" [class.active]="(activeVal==inx)" role="tabpanel">
            <div *ngIf='val.tabId == 0'>
              <at-a-glance-cluster [atAGlance]="refGlance" [logo]="logo"
                [clusterTitle]="clusterName +' '+ (occClusterText)?.Occ_Buttons_Reducer_Text?.common?.cluster"
                [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></at-a-glance-cluster>
            </div>

            <div *ngIf='val.tabId == 1'>
              <right-for-me [rightForMeData]="refRightForMe" [logo]="logo"
                [clusterTitle]="clusterName+' ' + (occClusterText)?.Occ_Buttons_Reducer_Text?.common?.cluster"
                [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></right-for-me>
            </div>
            <div *ngIf='val.tabId == 2'>
              <education [educationData]="refeducation" [logo]="logo"
                [clusterTitle]="clusterName+' ' + (occClusterText)?.Occ_Buttons_Reducer_Text?.common?.cluster"
                [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></education>
            </div>
            <div *ngIf='val.tabId == 3'>
              <pathways [pathwaysData]="refpathway" [logo]="logo"
                [clusterTitle]="clusterName+' '+ (occClusterText)?.Occ_Buttons_Reducer_Text?.common?.cluster"
                [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></pathways>
            </div>
            <div *ngIf='val.tabId == 4'>
              <related-careers-cluster [relatedcareersData]="refrelatedcareer" [logo]="logo"
                [clusterTitle]="clusterName+' ' + (occClusterText)?.Occ_Buttons_Reducer_Text?.common?.cluster"
                [tabTitle]="val.title" [frameWorkdata]="frameworkObj"></related-careers-cluster>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Here save,print floating button occures in mobile view. -->
    <div class="mobile-design-display  float-btn-plp3">
      <div class=''>
        <button aria-label="floating button" class='action-button' (click)="filter = !filter"
          [ngClass]="{'active': filter }">
          <span aria-hidden class='fa fa-plus'></span>
        </button>
        <ul class='actions'>
          <li class="pointer" (click)="clusterPrint()">
            <a>
              <span class='fa fa-print'></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <static-occ-footer class="footerheightlength"></static-occ-footer> -->