
/** Angular imports */
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/** rxjs imports */
// import { Observable } from 'rxjs/Rx';





/** Third party imports  */

import { ApiCallClass } from '../modal/apicall.modal';
import { StorageService } from '../outer-services/storage.service';
import { Utilities } from './utilities.service';
import { EventDispatchService } from './event-dispatch.service';
import { CommonExtendTime } from '../../Utilities/extend-time.service';
import { map, catchError } from 'rxjs/operators';

import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable } from 'rxjs/internal/Observable';

let eventTime;

@Injectable()
export class ServerApi implements OnDestroy {
  newSessionId;
  sessionOutTime;
  transText = {};
  constructor(
    private http: HttpClient,
    private router: Router,
    private utils: Utilities,
    private storageService: StorageService,
    private eventService: EventDispatchService,
    private extendTimeForUser: CommonExtendTime
  ) {}
  public extractData = function (res: any) {
    const body = res;
    // console.log('succes bodey-->'+JSON.stringify(body));
    eventTime = body.eventTime;
    window.localStorage.setItem('formioToken', body.sessionID);

    // this was overriding the post auth JWT.
    // @todo investigate possible use casses for setting auth_key here.
    // this.storageService.sessionStorageSet('auth_key', body.sessionID);
    this.storageService.sessionStorageSet('auth_key', body.sessionID);
    try {
      this.extendTimeForUser.entendTimeForUser(res);
    } catch (e) {
      console.log('exc==>' + e.message);
    }
    if (body.sessionID) {
      window.localStorage.setItem('formioToken', body.sessionID);

      // this was overriding the post auth JWT.
      // @todo investigate possible use casses for setting auth_key here.
      // this.storageService.sessionStorageSet('auth_key', body.sessionID);
      this.storageService.sessionStorageSet('auth_key', body.sessionID);
      /*Removed extendExpiration here. Beacause it is implemented in line number 185*/
      // try {
      //     this.extendExpiration(body, eventTime, this);
      // } catch (e) {
      //     console.log('exc==>' + e.message);
      // }
    }
    return body || {};
  }.bind(this);
  sessionExpired = function () {
    // @todo hit logout route here with jwt?  If the server redirects then pass logoutURL too
    this.utils.sessionExpired();
    /*this.utils.sessionStorageSet('sessionExp', 'true');

        let logoutURL = this.utils.sessionStorageGet('LogoutURL');
        if (logoutURL != '' && logoutURL != null && logoutURL != undefined) {
            this.router.navigateByUrl(logoutURL);
        }
        else {
            this.router.navigateByUrl('login/loginForm?status=expired');// window.location.href = 'login/loginForm?status=expired';
        }*/
  }.bind(this);

  callAuthApi(data): Observable<any> {
    if (data[0].method == 'POST') {
      // let headers = new Headers({ 'Content-Type': 'application/json' });

      // let options = new RequestOptions({ headers: headers });
      try {
        return this.http
          .post('/auth/authPost', { data: data[0] })
          .pipe(map(this.extractAuthData));
      } catch (e) {
        this.utils.handleError(
          `app.apicall.service.ts callAuthApi post: exception ${e.message}`
        );
      }
    } else {
      const observableBatch = [];
      try {
        // let headers = new Headers({ 'Cache-Control': 'no-cache' });

        // let options = new RequestOptions({ headers: headers });
        data.forEach((componentarray, inx) => {
          const str = Object.keys(componentarray)
            .map(function (key) {
              return (
                encodeURIComponent(key) +
                '=' +
                encodeURIComponent(componentarray[key])
              );
            })
            .join('&');
          observableBatch.push(
            this.http
              .get('/auth/authGet?' + str)
              .pipe(map(this.extractAuthData))
          );
        });
      } catch (e) {
        this.utils.handleError(
          `app.apicall.service.ts callAuthApi get: exception ${e.message}`
        );
      }
      return forkJoin(observableBatch);
    }
  }

  extractAuthData(res: Response) {
    const body = res.json();
    return body || {};
  }

  callLogin(data): Observable<any> {
    // try {
    const str = Object.keys(data)
      .map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
      })
      .join('&');
    return this.http.get('/loginApi/' + data.endUrl + '?' + str).pipe(
      map(this.extractData),
      catchError((e) =>
        this.utils.handleError(`app.apicall.service.ts callLogin: ${e.message}`)
      )
    );
    //   }
  }

  // This service is called each time the client tries to hit the server api after login
  callApi(data): Observable<any> {
    // alert('call with data:' + JSON.stringify(data));
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Cache-Control': 'not-store, no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      }),
    };
    if (data[0].method == 'POST') {
      // let headers = new Headers({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.utils.getAuthKey() });

      // let options = new RequestOptions({ headers: headers });
      try {
        return this.http.post('/api/postCall', { data: data[0] }).pipe(
          map(this.extractData),
          catchError((e) =>
            this.utils.handleError(
              `app.apicall.service.ts callApi post: ${e.message}`
            )
          )
        );
      } catch (e) {
        this.utils.handleError(
          `app.apicall.service.ts callApi post: exception ${e.message}`
        );
      }
    } else {
      const observableBatch = [];
      try {
        // let headers = new Headers({ 'Cache-Control': 'no-cache', 'Authorization': 'Bearer ' + this.utils.getAuthKey() });
        // let options = new RequestOptions({ headers: headers });
        data.forEach((componentarray, inx) => {
          const str = Object.keys(componentarray)
            .map(function (key) {
              return (
                encodeURIComponent(key) +
                '=' +
                encodeURIComponent(componentarray[key])
              );
            })
            .join('&');
          // console.log('str--->'+str+'----componentarray---->'+componentarray);
          observableBatch.push(
            this.http.get('/api/getCall?' + str, httpOptions1).pipe(
              map(this.extractData),
              catchError((e) =>
                this.utils.handleError(
                  `app.apicall.service.ts callApi get: ${e.message}`
                )
              )
            )
          );
          // .subscribe(data => { alert(data);},  this.handleError,  () => console.log('done'))
        });
      } catch (e) {
        this.utils.handleError(
          `app.apicall.service.ts callApi get: exception ${e.message}`
        );
      }
      return forkJoin(observableBatch);
    }
  }
  callDynamicPlpApi(data): Observable<any> {
    // alert('call with data:' + JSON.stringify(data));
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Cache-Control': 'not-store, no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      }),
    };
    const observableBatch = [];
    try {
      data.forEach((componentarray, inx) => {
        observableBatch.push(
          this.http.get(data[0].endUrl, httpOptions1).pipe(
            map(this.extractData),
            catchError((e) =>
              this.utils.handleError(
                `app.apicall.service.ts post: ${JSON.stringify(
                  e,
                  Object.getOwnPropertyNames(e)
                )}`
              )
            )
          )
        );
      });
    } catch (e) {
      alert('post exception:' + e.message);
    }
    return forkJoin(observableBatch);
  }
  async getUserSummery() {
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Cache-Control': 'not-store, no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      }),
    };
    const endUrl =
      '/Dynamicicap/UserSummary/' +
      this.utils.getAccountId() +
      '?isForCis360=true&stateAbbr=stateAbbr&lang=en';
    return await this.http.get(endUrl, httpOptions1).toPromise();
    // return this.server.callDynamicPlpApi([this.apiJson]).pipe(map((result: PlanUserSummaryState["planSummary"]) => {
    //     return new SetPlanSummaryDataAction(result);

    // }))
    //    return this.http.get(endUrl, httpOptions1).pipe(map(this.extractData), catchError(this.utils.handleError))
  }
  getStateAbbr(): Observable<any> {
    try {
      //  let headers = new Headers({ 'Cache-Control': 'no-cache', 'Authorization': 'Bearer ' + this.utils.getAuthKey() });
      //  let options = new RequestOptions({ headers: headers });
      //  let observableBatch = [];

      return this.http
        .get('/api/getRcState')
        .pipe(map(this.extractData), catchError(this.utils.handleError));
    } catch (e) {
      alert('getState get exception:' + e.message);
    }
  }

  sessionExpiry() {
    this.sessionExpired();
  }
  ngOnDestroy() {}
}


