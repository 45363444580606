<div role="tablist" aria-multiselectable="true">
  <!-- Displays overview card -->

    <p class="header_5 header-text-color">
      <b>{{careerJrCareerStore?.ThingsToKnowQuestion}}</b>
    </p>

  <div class="career-card cardcolor-5" *ngIf='(settingsTextTab?.sections).indexOf("Overview")!= -1 && (careerJrCareerStore?.Overview?.length!==0)'>
    <div class="career-card-header  pointer" role="tab" id="headingOne" activeIds="1" (click)="(expandCard != 6)?methodfilter(6):methodfilter(999)">
      <div class="card-title">
        <i class="icon-overview panal-icon-align" aria-hidden="true"></i>
        <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Overview}}</span>
      </div>
      <div class="d-table-cell align-middle ">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 6)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 6)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="expandCard == 6" class="icon-up-rounded-arrow icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="1" #filter1 *ngIf="expandCard == 6" role="tabpanel" aria-labelledby="headingOne">
      <div class="card-block">
        <div *ngFor="let value of careerJrCareerStore?.Overview">
          <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
          <ul *ngIf="(value.type == 'ul')" class="list pr-4">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Displays Job task card-->
  <div class="career-card cardcolor-12" *ngIf='(settingsTextTab?.sections).indexOf("TaskList")!= -1 && (careerJrCareerStore?.TaskList?.length!==0)'>
    <div class="career-card-header  pointer" role="tab" id="headingThree" (click)="(expandCard != 5)?methodfilter(5):methodfilter(999)">
      <div class="card-title">
        <i class="icon-job-list panal-icon-align" aria-hidden="true"></i>
        <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.TaskList}}</span>
      </div>
      <div class="d-table-cell align-middle ">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 5)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 5)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="expandCard == 5" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="5" #filter3 *ngIf="expandCard == 5" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (careerJrCareerStore)?.TaskList">
          <div class='header_6 text-white' *ngIf="(value.type == 'p')">
            <p [innerHtml]="value.text | MarkdownToLink"></p>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list-unstyled">
            <li class="findjobs-text" *ngFor="let val of value.text">
              <p class="mb-0 anchorclass" [innerHtml]="val | MarkdownToLink" (click)="navigateCluster(val)"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Displays Skills & Abilities card-->
  <div class="career-card cardcolor-9" *ngIf='(settingsTextTab?.sections).indexOf("SkillsAbilities")!= -1 && (careerJrCareerStore?.SkillsAbilities?.length!==0)'>
    <div class="career-card-header pointer" role="tab" id="headingThree" (click)="(expandCard != 3)?methodfilter(3):methodfilter(999)">
      <div class="card-title">

        <i class="icon-skills-abilities panal-icon-align" aria-hidden="true"></i>

        <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.SkillsAbilities}}</span>

      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 3)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 3)" class="icon-down-rounded-arrow  icn-font-size"></i>
          <i *ngIf="expandCard == 3" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="3" #filter3 *ngIf="expandCard == 3" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (careerJrCareerStore)?.SkillsAbilities">
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list pr-4">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>



</div>
<!-- print Start Job desc -->
<!-- Start Header -->
<div class="hidden-page">
  <div class="print-result-page" #atJobdescPrintPage> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="{{clusterIcon}}_color-sprite"></div>
        <span>{{clusterTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{mainlogo}}" width="50%"> 
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <p class="header_5 header-text-color">
        <b>{{careerJrCareerStore?.ThingsToKnowQuestion}}</b>
      </p>
      <!-- Displays overview card -->
      <div class="career-card text-dark" *ngIf='(settingsTextTab?.sections).indexOf("Overview")!= -1 && (careerJrCareerStore?.Overview?.length!==0)'>
        <div class="cardcolor-5 break-avoid"> 
          <div class="career-card-header">
            <div class="card-title">
              <div class="icon-overview-sprite panal-icon-align" aria-hidden="true"></div>
              <span class="w-100" tabindex="0">{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Overview}}</span>
            </div>      
          </div>
        </div>              
        <div class="pt-1 pl-3">
          <div *ngFor="let value of (careerJrCareerStore)?.Overview break-avoid">
            <p *ngIf="(value.type == 'p')">{{value.text}}</p>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4">
              <li *ngFor="let val of value.text">
                <div class="headding-arrow pl-2 mb-1">{{val}}</div>
              </li>
            </ul>
            <div class='header_6' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
          </div>
        </div>          
      </div>
      <!-- Displays Job task card-->
      <div class="career-card text-dark mt-4 pt-1" *ngIf='(settingsTextTab?.sections).indexOf("TaskList")!= -1 && (careerJrCareerStore?.TaskList?.length!==0)'>
          <div class="cardcolor-12 break-avoid">
            <div class="career-card-header">
              <div class="card-title">
                <div class="icon-job_list-sprite panal-icon-align" aria-hidden="true"></div>
                <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.TaskList}}</span>
              </div>        
            </div>
          </div>
          <div>
            <div class="pt-1 pl-3">
              <div *ngFor="let value of (careerJrCareerStore)?.TaskList">
                <div class='header_6' *ngIf="(value.type == 'p')">
                  <p>{{value.text}}</p>
                </div>
                <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4 mb-2">
                  <li *ngFor="let val of value.text" class="mb-0">
                  <p class="mb-0" [innerHTML]='val | constructText'></p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>  
      <!-- <div class="html2pdf__page-break"></div> -->
      <!-- Displays Skills & Abilities card-->
      <div class="career-card text-dark mt-2 break-avoid" *ngIf='(settingsTextTab?.sections).indexOf("SkillsAbilities")!= -1 && (careerJrCareerStore?.SkillsAbilities?.length!==0)'>
        <div class="cardcolor-9">
          <div class="career-card-header cardcolor-9">
            <div class="card-title">        
              <div class="icon-skills_abilities-sprite panal-icon-align" aria-hidden="true"></div>          
              <span class="w-100">{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.SkillsAbilities}}</span>          
            </div>          
          </div>
        </div>
  
        <div class="pt-1 pl-3">
          <div class="break-avoid" *ngFor="let value of (careerJrCareerStore)?.SkillsAbilities">
            <div class='header_6' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled list pr-4 mb-2">
              <li *ngFor="let val of value.text;let inx=index" class="mb-1">
                <div class="mb-1">{{val}}</div>
              </li>
            </ul>
          </div>
        </div>            
      </div>   
    </div>
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj?.Result?.headerFooter?.copyright}}</p>
    </div>
  </div>
</div><!--Job Description print Ending-->
