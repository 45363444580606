<div class="rc-assessment-popup modal-content modal-color">
  <div class="modal-header text-white py-2 px-3" >
  <h4 class="modal-title">{{header}}</h4>
  <button type="button" class="modal-close text-white" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="font-weight-bold my-1">{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark text-white" (click)="activeModal.close('Close click')">Close</button>
</div>
</div>
    