import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { Utilities } from './utilities.service';
import { ApiCallClass } from '../modal/apicall.modal';
import { StorageService } from './storage.service';
import { CommonExtendTime } from '../../Utilities/extend-time.service';
import { catchError, map } from 'rxjs/operators';
import { PracticeTestType } from '../../modules/practice-test/models/practiceTestType.model';
import { PracticeTestAccessUrlResponse } from '../../modules/practice-test/models/practiceTestAccessUrlResponse';

let expiredTime, eventTime;

@Injectable()
/** This service is used to track all the popup events and dispatch click events */
export class DashboardApiCallService {
  constructor(
    private http: HttpClient,
    private utils: Utilities,
    private apiJson: ApiCallClass,
    private storageService: StorageService,
    private apiJson1: ApiCallClass,
    private extendTimeForUser: CommonExtendTime
  ) {
    /**PLEASE KEEP this testing code for Testpres */
    // const temp = new PracticeTestAccessUrlResponse();
    // temp.isSuccessful = true;
    // temp.myItem =
    //   'https://gateway.petersons.com/gateway_redirect?returnURL=https%3A%2F%2Flearn.petersons.com%2Fd2l%2Flp%2Fauth%2Flogin%2FssoLogin.d2l%3Fguid%3DuEhCoUu0G4pPMNsdaaSCws8H9GcHKe9KqiHMwhdHcp-Q49tB88_E-usYJ_buO2e_giPGZY_nE7qY_r_pjVSLMpjiGuK1lfAJmnw97r71oiqEx2dV_rqYILJjMUjdMYnXA0X_a5awSjeM5mh39SqbpE3y9DrF-lAhlW72jselnKiVmkOzlFVqx_WOiUDAgEkGuzcfISkCGcI6YPS0T9R6db4YRMMPpNaMASdxr7wDrJXitD971hzjt8Dv8PleVVL1xFAmi3JxddCakPHQvmZvVNGQlqCA77OlefrhROFnjq2B00jRMQGqk1bAXxNH0Rsdx1TkZI_fDAUZeTXiPtmC3BpbHTCeoVqxMBxIhpMS40heU26tkHMwp24YNuJsNqGYgrnkBFd5NFj5y4SXpAGj_T4odhT9dKdPeaBRknTNh0B05FFZ-0Kbvh91U4fcpQqzWCZQp1kejy9KMAFu0cjN6CuMFedqEq7TrZ2nvmW0RE9gMwYgkoleer3sEp1B_rMWm1yAuuNuHZcnIhPeWvzum9SlSc75Xg0q9GAfmDcvTcgb9sefAkTlVopsxAPMRBza41r7BCCv9nCOuhHlha3m0wmJtgxnWYdsymO-OEwO4Euw_FTuanUEcDBIakMhlsDiFU_ZHm5tfGE-g6f8MqjTUQ..%26orgId%3D513978%26orgDefinedId%3D10.MN90944&userId=56139&clientCourseId=2134&schoolCourseId=58281';
    // this.message2$ = of(temp);
  }
  newSessionId;
  sessionOutTime;
  transText = {};
  message2$: Observable<PracticeTestAccessUrlResponse>;
  /*Here the headers are clearing the cache because the updated favorite is not show in IE browser .
 Every new api hitting IE browser is loading local cache */
  httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'not-store, no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    }),
  };
  httpOptionsWithResponseType: Object = {
    headers: new HttpHeaders({
      'Cache-Control': 'not-store, no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    }),
    responseType: 'text' as 'json'
  };
  extractAuthData(res: Response) {
    const body = res.json();
    return body || {};
  }
  /* APi endpoint for favourites for all the career and education modules*/
  getFavourites() {
    try {
      return this.http
        .get('/favDashboard/favList?isForCis360=true&lang=en', this.httpOptionsWithResponseType)
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts getFavourites: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getFavourites: exception ${e.message}`
      );
    }
  }
  /* APi endpoint for delete favourites for all the career and education modules*/
  removeFavourites(fileType, fileId) {
    try {
      return this.http
        .delete(
          `/favDashboard/favDelete/${fileType}/${fileId}?isForCis360=true&lang=en`,
          { responseType: 'json' }
        )
        .pipe(
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts removeFavourites: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts removeFavourites: exception ${e.message}`
      );
    }
  }
  /* APi endpoint for save favourites notes for all the career and education modules*/
  saveFavouriteNotes(fileId, fileType, notes) {
    try {
      const saveNotes = {
        fileId: fileId,
        fileType: fileType,
        note: notes,
      };
      // console.log('saveFavouriteNotes',saveNotes);

      return this.http
        .put(
          `/favDashboard/favNotes?isForCis360=true&lang=en`,
          saveNotes,
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts saveFavouriteNotes: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts saveFavouriteNotes: exception ${e.message}`
      );
    }
  }
  /* APi endpoint for save favourites notes for all the career and education modules*/
  saveAnswerSetNotes(sortId, answerSetId, notes) {
    try {
      const saveAnswersetNotes = {
        sortId: sortId,
        answerSetId: answerSetId,
        note: notes,
      };

      return this.http
        .put(
          `/favDashboard/answerSetNotes?isForCis360=true&lang=en`,
          saveAnswersetNotes,
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts saveAnswerSetNotes: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts saveAnswerSetNotes: exception ${e.message}`
      );
    }
  }
  dashboardAssessmentPageText() {
    try {
      return this.http
        .get(
          '/favDashboard/dashboardPageText?isForCis360=true&lang=' +
          this.storageService.sessionStorageGet('langset'),
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts dashboardAssessmentPageText: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts dashboardAssessmentPageText: exception ${e.message}`
      );
    }
  }
  dashboardPersonalInfo() {
    try {
      return this.http
        .get(
          '/favDashboard/dashboardPersonalInfo?isForCis360=true&lang=en',
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts dashboardPersonalInfo: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts dashboardPersonalInfo exception: ${e.message}`
      );
    }
  }
  dashboardBestCareers() {
    try {
      return this.http
        .get(
          '/favDashboard/bestCareersmatch?isForCis360=true&lang=en',
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts dashboardBestCareers: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts dashboardBestCareers: exception ${e.message}`
      );
    }
  }
  getDashboardAssementResult() {
    try {
      return this.http
        .get(
          '/favDashboard/assessmentResult?isForCis360=true&lang=en',
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts getDashboardAssessmentResult: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts dashboardAssessmentResult: exception ${e.message}`
      );
    }
  }
  getAssessmentAnswersets(sortId) {
    try {
      return this.http
        .get(
          `/favDashboard/asmtAnswerSets/${sortId}?isForCis360=true&lang=en`,
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts getAssessmentAnswersets: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getAssessmentAnswersets: exception ${e.message}`
      );
    }
  }
  deleteAnserSet(AnswerSetId, sortId) {
    try {
      return this.http
        .delete(
          `/favDashboard/deleteAnswerSet/${sortId}/${AnswerSetId}?isForCis360=true&lang=en`,
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts deleteAnswerSet: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts deleteAnswerSet: ${e.message}`
      );
    }
  }
  getAccountSettingPageText() {
    try {
      return this.http
        .get(
          '/favDashboard/accountSettingPageText?isForCis360=true&lang=en',
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts getAccountSettingPageText: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getAccountSettingPageText: exception ${e.message}`
      );
    }
  }
  getAccountSettingImage() {
    try {
      return this.http
        .get('/favDashboard/accountSettingImages', this.httpOptionsWithResponseType)
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts getAccountSettingImage: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getAccountSettingImage: exception ${e.message}`
      );
    }
  }
  getPortfolioSettings() {
    try {
      return this.http
        .get(
          '/favDashboard/getPortfolioSettings?isForCis360=true&lang=en',
          this.httpOptionsWithResponseType
        )
        .pipe(map(this.extractDataWithParsing),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts getPortfolioSettings: ${e.message}`
            )
          )
        );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getPortfolioSettings: exception ${e.message}`
      );
    }
  }
  getEnterprise() {
    try {
      if (this.storageService.sessionStorageGet('LogoutURL')) {
        return this.http
          .post(
            `/favDashboard/LTIEnterprise?isForCis360=true&lang=en`,
            { logoutUrl: this.storageService.sessionStorageGet('LogoutURL') },
            { responseType: 'blob' }
          )
          .pipe(
            catchError((e) =>
              this.utils.handleError(
                `dashBoard-api-calls.service.ts getEnterprise post: ${e.message}`
              )
            )
          );
      } else {
        return this.http
          .get('/favDashboard/goToEnterprise?isForCis360=true&lang=en', {
            responseType: 'blob',
          })
          .pipe(
            catchError((e) =>
              this.utils.handleError(
                `dashBoard-api-calls.service.ts getEnterprise get: ${e.message}`
              )
            )
          );
      }
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getEnterprise: exception ${e.message}`
      );
    }
  }
  callAPiDashBoard(data) {
    const observableBatch = [];
    data.forEach((componentarray, inx) => {
      const str = Object.keys(componentarray)
        .map(function (key) {
          return (
            encodeURIComponent(key) +
            '=' +
            encodeURIComponent(componentarray[key])
          );
        })
        .join('&');
      // console.log('str--->'+str+'----componentarray---->'+componentarray);
      observableBatch.push(
        this.http.get('/api/getCall?' + str, { responseType: 'json' }).pipe(
          map(this.extractData),
          catchError((e) =>
            this.utils.handleError(
              `dashBoard-api-calls.service.ts callAPiDashBoard: ${e.message}`
            )
          )
        )
      );
    });
    return forkJoin(observableBatch);
  }
  /* APi endpoint to change to new school site*/
  changeAccountToNewSite(userData) {
    try {
      return this.http.put(
        `/favDashboard/changeAccountToNewSite?isForCis360=true&lang=en`,
        userData,
        this.httpOptions
      );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts changeAccountToNewSite: exception ${e.message}`
      );
    }
  }
  // * Api endpoint to update the Account details
  updateAccountUserData(updatedAccountData) {
    try {
      return this.http.put(
        `/favDashboard/updateAccountUserData?isForCis360=true&lang=en`,
        updatedAccountData,
        this.httpOptions
      );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts updateAccountUserData: exception ${e.message}`
      );
    }
  }
  // * Api endpoint to create the Account details
  createAccountUserData(createdAccountData) {
    try {
      //console.log("This is the createAccount call",createdAccountData)
      return this.http.post(
        `/favDashboard/createAccountUserData?isForCis360=true&lang=en`,
        createdAccountData,
        this.httpOptions
      );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts createAccountUserData: exception ${e.message}`
      );
    }
  }

  public extractData = function (res) {
    const body = res;
    // console.log('succes bodey-->' + JSON.stringify(body));
    eventTime = body.eventTime;
    window.localStorage.setItem('formioToken', body.sessionID);

    // this was overriding the post auth JWT.
    // @todo investigate possible use casses for setting auth_key here.
    //this.storageService.sessionStorageSet('auth_key', body.sessionID);
    this.storageService.sessionStorageSet('auth_key', body.sessionID);
    try {
      this.extendTimeForUser.entendTimeForUser(res);
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts extractData: exception ${e.message}`
      );
    }

    return body || {};
  }.bind(this);

  public extractDataWithParsing = function (res) {
    let body = { eventTime: '', sessionID: '', result: '' };
    try {
      body = JSON.parse(res);
      if (body.result && body.result === 'jwt expired') {
        this.utils.log('dashboard-api-call.service.ts extractData jwt expired:;', 'debug').subscribe();
      }
    }
    catch (e) {
      this.utils.log(`dashboard-api-call.service.ts extractData parse failure: ${res}`, 'debug').subscribe();
      throw e;
    }
    // eventTime = body.eventTime;
    // StorageService.staticSessionStorageSet('auth_key', body.sessionID);
    // try {
    //     this.extendTimeForUser.entendTimeForUser(body);
    // } catch (e) {
    //     console.log('exc==>' + e.message);
    // }


    return body || {};
  }.bind(this);

  sessionExpiry() {
    this.sessionExpired();
  }

  sessionExpired = () => {
    // @todo hit logout route here with jwt?  If the server redirects then pass logoutURL too
    this.utils.sessionExpired();
    /*this.utils.sessionStorageSet('sessionExp', 'true');

        let logoutURL = this.utils.sessionStorageGet('LogoutURL');
        if (logoutURL != '' && logoutURL != null && logoutURL != undefined) {
            this.router.navigateByUrl(logoutURL);
        }
        else {
            this.router.navigateByUrl('login/loginForm?status=expired');// window.location.href = 'login/loginForm?status=expired';
        }*/
  };

  getEncryptKey() {
    try {
      return this.http.get('/favDashboard/getEncryptKey');
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getEncryptKey: ${e.message}`
      );
    }
  }

  getPracticeTestCateogry(testType: number): Observable<PracticeTestType> {
    try {
      if (testType < 0 || testType > 3) {
        testType = 0;
      }
      return this.http.get<PracticeTestType>(
        `/favDashboard/getPracTestCategory/${testType}`
      );
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getPracticeTestCategory: exception ${e.message}`
      );
    }
  }

  getPracticeTestAccessUrl(
    testCode: number
  ): Observable<PracticeTestAccessUrlResponse> {
    try {
      if (testCode < 0) {
        testCode = 0;
      }
      return this.http.get<PracticeTestAccessUrlResponse>(
        `/favDashboard/getPracTestUserRoute/${testCode}`
      );
      /**PLEASE KEEP this code for testprep testing  */
      // return this.message2$;
    } catch (e) {
      this.utils.handleError(
        `dashBoard-api-calls.service.ts getPracticeTestAccessUrl: ${e.message}`
      );
    }
  }
}
