<div style="min-height:650px;">
    <div class="topSection">
        <div class="assessment-header">
            <div class="common-headding mt-2 d-flex align-items-center" #interviewHeaderFocus>
                <span><img alt="job-interview-icon" src="{{landingImageUrl}}interviews.png"></span>
                <span class="title-wraps ml-2">{{jobInterviewIntroLangSet.header}}</span>
            </div>
            <div class="clear-fixed mb-2"> </div>
            <div class="hr-line"></div>
        </div>
        <div *ngIf='true'>
            <img alt="header-career-image" src='https://images.intocareers.org/JS/JS_Interview.jpg'
                class="w-100">
            <div class="opacity-career-header row">
                <!--video popup-->
                <!-- <div class="col-lg-5  col-2 col-md-6 col-sm-2">
                    <a class="video-icon" href="#myModal" data-toggle="modal" data-target="#myModal" [hidden]="((occCareerStore|async)?.Video|json) == '[]'">
                          <img class="video-hight-plp3 pointer" src="/assets/images/video-icon-plp3.png" alt="" />
                        </a>
                    <ng-template #tipContent>{{jobInterviewVideoUrl}}</ng-template>
                  
                </div> -->
                <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal"
                    aria-hidden="true" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header blue">
                                <button type="button" class="close plp3-model-close-align" (click)="stopVideo()"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body">
                                <video *ngIf="jonInterviewCaptionUrl; else notrack" crossorigin="anonymous" disablePictureInPicture class="video-box"
                                    controls controlsList="nodownload" #videoplayer>
                                    <source src="{{jobInterviewVideoUrl}}" />
                                    <track src="{{jobInterviewCaptionUrl}}" type="text/vtt" kind="subtitles"
                                        srclang="en" label="On" default />
                                </video>
                                <ng-template #notrack>
                                    <video class="video-box" disablePictureInPicture controls controlsList="nodownload"
                                        #videoplayer>
                                        <source src="{{jobInterviewVideoUrl}}" />
                                    </video>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Breif intro under image-->
    <div class="w-100 mt-2 mb-3">
        <p *ngIf="!hasError">{{jobInterviewIntroLangSet.introduction}}</p>

    </div>

    <!--start layout panels  -->
    <div *ngIf="jobInterviewPanelLangSet && jobInterviewPanelLangSet.length<=4 && !hasError">
        <div *ngFor="let panel of jobInterviewPanelLangSet " class="career-card {{panel.tabColorClass}}">
            <div class="career-card-header pointer" role="tab" id="headingThree"
                (click)="(openPanel != panel.tabId) ? openCloseAction(panel.tabId): openCloseAction('all')">
                <div class="card-title align-items-center">
                    <img src='{{panel.iconUrl}}' class="ml-1 mr-2" alt="job interview prepare" aria-hidden />
                    <span class="w-100" tabindex="0"
                        (keyup.enter)="(openPanel != panel.tabId) ? openCloseAction(): openCloseAction('none')">
                        {{panel.title}}</span>
                </div>
                <div class="d-table-cell align-middle">
                    <button class="common-button default m-0"
                        [attr.aria-label]="(openPanel != panel.tabId)? 'down rounded arrow': 'up rounded arrow'">
                        <i [ngClass]="{'icon-down-rounded-arrow':(educationExpand != panel.tabId), 'icon-up-rounded-arrow': (educationExpand == panel.tabId)}"
                            class="icn-font-size"></i>
                    </button>
                </div>
            </div>

            <div id="{{panel.tabId}}" *ngIf="openPanel == panel.tabId" role="tabpanel" aria-labelledby="headingThree">
                <div class="card-block">
                    {{panel.description}}
                    <div *ngIf="panel.videoUrl && panel.videoUrl.length>0 ">
                        <ng-template #tipContent>Video</ng-template>
                        <a class="video-icon" href="#myModal" data-toggle="modal" data-target="#myModal" [hidden]="false" #t="ngbTooltip"
                            (blur)='t.close()' placement="top" [ngbTooltip]="tipContent"
                            (click)="playOrSwitchVideo(panel.tabId)">
                            <img class="video-hight-plp3 pointer videoIcon" src="/assets/images/video-icon-plp3.png" alt="" />
                        
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
<div *ngIf="hasError" style="color:darkred;">
    An error occured, please try again.
</div>
</div>