import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared-module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { OccDetailStaticHeaderComponent } from './careersJr-detail-header-component';
import { CareersJrIndexComponent } from './careersJr-shared/careersJr-index/careersJr-index-component';
import { CareersJrDetailsRoutingModule } from './careersJr-details-routing';
import { CareersJrCompareComponent } from './careersJr-compare/careersJr-compare.component';
import { CareersJrCommonMethodsService } from './careersJr-services/careersJr-common-methods.service';
import { CareersJrDispatchService } from './careersJr-services/careersJr-dispatch.service';
import { CareersJrApiCallService } from './careersJr-services/careersJr-apicalls.service';
import { CareersJrApiCallClass } from './careersJr-constants/model';
import { CareersJrSnackBar, CareersJrModalPopup } from './careersJr-modal/careersJr-modal-component';
import { EffectsModule } from '@ngrx/effects';
import { OccEffects } from './careersJr-state-management/careersJr-effects/careersJr-effects';
// import { AuthInterceptor } from '../../../shared/outer-services/authentication-interceptors';
import {StaticCareersJrFooterComponent} from './careersJr-shared/careersJr-common-footer/careersJr-footer-component';
import { StoreModule } from '@ngrx/store';
import { ChartsModule } from 'ng2-charts';
import {
  CareersJr_Index_Reducer_Text_Reducer,
  CareersJr_Settings_Tab_Text_Reducer,
  CareersJr_EmpOutlook_Wages_Reducer,
  CareersJr_Buttons_Reducer_Text,
  Occ_Jr_Assessment_Filter_Reducer,
  CareersJr_AtGlance_Reducer,
  CareersJr_ThingsToknow_Reducer,
  CareersJr_Employment_Reducer,
  CareersJr_Education_Reducer,
  CareersJr_RelatedCareers_Reducer,
} from './careersJr-state-management/careersJr-reducer/careersJr-reducer';
import { FrameworkConfigReducer } from '../../../state-management/reducers/main-reducer';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CareersJrHeaderComponent } from './careersJr-shared/careersJr-common-header/careersJr-header-component';
import { CareersJrEmergHeaderComponent } from './careersJr-shared/careersJr-common-header/emergCareersJr-header-component';
import { AtAGlanceEmergingCareersJrComponent } from './careersJr-emerging/at-a-glance/at-a-glance-emerging-careersJr.component';
import { EducationEmergingCareersJrComponent } from './careersJr-emerging/education/education-emerging-careersJr.component';
import { RelatedCareersJrEmergComponent } from './careersJr-emerging/related-careers/related-emerging-careers.component';
import { ThingsToKnowEmergingCareersJrComponent } from './careersJr-emerging/things-to-know/things-to-know.component';

import { AtAGlanceCareersJrComponent } from './careersJr-careers/at-a-glance/at-a-glance-careersJr.component';
import { EducationCareersJrComponent } from './careersJr-careers/education/education-careersJr.component';
import { EmploymentCareersJrComponent } from './careersJr-careers/employment/employment-careersJr.component';
import { RelatedCareersJrComponent } from './careersJr-careers/related-careers/related-careersJr-component';
import { ThingsToKnowCareersJrComponent } from './careersJr-careers/things-to-know/things-to-know.component';
import { WagesCareersJrComponent } from './careersJr-careers/wages/wages-careersJr.component';
import { CareersJrClusterHeaderComponent } from './careersJr-shared/careersJr-common-header/careersJr-cluster-header-component';
import { ThingsToKnowClusterCareersJrComponent } from './careersJr-cluster/things-to-know/things-to-know-cluster.component';
import { RelatedCareersJrClusterComponent } from './careersJr-cluster/related-careers/related-cluster-careers.component';
import { EducationClusterCareersJrComponent } from './careersJr-cluster/education/education-cluster-careersJr.component';
import { AtAGlanceClusterCareersJrComponent } from './careersJr-cluster/at-a-glance/at-a-glance-cluster-careersJr.component';
@NgModule({
  imports: [
    // HttpClientModule,
    FormsModule,
    CommonModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    CareersJrDetailsRoutingModule,
    ChartsModule,
    EffectsModule.forRoot([OccEffects]),
    StoreModule.forRoot({
      CareersJr_Index_Reducer_Text: CareersJr_Index_Reducer_Text_Reducer,
      CareersJr_Settings_Tab_Text: CareersJr_Settings_Tab_Text_Reducer,
      CareersJr_EmpOutlook_Wages: CareersJr_EmpOutlook_Wages_Reducer,
      CareersJr_Buttons_Reducer_Text: CareersJr_Buttons_Reducer_Text,
      Occ_Jr_Assessment_Filter_Reducer: Occ_Jr_Assessment_Filter_Reducer,
      config: FrameworkConfigReducer,
      CareersJr_Detail_AtGlance_Selector: CareersJr_AtGlance_Reducer,
      CareersJr_Detail_ThingsToKnow_Selector: CareersJr_ThingsToknow_Reducer,
      CareersJr_Detail_Employment_Selector: CareersJr_Employment_Reducer,
      CareersJr_Detail_Education_Selector: CareersJr_Education_Reducer,
      CareersJr_Detail_RelatedCareers_Selector:
        CareersJr_RelatedCareers_Reducer,
    }),
  ],
  declarations: [
    CareersJrModalPopup,
    CareersJrSnackBar,
    OccDetailStaticHeaderComponent,
    CareersJrIndexComponent,
    CareersJrCompareComponent,
    StaticCareersJrFooterComponent,
    CareersJrHeaderComponent,
    CareersJrEmergHeaderComponent,
    AtAGlanceEmergingCareersJrComponent,
    EducationEmergingCareersJrComponent,
    RelatedCareersJrEmergComponent,
    ThingsToKnowEmergingCareersJrComponent,
    AtAGlanceCareersJrComponent,
    EducationCareersJrComponent,
    EmploymentCareersJrComponent,
    RelatedCareersJrComponent,
    ThingsToKnowCareersJrComponent,
    WagesCareersJrComponent,
    CareersJrClusterHeaderComponent,
    ThingsToKnowClusterCareersJrComponent,
    RelatedCareersJrClusterComponent,
    EducationClusterCareersJrComponent,
    AtAGlanceClusterCareersJrComponent,
  ],
  providers: [
    CareersJrApiCallService,
    CareersJrDispatchService,
    CareersJrApiCallClass,
    CareersJrCommonMethodsService,
    DeviceDetectorService,
    // {
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: OccAuthInterceptor,
    //     multi: true
    // }
  ],
  entryComponents: [CareersJrModalPopup, CareersJrSnackBar],
})
export class CareersJrDetailModule {}
