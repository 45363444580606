<div role="tablist" aria-multiselectable="true">
  <!-- Displays overview card -->
  <div class="career-card cardcolor-5" *ngIf='(settingsTextTab?.sections).indexOf("Overview")!= -1'>
    <div class="career-card-header  pointer" role="tab" id="headingOne" activeIds="1" (click)="(expandCard != 6)?methodfilter(6):methodfilter(999)">
      <div class="card-title">
        <i class="icon-overview panal-icon-align" aria-hidden="true"></i>
        <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.Overview}}</span>
      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 6)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 6)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="expandCard == 6" class="icon-up-rounded-arrow icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="1" #filter1 *ngIf="expandCard == 6" role="tabpanel" aria-labelledby="headingOne">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore|async)?.Overview">
          <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"></p>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Displays Job task card-->
  <div class="career-card cardcolor-12" *ngIf='(settingsTextTab?.sections).indexOf("TaskList")!= -1'>
    <div class="career-card-header  pointer" role="tab" id="headingThree" (click)="(expandCard != 5)?methodfilter(5):methodfilter(999)">
      <div class="card-title">
        <i class="icon-job-list panal-icon-align pb-1" aria-hidden="true"></i>
        <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TaskList}}</span>
      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 5)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 5)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="expandCard == 5" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="5" #filter3 *ngIf="expandCard == 5" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore|async)?.TaskList">
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Displays Skills & Abilities card-->
  <div class="career-card cardcolor-9" *ngIf='(settingsTextTab?.sections).indexOf("SkillsAbilities")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingThree" (click)="(expandCard != 3)?methodfilter(3):methodfilter(999)">
      <div class="card-title">
        <i class="icon-skills-abilities panal-icon-align pb-1" aria-hidden="true"></i>
        <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.SkillsAbilities}}</span>

      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 3)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 3)" class="icon-down-rounded-arrow  icn-font-size"></i>
          <i *ngIf="expandCard == 3" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="3" #filter3 *ngIf="expandCard == 3" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore|async)?.SkillsAbilities">
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Displays Work Setting card-->
  <div class="career-card cardcolor-11" *ngIf='(settingsTextTab?.sections).indexOf("WorkingConditions")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingTwo" (click)="(expandCard != 2)?methodfilter(2):methodfilter(999)">
      <div class="card-title">

        <i class="icon-work-setting panal-icon-align pb-2" aria-hidden="true"></i>

        <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.WorkingConditions}}</span>
      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 2)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 2)" class="icon-down-rounded-arrow  icn-font-size"></i>
          <i *ngIf="expandCard == 2" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="2" #filter2 *ngIf="expandCard == 2" role="tabpanel" aria-labelledby="headingTwo">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore|async)?.WorkingConditions">
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Displays  Physical Demands card-->
  <div class="career-card cardcolor-6" *ngIf='(settingsTextTab?.sections).indexOf("PhysicalDemands")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingThree" (click)="(expandCard != 4)?methodfilter(4):methodfilter(999)">
      <div class="card-title">
        <i class="icon-physical-demands panal-icon-align pb-1" aria-hidden="true"></i>
        <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.PhysicalDemands}}</span>
      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 4)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 4)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="expandCard == 4" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="4" #filter3 *ngIf="expandCard == 4" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore|async)?.PhysicalDemands">
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--Displays More Description section-->
  <!--rebuilding the issue-->
<div *ngIf='(settingsTextTab?.sections).indexOf("MoreDescription")!= -1'>
  <div *ngIf="(occCareerStore|async)?.MoreDescription?.length!=0">
  <div *ngFor="let value of (occCareerStore|async)?.MoreDescription; let inx1=index;let first=first;" (click)="methodfilter(inx1)">
    <!-- <ng-template #tipContentfly>
            {{clus.flyover}}
        </ng-template> -->
        <div class="career-card cardcolor-28 mb-2">
            <div class="career-card-header">
                <!-- <span [ngClass]="programsValue['cls_'+clus.clusterID].clusterIconValue"></span> -->
                <img src="{{CareersImages}}moredescription.png"/>
                <span class="card-title pl-2"  tabindex="0">{{value.title}}</span>
                <button class="common-button default m-0" [attr.aria-label]="(descriptionExpand != inx1)? 'down rounded arrow': 'up rounded arrow'">
                    <i *ngIf="(descriptionExpand != inx1)" class="icon-down-rounded-arrow icn-font-size"></i>
                    <i *ngIf="(descriptionExpand == inx1)" class="icon-up-rounded-arrow icn-font-size"></i>
                </button>
            </div>
            <div class="card-block" id="value{{inx1}}" *ngIf="(descriptionExpand ==inx1)">
            <div *ngFor="let val of value.lines">
              <p class="px-3" *ngIf="val.type == 'p'" [innerHTML]='val.text | MarkdownToLink'></p>
              <div class="py-2 px-3 font-weight-bold" *ngIf="(val.type == 'h')" [innerHtml]="val.text | MarkdownToLink"></div>
               <div class="list-table px-3" *ngIf="(val.type == 'table')">
                 <div class="alternative-list-color" *ngFor="let list of val.text">
                   <div class="row list-head p-1" *ngIf="(list.type == 'head')">
                    <div class="col-6 font-weight-bold" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink"></div>
                   </div>
                   <div class="row sub-list-color p-1" *ngIf="(list.type == 'row')">
                    <div class="col-6" *ngFor="let text of list.text" [innerHtml]="text | MarkdownToLink">
                     </div>
                   </div>
                 </div>
               </div>
             </div>
            </div>
        </div>
</div>
</div>

</div>
</div>
<!-- print Start Job desc -->
<!-- Start Header -->
<div class="hidden-page">
  <div class="print-result-page" #atJobdescPrintPage> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-occresize-sprite"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{logo}}" > 
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <!-- Displays overview card -->
      <div class="career-card text-dark" *ngIf='(settingsTextTab?.sections).indexOf("Overview")!= -1'>
        <div class="cardcolor-5"> 
          <div class="career-card-header">
            <div class="card-title">
              <div class="icon-overview-sprite panal-icon-align" aria-hidden="true"></div>
              <span class="w-100" tabindex="0">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.Overview}}</span>
            </div>      
          </div>
        </div>              
        <div class="pt-2 pl-3">
          <div *ngFor="let value of (occCareerStore|async)?.Overview">
            <p class="mb-2" *ngIf="(value.type == 'p')">{{value.text}}</p>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled">
              <li *ngFor="let val of value.text">
                <div class="headding-arrow pl-2 mb-1">{{val}}</div>
              </li>
            </ul>
            <div class='header_6' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
          </div>
        </div>          
      </div> 
      <div class="html2pdf__page-break"></div> 
      <!-- Displays Job task card-->
      <div class="career-card text-dark mt-1" *ngIf='(settingsTextTab?.sections).indexOf("TaskList")!= -1'>
          <div class="cardcolor-12 mt-3">
            <div class="career-card-header">
              <div class="card-title">
                <div class="icon-job_list-sprite panal-icon-align" aria-hidden="true"></div>
                <span class="w-100" tabindex="0"> {{(settingsText).Occ_Settings_Tab_Text?.allSections?.TaskList}}</span>
              </div>        
            </div>
          </div>
          <div>
            <div class="pt-2 pl-3">
              <div class="career-indent" *ngFor="let value of (occCareerStore|async)?.TaskList">
                <div class='header_6 pl-3' *ngIf="(value.type == 'h')">
                  <b>{{value.text}}</b>
                </div>
                <ul *ngIf="(value.type == 'ul')" class="list-unstyled mb-2 pl-3">
                  <li *ngFor="let val of value.text">
                    <div class="headding-arrow pl-2 mb-1">{{val}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>
      <div class="html2pdf__page-break"></div>
      <!-- Displays Skills & Abilities card-->
      <div class="career-card text-dark mt-1" *ngIf='(settingsTextTab?.sections).indexOf("SkillsAbilities")!= -1'>
        <div class="cardcolor-9 mt-3">
          <div class="career-card-header cardcolor-9">
            <div class="card-title">        
              <div class="icon-skills_abilities-sprite panal-icon-align" aria-hidden="true"></div>          
              <span class="w-100">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.SkillsAbilities}}</span>          
            </div>          
          </div>
        </div>
  
        <div class="pt-2 pl-3">
          <div *ngFor="let value of (occCareerStore|async)?.SkillsAbilities">
            <div class='header_6 break-avoid' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled mb-1">
              <li *ngFor="let val of value.text;let inx=index">
                <div class="headding-arrow pl-2">{{val}}</div>
              </li>
            </ul>
          </div>
        </div>            
      </div>
      <div class="html2pdf__page-break"></div>
      <!-- Displays Work Setting card-->
      <div class="career-card text-dark mt-1" *ngIf='(settingsTextTab?.sections).indexOf("WorkingConditions")!= -1'>
        <div class="cardcolor-11 mt-3">
          <div class="career-card-header">
            <div class="card-title">          
              <div class="icon-work_setting-sprite panal-icon-align" aria-hidden="true"></div>          
              <span class="w-100">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.WorkingConditions}}</span>
            </div>          
          </div>
        </div>          
        <div class="pt-2 pl-3">
          <div class="career-indent" *ngFor="let value of (occCareerStore|async)?.WorkingConditions">
            <div class='header_6 pb-1 pl-3' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled mb-2 pl-3">
              <li *ngFor="let val of value.text">
                <div class="headding-arrow pl-2 mb-1">{{val}}</div>
              </li>
            </ul>
          </div>
        </div>            
      </div>   
      <div class="html2pdf__page-break"></div> 
      <!-- Displays  Physical Demands card-->
      <div class="career-card text-dark mt-1" *ngIf='(settingsTextTab?.sections).indexOf("PhysicalDemands")!= -1'>
        <div class="cardcolor-6 mt-3">
          <div class="career-card-header">
            <div class="card-title">
              <div class="icon-physical_demands-sprite panal-icon-align" aria-hidden="true"></div>
              <span class="w-100" tabindex="0">{{(settingsText).Occ_Settings_Tab_Text?.allSections?.PhysicalDemands}}</span>
            </div>          
          </div>
        </div>
      
        <div class="pt-2 pl-3">
          <div class="career-indent" *ngFor="let value of (occCareerStore|async)?.PhysicalDemands">
            <div class='header_6 pl-3' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled mb-2 pl-3">
              <li *ngFor="let val of value.text">
                <div class="headding-arrow pl-2 mb-1">{{val}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div> 
      <!--MoreDescription section Print code-->  
      <div *ngIf='(settingsTextTab?.sections).indexOf("MoreDescription")!= -1'>
        <div *ngIf="(occCareerStore|async)?.MoreDescription?.length!=0">
        <div *ngFor="let value of (occCareerStore|async)?.MoreDescription; let inx1=index;let first=first;">
              <div class="career-card cardcolor-28 mb-3 mt-3">
                  <div class="career-card-header">
                    <div class="icon-moredescription-sprite" aria-hidden="true"></div>
                      <span class="card-title pl-2"  tabindex="0">{{value.title}}</span>
                  </div>
              </div>
                  <div class="card-body pl-3 py-1" id="value{{inx1}}">
                  <div *ngFor="let val of value.lines">
                    <p class="mb-0 text-secondary" *ngIf="val.type == 'p'" [innerHTML]='val.text | PrintWeblinkPipe'></p>
                     <div class="py-2 font-weight-bold" *ngIf="(val.type == 'h')"> {{val.text}}</div>
                     <div class="list-table px-3" *ngIf="(val.type == 'table')">
                       <div class="alternative-list-color" *ngFor="let list of val.text">
                         <div class="row list-head p-1" *ngIf="(list.type == 'head')">
                           <div class="col-6 font-weight-bold" *ngFor="let text of list.text">{{text}}</div>
                         </div>
                         <div class="row sub-list-color p-1" *ngIf="(list.type == 'row')">
                           <div class="col-6 pb-1 mb-0" *ngFor="let text of list.text">
                             {{text}}
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                  </div>
      </div>
      </div>
      
      </div>       
    </div>
    <div class="mt-2 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!--Job Description print Ending-->
