<p *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers")!= -1' class="header_5 header-text-color"><b> {{(occCareerStore).RelatedCareers?.intro}}</b></p>
<div class="row gutter-10" *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers")!= -1'>
  <!-- Display  Careers card with list of careers -->
  <div *ngIf='(occCareerStore)?.RelatedCareers?.lists[0]?.links.length != 0'class="col-lg col-md col-12">
    <div class="career-card cardcolor-25">
      <div class="career-card-header">
        <div class="card-title">
          <i class="icon-careers  panal-icon-align" aria-hidden="true"></i> {{(occCareerStore)?.RelatedCareers?.lists[0]?.header}}
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <ul class="list pr-4">
          <li *ngFor="let val of (occCareerStore)?.RelatedCareers?.lists[0]?.links">
            <a href="#" class="pointer" (click)="$event.preventDefault();CallOccDetailCareer(val.linkID,val.linkTitle)">{{val.linkTitle}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Display  cluster card with cluster link -->
  <div class="col-lg col-md col-12">
    <div class="career-card" [ngStyle]="{'background-color': (occCareerStore)?.ParentCluster?.parentClusterColor}">
      <div class="career-card-header">
        <div class="card-title">
          <i class="{{(occCareerStore).ParentCluster?.parentClusterIcon}}  panal-icon-align" aria-hidden="true"></i> {{(occCareerStore)?.RelatedCareers?.lists[1]?.header}}
        </div>
      </div>
      <div class="card-block career-card-body scroll-class">
        <ul class="list pr-4">
          <li *ngFor="let value of (occCareerStore)?.RelatedCareers?.lists[1]?.links">
            <a href="#" class="pointer" (click)="$event.preventDefault();CallOccDetailCluster(value.linkID,value.linkTitle)">{{value.linkTitle}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<br>
 <!-- Print Start Related Careers -->
<!-- Start Header -->
<div class="hidden-page">
  <div class="print-result-page" #RelatedCarees> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-careersJr-sprite mr-1"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{logo}}" width="50%"> 
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div> 
      <p *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers")!= -1' class="header_5 header-text-color"><b> {{(occCareerStore).RelatedCareers?.intro}}</b></p>
      <div class="row gutter-10" *ngIf='(settingsTextTab?.sections).indexOf("RelatedCareers")!= -1'>
          <!-- Display  Careers card with list of careers -->
          <div  *ngIf='(occCareerStore)?.RelatedCareers?.lists[0]?.links.length != 0'class="col-12">
            <div class="career-card ">
              <div class="cardcolor-25 mb-3"> 
              <div class="career-card-header">
                <div class="card-title pre">
                  <div class="icon-careers-sprite panal-icon-align" aria-hidden="true"></div> {{(occCareerStore)?.RelatedCareers?.lists[0]?.header}}
                </div>
              </div>
            </div>
              <div class="card-block p-0 ">
                <ul class="list-unstyled pr-4 text-dark">
                  <li class="text-dark" *ngFor="let val of (occCareerStore)?.RelatedCareers?.lists[0]?.links">
                    <div class="text-dark headding-arrow list">{{val.linkTitle}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Display  cluster card with cluster link -->
          <div class="col-12">
            <div class="career-card">
              <div [ngStyle]="{'background':(occCareerStore).ParentCluster?.parentClusterColor}" class="mb-2"> 
              <div class="career-card-header">
                <div class="card-title pre">                  
                  <div class="icon-clu_human_services-sprite panal-icon-align" aria-hidden="true"></div>
                  {{(occCareerStore)?.RelatedCareers?.lists[1]?.header}}
                </div>
              </div>
            </div>
              <div class="card-block p-0">
                <ul class="list-unstyled pr-4 text-dark">
                  <li class="text-dark" *ngFor="let value of (occCareerStore)?.RelatedCareers?.lists[1]?.links">
                    <div class="text-dark headding-arrow list">{{value.linkTitle}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>    
        </div>
    </div>
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!-- Print Start Related Careers -->