<div role="tablist" aria-multiselectable="true">
    <p class="header_5 header-text-color"><b>{{(occCareerStore).ThingsToKnowQuestion}}</b></p>
  <!-- Displays overview card -->
  <div class="career-card cardcolor-5" *ngIf='(settingsTextTab?.sections).indexOf("Overview")!= -1'>
    <div class="career-card-header  pointer" role="tab" id="headingOne" activeIds="1" (click)="(expandCard != 6)?methodfilter(6):methodfilter(999)">
      <div class="card-title">
        <i class="icon-overview panal-icon-align" aria-hidden="true"></i>
        <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Overview}}</span>
      </div>
      <div class="d-table-cell align-middle ">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 6)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 6)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="expandCard == 6" class="icon-up-rounded-arrow icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="1" #filter1 *ngIf="expandCard == 6" role="tabpanel" aria-labelledby="headingOne">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore)?.Overview">
          <p *ngIf="(value.type == 'p')" [innerHtml]="value.text | MarkdownToLink"> </p>
          <ul *ngIf="(value.type == 'ul')" class="list pr-4">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Displays TaskList card-->
  <div class="career-card cardcolor-12" *ngIf='(settingsTextTab?.sections).indexOf("TaskList")!= -1'>
    <div class="career-card-header  pointer" role="tab" id="headingThree" (click)="(expandCard != 5)?methodfilter(5):methodfilter(999)">
      <div class="card-title">
        <i class="icon-job-list panal-icon-align" aria-hidden="true"></i>
        <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.TaskList}}</span>
      </div>
      <div class="d-table-cell align-middle ">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 5)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 5)" class="icon-down-rounded-arrow icn-font-size"></i>
          <i *ngIf="expandCard == 5" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="5" #filter3 *ngIf="expandCard == 5" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore)?.TaskList">
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list pr-4">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Displays SkillsAbilities card-->
  <div class="career-card cardcolor-9" *ngIf='(settingsTextTab?.sections).indexOf("SkillsAbilities")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingThree" (click)="(expandCard != 3)?methodfilter(3):methodfilter(999)">
      <div class="card-title">

        <i class="icon-skills-abilities panal-icon-align" aria-hidden="true"></i>

        <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.SkillsAbilities}}</span>

      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 3)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 3)" class="icon-down-rounded-arrow  icn-font-size"></i>
          <i *ngIf="expandCard == 3" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="3" #filter3 *ngIf="expandCard == 3" role="tabpanel" aria-labelledby="headingThree">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore)?.SkillsAbilities">
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list pr-4">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Displays WorkingConditions card-->
  <div class="career-card cardcolor-11" *ngIf='(settingsTextTab?.sections).indexOf("WorkingConditions")!= -1'>
    <div class="career-card-header pointer" role="tab" id="headingTwo" (click)="(expandCard != 2)?methodfilter(2):methodfilter(999)">
      <div class="card-title">

        <i class="icon-work-setting panal-icon-align" aria-hidden="true"></i>

        <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.WorkingConditions}}</span>
      </div>
      <div class="d-table-cell align-middle">
        <button class="common-button default m-0" [attr.aria-label]="(expandCard != 2)? 'down rounded arrow': 'up rounded arrow'">
          <i *ngIf="(expandCard != 2)" class="icon-down-rounded-arrow  icn-font-size"></i>
          <i *ngIf="expandCard == 2" class="icon-up-rounded-arrow  icn-font-size"></i>
        </button>
      </div>
    </div>
    <div id="2" #filter2 *ngIf="expandCard == 2" role="tabpanel" aria-labelledby="headingTwo">
      <div class="card-block">
        <div *ngFor="let value of (occCareerStore)?.WorkingConditions">
          <div class='header_6 text-white' *ngIf="(value.type == 'h')">
            <b [innerHtml]="value.text | MarkdownToLink"></b>
          </div>
          <ul *ngIf="(value.type == 'ul')" class="list pr-4">
            <li *ngFor="let val of value.text">
              <div [innerHtml]="val | MarkdownToLink"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- print Start Job desc -->
<!-- Start Header -->
<div class="hidden-page">
  <div class="print-result-page" #atJobdescPrintPage> 
    <div class="header d-flex align-items-center justify-content-between border-bottom">
      <div class="common-headding d-flex align-items-center">
        <div class="icon-careersJr-sprite mr-1"></div>
        <span>{{careerTitle}}</span>
      </div>
      <div class="print-header-logo">
        <img alt="Career Information System-logo" class="float-right" src="{{logo}}" width="50%"> 
      </div>
    </div>
    <div class="main-body white-space">
      <div class="careers-title">{{tabTitle}}</div>
      <p class="header_5 header-text-color"><b>{{(occCareerStore).ThingsToKnowQuestion}}</b></p>
      <!-- Displays overview card -->
      <div class="career-card text-dark" *ngIf='(settingsTextTab?.sections).indexOf("Overview")!= -1'>
        <div class="cardcolor-5"> 
          <div class="career-card-header">
            <div class="card-title">
              <div class="icon-overview-sprite panal-icon-align" aria-hidden="true"></div>
              <span class="w-100" tabindex="0">{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.Overview}}</span>
            </div>      
          </div>
        </div>              
        <div class="pt-3 pl-3">
          <div *ngFor="let value of (occCareerStore)?.Overview">
            <p *ngIf="(value.type == 'p')">{{value.text}}</p>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4 mb-0">
              <li *ngFor="let val of value.text">
                <div class="headding-arrow pl-2 mb-1">{{val}}</div>
              </li>
            </ul>
            <div class='header_6' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
          </div>
        </div>          
      </div>
      <!-- Displays TaskList card-->
      <div class="career-card text-dark mt-3" *ngIf='(settingsTextTab?.sections).indexOf("TaskList")!= -1'>
          <div class="cardcolor-12">
            <div class="career-card-header">
              <div class="card-title">
                <div class="icon-job_list-sprite panal-icon-align" aria-hidden="true"></div>
                <span class="w-100" tabindex="0"> {{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.TaskList}}</span>
              </div>        
            </div>
          </div>
          <div>
            <div class="pt-3 pl-3">
              <div class="break-avoid" *ngFor="let value of (occCareerStore)?.TaskList">
                <div class='header_6' *ngIf="(value.type == 'h')">
                  <b>{{value.text}}</b>
                </div>
                <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4 mb-2">
                  <li *ngFor="let val of value.text" class="mb-1">
                    <div class="headding-arrow pl-2 mb-1">{{val}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>  
      <!-- Displays Skills & Abilities card-->
      <div class="html2pdf__page-break"></div>
      <div class="career-card text-dark mt-3" *ngIf='(settingsTextTab?.sections).indexOf("SkillsAbilities")!= -1'>
        <div class="cardcolor-9">
          <div class="career-card-header cardcolor-9">
            <div class="card-title">        
              <div class="icon-skills_abilities-sprite panal-icon-align" aria-hidden="true"></div>          
              <span class="w-100">{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.SkillsAbilities}}</span>          
            </div>          
          </div>
        </div>
  
        <div class="pt-3 pl-3">
          <div *ngFor="let value of (occCareerStore)?.SkillsAbilities; let inx=index" 
          [class.html2pdf__page-break]="(inx==4)">
            <div class='header_6 mb-2' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4 mb-2">
              <li *ngFor="let val of value.text;let inx=index" class="mb-1">
                <div class="headding-arrow pl-2 mb-1">{{val}}</div>
              </li>
            </ul>
          </div>
        </div>            
      </div>
      <div class="html2pdf__page-break"></div>
      <!-- Displays TaskList card-->
      <div class="career-card text-dark mt-3" *ngIf='(settingsTextTab?.sections).indexOf("TaskList")!= -1'>
        <div class="cardcolor-11">
          <div class="career-card-header">
            <div class="card-title">          
              <div class="icon-work_setting-sprite panal-icon-align" aria-hidden="true"></div>          
              <span class="w-100">{{(settingsText).CareersJr_Settings_Tab_Text?.allSections?.WorkingConditions}}</span>
            </div>          
          </div>
        </div>          
        <div class="pt-3 pl-3">
          <div class="break-avoid" *ngFor="let value of (occCareerStore)?.WorkingConditions">
            <div class='header_6 pb-1' *ngIf="(value.type == 'h')">
              <b>{{value.text}}</b>
            </div>
            <ul *ngIf="(value.type == 'ul')" class="list-unstyled pr-4 mb-2">
              <li *ngFor="let val of value.text" class="mb-1">
                <div class="headding-arrow pl-2 mb-1">{{val}}</div>
              </li>
            </ul>
          </div>
        </div>            
      </div>       
    </div> 
    <div class="mt-4 pt-4 text-center">
      <p class="pt-2 border-top">{{frameWorkObj.Result.headerFooter.copyright}}</p>
    </div>
  </div>
</div><!--Job Description print Ending-->
